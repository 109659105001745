import { NavLink } from "react-router-dom";
import { triangle } from "polished";
import styled from "styled-components/macro";

const activeclassname = "nav-item-active";

export const NavItem = styled(NavLink).attrs({
  activeclassname
})`
	text-align: center;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	position: relative;
	font-family: ${props => props.theme.typography.type.primary};
	font-size: 14px;
	font-weight: 300;
	letter-spacing: 0.28px;
	background: ${props => props.theme.colors.plain};
	border: none;
	color: ${props => props.theme.colors.secondaryBlue};
	border-bottom: 1px solid ${props => props.theme.colors.grayRegular};
	line-height: 40px;
	&:hover {
		opacity: 0.7;
	}
	&.${activeclassname}, &.active {
		color: ${props => props.theme.colors.plain};
		background: ${props => props.theme.colors.secondaryBlue};
		border-bottom: 1px solid ${props => props.theme.colors.secondaryBlue};
		&:after {
			${props =>
    triangle({
      pointingDirection: "bottom",
      width: "24px",
      height: "12px",
      foregroundColor: props.theme.colors.secondaryBlue
    })}
			transform: translateX(-50%);
			position: absolute;
			z-index: 1;
			content: "";
			top: 100%;
			left: 50%;
		}
	}
`;
