import React from 'react';
import { useFormikContext } from 'formik';
import { Currency, DashForm, DashTextArea } from 'shared-components';
import { Box } from 'atomic-layout';
import 'styled-components/macro';
import { FormInput, FormMessage, LoadingButton, Link, Icon } from '@ffn/ui';
import { ifElse, pipe, always } from 'ramda';
import { format, parseISO } from 'date-fns/fp';
import { useBrand } from 'lib/hooks';
import { PhoneNumber } from 'shared-components/NumberFormatters';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { isNotNil } from 'lib/utils';
import { getTestIds } from 'lib/utils/dataTestIds';
import { useTranslation } from 'lib/hooks';

import styles from './SettlementAuthForm.module.scss';
const notifyTestIDs = getTestIds('notifications');

function Disclaimer({ account }) {
  const { t } = useTranslation();
  if (
    account.feesScheduled === 0 &&
    (account.settlementPercentage === 0 ||
      /1099c/i.test(account.resolutionType))
  ) {
    return (
      <strong>
        {t('alerts.settlementAuth.congratulationsYourAccountWith')}{' '}
        {account.originalCreditor} ***{account.accountNumber}{' '}
        {t('alerts.settlementAuth.willBeResolvedAsA_0SettlementWhich')}
      </strong>
    );
  }

  if (account.feesScheduled === 0 && account.settlementPercentage >= 100) {
    return (
      <strong>
        {t('alerts.settlementAuth.weDidOurBestToNegotiate')}{' '}
        {account.originalCreditor} ***{account.accountNumber}{' '}
        {t('alerts.settlementAuth.accountButUnfortunately')}
      </strong>
    );
  }

  return (
    <strong>
      {t('alerts.settlementAuth.congratulationsWeNegotiated')}{' '}
      {account.originalCreditor} ***
      {account.accountNumber}{' '}
      {t('alerts.settlementAuth.accountPleaseReviewTheDetails')}
    </strong>
  );
}

export function SettlementAuthForm({
  account,
  error,
  showReject,
  setShowReject
}) {
  const brand = useBrand();
  const formikProps = useFormikContext();
  const { t } = useTranslation();

  function getActionText(account) {
    if (
      account.feesScheduled === 0 &&
      (account.settlementPercentage === 0 ||
        /1099c/i.test(account.resolutionType))
    ) {
      return t('alerts.settlementAuth.acknowledgeSettlement');
    }

    return t('alerts.settlementAuth.authorizeSettlement');
  }
  return (
    <DashForm>
      <Disclaimer account={account} />
      {error && (
        <FormMessage
          data-testid="formMessage"
          variant="error"
          css={`
            margin-bottom: 0px;
          `}
        >
          <strong>
            {/agent/i.test(error.message) ? (
              error.message
            ) : (
              <>
                {t('alerts.settlementAuth.thereWasAnErrorProcessing')}{' '}
                <PhoneNumber value={brand('contact.customer-service.phone')} />
              </>
            )}
          </strong>
        </FormMessage>
      )}
      <Box
        padding={24}
        css={`
          background: white;
        `}
        data-testid="settlementAuthFormContainer"
      >
        <Box
          widthMd="50%"
          css={`
            & > * {
              margin-bottom: 16px;
            }
          `}
        >
          <FormInput
            readOnly
            label={t('alerts.settlementAuth.originalCreditor')}
            value={account.originalCreditor}
          />
          <FormInput
            readOnly
            label={t('alerts.settlementAuth.currentCreditor')}
            value={account.creditorName}
          />
          <Currency
            displayType="input"
            customInput={FormInput}
            readOnly
            label={t('alerts.settlementAuth.currentBalance')}
            value={account.currentBalance}
          />
          <Currency
            displayType="input"
            customInput={FormInput}
            readOnly
            label={t('alerts.settlementAuth.settlementAmount')}
            value={account.settlementAmount}
          />
          <FormInput
            readOnly
            label={t('alerts.settlementAuth.numberOfSettlementPayments')}
            value={account.numberOfPayments}
          />
          <FormInput
            readOnly
            label={t('alerts.settlementAuth.firstPaymentDate')}
            value={ifElse(
              isNotNil,
              pipe(parseISO, format('M/d/yyyy')),
              always('')
            )(account.settlementStartDate)}
          />
          <FormInput
            readOnly
            label={t('alerts.settlementAuth.lastPaymentDate')}
            value={ifElse(
              isNotNil,
              pipe(parseISO, format('M/d/yyyy')),
              always('')
            )(account.settlementEndDate)}
          />
        </Box>
        {showReject && (
          <span
            css={`
              color: ${({ theme }) => theme.colors.secondaryRed};
              font-size: 12px;
              font-weight: 600;
            `}
            data-testid="rejectWarningMessage"
          >
            {t('alerts.settlementAuth.areYouSureYouWantToRejectThis')}
            <ul
              css={`
                padding: 0px;
                list-style-position: inside;
                li {
                  padding-left: 16px;
                }
              `}
            >
              {t('alerts.settlementAuth.donTBeConcernedIf')}
              {':'}
              <li>{t('alerts.settlementAuth.thereAreNotEnoughFundsInYour')}</li>
              <li>{t('alerts.settlementAuth.ifTheNumberOfPayments')}</li>
            </ul>
          </span>
        )}
      </Box>
      <Box>
        {showReject ? (
          <div data-testid="rejectReasonInput">
            <strong>
              {t('alerts.settlementAuth.ifYouStillWishToReject')}
              {':'}
            </strong>
            <DashTextArea
              noTouch
              css={`
                margin-top: 8px;
              `}
              id="rejectReason"
              name="rejectReason"
            />
          </div>
        ) : (
          <>
            <p
              css={`
                color: ${({ theme }) => theme.colors.black};
                font-size: 11px;
                line-height: 13px;
                font-weight: 400;
              `}
            >
              {t('alerts.settlementAuth.thisSettlementAndAny')}{' '}
              {account.numberOfPayments} {t('alerts.settlementAuth.payments')}
            </p>
            <p
              css={`
                color: ${({ theme }) => theme.colors.black};
                font-size: 11px;
                line-height: 13px;
                font-weight: 400;
              `}
            >
              {t('alerts.settlementAuth.byAuthorizingThisSettlement')}
            </p>
          </>
        )}
      </Box>
      <Box
        flex
        justifyContent="space-between"
        css={`
          button {
            white-space: nowrap;
          }
        `}
      >
        {showReject ? (
          <>
            <Box flex flexDirection="column">
              <strong>
                {t('alerts.settlementAuth.notSureAndWantToDiscuss')}{' '}
              </strong>
              <Link
                href={`tel:${brand('contact.customer-service.phone')}`}
                data-testid={notifyTestIDs.notification_content_cs_link}
              >
                {t('alerts.settlementAuth.contactCustomerService')}
              </Link>
            </Box>
            <Box flex gap={16}>
              <LoadingButton
                data-testid={notifyTestIDs.notification_content_success_action}
                isLoading={formikProps.isSubmitting}
                variant="primary"
                rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                height={40}
                onClick={() => {
                  formikProps.setFieldValue('rejectSettlement', false);
                  formikProps.submitForm();
                }}
              >
                {getActionText(account)}
              </LoadingButton>
              <LoadingButton
                data-testid={notifyTestIDs.notification_content_reject_action}
                isLoading={formikProps.isSubmitting}
                variant="primary"
                rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                height={40}
                onClick={() => {
                  formikProps.setFieldValue('rejectSettlement', true);
                  formikProps.submitForm();
                }}
              >
                {t('alerts.settlementAuth.rejectSettlement')}
              </LoadingButton>
            </Box>
          </>
        ) : (
          <>
            <Box className={styles.authorizeSectionWrapper}>
              <div className={styles.buttonSection}>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    data-testid={
                      notifyTestIDs.notification_content_success_action
                    }
                    isLoading={formikProps.isSubmitting}
                    variant="primary"
                    rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                    height={40}
                    onClick={() => {
                      formikProps.setFieldValue('rejectSettlement', false);
                      formikProps.submitForm();
                    }}
                  >
                    {getActionText(account)}
                  </LoadingButton>
                </div>
                <div className={styles.link}>
                  <Link
                    variant="tertiary"
                    onClick={() => setShowReject(true)}
                    data-testid={notifyTestIDs.notification_content_reject_link}
                    rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                  >
                    {t('alerts.settlementAuth.rejectSettlement')}
                  </Link>
                </div>
              </div>

              <div className="questionWrapper">
                <strong>{t('alerts.settlementAuth.questions')}</strong>
                <br />
                <Link
                  href={`tel:${brand('contact.customer-service.phone')}`}
                  data-testid={notifyTestIDs.notification_content_cs_link}
                >
                  {t('alerts.settlementAuth.contactCustomerService')}
                </Link>
              </div>
            </Box>
          </>
        )}
      </Box>
    </DashForm>
  );
}
