import { Composition } from 'atomic-layout';
import 'styled-components/macro';
import React from 'react';
import { Text } from '@ffn/ui';
import { UploadOptions } from './utils';
import { CustomPanel } from '../CustomTabs';
import { DropDownOptions } from './shared-components/DropDownOptions';
import { useTranslation } from 'lib/hooks';

const mobileAreas = `
  instructions
  mobileSelectView
`;

const areas = `
  instructions
  desktopSelectView
`;

export function SelectDocType() {
  const { t } = useTranslation();
  return (
    <CustomPanel heading={t('docUpload.uploadDocumentsNeeded')}>
      <Composition areas={mobileAreas} areasSm={areas} gap={16}>
        {({ Instructions, DesktopSelectView, MobileSelectView }) => (
          <>
            <Instructions>
              <Text
                css={`
                  margin-top: 0;
                `}
              >
                {t('docUpload.toMakeSureYourDocuments')}
              </Text>
              <Text>
                <strong>{t('docUpload.tip')}</strong>{' '}
                {t('docUpload.weRecommendGathering')}
              </Text>
            </Instructions>
            <DesktopSelectView>
              <CardOptions />
            </DesktopSelectView>
            <MobileSelectView>
              <DropDownOptions />
            </MobileSelectView>
          </>
        )}
      </Composition>
    </CustomPanel>
  );
}

function CardOptions() {
  return (
    <Composition
      templateCols="repeat(auto-fill, minmax(325px, 1fr))"
      templateRows="repeat(3, 125px)"
      marginTop="16px"
      gap={32}
    >
      <UploadOptions card={true} />
    </Composition>
  );
}
