import { createPagePathString } from './createPagePathString';
import { getPageName } from './getPageName';
/** createTealiumPay load
 * collects information for Tealium
 *
 * @param { object } application_data - application specific data
 * @returns { object } {} - guidelines specified in  "DATA LAYER TECHNICAL SPECIFICATIONS":
  application_version - Dev | 4.0, Stg | 4.0, Prod | 4.0
    page_title - "Enrolled Debt", "Handling Creditors Calls"
    page_name - <application> | <Page Title>
    page_url - Page URL without parameters
    full_page_url - Page URL with parameters
    referring_url - Previous page URL
    url_params - URL Params
    page_path - <application-name> | <url path> 
      EX. FDR Client Dashboard | program-resources: videos: achieving-your-goals
    scroll - % of page scrolled, just number, if whole page is visible without the need of scrolling, pass 100
*/
export const createTealiumPayload = (application_data, appNameString) => {
  // dom attributes to calculate scroll position
  const documentHeight = document?.body?.clientHeight ?? 0;
  const scrollPosition = window?.scrollY;
  const windowHeight = window?.innerHeight ?? 0;

  let {
    referringPath,
    currentPath,
    url_params = '',
    clickText = ''
  } = application_data;

  // currentPath for event tracking, is not passed and is taken from location object
  // currentPath for page tracking, is argument of analyticsTrackPage and passed in application_data
  // page tracking needs page_url to be the current page and not the loading/loaded page
  currentPath = currentPath ?? application_data?.location?.pathname;

  const pageName = getPageName(currentPath);
  const page_path = createPagePathString(currentPath, appNameString, pageName);

  // find if http or https schema
  const schema = document.URL.split('//')[0];

  const referring_url = referringPath
    ? schema + '//' + document.domain + referringPath // schema + // + domain + referring path
    : document.URL; // current page for track event
  const page_url = schema + '//' + document.domain + currentPath; // schema + // + domain + current path

  const full_page_url = page_url + url_params;

  // some click events require the click_text key of the format '<appNameString> | <clickText>
  const click_text = clickText ? appNameString + ' ' + clickText : '';

  const scrollPercentage =
    documentHeight <= windowHeight
      ? 100
      : Math.round(100 * (scrollPosition / (documentHeight - windowHeight)));

  return {
    application_version:
      `${process.env.REACT_APP_TEALIUM_ENV.toUpperCase()} | 4.0` ??
      'DEV | 4.0',
    page_title: pageName ?? '',
    page_name: `${appNameString} ${pageName}` ?? '',
    page_url,
    full_page_url,
    referring_url,
    url_params,
    page_path,
    click_text,
    scroll: String(scrollPercentage) ?? '0'
  };
};
