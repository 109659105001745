import React from 'react';
import { Box, Button, Link, Typography, useTheme } from '@achieve/sunbeam';
import { useBrand, useTranslation } from 'lib/hooks';
import { CongratsSVG } from '../assets/Congrats';
import { colors } from '../shared/constants';
import { getAppSubDomain } from 'shared-components/ProvideDashboardType';
import { analyticsTrackEvent } from 'lib/utils/analytics';

const DASHBOARD_PATH_MAP = {
  achieve: '/dashboard/resolution/dedicated-account',
  fdr: '/dashboard/dedicated-account',
  turnbull: '/dedicated-account'
};

export const Success = ({ testIdPrefix }) => {
  const { t } = useTranslation();
  const subdomain = getAppSubDomain();
  const brand = useBrand();
  const theme = useTheme();
  const phone = brand('contact.customer-service.phone');
  const blue = theme?.palette?.primary?.main ?? '#154199';

  const visitDashboardHref = DASHBOARD_PATH_MAP[subdomain];

  return (
    <Box
      data-testid={`${testIdPrefix}-success-container`}
      display="flex"
      flexDirection="column"
      gap="48px"
      alignItems="center"
      maxWidth="365px"
    >
      <CongratsSVG />
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <Typography
          variant="displayM30"
          fontWeight="500"
          fontSize="28px"
          textAlign="center"
          lineHeight="33.6px"
        >
          {t('oneClick.autoDepositAuth.weReOnIt')}
          <br />
          {t('oneClick.autoDepositAuth.thanksForAuthorizing')}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
      >
        <Typography
          variant="bodyM30"
          fontWeight="300"
          fontSize="16px"
          color={colors.secondaryGrayColor}
        >
          {t('oneClick.autoDepositAuth.whatYouCanDo')}
        </Typography>
        <ul>
          <li>
            <Typography
              variant="bodyM30"
              fontWeight="300"
              fontSize="16px"
              color={colors.secondaryGrayColor}
            >
              {t('oneClick.autoDepositAuth.makeSureYourBank')}
            </Typography>
          </li>
          <li>
            <Typography
              variant="bodyM30"
              fontWeight="300"
              fontSize="16px"
              color={colors.secondaryGrayColor}
            >
              {t('oneClick.autoDepositAuth.checkYourDedicatedAccount')}
            </Typography>
          </li>
        </ul>
      </Box>

      <Button
        data-testid={`${testIdPrefix}-success-visit-dash-button`}
        sx={{ backgroundColor: blue, textTransform: 'none' }}
        size="large"
        onClick={() => {
          analyticsTrackEvent(
            {
              category: 'auto_email_deposit_auth',
              action: 'visit_dashboard_clicked',
              label: 'ras'
            },
            'Visit Dashboard clicked from success page'
          );
        }}
        href={visitDashboardHref}
      >
        {t('oneClick.visitYourDashboard')}
      </Button>
      <Typography
        variant="bodyM30"
        fontWeight="400"
        fontSize="16px"
        textAlign="center"
        color={colors.secondaryGrayColor}
      >
        {t('oneClick.autoDepositAuth.didnTMeanToAuthorizeThisDeposit')}{' '}
        <Link href={`tel:${phone}`}>{phone}</Link>
      </Typography>
    </Box>
  );
};
