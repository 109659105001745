import React from 'react';
import 'styled-components/macro';
import { Composition } from 'atomic-layout';
import { useBrand, useFeatureFlag } from 'lib/hooks';
import { Link } from '@ffn/ui';
import { formatPhoneNumberSafe } from 'lib/utils';
import { useTranslation } from 'lib/hooks';

export const ContactQuestions = () => {
  const brand = useBrand();
  const { featureFlag } = useFeatureFlag();
  const hasChatFeature = featureFlag({ flagKey: 'customer-service.chat' });
  const { t } = useTranslation();

  return (
    <Composition
      areas={`
        Questions
        Options
      `}
      areasMd={`Questions Options`}
      templateCols="1fr"
      templateRows="auto auto"
      templateColsMd="auto 1fr"
      gap={16}
      marginBottom={16}
    >
      {({ Questions, Options }) => (
        <>
          <Questions>
            <strong>{t('common.questions')}</strong>
          </Questions>
          <Options>
            {!hasChatFeature && <>{t('common.callUsAt')} </>}
            <Link
              href={`tel:${brand('contact.customer-service.phone')}`}
              css={`
                display: inline;
              `}
            >
              {formatPhoneNumberSafe(brand('contact.customer-service.phone'))}
            </Link>
            .
          </Options>
        </>
      )}
    </Composition>
  );
};

export default ContactQuestions;
