import React, { useMemo } from 'react';
import { useTranslation } from 'lib/hooks';
import { Info } from 'react-feather';
import { Tooltip, Typography, SvgIcon } from '@achieve/sunbeam';

const TooltipContent = ({ tipKey }) => {
  const { t } = useTranslation();
  const content = useMemo(() => {
    return {
      enrolledBalance: {
        title: t('tooltip.enrolledBalanceTitle'),
        text: t('tooltip.enrolledBalanceText')
      },
      currentBalance: {
        title: t('tooltip.currentBalanceTitle'),
        text: t('tooltip.currentBalanceText')
      },
      estimatedAmount: {
        title: t('tooltip.estimatedAmountTitle'),
        text: t('tooltip.estimatedAmountText')
      }
    };
  }, [t]);

  if (!content[tipKey]) {
    return null;
  }

  return (
    <>
      <Typography
        data-testid={`settlement-tooltip-heading-${tipKey}`}
        variant="ascendBodyXs"
        fontWeight="bold"
        component="h5"
      >
        {content[tipKey].title}
      </Typography>
      <Typography
        data-testid={`settlement-tooltip-text-${tipKey}`}
        variant="ascendBodyXs"
        component="p"
      >
        {content[tipKey].text}
      </Typography>
    </>
  );
};

export function SettlementTooltip({ tipKey }) {
  return (
    <>
      <Tooltip
        data-testid={`settlement-tooltip-${tipKey}`}
        title={<TooltipContent tipKey={tipKey} />}
      >
        <SvgIcon htmlColor="#5C708A">
          <Info />
        </SvgIcon>
      </Tooltip>
    </>
  );
}
