import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import CalendarMonth from '@mui/icons-material/CalendarToday';
import { Typography, Dialog, DialogTitle, Button, Box } from '@achieve/sunbeam';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useBrand } from 'lib/hooks';
import styles from './Calendar.css';

function constructDashboardUrl(brand) {
  if (brand === 'achieve') {
    return 'https://www.achieve.com/dashboard/resolution';
  } else if (brand === 'fdr') {
    return 'https://www.freedomdebtrelief.com/dashboard';
  } else {
    return `https://${brand}.programdashboard.com`;
  }
}

function AddToCalendar() {
  const brand = useBrand();
  const client = useSelector(state => state?.models?.client);
  const monthlyDraftAmt = Number(client?.monthlyDraftsFeeAmount);

  const composedStartDate = `${client.nextScheduledDraftDate}T09:00`;
  const composedEndDate = `${client.nextScheduledDraftDate}T11:00`;
  const startDatetime = new Date(composedStartDate);
  const endDatetime = new Date(composedEndDate);
  const duration = startDatetime.getHours() - endDatetime.getHours();
  const dashboardUrl = constructDashboardUrl(brand);
  const brandPhone = brand('contact.customer-service.phone');
  const description = `Your program deposit of $${monthlyDraftAmt} is scheduled for today. Ensure you have enough funds in your bank account. See your deposit details on your Client Dashboard ${dashboardUrl}.\nQuestions? Give us a call at ${brandPhone}`;
  const event = {
    title: 'FDR deposit date',
    description,
    duration,
    endDatetime: moment(endDatetime).format('YYYYMMDDTHHmmss'),
    startDatetime: moment(startDatetime).format('YYYYMMDDTHHmmss')
  };
  console.log('to avoid lint failing build - ' + styles.atcDropdownTitle);

  const CalendarModal = ({ children, onRequestClose }) => {
    const [modalVisiblity, setModalVisiblity] = useState(true);

    const handleClose = () => {
      setModalVisiblity(false);
    };

    return (
      <Dialog
        data-testid="calendar-dialog"
        open={modalVisiblity}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto'
          }}
        >
          <DialogTitle onClose={handleClose}>
            <Typography variant="displayS20" fontWeight="bold" textAlign="left">
              Add to calendar
            </Typography>
            <Box>
              <Typography
                variant="bodyS30"
                fontWeight="regular"
                textAlign="left"
              >
                Save a recurring reminder in the calendar of your choice to be
                notified when your drafts occur.
              </Typography>
            </Box>
          </DialogTitle>
          {children.map((link, i) => (
            <Box
              sx={{ width: '100%' }}
              key={i}
              onClick={() =>
                analyticsTrackEvent(
                  {
                    category: 'edu',
                    action: 'task_click',
                    label: 'client_edu_v1_1_add_to_cal'
                  },
                  'task clicked'
                )
              }
            >
              <Button
                color="tertiary"
                size="small"
                variant="contained"
                fullWidth
                sx={{ textDecoration: 'none !important', mb: '8px' }}
                data-testid={`add-to-calendar-button-${link?.key}`}
              >
                {link}
              </Button>
            </Box>
          ))}
          <Button
            data-testid="addToCalendar-cancel-btn"
            color="tertiary"
            onClick={handleClose}
            variant="contained"
            size="small"
            fullWidth
            sx={{ textDecoration: 'none !important', mb: '8px' }}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
    );
  };

  const CalendarButton = args => {
    return (
      <Box variant={'text'} onClick={args.onClick}>
        {args.children}
      </Box>
    );
  };

  const styledBtn = (
    <Button
      color="primary"
      size="small"
      startIcon={<CalendarMonth />}
      variant="text"
    >
      Add to calendar
    </Button>
  );

  const AddToCalendarModal = AddToCalendarHOC(CalendarButton, CalendarModal);
  return (
    <AddToCalendarModal
      buttonText={styledBtn}
      linkProps={{
        className: 'atcDropdownTitle'
      }}
      event={event}
      filename="Reminder-First Payment"
    />
  );
}

export default AddToCalendar;
