import React from 'react';
import { Button, Icon, Text } from '@ffn/ui';
import { MicrosoftWord, Excel, Pdf, HelpfulDoc } from '@ffn/icons';
import 'styled-components/macro';
import { Box } from '@achieve/sunbeam';

export function SuccessMessage({ files, resetForm, path = '', handleReset }) {
  const handleClick = () => {
    handleReset();
    resetForm();
  };

  return (
    <Box maxWidth="365px" width="90%" margin="0 auto">
      {files?.map(file => (
        <Box key={`${file.id}-${file.index}`} flex alignItems="center">
          <Icon
            icon={
              file.document.path.includes('.doc')
                ? MicrosoftWord
                : file.document.path.includes('.pdf')
                ? Pdf
                : file.document.path.includes('.xls')
                ? Excel
                : HelpfulDoc
            }
            size={24}
          />
          <Text
            css={`
              margin: 0;
              margin-left: 8px;
            `}
          >
            {file.document.path}
          </Text>
        </Box>
      ))}
      <Button
        data-testid="doc-upload-ok-button"
        onClick={handleClick}
        href={path}
        variant="primary"
        marginTop="10px"
      >
        Ok
      </Button>
    </Box>
  );
}

export default SuccessMessage;
