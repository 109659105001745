import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import { useAppData, useLaunchDarkly, useSuipLead } from 'lib/hooks';
import {
  getLawFirmId,
  getProductTourData,
  getUserRecord
} from 'shared-selectors/userRecord';
import { getClient } from 'shared-selectors/client';
import { getLifecycleMarketingSegment } from 'shared-selectors/enrolled-debt';
import updateFirestoreUserRecord from 'lib/api/updateFirestoreUserRecord';

/** Array of all law firm ids allowed to see this feature. */
const LAW_FIRM_IDS_TO_SEE_TOUR = ['1059', 1059, '3001', 3001];
const MANDATORY_INTERACTION = 3;

const ProductTourContext = createContext();

export function ProductTourProvider({ children }) {
  const { fetchUserRecord } = useAppData();
  const lawFirmId = useSelector(getLawFirmId);
  const lcmSegment = useSelector(getLifecycleMarketingSegment);
  const { flags, activeFlagNames } = useLaunchDarkly();
  const [tourType, setTourType] = useState(null);
  const userRecord = useSelector(getUserRecord);
  const client = useSelector(getClient);
  const productTourData = useSelector(getProductTourData);
  const [dismissedForSession, setDismissedForSession] = useState(false);
  const { lead, leadFetched } = useSuipLead();

  /** Total number of times user has been served the Product Tour  */
  const tourCount = productTourData?.tourCount ?? 0;
  const isLCMSegment1 = lcmSegment === 'Segment 1';

  /***
   * Updates number of times user has seen the PT and permanentlyDisableProductTour boolean
   */
  const incrementProductTourCount = useCallback(async () => {
    const tourCount = userRecord?.productTour?.tourCount
      ? userRecord?.productTour?.tourCount + 1
      : 1;
    await updateFirestoreUserRecord({
      productTour: {
        tourCount,
        permanentlyDisableProductTour: tourCount >= MANDATORY_INTERACTION
      }
    });
  }, [userRecord?.productTour?.tourCount]);

  /***
   * Sets a boolean to permanently disable the PT from being displayed
   * This should be set after a pre-determined number of visits (3 here )
   */
  const permanentlyDisableProductTour = useCallback(async () => {
    await updateFirestoreUserRecord({
      productTour: {
        permanentlyDisableProductTour: true
      }
    });
    /***
     * We want to refresh the user's data so that we don't display the tour
     * for the remainder of the session in which the user has permanently disabled it.
     */
    fetchUserRecord();
  }, [fetchUserRecord]);

  const markCompleteForSession = useCallback(async () => {
    setDismissedForSession(true);
  }, [setDismissedForSession]);

  /** Checks for WelcomeCall status, LD Flag, FDR brand and if less than 3 times  */
  useEffect(() => {
    /** Feature flag says user should see experiment */
    const featureFlagEnabled =
      flags && flags[activeFlagNames?.showProductTour] === 'show';
    /** Check that law firm id is in the list of law firms we want to see this feature  */
    const stringifiedLawFirmId = lawFirmId?.toString();
    const lawFirmEnabled = LAW_FIRM_IDS_TO_SEE_TOUR.includes(
      stringifiedLawFirmId
    );
    /** User has not had a welcome call */
    const welcomeCallNotCompleted =
      client && client?.welcomeCallCompleteDate === null;
    /** User has seen the PT fewer than the mandatory number of times */
    const countIsBelowThreshold = tourCount < MANDATORY_INTERACTION;
    /** User has already completed the PT */
    const userHasCompletedTour =
      productTourData?.permanentlyDisableProductTour ?? false;

    /** These conditions must all be met to show the short-form, "abbreviated" tour */
    const showAbbreviatedTour =
      featureFlagEnabled &&
      lawFirmEnabled &&
      welcomeCallNotCompleted &&
      countIsBelowThreshold &&
      isLCMSegment1;

    /** Tracking user interaction with PT */
    const tourDismissed = userHasCompletedTour || dismissedForSession;

    /***
     * Calling this up front so that we don't have to wait to render
     * children components if we know up front the tour has been dismissed.
     * Most users will fall into this bucket, and we don't want them waiting
     * for the lead data to load before showing them their app.
     */
    if (tourDismissed === true) {
      return setTourType('disabled');
    }
    /***
     * Avoid setting any tourType here because we still don't know what we should show.
     * We want to avoid rendering the four-step tour if we don't know whether the user
     * should see the extended tour.
     */
    if (!leadFetched || lead?.serveExtendedProductTour === undefined) {
      return;
    }

    /** Order matters here. Tread carefully if changing the logic below. */
    if (lead?.serveExtendedProductTour === true) {
      return setTourType('extended');
    } else if (showAbbreviatedTour === true) {
      return setTourType('abbreviated');
    } else {
      return setTourType('none');
    }
  }, [
    flags,
    lawFirmId,
    activeFlagNames,
    client,
    dismissedForSession,
    productTourData,
    tourCount,
    isLCMSegment1,
    lead,
    leadFetched
  ]);

  const value = useMemo(() => {
    return {
      incrementProductTourCount,
      permanentlyDisableProductTour,
      /*** Prevent product tour from reappearing when navigating back to the
       * index page after dismissing the tour using the close button.
       */
      markCompleteForSession,
      tourType,
      tourCount,
      checkedForExtendedProductTour: leadFetched
    };
  }, [
    incrementProductTourCount,
    permanentlyDisableProductTour,
    markCompleteForSession,
    tourType,
    tourCount,
    leadFetched
  ]);

  return (
    <ProductTourContext.Provider value={value}>
      {children}
    </ProductTourContext.Provider>
  );
}

export function useProductTour() {
  const context = useContext(ProductTourContext);
  if (context === undefined) {
    throw new Error(
      'useProductTour must be used within a ProductTourContextProvider'
    );
  }

  return context;
}

export default useProductTour;
