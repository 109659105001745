import React from 'react';

import { useTheme } from '@achieve/sunbeam';
import { coreQuestionContent } from '../assets/coreQuestionContent';
import { CoreQuestion } from './CoreQuestion/index.js';

/**
 * CoreQuestions renders a list of CoreQuestion components based on provided question IDs.
 * It sources question data from a pre-defined content object and passes necessary props to each CoreQuestion.
 * CoreQuestions will be rendered in the order of the provided question ids
 *
 * See the CoreQuestion component for styling defaults and options
 *
 * @component
 * @param {Object} props
 * @param {Array<string>} props.activeCoreQuestions - Array of question IDs used to fetch question data.
 * @returns {React.ReactElement} - A React element representing a section with multiple CoreQuestion components.
 */
export function CoreQuestions({ activeCoreQuestions }) {
  // Map question IDs to question data, ensuring only specified questions are displayed.
  const displayedQuestions = activeCoreQuestions.reduce((acc, questionId) => {
    if (questionId in coreQuestionContent)
      acc.push(coreQuestionContent[questionId]);
    return acc;
  }, []);

  const theme = useTheme();
  const grey = theme?.ascend?.colors?.neutral[150];

  return (
    <section style={{ borderTop: `1px solid ${grey}`, paddingTop: '16px' }}>
      {displayedQuestions?.map(({ id, summaryKey, detailsComponent }) => (
        <CoreQuestion
          border={false}
          key={id}
          id={id}
          summaryKey={summaryKey}
          detailsComponent={detailsComponent}
        />
      ))}
    </section>
  );
}
