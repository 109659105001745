import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParam, useTranslation } from 'lib/hooks';
import { FlatPageLayout } from 'shared-components/Layouts/PageLayout';
import { logger, ReCaptcha } from 'shared-components';
import { Loading } from '../shared/Loading';
import { Success } from './Success';
import { TryAgain } from '../shared/TryAgain';
import { ContactAgent as ContactAgentContainer } from '../shared/ContactAgent';
import { analyticsPayloads, views } from './constants';
import { sendAnalyticsEvent, submitDepositAuth } from './utils';
import LocaleContext from 'lang/LocaleContext';
import { useMutation } from 'react-query';
import rawAxios from 'axios';
import { Typography } from '@achieve/sunbeam';
import { colors } from '../shared/constants';

const LOGGER_PREFIX = `EMAIL DEPOSIT AUTH ::`;
const TEST_ID_PREFIX = 'auto-deposit-auth';

const setEventName = (statusCode, isRetry, message) => {
  if (statusCode === '400' || statusCode === 400) {
    return 'Alert id not found';
  } else if (isRetry) {
    return `Deposit could not be authorized after retry (${message})`;
  } else {
    return `Deposit could not be authorized (${message})`;
  }
};

const ContactAgent = ({ analyticsPayloads }) => {
  const { t } = useTranslation();

  return (
    <ContactAgentContainer
      testIdPrefix={TEST_ID_PREFIX}
      analyticsPayloads={analyticsPayloads}
    >
      <Typography
        variant="bodyM30"
        fontWeight="300"
        fontSize="16px"
        textAlign="center"
        color={colors.secondaryGrayColor}
      >
        {t('oneClick.autoDepositAuth.weHadAnIssueReschedulingYourDeposit')}
        <br />
        {t('oneClick.autoDepositAuth.callUs')}
      </Typography>
    </ContactAgentContainer>
  );
};

export const AutoDepositAuth = () => {
  const { alertId } = useParams();
  const queryParams = useQueryParam();
  const { locale } = useContext(LocaleContext);
  const isRetry = queryParams.get('retry') === 'true';
  const [viewToRender, setViewToRender] = React.useState(views.loading);
  const [isReCaptchaVerified, setIsReCaptchaVerified] = useState(null);
  const reCaptchaRef = useRef(null);

  // Catch all function for setting the correct view in the UI, adding logging and analytics events
  const renderView = React.useCallback(
    (viewName, { eventKey, eventName, alertId }) => {
      logger.info(`${LOGGER_PREFIX} ${eventName}`);

      setViewToRender(viewName);
      sendAnalyticsEvent({
        eventKey,
        alertId,
        name: eventName
      });
    },
    []
  );

  // More on useMutation: https://tanstack.com/query/v4/docs/react/guides/mutations
  const mutation = useMutation({
    mutationFn: () => submitDepositAuth({ alertId }),
    onSettled: (data, error) => {
      // If there's an error, we want to render the contact agent view
      if (error) {
        let message = error.message;
        let statusCode = null;

        if (rawAxios.isAxiosError(error)) {
          message = error.response?.data?.message;
          statusCode = error.response?.data?.statusCode;
        }

        const eventName = setEventName(statusCode, isRetry, message);

        isRetry
          ? renderView(views.contactAgent, {
              eventKey: 'failure',
              eventName,
              alertId // In this case, we're passing back the raw input from the URL
            })
          : renderView(views.tryAgain, {
              eventKey: 'failure',
              eventName,
              alertId // In this case, we're passing back the raw input from the URL
            });
      }

      // Payload is from the FDR Gateway API
      // More info here: https://miro.com/app/board/uXjVLbwNFdQ=/
      const { responseCode, alert_id: decodedAlertId } = data;

      if (responseCode) {
        renderView(views.success, {
          eventKey: 'success',
          eventName:
            responseCode === 200
              ? 'Authorization successful'
              : 'Already authorized',
          alertId: decodedAlertId
        });
      } else {
        renderView(views.contactAgent, {
          eventKey: 'failure',
          eventName: 'Unknown Error :: No Response Code',
          alertId: decodedAlertId
        });
      }
    }
  });

  /*** We're firing off our mutation when the route mounts, and only want to do it once */
  useEffect(() => {
    if (isReCaptchaVerified === null) {
      renderView(views.loading, {
        eventKey: 'loading',
        eventName: 'reCaptcha verification in progress'
      });
    } else if (isReCaptchaVerified) {
      mutation.mutate({ alertId });
    } else {
      // If reCaptcha is not verified, show contact agent view
      renderView(views.contactAgent, {
        eventKey: 'failure',
        eventName: 'reCaptcha verification failed'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReCaptchaVerified, renderView]);

  return (
    <>
      <FlatPageLayout>
        {/*** Preventing a render until we know the correct locale to display.
         *  This avoids a potential race condition and/or a Spanish user seeing the <Loading /> view in English
         */}
        {viewToRender === views.loading && locale ? (
          <Loading testIdPrefix={TEST_ID_PREFIX} />
        ) : viewToRender === views.success ? (
          <Success testIdPrefix={TEST_ID_PREFIX} />
        ) : viewToRender === views.contactAgent ? (
          <ContactAgent analyticsPayloads={analyticsPayloads} />
        ) : viewToRender === views.tryAgain ? (
          <TryAgain
            testIdPrefix={TEST_ID_PREFIX}
            analyticsPayloads={analyticsPayloads}
          />
        ) : (
          <ContactAgent analyticsPayloads={analyticsPayloads} />
        )}
      </FlatPageLayout>
      <ReCaptcha
        isReCaptchaVerified={isReCaptchaVerified}
        setIsReCaptchaVerified={setIsReCaptchaVerified}
        reCaptchaRef={reCaptchaRef}
      />
    </>
  );
};
