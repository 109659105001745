import SchoolIcon from '@mui/icons-material/School';
import { Button } from '@achieve/sunbeam';
import { useClientEducation } from 'lib/hooks';
import 'styled-components/macro';

export function OrientationButton() {
  const { openDrawer, userShouldSeeEduModule } = useClientEducation();

  if (!userShouldSeeEduModule) return null;

  return (
    <Button
      css={`
        display: flex;
        gap: 1rem;
      `}
      data-testid="orientation-btn"
      onClick={() => openDrawer({ location: 'orientation_btn' })}
    >
      <SchoolIcon /> Orientation
    </Button>
  );
}
