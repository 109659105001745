import 'styled-components/macro';
import React from 'react';
import { Box, Composition, Only } from 'atomic-layout';
import { Icon, Tooltip } from '@ffn/ui';
import { useBrand, useTranslation } from 'lib/hooks';
import { Award, Info } from 'react-feather';
import {
  getAbbrSettlementRange,
  getFullSettlementRange
} from '../../lib/utils';

function DebtHighlightXs({ creditorName, accountNumber, nextSettlementRange }) {
  const { t } = useTranslation();
  const brand = useBrand();
  const tooltipText =
    t('debtHighlight.nextSettlementPart1') +
    ' ' +
    brand('business-name.abbr') +
    ' ' +
    t('debtHighlight.nextSettlementPart2');

  return (
    <Box
      css={`
        color: #fff;
        padding: 10px;
        margin-bottom: 16px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24),
          0 0 4px 0 rgba(0, 0, 0, 0.12);
        ${({ theme }) =>
          `background-image: linear-gradient(100deg, ${theme.colors.secondaryGradientStart}, ${theme.colors.secondaryGradientEnd});`}
      `}
    >
      <Box flex marginBottom={24}>
        <Icon
          icon={Award}
          size={24}
          css={`
            margin-left: 5px;
            margin-top: 5px;
          `}
        />
        <div
          css={`
            margin-left: 10px;
          `}
        >
          <div>
            {t('debtHighlight.estNextSettlement')}{' '}
            <Icon
              icon={Info}
              size={15}
              data-tip
              data-for="nextSettlement"
              onClick={ev => {
                ev.stopPropagation();
              }}
            />
            <Tooltip id="nextSettlement" place="bottom" delayHide={800}>
              {tooltipText}
            </Tooltip>
          </div>
          <div
            css={`
              margin-top: 5px;
              font-weight: 400;
              font-size: 18px;
            `}
          >
            {nextSettlementRange}
          </div>
        </div>
      </Box>
      <Box>
        <Box flex justifyContent="space-between">
          <span>{t('debtHighlight.creditor')}</span>
          <span>{t('debtHighlight.accountNumber')}</span>
        </Box>
        <Box
          flex
          justifyContent="space-between"
          css={`
            margin-top: 5px;
            font-weight: 600;
            font-size: 16px;
          `}
        >
          <span>{creditorName}</span>
          <span>****{accountNumber}</span>
        </Box>
      </Box>
    </Box>
  );
}

function DebtHighlightm({ creditorName, accountNumber, nextSettlementRange }) {
  const { t } = useTranslation();
  const brand = useBrand();
  const tooltipText =
    t('debtHighlight.nextSettlementPart1') +
    ' ' +
    brand('business-name.abbr') +
    ' ' +
    t('debtHighlight.nextSettlementPart2');

  return (
    <Box
      css={`
        color: #fff;
        padding: 10px;
        margin-bottom: 16px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24),
          0 0 4px 0 rgba(0, 0, 0, 0.12);
        ${({ theme }) =>
          `background-image: linear-gradient(100deg, ${theme.colors.secondaryGradientStart}, ${theme.colors.secondaryGradientEnd});`}
      `}
    >
      <Box flex marginBottom={24}>
        <Icon
          icon={Award}
          size={24}
          css={`
            margin-left: 5px;
            margin-top: 5px;
          `}
        />
        <div
          css={`
            margin-left: 10px;
          `}
        >
          <div>
            {t('debtHighlight.estNextSettlement')}{' '}
            <Icon
              icon={Info}
              size={15}
              data-tip
              data-for="nextSettlement"
              onClick={ev => {
                ev.stopPropagation();
              }}
            />
            <Tooltip id="nextSettlement" place="bottom" delayHide={800}>
              {tooltipText}
            </Tooltip>
          </div>
          <div
            css={`
              margin-top: 5px;
              font-weight: 400;
              font-size: 18px;
            `}
          >
            {nextSettlementRange}
          </div>
        </div>
      </Box>
      <Box>
        <Box flex justifyContent="space-between">
          <span>{t('debtHighlight.creditor')}</span>
          <span>{t('debtHighlight.accountNumber')}</span>
        </Box>
        <Box
          flex
          justifyContent="space-between"
          css={`
            margin-top: 5px;
            font-weight: 600;
            font-size: 16px;
          `}
        >
          <span>{creditorName}</span>
          <span>****{accountNumber}</span>
        </Box>
      </Box>
    </Box>
  );
}

function DebtHighlightMd({ creditorName, accountNumber, nextSettlementRange }) {
  const { t } = useTranslation();
  const brand = useBrand();
  const tooltipText =
    t('debtHighlight.nextSettlementPart1') +
    ' ' +
    brand('business-name.abbr') +
    ' ' +
    t('debtHighlight.nextSettlementPart2');
  return (
    <Composition
      templateCols={`2fr 1fr`}
      css={`
        color: #fff;
        padding: 10px;
        margin-bottom: 16px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24),
          0 0 4px 0 rgba(0, 0, 0, 0.12);
        ${({ theme }) =>
          `background-image: linear-gradient(100deg, ${theme.colors.secondaryGradientStart}, ${theme.colors.secondaryGradientEnd});`}
      `}
    >
      <Box flex>
        <Icon
          icon={Award}
          size={40}
          css={`
            margin-left: 5px;
            margin-top: 5px;
          `}
        />
        <div
          css={`
            margin-left: 10px;
          `}
        >
          <div>
            {t('debtHighlight.estNextSettlement')}{' '}
            <Icon
              icon={Info}
              size={15}
              data-tip
              data-for="nextSettlement"
              onClick={ev => {
                ev.stopPropagation();
              }}
            />
            <Tooltip id="nextSettlement" place="bottom" delayHide={800}>
              {tooltipText}
            </Tooltip>
          </div>
          <div
            css={`
              margin-top: 5px;
              font-weight: 400;
              font-size: 18px;
            `}
          >
            {nextSettlementRange}
          </div>
        </div>
      </Box>
      <Box
        flex
        justifyContent="space-between"
        paddingLeft={16}
        css={`
          border-left: 1px solid #fff;
        `}
      >
        <Box flex flexDirection="column">
          <span>{t('debtHighlight.creditor')}:</span>
          <span
            css={`
              margin-top: 8px;
            `}
          >
            {t('debtHighlight.accountNumber')}:
          </span>
        </Box>
        <Box
          flex
          flexDirection="column"
          css={`
            font-weight: 600;
            font-size: 14px;
            text-align: right;
          `}
        >
          <span>{creditorName}</span>
          <span
            css={`
              margin-top: 10px;
            `}
          >
            ****{accountNumber}
          </span>
        </Box>
      </Box>
    </Composition>
  );
}

export function DebtHighlight({ highlightedDebt }) {
  const {
    accountNumber,
    creditor: creditorName,
    estimatedSettlementRange
  } = highlightedDebt;
  const props = { accountNumber, creditorName };

  const mobileProps = {
    ...props,
    nextSettlementRange: getAbbrSettlementRange(estimatedSettlementRange)
  };

  const desktopProps = {
    ...props,
    nextSettlementRange: getFullSettlementRange(estimatedSettlementRange)
  };

  return (
    <>
      <Only from="md">
        <DebtHighlightMd {...desktopProps} />
      </Only>
      <Only from="sm" to="md">
        <DebtHighlightm {...desktopProps} />
      </Only>
      <Only from="xs" to="sm">
        <DebtHighlightXs {...mobileProps} />
      </Only>
    </>
  );
}
