import React from 'react';
import { Typography } from '@achieve/sunbeam';
import { useTranslation } from 'lib/hooks';
import { getAppSubDomain } from 'shared-components/ProvideDashboardType';

import styles from './ProgramDetailsFooter.module.scss';

export const ProgramDetailsFooter = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.programDetailsFooter}>
      <Typography variant="ascendBodyXs">
        <ProgramDetailsDisclaimerParagraphs />
        <div data-testid="program-details-footer-list">
          <ol>
            <li>{t('programDetails.estimatedCurrentTotalMinimumMonthly')}</li>
            <li>{t('programDetails.timeToPayDownYourDebtsIsTheTotal')}</li>
            <li>{t('programDetails.totalEstimatedAmountYouWillPay')}</li>
          </ol>
        </div>
      </Typography>
    </section>
  );
};

const ProgramDetailsDisclaimerParagraphs = () => {
  const { t } = useTranslation();
  const subdomain = getAppSubDomain();
  const isTurnbull = subdomain === 'turnbull';

  // There are subtle differences between the paragraph disclaimers for TLG
  // compared to what we're using for AR and FDR
  const graph1Content = isTurnbull
    ? t('programDetails.theseEstimatesAreNotActualAmountsTLG')
    : t('programDetails.theseEstimatesAreNotActualAmounts');
  const graph2Content = isTurnbull
    ? t('programDetails.theseEstimatesAssumeThatThereAreTLG')
    : t('programDetails.theseEstimatesAssumeThatThereAre');

  return (
    <div data-testid="program-details-footer-paragraphs">
      <p>{graph1Content}</p>
      <p>{graph2Content}</p>
    </div>
  );
};
