import { css } from 'styled-components/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SortableTable } from '@ffn/ui';
import { useSelector } from 'react-redux';
import {
  getDebtsInNegotiation,
  getDebtsWithSettlements
} from 'shared-selectors/enrolled-debt';
import {
  Meter,
  PhaseTooltip,
  ActivityTooltip,
  Currency,
  Percentage
} from 'shared-components';
import { Box } from 'atomic-layout';
import * as R from 'ramda';
import { useFeatureFlag } from 'lib/hooks';
import { useTranslation } from 'lib/hooks';

const sharedColumns = t => {
  return {
    creditor: {
      Header: `${t('enrolledDebt.creditor')}`,
      accessor: 'originalCreditor',
      width: '1fr',
      sortOptions: ['A-Z', 'Z-A'],
      Cell: ({ value, row }) => (
        <Box flex flexDirection="column">
          <span>{value}</span>
          <span css="font-size: 10px">**** {row.original.accountNumber}</span>
        </Box>
      )
    },
    settlementPayments: {
      Header: `${t('enrolledDebt.settlementPayments')}`,
      accessor: 'numberPaymentsPaid',
      width: 'min-content',
      widthLg: '1fr',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        const {
          numberPaymentsPaid,
          numberOfPayments,
          totalAmountRemaining
        } = row.original;
        const finalVal =
          totalAmountRemaining <= 0 && numberPaymentsPaid < numberOfPayments
            ? numberPaymentsPaid
            : numberOfPayments;
        return (
          <Box flex justifyContent="flex-end" alignItems="center">
            <span>
              {value}/{finalVal}
            </span>
            <Box width={40}>
              <Meter
                min={0}
                value={value}
                max={finalVal}
                style={{ marginLeft: 8 }}
              />
            </Box>
          </Box>
        );
      }
    },
    balanceAtSettlement: {
      Header: `${t('enrolledDebt.balanceAtSettlement')}`,
      accessor: 'currentBalance',
      width: 'min-content',
      widthLg: '1fr',
      mobile: false,
      sortOptions: ['Low-High', 'High-Low'],
      Cell: ({ value }) => <Currency value={value} />
    },
    settlementAmount: {
      Header: `${t('enrolledDebt.settlementAmount')}`,
      accessor: 'settlementAmount',
      width: 'min-content',
      widthLg: '1fr',
      mobile: false,
      sortOptions: ['Low-High', 'High-Low'],
      Cell: ({ value }) => <Currency value={value} />
    },
    settlementPercentage: {
      Header: `${t('enrolledDebt.settlementPercentage')}`,
      accessor: 'settlementPercentage',
      width: 'min-content',
      widthLg: '1fr',
      mobile: false,
      sortOptions: ['Low-High', 'High-Low'],
      Cell: ({ value }) => <Percentage value={value} />
    },
    phase: {
      Header: `${t('enrolledDebt.phase')}`,
      accessor: 'debtProgressionPhase',
      width: 'min-content',
      widthLg: '1fr',
      mobile: false,
      sortOptions: ['A-Z', 'Z-A'],
      Cell: ({ value }) =>
        value && (
          <Box flex alignItems="center">
            <Box marginRight={8}>{t('enrolledDebt.phases', value)}</Box>
            <Box width={15} height={15}>
              <PhaseTooltip tipKey={value} />
            </Box>
          </Box>
        )
    },
    activity: {
      Header: `${t('enrolledDebt.activity')}`,
      accessor: 'negotiationsActivity',
      width: 'min-content',
      widthLg: '1fr',
      disableSortBy: true,
      mobile: false,
      Cell: ({ value }) =>
        value && (
          <Box flex alignItems="center">
            <Box marginRight={8}>{t('enrolledDebt.activities', value)}</Box>
            <Box width={15} height={15}>
              <ActivityTooltip tipKey={value} />
            </Box>
          </Box>
        )
    },
    enrolledBalance: {
      Header: `${t('enrolledDebt.enrolledBalance')}`,
      accessor: 'beginningBalance',
      width: 'min-content',
      cwidthLg: '1fr',
      mobile: false,
      sortOptions: ['Low', 'High'],
      Cell: ({ value }) => <Currency value={value} />
    }
  };
};

/*** This only renders on mobile detail expand  */
function basicRenderDetail({ row }) {
  return R.difference(row.allCells, row.cells).map(cell => (
    <Box
      flex
      justifyContent="space-between"
      padding={5}
      marginHorizontal={5}
      css={css`
        border-top: 1px solid ${props => props.theme.colors.grayRegular};
      `}
    >
      <div>{cell.column.render('Header')}</div>
      <div>{cell.render('Cell')}</div>
    </Box>
  ));
}

export function SettlementsTable({ homePage = false }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { path } = useFeatureFlag();
  const debtsWithSettlements = useSelector(getDebtsWithSettlements);
  const settlementColumns = React.useMemo(() => {
    const columns = [
      sharedColumns(t).creditor,
      sharedColumns(t).settlementPayments,
      sharedColumns(t).balanceAtSettlement,
      sharedColumns(t).settlementAmount
    ];
    if (homePage) {
      return columns.concat(sharedColumns(t).phase);
    }
    return columns.concat(
      sharedColumns(t).settlementPercentage,
      sharedColumns(t).phase,
      sharedColumns(t).activity
    );
  }, [homePage, t]);
  const renderDetail = React.useCallback(basicRenderDetail, []);
  const rowHandler = path('/enrolled-debt/:accountId')
    ? ({ tag }) => navigate(`/enrolled-debt/${tag}`)
    : undefined;
  return (
    <SortableTable
      columns={settlementColumns}
      data={debtsWithSettlements}
      renderDetails={renderDetail}
      onRowClick={rowHandler}
    />
  );
}

export function InNegotiationTable() {
  const navigate = useNavigate();
  const { path } = useFeatureFlag();
  const debtsInNegotiation = useSelector(getDebtsInNegotiation);
  const { t } = useTranslation();
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const { current: container } = containerRef;
    if (!container) return;
    const rows = container.querySelectorAll('[role="row"]');
    // Header is at index 0, first content row is at index 1
    const firstRow = rows[1];
    if (!firstRow) return;
    const firstCell = firstRow.querySelector('[role="cell"]');
    if (!firstCell) return;

    // Add tour tip target to only the first row
    firstCell.setAttribute('data-tour', 'enrolled-debt-status');
  }, [debtsInNegotiation]);

  const inNegotiationColumns = React.useMemo(
    () => [
      sharedColumns(t).creditor,
      sharedColumns(t).enrolledBalance,
      sharedColumns(t).phase,
      sharedColumns(t).activity
    ],
    [t]
  );
  const renderDetail = React.useCallback(basicRenderDetail, []);
  const rowHandler = path('/enrolled-debt/:accountId')
    ? ({ tag }) => navigate(`/enrolled-debt/${tag}`)
    : undefined;

  return (
    <Box ref={containerRef}>
      <SortableTable
        columns={inNegotiationColumns}
        data={debtsInNegotiation}
        renderDetails={renderDetail}
        onRowClick={rowHandler}
      />
    </Box>
  );
}
