import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { Box } from 'atomic-layout';
import { Text, Button, Icon } from '@ffn/ui';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { alertUpdate, refreshNotifications } from 'lib/api';
import { useNotificationTracking } from 'lib/hooks';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { ContactQuestions } from 'shared-components';
import { DefaultStep } from './DefaultStep';
import { ConfirmStep } from './ConfirmStep';
import { DisputeStep } from './DisputeStep';
import { SuccessStep } from './SuccessStep';
import { useTranslation } from 'lib/hooks';

const DefaultMessage = ({ notification, children }) => {
  return (
    <>
      <DefaultStep notification={notification} />
      {children}
      <CustomerServiceBox />
    </>
  );
};

const CustomerServiceBox = () => {
  return (
    <Box marginTop={10}>
      <ContactQuestions />
    </Box>
  );
};

export function AccountRemovalRequest({
  notification,
  viewLocation,
  setIsResolved,
  isVisible
}) {
  const { trackingLabel, referenceId, payload } = notification;
  const trackNotificationView = useNotificationTracking();
  const [requestStatus, setRequestStatus] = useState('default'); // 'default', 'review', 'dispute', 'resolved', 'error'
  const [requestType, setRequestType] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    trackNotificationView({
      trackingLabel,
      viewLocation,
      isVisible
    });
  }, [trackingLabel, viewLocation, isVisible, trackNotificationView]);

  const { alertId } = payload;

  // This should only ever get called with one of the properties in the argument object
  // We're using this to determine the operation to perform here, along with the requestStatus kept in state
  const handleSubmit = async ({ disputeReason, formattedNextDraftDate }) => {
    const updatePayload = [];

    setIsSubmitting(true);
    setErrorMessage(null);

    if (requestStatus === 'dispute') {
      setRequestType('dispute');

      if (!disputeReason) {
        throw new Error('Must submit with a disputeReason');
      }

      updatePayload.push({
        alert_id: alertId,
        dispute_reason: disputeReason,
        comments: 'String',
        related_debtaccount: null,
        authorized_removal: false,
        next_draft_date: null
      });
    } else {
      if (!formattedNextDraftDate) {
        throw new Error('Must submit with a formattedNextDraftDate');
      }

      updatePayload.push({
        alert_id: alertId,
        dispute_reason: null,
        related_debtaccount: referenceId,
        authorized_removal: true,
        next_draft_date: formattedNextDraftDate
      });
    }

    try {
      const res = await alertUpdate(updatePayload);

      if (!res.success) {
        throw new Error(res.error_message);
      }

      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'success',
          label: 'arr'
        },
        'Account Removal Request Success'
      );

      setRequestStatus('resolved');
      setIsSubmitting(false);
      setErrorMessage(null);

      if (typeof setIsResolved === 'function') {
        setIsResolved(true);
      }
      setTimeout(() => {
        refreshNotifications(['alertTasks']);
      }, 6000);
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
      setErrorMessage(e.message);
      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'failure',
          label: 'arr'
        },
        'Account Removal Request Failure'
      );
    }
  };

  const resetStatus = () => {
    setRequestStatus('default');
    setIsSubmitting(false);
    setErrorMessage(null);
  };

  if (requestStatus === 'default') {
    return (
      <>
        <DefaultMessage notification={notification}>
          <Box flex justifyContent="center" width="100%" marginTop={16}>
            <Box width={300}>
              <Button
                variant="primary"
                leftEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                height={40}
                width="100%"
                onClick={() => {
                  setRequestStatus('review');
                }}
                data-testid="approveAccountRemovalButton"
              >
                {t('alerts.accountRemovalRequest.approveAccountRemoval')}
              </Button>
            </Box>
          </Box>
          <Box flex justifyContent="center" width="100%">
            <Box width={300}>
              <Text
                css={`
                  text-align: center;
                  :hover {
                    cursor: pointer;
                    text-decoration: underline;
                  }
                `}
                onClick={() => setRequestStatus('dispute')}
                data-testid="disputeAccountRemovalButton"
              >
                {t('alerts.accountRemovalRequest.disputeAccountRemoval')}
              </Text>
            </Box>
          </Box>
        </DefaultMessage>
      </>
    );
  } else if (requestStatus === 'dispute') {
    return (
      <>
        <DefaultMessage notification={notification}>
          <DisputeStep
            isSubmitting={isSubmitting}
            errorMessage={errorMessage}
            handleBack={resetStatus}
            handleSubmit={handleSubmit}
          />
        </DefaultMessage>
      </>
    );
  } else if (requestStatus === 'review') {
    return (
      <ConfirmStep
        notification={notification}
        isSubmitting={isSubmitting}
        errorMessage={errorMessage}
        handleSubmit={handleSubmit}
        handleBack={resetStatus}
      />
    );
  } else if (requestStatus === 'resolved') {
    return <SuccessStep requestType={requestType} />;
  } else if (requestStatus === 'error') {
  }

  return null;
}
