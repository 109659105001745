import { useTranslation } from 'lib/hooks';
import styles from './AchieveLeadDocFooter.module.scss';
import { Typography, useTheme } from '@achieve/sunbeam';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { EVENT_ACTION } from 'lib/utils/eventHorizon';

import AchieveLogo from '../AchieveFooter/SocialIcons/assets/2022_Achieve_Logomark_RGB.svg';
import SocialIcons from '../AchieveFooter/SocialIcons';

const domain = document?.domain ?? '';

const footerLinkAnalyticEvent = (footerLinkName, path) => {
  analyticsTrackEvent(
    {
      nav_link_section: 'Footer',
      event_action: EVENT_ACTION.LINK_CLICK,
      click_type: 'Link Click',
      click_id: footerLinkName,
      click_url: 'https://' + domain + path,
      clickText: footerLinkName,
      track_event: 'global_footer'
    },
    `Footer: ${footerLinkName} Link Click`
  );
};

export const AchieveLeadDocFooter = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={styles.footer} data-testid="achieve-leaddoc-footer">
      <div className={styles.brandingFooter}>
        <div className={styles.branding}>
          <div
            className={styles.logo}
            data-testid="achieve-leaddoc-footer-logo"
          >
            <a
              href="/"
              onClick={() => footerLinkAnalyticEvent('Homepage', '/')}
            >
              <img src={AchieveLogo} alt="Achieve Logo" />
            </a>
          </div>
          <div className={styles.contactUsText}>
            <Typography>{t('footer.helpText')}</Typography>
            <Typography className={styles.link} variant="bodyS30">
              <a
                data-testid="achieve-leaddoc-contactus-link"
                onClick={() =>
                  footerLinkAnalyticEvent('Contact Us', '/about/contact-us')
                }
                href="/about/contact-us"
              >
                {t('footer.achieve.Contact Us')}
              </a>
            </Typography>
            <Typography>{t('footer.page')}</Typography>
          </div>
          <div className={styles.brandingCopy}>
            <Typography
              variant="bodyS30"
              color={theme.palette.grey['900']}
              data-testid="achieve-leaddoc-footer-branding-copy"
            >
              {t('footer.achieveBranding')}
            </Typography>
          </div>
        </div>
      </div>
      <div
        className={styles.disclouserSections}
        data-testid="achieve-leaddoc-disclosure-section"
      >
        <Typography variant="bodyS10" color={theme.palette.grey['700']}>
          {t('footer.achieveDisclaimerText')}
        </Typography>
      </div>
      <hr color="#BEC6D5" size={1} />
      <div className={styles.tradeMarkText}>
        <div
          className={styles.copyright}
          data-testid="achieve-leaddoc-footer-copyright"
        >
          <Typography variant="bodyS30" color={theme.palette.grey['700']}>
            © 2023 Achieve. All rights reserved.
          </Typography>
        </div>
        <SocialIcons />
      </div>
    </div>
  );
};
export default AchieveLeadDocFooter;
