import { AUTH0_PROVIDER_VARS } from 'constants';

export function getAuth0Vars(subDomain) {
  if (!subDomain) {
    throw new Error('Missing subDomain');
  }

  const auth0Vars = AUTH0_PROVIDER_VARS[subDomain];

  if (!auth0Vars) {
    throw new Error('Auth0 environment variables could not be determined');
  }

  return {
    ...auth0Vars
  };
}
