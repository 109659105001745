import { axiosInstance as axios } from 'lib/axios';
import getApiUrl from 'lib/utils/getApiUrl';
import { logger } from 'shared-components';

const LOGGER_PREFIX = 'DOC UPLOAD TO ECM ::';

/***
 * Payload should be:
 * { file: File, attributes: ...file metadata collected from form as stringified JSON }
 */
export const uploadToECM = async (payload, oobCode) => {
  const urlStem = getApiUrl();

  const apiUrl = `${urlStem}/document/upload`;
  const authHeaders = {};

  if (oobCode) {
    authHeaders['x-oob-code'] = oobCode;
  }

  try {
    const response = await axios.post(apiUrl, payload, {
      headers: {
        'Content-type': 'multipart/form-data',
        ...authHeaders
      },
      validateStatus: status => status === 200
    });

    return response.data;
  } catch (err) {
    if (err.response?.data) {
      const error = new Error(
        err.response.data.message || 'Error uploading document'
      );
      logger.error(`${LOGGER_PREFIX} ${error.message}`);
      throw error;
    }
    logger.error(`${LOGGER_PREFIX} Error uploading document`);
    throw err;
  }
};
