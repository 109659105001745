import React, { Suspense } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import 'styled-components/macro';
import { FallBackLoader, ProtectedRoutesWrapper, RouteGuard, UpscopeInit } from 'shared-components';
import { Composition } from 'atomic-layout';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';
import { useBrand, useLaunchDarkly } from 'lib/hooks';
import { ErrorFallbackRouter, Footer as OnboardingFooter, LoginError, OnboardingNav } from 'shared-pages/common';
import { Home } from 'shared-pages/onboarding';
import { About, Disclosures, NotFound, PrivacyPolicy, Settings } from 'shared-pages/dashboard/lazy';
import { ErrorFallback } from 'shared-pages/common/lazy';

const areas = `
  header
  main
  footer
`;

export function Onboarding() {
  const brand = useBrand();
  const { flags, activeFlagNames } = useLaunchDarkly();

  const navigate = useNavigate();

  return (
    <Composition
      areas={areas}
      templateCols="1fr"
      templateRows="auto 1fr auto"
      height="100vh"
    >
      {({ Header, Main, Footer }) => (
        <>
          <Helmet>
            <title>{brand('business-name.long')} Dashboard</title>
          </Helmet>
          <Header>
            <OnboardingNav />
            {flags &&
            flags[activeFlagNames.loadUpscopeScriptOnboarding] === 'show' ? (
              <UpscopeInit />
            ) : null}
          </Header>
          <div
            css={`
              background-color: ${props => props.theme.colors.grayLight};
            `}
          >
            <ErrorBoundary
              FallbackComponent={ErrorFallbackRouter}
              onReset={() => navigate('/page-error')}
            >
              <Main>
                <Suspense fallback={<FallBackLoader isPage />}>
                  <Routes>
                    {/****
                     **** RESTRICTED ROUTES ****
                     ** NOTE: We're wrapping all these routes in a <RouteGuard /> component
                     ** to ensure that the user has authenticated and has the appropriate tokens/permissions needed.
                     ****/}
                    <Route element={<RouteGuard wrapper={ProtectedRoutesWrapper}/>}>
                      <Route path="/" element={<Home />} />
                      <Route path="/banking-info" element={<Home />} />
                      <Route path="/enrolled-debts" element={<Home />} />
                      <Route path="/important-notices" element={<Home />} />
                      <Route path="/personal-info" element={<Home />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/settings/:sub" element={<Settings />} />
                      <Route path="/" element={<Home />} />
                    </Route>
                    {/******** OPEN ROUTES ********/}
                    <Route path="/about" element={<About />} />
                    <Route path="/disclosures" element={<Disclosures />} />
                    <Route path="/login-error" element={<LoginError />} />
                    <Route path="/page-error" element={<ErrorFallback />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </Main>
            </ErrorBoundary>
          </div>
          <Footer>
            <OnboardingFooter />
          </Footer>
        </>
      )}
    </Composition>
  );
}
