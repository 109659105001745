import { axiosInstance as axios } from 'lib/axios';

import { formatISO } from 'date-fns/fp';

// Sending form data from the front end to the backend (currently a FB function)
export const bankAccountChange = async ({ fieldValues, description }) => {
  try {
    const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/bankAccountChange`;

    const { type } = fieldValues;

    // 'Renew Authorization' needs to be the value here if the client is only re-authorizing their account information
    // Otherwise, take the value that got passed in from the verifyBank call up the stack
    const changeDescription =
      type === 'authOnly' ? 'Renew Authorization' : description;

    const sfObj = {
      new_account_number: fieldValues.newAccountNumber,
      new_routing_number: fieldValues.newRoutingNumber,
      names_on_account: fieldValues.newAccountNames,
      bank_name: fieldValues.newBankName,
      bank_account_type: fieldValues.newAccountType,
      description: changeDescription,
      type,
      ach_agreement_time: formatISO(new Date())
    };

    const response = await axios.post(endpoint, sfObj, {
      validateStatus: status => status === 200
    });

    return response.data.recordid;
  } catch (error) {
    if (error.response.status === 404) {
      const appError = new Error();
      appError.code =
        error.response.data.error_code || 'BANK_ACCOUNT_CHANGE_GENERAL_ERROR';
      if (
        error.response.data.error_message !== null &&
        error.response.data.error_message.indexOf('It has not been 30 days') >
          -1
      ) {
        appError.code = 'BANK_ACCOUNT_CHANGE_30_DAY_ERROR';
      }
      throw appError;
    }
    throw new Error('DATA_INSERTS_ERROR');
  }
};
