import React, { useState } from 'react';
import 'styled-components/macro';
import { Box } from 'atomic-layout';
import { Text, LoadingButton, FormTextArea, Icon, FormMessage } from '@ffn/ui';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { useTranslation } from 'lib/hooks';

export function DisputeStep({
  isSubmitting,
  handleSubmit,
  handleBack,
  errorMessage
}) {
  const { t } = useTranslation();
  const [disputeReason, setDisputeReason] = useState(undefined);
  return (
    <>
      <Text>{t('alerts.accountRemovalRequest.youHaveChosenNotToRemove')}</Text>
      <FormTextArea
        value={disputeReason}
        data-testid="disputeAccountRemovalTextbox"
        onChange={e => setDisputeReason(e.target.value)}
      ></FormTextArea>
      {errorMessage ? (
        <Box marginTop={8}>
          <FormMessage variant="error">
            {t('alerts.accountRemovalRequest.somethingWentWrong')}
          </FormMessage>
        </Box>
      ) : null}
      <Box flex justifyContent="center" width="100%" marginTop={16}>
        <Box width={300}>
          <LoadingButton
            data-testid="submitAccountDisputeButton"
            width={'100%'}
            variant="primary"
            disabled={!disputeReason || disputeReason.length === 0}
            isLoading={isSubmitting}
            height={40}
            leftEnhancer={<Icon icon={ChevronThinRight} size={14} />}
            onClick={() => handleSubmit({ disputeReason })}
          >
            {t('alerts.accountRemovalRequest.submitAccountDispute')}
          </LoadingButton>
        </Box>
      </Box>
      {isSubmitting ? null : (
        <Box flex justifyContent="center" width="100%">
          <Box width={300}>
            <Text
              data-testid="submitAccountDisputeBackButton"
              css={`
                text-align: center;
                :hover {
                  cursor: pointer;
                  text-decoration: underline;
                }
              `}
              onClick={handleBack}
            >
              {t('alerts.accountRemovalRequest.back')}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
}
