import React from 'react';
import { Box, Typography } from '@achieve/sunbeam';
import { useTranslation } from 'lib/hooks';
import FallBackLoader from '../FallBackLoader';

/**
 * This component is used to display loading screens while the application is
 * loading initial data (ie: the bootstrap call)
 * @returns {JSX.Element}
 * @constructor
 */
export function BootstrapLoader() {
  const { t } = useTranslation();
  const LOADING_MESSAGES = [
    <MessageContainer content={t('bootstrapLoader.pullingUp')} />,
    <MessageContainer content={t('bootstrapLoader.holdTight')} />,
    <MessageContainer content={t('bootstrapLoader.finishingUp')} />
  ];
  const [messageIndex, setMessageIndex] = React.useState(0);
  React.useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex(prevIndex => {
        const totalMessages = LOADING_MESSAGES.length - 1;
        const isFinal = prevIndex === totalMessages;
        return isFinal ? totalMessages : prevIndex + 1;
      });
    }, 3500); // Change message every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [LOADING_MESSAGES.length]);

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="20px"
    >
      <Box width="375px" display="flex" alignItems="center">
        {LOADING_MESSAGES[messageIndex]}
      </Box>
      <FallBackLoader />
    </Box>
  );
}

export const MessageContainer = ({ content }) => {
  return (
    <Typography variant="ascendDisplayMd" textAlign="center" width="100%">
      {content}...
    </Typography>
  );
};
