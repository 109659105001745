import { axiosInstance as axios } from 'lib/axios';

export const loadDocuSign = async ({
  fullName,
  email,
  envelopeId,
  alertId,
  clientGuid
}) => {
  try {
    const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/docusignUrl`;
    const response = await axios.post(endpoint, {
      fullName,
      email,
      envelopeId,
      alertId,
      clientGuid
    });

    return response?.data?.url;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default loadDocuSign;
