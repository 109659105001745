import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Button } from '@achieve/sunbeam';
import 'styled-components/macro';
import { useClientEducation } from 'lib/hooks';
import { getUserRecord } from 'shared-selectors/userRecord';

function FinalAckScreen({ hideAckScreen }) {
  const { closeLesson } = useClientEducation();
  const { firstName } = useSelector(getUserRecord);

  if (hideAckScreen) return null;

  return (
    <Box
      sx={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 'calc(100% - 64px)'
      }}
    >
      <img
        alt="fireworks"
        src={
          'https://images.ctfassets.net/b32zuu6bt176/3L8RqwJar0oec3KQ3j9wru/fdc7b25b96e873941e703430750bad59/100-_Icon.svg'
        }
        width="40%"
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
          marginTop: '16px'
        }}
      />
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: '3rem'
        }}
      >
        <Typography variant="displayS20" fontWeight="bold">
          Congrats on completing your orientation
          {firstName ? `, ${firstName}` : ''}!
        </Typography>
      </Box>
      <Typography variant="displayS10" fontWeight="bold">
        Key Takeaways
      </Typography>
      <ul style={{ marginBottom: '3rem' }}>
        <li>
          <Typography variant="displayXS30">
            Make all your program deposits on time and in the full amount.{' '}
          </Typography>
        </li>
        <li>
          <Typography variant="displayXS30">
            Alerts and settlement offers are time-sensitive: Respond to them
            quickly to keep your program on track.{' '}
          </Typography>
        </li>
        <li>
          <Typography variant="displayXS30">
            Voluntarily stop paying creditors so they will be open to our
            negotiations. When they call you, stay calm—we have all the guidance
            you need for these calls in the “Program Resources” section of your
            Client Dashboard.
          </Typography>
        </li>
      </ul>
      <Box
        sx={{
          paddingTop: '2rem'
        }}
      >
        <Button
          data-testid="client-orientation-complete-button"
          sx={{
            display: 'flex',
            margin: 'auto',
            fontWeight: 'bold'
          }}
          onClick={closeLesson}
        >
          <Typography variant="displayS11CTA">Complete</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default FinalAckScreen;
