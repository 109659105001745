import React from 'react';
import styled from 'styled-components';
import { Box } from 'atomic-layout';
import { Icon } from '@ffn/ui';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { useTranslation } from 'lib/hooks';

const StyledBox = styled(Box)`
  color: ${props => props.theme.colors.secondaryGreen};
`;

export function SuccessMessageText({ type }) {
  const { t } = useTranslation();
  const text =
    type === 'renew'
      ? t('bankAccountChange.authText')
      : t('bankAccountChange.successText');
  return (
    <StyledBox data-testid="bank-account-change-success-message">
      <Icon icon={CheckCircle} size={20} /> {text}
    </StyledBox>
  );
}
