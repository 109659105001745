import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth, useBrand, useLocale, useTranslation } from 'lib/hooks';
import 'styled-components/macro';
import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownSeparator,
  Icon,
  Image
} from '@ffn/ui';
import { Logout } from '@ffn/icons';
import { FeatureFlag, LanguageToggle } from 'shared-components';
import { Box, Composition, Only } from 'atomic-layout';
import { MobileNav } from './MobileNav';
import { getUrlExtension } from 'lib/utils';
import { getTestIds } from 'lib/utils/dataTestIds';
import { modelsStatus } from 'shared-selectors/dataStatus';

const headerTestIDs = getTestIds('header');

const mobileTemplate = `
  menus brand account
`;
const desktopTemplate = `
  brand menus account
`;

function DesktopNav({ showMenuItems }) {
  const brand = useBrand();
  const { t } = useTranslation();
  const { changeLocale, locale } = useLocale();
  const firstName = useSelector(state => state.userRecord?.firstName);
  const { logout } = useAuth();

  return (
    <Composition
      as={Container}
      areas={mobileTemplate}
      areasLg={desktopTemplate}
      templateCols="50px 1fr 50px"
      templateRows="64px"
      templateColsLg="min-content max-content 1fr"
      templateRowsLg="80px"
      alignItems="center"
    >
      {({ Menus, Brand, Account }) => (
        <>
          <Brand flex justifyContent="center" marginRight={16}>
            <NavLink
              to="/"
              css={`
                display: block;
              `}
              data-testid={headerTestIDs.brand_home_link}
            >
              {getUrlExtension(brand('logo.main')) === 'svg' ? (
                <Image
                  src={brand('logo.main')}
                  css={`
                    height: 48px;
                  `}
                />
              ) : (
                <Image
                  src={brand('logo.main')}
                  css={`
                    max-height: 48px;
                    max-width: 180px;
                    vertical-align: middle;
                  `}
                />
              )}
            </NavLink>
          </Brand>
          {showMenuItems && (
            <>
              <Account justify="end">
                {firstName && (
                  <Dropdown
                    content={
                      <>
                        {/* <FeatureFlag pathKey="/settings/program">
													<DropdownItem as={NavLink} to="/settings/program">
														<Icon icon={AccountSettings} size={20} />
														<span style={{ marginLeft: 8 }} data-testid={headerTestIDs.account_settings_link}>
															Account Settings
														</span>
													</DropdownItem>
												</FeatureFlag> */}
                        <DropdownItem
                          onClick={event => logout()}
                          css={`
                            cursor: pointer;
                          `}
                        >
                          <Icon icon={Logout} size={20} />
                          <span
                            style={{ marginLeft: 8 }}
                            data-testid={headerTestIDs.account_logout_link}
                          >
                            {t('nav.logout')}
                          </span>
                        </DropdownItem>
                        <FeatureFlag flagKey="nav.spanish-toggle">
                          <DropdownSeparator />
                          <LanguageToggle
                            language={locale}
                            changeLocale={changeLocale}
                          />
                        </FeatureFlag>
                      </>
                    }
                    placement="bottom-end"
                  >
                    <Box
                      flex
                      alignItems="center"
                      css={`
                        cursor: pointer;
                      `}
                      height="100%"
                    >
                      <span
                        css={`
                          font-size: 13px;
                          font-weight: 400;
                          margin-left: 8px;
                          color: ${({ theme }) => theme.colors.primaryBlue};
                        `}
                        data-testid={headerTestIDs.account_menu_button}
                      >
                        {firstName}
                      </span>
                    </Box>
                  </Dropdown>
                )}
              </Account>
            </>
          )}
        </>
      )}
    </Composition>
  );
}

export function OnboardingNav() {
  const { isAuthenticated } = useAuth();
  const modelsLoadingStatus = useSelector(modelsStatus);
  const MODELS_LOADED = modelsLoadingStatus === 'success';

  const SHOW_MENU_ITEMS = isAuthenticated && MODELS_LOADED;

  return (
    <>
      <Only to="lg">
        <MobileNav showMenuItems={SHOW_MENU_ITEMS} />
      </Only>
      <Only from="lg">
        <DesktopNav showMenuItems={SHOW_MENU_ITEMS} />
      </Only>
    </>
  );
}

export default OnboardingNav;
