import React from 'react';
import { Box } from 'atomic-layout';
import 'styled-components/macro';
import {
  formatWithOptions,
  format,
  parseISO,
  differenceInDays,
  isDate
} from 'date-fns/fp';
import { isToday, isYesterday } from 'date-fns';
import * as R from 'ramda';
import { NavLink } from 'react-router-dom';
import { useLocale, useTranslation } from 'lib/hooks';

const isWithinSevenDays = R.pipe(
  differenceInDays(new Date()),
  Math.abs,
  R.gte(7)
);

export function NotificationListItem({ unread, title, date, referenceIds }) {
  const [firstId] = referenceIds;
  const { t } = useTranslation();
  const { dateFnsLocale } = useLocale();

  const formatAlertDate = R.pipe(
    R.when(R.complement(isDate), parseISO),
    R.cond([
      [isToday, format('p')],
      [isYesterday, R.always(t('common.datetime.yesterday'))],
      [isWithinSevenDays, formatWithOptions({ locale: dateFnsLocale }, 'cccc')],
      [R.T, format('MM/dd/yy')]
    ])
  );

  return (
    <Box
      as={NavLink}
      to={`${firstId}`}
      css={`
        text-decoration: none;
        cursor: pointer;
        color: initial;
        border-bottom: 1px solid ${props => props.theme.colors.grayRegular};
        &.active {
          background: ${props => props.theme.colors.grayLight};
        }
        &:hover {
          background: hsla(0, 0%, 94.9%, 0.5);
        }
      `}
    >
      <Box
        flex
        alignItems="center"
        paddingHorizontal={16}
        paddingVertical={8}
        data-testid={'alert-header'}
      >
        <Box size={8} marginRight={16}>
          {unread && (
            <div
              css={`
                background: ${({ theme }) => theme.colors.secondaryRed};
                border-radius: 50%;
                width: 8px;
                height: 8px;
              `}
            />
          )}
        </Box>
        <Box
          data-testid={'alert-title'}
          as={unread ? 'strong' : 'span'}
          flexGrow={1}
          css={`
            word-break: break-word;
          `}
        >
          {title}
        </Box>
        <Box
          as="small"
          marginLeft={16}
          css={`
            font-size: 10px;
            white-space: nowrap;
          `}
        >
          {formatAlertDate(date)}
        </Box>
      </Box>
    </Box>
  );
}
