import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownSeparator,
  Icon,
  Link,
  NavItem
} from '@ffn/ui';
import { FeatureFlag } from 'shared-components';
import { useSingleton } from '@tippyjs/react';
import 'styled-components/macro';
import { CustomerServiceHours } from './CustomerServiceHours';
import { useBrand, useTranslation } from 'lib/hooks';
import { getTestIds } from 'lib/utils/dataTestIds';
import { formatPhoneNumberSafe } from 'lib/utils';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { EVENT_ACTION } from 'lib/utils/eventHorizon';
import { Clock, Phone } from 'react-feather';

const headerTestIDs = getTestIds('header');

export function DesktopNavItems() {
  const { t } = useTranslation();
  const brand = useBrand();
  const [source, target] = useSingleton();
  const enrolledDebtRef = useRef();
  const programResoucesRef = useRef();
  const customerServiceRef = useRef();

  return (
    <>
      <FeatureFlag pathKey="/enrolled-debt">
        <NavItem
          as={NavLink}
          to="/enrolled-debt"
          ref={enrolledDebtRef}
          data-testid={headerTestIDs.enrolled_debt_link}
          data-tour="enrolled-debt-nav"
          onClick={() =>
            analyticsTrackEvent(
              {
                event_action: EVENT_ACTION.LINK_CLICK,
                nav_link_section: 'Mega Menu',
                mega_menu_column: '',
                mega_menu_tab: 'Enrolled Debt',
                mega_menu_link: 'Enrolled Debt',
                track_event: 'mega_menu'
              },
              'Enrolled Debt Link Click'
            )
          }
        >
          {t('nav.enrolledDebt')}
        </NavItem>
      </FeatureFlag>
      <FeatureFlag pathKey="/dedicated-account">
        <NavItem
          as={NavLink}
          to="/dedicated-account"
          data-testid={headerTestIDs.dedicated_account_link}
          data-tour="dedicated-account-nav"
          onClick={() =>
            analyticsTrackEvent(
              {
                event_action: EVENT_ACTION.LINK_CLICK,
                nav_link_section: 'Mega Menu',
                mega_menu_column: 'Dedicated Account',
                mega_menu_tab: 'Dedicated Account',
                mega_menu_link: 'Dedicated Account',
                track_event: 'mega_menu'
              },
              'Dedicated Account Link Click'
            )
          }
        >
          {t('nav.dedicatedAccount')}
        </NavItem>
      </FeatureFlag>
      <FeatureFlag pathKey="/program-resources">
        <NavItem
          as={NavLink}
          to="/program-resources"
          ref={programResoucesRef}
          data-testid={headerTestIDs.program_resources_link}
          data-tour="program-resources-nav"
          onClick={() =>
            analyticsTrackEvent(
              {
                event_action: EVENT_ACTION.LINK_CLICK,
                nav_link_section: 'Mega Menu',
                mega_menu_column: '',
                mega_menu_tab: 'Program Resources',
                mega_menu_link: 'Program Resources',
                track_event: 'mega_menu'
              },
              'Program Resources Link Click'
            )
          }
        >
          {t('nav.programResources')}
        </NavItem>
      </FeatureFlag>
      <FeatureFlag pathKey="/customer-service">
        <NavItem
          as={NavLink}
          to="/customer-service"
          ref={customerServiceRef}
          data-testid={headerTestIDs.customer_service_link}
          data-tour="customer-service-nav"
          onClick={() =>
            analyticsTrackEvent(
              {
                event_action: EVENT_ACTION.LINK_CLICK,
                nav_link_section: 'Mega Menu',
                mega_menu_column: '',
                mega_menu_tab: 'Customer Service',
                mega_menu_link: 'Customer Service',
                track_event: 'mega_menu'
              },
              'Customer Service Link Click'
            )
          }
        >
          {t('nav.customerService')}
        </NavItem>
      </FeatureFlag>

      <Dropdown
        singleton={source}
        maxWidth="none"
        appendTo={document.getElementById('root')}
      />
      <Dropdown
        content={
          <>
            <DropdownItem
              as={NavLink}
              to="/enrolled-debt"
              data-testid={headerTestIDs.enrolled_debt_dropdown_link}
              onClick={() =>
                analyticsTrackEvent(
                  {
                    event_action: EVENT_ACTION.DROPDOWN_SELECT,
                    nav_link_section: 'Mega Menu',
                    mega_menu_column: '',
                    mega_menu_tab: 'Enrolled Debt',
                    mega_menu_link: 'Enrolled Debt List',
                    track_event: 'mega_menu'
                  },
                  'Enrolled Debt List Link Click'
                )
              }
            >
              {t('nav.enrolledDebtList')}
            </DropdownItem>
            <FeatureFlag pathKey="/enrolled-debt/negotiation-overview">
              <DropdownItem
                as={NavLink}
                to="/enrolled-debt/negotiation-overview/upcoming"
                data-testid={headerTestIDs.negotiation_overview_dropdown_link}
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Enrolled Debt',
                      mega_menu_link: 'Negotiation Overview',
                      track_event: 'mega_menu'
                    },
                    'Negotiation Overview Link Click'
                  )
                }
              >
                {t('nav.negotiationOverview')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/enrolled-debt/settlement-overview">
              <DropdownItem
                as={NavLink}
                to="/enrolled-debt/settlement-overview"
                data-testid={headerTestIDs.settlement_overview_dropdown_link}
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Enrolled Debt',
                      mega_menu_link: 'Settlement Overview',
                      track_event: 'mega_menu'
                    },
                    'Settlement Overview Link Click'
                  )
                }
              >
                {t('nav.settlementOverview')}
              </DropdownItem>
            </FeatureFlag>
          </>
        }
        singleton={target}
        reference={enrolledDebtRef}
      />
      <Dropdown
        content={
          <>
            <FeatureFlag pathKey="/customer-service">
              <DropdownItem
                as={NavLink}
                to="/customer-service"
                data-testid={headerTestIDs.customer_service_link}
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Customer Service',
                      mega_menu_link: 'Contact Us',
                      track_event: 'mega_menu'
                    },
                    'Contact Us Link Click'
                  )
                }
              >
                {t('nav.contactUs')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/customer-service/deposits">
              <DropdownItem
                as={NavLink}
                to="/customer-service/deposits"
                data-testid={headerTestIDs.dedicated_account_dropdown_link}
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Customer Service',
                      mega_menu_link: 'Manage Dedicated Accounts',
                      track_event: 'mega_menu'
                    },
                    'Manage Dedicated Accounts Link Click'
                  )
                }
              >
                {t('nav.manageDedicatedAccount')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/customer-service/bank-account-change">
              <DropdownItem
                as={NavLink}
                to="/customer-service/bank-account-change"
                data-testid={headerTestIDs.account_change_dropdown_link}
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Customer Service',
                      mega_menu_link: 'Bank Account Change',
                      track_event: 'mega_menu'
                    },
                    'Bank Account Change Link Click'
                  )
                }
              >
                {t('nav.bankAccountChange')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/customer-service/enroll-new-debt">
              <DropdownItem
                as={NavLink}
                to="/customer-service/enroll-new-debt"
                data-testid={headerTestIDs.enroll_debt_dropdown_link}
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Customer Service',
                      mega_menu_link: 'Enroll New Debt',
                      track_event: 'mega_menu'
                    },
                    'Enroll New Debt Link Click'
                  )
                }
              >
                {t('nav.enrollNewDebt')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/customer-service/document-upload">
              <DropdownItem
                as={NavLink}
                to="/customer-service/document-upload"
                data-testid={headerTestIDs.upload_document_dropdown_link}
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Customer Service',
                      mega_menu_link: 'Document Upload',
                      track_event: 'mega_menu'
                    },
                    'Document Upload Link Click'
                  )
                }
              >
                {t('nav.uploadDocument')}
              </DropdownItem>
            </FeatureFlag>
            <DropdownSeparator />
            <DropdownItem
              as={Link}
              href={'tel:' + brand('contact.customer-service.phone')}
              dark
              css={`
                display: flex;
                align-items: center;
                line-height: 30px;
                padding: 8px 24px;
                color: #213f7e;
                font-weight: 600;

                &:hover {
                  background: #f2f2f2;
                  opacity: 1;
                }
              `}
              data-testid={headerTestIDs.phone_contact_dropdown_link}
              onClick={() =>
                analyticsTrackEvent(
                  {
                    event_action: EVENT_ACTION.DROPDOWN_SELECT,
                    nav_link_section: 'Mega Menu',
                    mega_menu_column: '',
                    mega_menu_tab: 'Customer Service',
                    mega_menu_link: 'Phone',
                    track_event: 'mega_menu'
                  },
                  'Phone Link Click'
                )
              }
            >
              <Icon
                icon={Phone}
                size={20}
                css={`
                  margin-right: 10px;
                `}
              />
              {formatPhoneNumberSafe(brand('contact.customer-service.phone'))}
            </DropdownItem>
            <DropdownItem
              hover={false}
              css={'padding: 8px 24px; display: flex; align-items: flex-start;'}
            >
              <Icon icon={Clock} size={20} />
              <CustomerServiceHours />
            </DropdownItem>
          </>
        }
        singleton={target}
        reference={customerServiceRef}
      />
      <Dropdown
        content={
          <>
            <FeatureFlag pathKey="/program-resources/how-it-works">
              <DropdownItem
                as={NavLink}
                to="/program-resources/how-it-works"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Program Resources',
                      mega_menu_link: 'How It Works',
                      track_event: 'mega_menu'
                    },
                    'How It Works Link Click'
                  )
                }
              >
                {t('nav.howItWorks')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/program-resources/creditor-calls">
              <DropdownItem
                as={NavLink}
                to="/program-resources/creditor-calls"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Program Resources',
                      mega_menu_link: 'Handling Creditor Calls',
                      track_event: 'mega_menu'
                    },
                    'Handling Creditor Calls Link Click'
                  )
                }
              >
                {t('nav.creditorCalls')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/program-resources/credit-impact">
              <DropdownItem
                as={NavLink}
                to="/program-resources/credit-impact"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Program Resources',
                      mega_menu_link: 'Credit Impact',
                      track_event: 'mega_menu'
                    },
                    'Credit Impact Link Click'
                  )
                }
              >
                {t('nav.creditImpact')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/program-resources/videos">
              <DropdownItem
                as={NavLink}
                to="/program-resources/videos"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Program Resources',
                      mega_menu_link: 'Videos',
                      track_event: 'mega_menu'
                    },
                    'Videos Link Click'
                  )
                }
              >
                {t('nav.videos')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/program-resources/faq">
              <DropdownItem
                as={NavLink}
                to="/program-resources/faq"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Program Resources',
                      mega_menu_link: 'FAQ',
                      track_event: 'mega_menu'
                    },
                    'FAQ Link Click'
                  )
                }
              >
                {t('nav.faq')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/program-resources/program-glossary">
              <DropdownItem
                as={NavLink}
                to="/program-resources/program-glossary"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Program Resources',
                      mega_menu_link: 'Program Glossary',
                      track_event: 'mega_menu'
                    },
                    'Program Glossary Link Click'
                  )
                }
              >
                {t('nav.programGlossary')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/program-resources/helpful-documents">
              <DropdownItem
                as={NavLink}
                to="/program-resources/helpful-documents"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Program Resources',
                      mega_menu_link: 'Helpful Documents',
                      track_event: 'mega_menu'
                    },
                    'Helpful Documents Link Click'
                  )
                }
              >
                {t('nav.helpfulDocuments')}
              </DropdownItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/program-resources/blog">
              <DropdownItem
                as={NavLink}
                to="/program-resources/blog"
                onClick={() =>
                  analyticsTrackEvent(
                    {
                      event_action: EVENT_ACTION.DROPDOWN_SELECT,
                      nav_link_section: 'Mega Menu',
                      mega_menu_column: '',
                      mega_menu_tab: 'Program Resources',
                      mega_menu_link: 'Blog',
                      track_event: 'mega_menu'
                    },
                    'Blog Link Click'
                  )
                }
              >
                {t('nav.blog')}
              </DropdownItem>
            </FeatureFlag>
          </>
        }
        reference={programResoucesRef}
        singleton={target}
      />
    </>
  );
}
