import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@achieve/sunbeam';
import { useTranslation } from 'lib/hooks';

const displayDuration = 5000;

export const Loading = ({ testIdPrefix }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const messages = {
    initial: t('oneClick.holdTightThisShouldnTTakeLong'),
    finishing: t('oneClick.finishingUp')
  };
  const [message, setMessage] = React.useState(messages.initial);
  /** Starting a timer to change the message after 4 seconds */
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setMessage(messages.finishing);
    }, displayDuration);

    return () => {
      clearTimeout(timeout);
    };
  }, [messages.finishing]);
  const blue = theme?.palette?.primary?.main ?? '#154199';

  return (
    <Box
      data-testid={`${testIdPrefix}-loading-container`}
      display="flex"
      flexDirection="column"
      gap="28px"
      maxWidth="370px"
      alignItems="center"
    >
      <Typography
        variant="displayM30"
        fontWeight="500"
        fontSize="28px"
        textAlign="center"
      >
        {message}
      </Typography>
      <CircularProgress sx={{ color: blue }} />
    </Box>
  );
};
