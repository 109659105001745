import React from 'react';
import { FormDatePicker } from '@ffn/ui';
import { useField } from 'formik';
import { isValid } from 'date-fns';
import {
  endOfYear,
  endOfMonth,
  endOfDay,
  startOfYear,
  startOfMonth,
  startOfDay
} from 'date-fns/fp';
import { useLocale } from 'lib/hooks';
import * as R from 'ramda';

const getStartDate = R.cond([
  [R.has('showYearPicker'), R.always(startOfYear)],
  [R.has('showMonthYearPicker'), R.always(startOfMonth)],
  [R.T, R.always(startOfDay)]
]);

const getEndDate = R.cond([
  [R.has('showYearPicker'), R.always(endOfYear)],
  [R.has('showMonthYearPicker'), R.always(endOfMonth)],
  [R.T, R.always(endOfDay)]
]);

const getDate = R.cond([
  [R.has('selectsStart'), getStartDate],
  [R.has('selectsEnd'), getEndDate],
  [R.T, R.always(R.identity)]
]);

export function CustomDatePicker({
  msgs = [],
  successMsg,
  dateFormat = 'MM/dd/yyyy',
  disabled,
  name,
  id,
  placeholder,
  onFocus,
  onBlur,
  className,
  autoComplete = 'off',
  ...rest
}) {
  const { locale } = useLocale();
  const [{ value }, meta, { setValue }] = useField(name);
  const errMsg = meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && !meta.error ? { type: 'success', message: successMsg } : [];
  const handleChangeRaw = event => {
    const { value: newValue } = event.target;
    const validChars = /^\d{0,2}[./]{0,1}\d{0,2}[./]{0,1}\d{0,4}$/;
    if (!validChars.test(newValue)) {
      event.preventDefault();
    }

    if (isValid(new Date(newValue))) {
      setValue(newValue);
    }
  };

  const handleChange = date => {
    if (date && isValid(date)) {
      setValue(getDate(rest)(date));
    } else {
      setValue('');
    }
  };
  return (
    <FormDatePicker
      {...rest}
      disabledKeyboardNavigation
      autoComplete={autoComplete}
      id={id || name}
      name={name}
      className={className}
      selected={value && new Date(value)}
      placeholderText={placeholder}
      dateFormat={dateFormat}
      onChangeRaw={handleChangeRaw}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      locale={locale}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
}
