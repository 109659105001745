import PropTypes from 'prop-types';
import React from 'react';

import { WithoutSidebar } from './WithoutSidebar';
import { WithSidebar } from './WithSidebar';
import { useAllowedSidebarFeatures } from './hooks/useAllowedSidebarFeatures';

import 'styled-components/macro';

const PageLayout = ({
  children,
  sidebarFeatures = [],
  breadCrumbs = [],
  pageTitle = '',
  ...props
}) => {
  const allowedSidebarFeatures = useAllowedSidebarFeatures(sidebarFeatures);
  const hasSidebar = allowedSidebarFeatures.length > 0;

  return hasSidebar ? (
    <WithSidebar
      data-testid="layout-with-sidebar"
      sidebarFeatures={allowedSidebarFeatures}
      pageTitle={pageTitle}
      breadCrumbs={breadCrumbs}
      {...props}
    >
      {children}
    </WithSidebar>
  ) : (
    <WithoutSidebar
      data-testid="layout-without-sidebar"
      pageTitle={pageTitle}
      breadCrumbs={breadCrumbs}
      {...props}
    >
      {children}
    </WithoutSidebar>
  );
};

PageLayout.propTypes = {
  children: PropTypes.any.isRequired,
  sidebarFeatures: PropTypes.array,
  breadCrumbs: PropTypes.array
};

export { PageLayout };
