import React, { createContext, useMemo, useState } from 'react';
import { useDashboardType } from 'lib/hooks';
import { SITE_CONFIG } from '../../constants/siteConfig';

export const siteDataContext = createContext();

export function ProvideSiteData({ children }) {
  const { subDomain } = useDashboardType();
  const [eduOpen, setEduOpen] = useState(false);
  const [subDrawerOpen, setSubDrawerOpen] = useState([false, false, false]);
  const [eduTab, setEduTab] = useState(0);
  const [shouldEduShow, setShouldEduShow] = useState(null);
  const [ldLoading, setLdLoading] = useState(false);
  const featureConfig = SITE_CONFIG[subDomain].features;
  const routesConfig = SITE_CONFIG[subDomain].routes;
  const brandConfig = SITE_CONFIG[subDomain].brandConfig;
  const globalConfig = SITE_CONFIG[subDomain].globalConfig;
  const pages = SITE_CONFIG[subDomain].pages;

  const contextValue = useMemo(() => {
    return {
      features: featureConfig,
      routes: routesConfig,
      brandConfig: brandConfig,
      pages: pages,
      globalConfig: globalConfig,
      eduOpen,
      setEduOpen,
      eduTab,
      setEduTab,
      shouldEduShow,
      setShouldEduShow,
      ldLoading,
      setLdLoading,
      subDrawerOpen,
      setSubDrawerOpen,
      subDomain
    };
  }, [
    featureConfig,
    routesConfig,
    brandConfig,
    pages,
    globalConfig,
    eduOpen,
    eduTab,
    ldLoading,
    shouldEduShow,
    subDomain,
    subDrawerOpen
  ]);

  return (
    <siteDataContext.Provider value={contextValue}>
      {children}
    </siteDataContext.Provider>
  );
}
