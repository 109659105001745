import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Currency, DashForm } from 'shared-components';
import { PhoneNumber } from 'shared-components/NumberFormatters';
import {
  getOmittedAccounts,
  getNextEffectiveDate,
  getRemainingMonths
} from 'shared-selectors/omittedAccounts';
import { useSelector } from 'react-redux';
import { Box } from 'atomic-layout';
import { FormMessage, Link, FormCheckbox } from '@ffn/ui';
import { alertUpdate, refreshNotifications } from 'lib/api';
import { format, parseISO } from 'date-fns/fp';
import * as yup from 'yup';
import { AccountForm } from './AccountForm';
import { Review } from './Review';
import { Success } from './Success';
import { useBrand, useNotificationTracking } from 'lib/hooks';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'lib/hooks';
import styled from 'styled-components';

const PlainBox = styled.div`
  background-color: ${props => props.theme.colors.plain};
  padding: ${props => (props.isResolved ? '0px' : '16px')};
  padding-top: 0;
`;

export function AddAccountOpportunity({
  notification,
  viewLocation,
  isVisible
}) {
  const { t } = useTranslation();

  const {
    trackingLabel,
    payload: { omittedAccountAlertMap }
  } = notification;

  const [error, setError] = useState('');
  const [step, setStep] = useState('form');
  const trackNotificationView = useNotificationTracking();
  const client = useSelector(state => state?.models?.client);
  const userRecord = useSelector(state => state.userRecord);
  const omittedAccounts = useSelector(getOmittedAccounts);
  const nextDraftDate = useSelector(getNextEffectiveDate);
  const remainingMonths = useSelector(getRemainingMonths);

  const includedAccounts = Object.keys(omittedAccountAlertMap);
  const filteredOmittedAccounts = omittedAccounts.filter(acct => {
    return includedAccounts.includes(acct.omittedAccountId);
  });

  const [selectedAccounts, setSelectedAccounts] = useState(
    filteredOmittedAccounts.map(acct => acct.omittedAccountId)
  );
  const [unSelectedReasons, setUnSelectedReasons] = useState({});
  const brand = useBrand();

  useEffect(() => {
    trackNotificationView({
      trackingLabel,
      viewLocation,
      isVisible
    });
  }, [trackingLabel, viewLocation, isVisible, trackNotificationView]);

  const toggleSelectedItem = ev => {
    const itemId = ev.currentTarget.value;
    if (selectedAccounts.includes(itemId)) {
      setSelectedAccounts(selectedAccounts.filter(acctId => acctId !== itemId));
    } else {
      setSelectedAccounts([...selectedAccounts, itemId]);
    }
  };

  const sharedColumns = {
    checkbox: {
      Header: t('alerts.addAccountOpportunity.enroll'),
      width: 'auto',
      Cell: ({ row }) => {
        return (
          <FormCheckbox
            name="enrollCheckbox"
            value={row.original.omittedAccountId}
            onChange={toggleSelectedItem}
            checked={selectedAccounts.includes(row.original.omittedAccountId)}
          />
        );
      }
    },
    creditor: {
      Header: t('alerts.addAccountOpportunity.currentCreditor'),
      accessor: 'creditorName',
      width: '1fr'
    },
    originalCreditor: {
      Header: t('alerts.addAccountOpportunity.originalCreditor'),
      accessor: 'originalCreditor',
      width: '1fr',
      Cell: ({ value, row }) => (
        <Box flex flexDirection="column">
          {value || row.original.creditorName}
        </Box>
      )
    },
    accountNumber: {
      Header: t('alerts.addAccountOpportunity.accountEndingIn'),
      accessor: 'accountNumber',
      width: '1fr',
      Cell: ({ value, row }) => {
        if (row.original.opportunitySource === 'CBR New Account') {
          return '-';
        } else {
          return (
            <Box flex flexDirection="column">
              <span>****{value.slice(value.length - 4)}</span>
            </Box>
          );
        }
      }
    },
    reportingDate: {
      Header: t('alerts.addAccountOpportunity.reportingDate'),
      accessor: 'reportingDate',
      width: '1fr',
      Cell: ({ value, row }) => {
        if (row.original.reportingDate) {
          const formattedDate = format('MM/dd/yyyy', parseISO(value));
          return (
            <Box flex flexDirection="column">
              <span>{formattedDate}</span>
            </Box>
          );
        } else {
          return '-';
        }
      }
    },
    currentBalance: {
      Header: t('alerts.addAccountOpportunity.currentBalance'),
      accessor: 'balance',
      width: '1fr',
      Cell: ({ value }) => <Currency value={value} />
    },
    addedAccountDraftsIncrease: {
      Header: t('alerts.addAccountOpportunity.depositIncreases'),
      accessor: 'addedAccountDraftsIncrease',
      width: '1fr',
      Cell: ({ value }) => <Currency value={value} />
    }
  };

  const csClientId = userRecord?.csClientId;

  const app = userRecord?.app;
  const isFDR = app && app === 'fdr';
  // This copy has to be different as of 01/2023 b/c of legal requirements.
  // Less than ideal, but this is the simplest implementation.
  // We have to also pass this to <AccountForm /> for non-GSC clients
  const IntroText = ({ isFDR }) => {
    // Returning two separate <strong> tags because they'll have different data-testids
    return isFDR ? (
      <strong data-testid="aao-legal-intro">
        {' '}
        {`${t(
          'alerts.addAccountOpportunity.itAppearsThereMayBeADebtOrMultipleDebts'
        )} ${brand('business-name.abbr')} ${t(
          'alerts.addAccountOpportunity.programThatWeMayBeAbleToHelpYouResolve'
        )}`}
      </strong>
    ) : (
      <strong data-testid="aao-original-intro">
        {`${t(
          'alerts.addAccountOpportunity.weVeIdentifiedDebtThatYouDonTHaveEnrolledInYour'
        )} 
          ${brand('business-name.abbr')} ${t(
          'alerts.addAccountOpportunity.programWhichWeCanHelpYouResolvePleaseReview'
        )}
          ${brand('contact.customer-service.phone')} ${t(
          'alerts.addAccountOpportunity.toEnrollThisDebtSoWeCanNegotiateOnYourBehalf'
        )}`}
      </strong>
    );
  };

  return (
    <PlainBox>
      <Box>
        {client.spa === 'GCS' && (
          <Box paddingVertical={12}>
            <IntroText isFDR={isFDR} />
            <p>
              {t('alerts.addAccountOpportunity.enrollingAdditional')}{' '}
              {brand('business-name.abbr')}{' '}
              {t('alerts.addAccountOpportunity.programOrYouMayWant')}
            </p>
            <strong>
              {t('alerts.addAccountOpportunity.enrollDebtNowByContacting')}:
            </strong>
            <strong>{t('alerts.addAccountOpportunity.questions')}</strong>
            <Link href={`tel:${brand('contact.customer-service.phone')}`}>
              {t('alerts.addAccountOpportunity.contactCustomerService')}
            </Link>
          </Box>
        )}
        {client.spa !== 'GCS' && (
          <Formik
            initialValues={{
              needsAuthorization: false,
              authorize: false,
              EFTA: false
            }}
            validationSchema={yup.object().shape({
              needsAuthorization: yup.boolean().required(),
              EFTA: yup.boolean().when('needsAuthorization', {
                is: true,
                then: yup
                  .bool()
                  .oneOf([true], t('common.form.validationsAndErrors.required'))
              }),
              authorize: yup.boolean().when('needsAuthorization', {
                is: true,
                then: yup
                  .bool()
                  .oneOf([true], t('common.form.validationsAndErrors.required'))
              })
            })}
            onSubmit={async (values, formikBag) => {
              formikBag.setSubmitting(true);
              setError('');
              // TODO: Refactor this API on the SalesForce side so that far less data is
              // required to be sent to the API.
              try {
                const apiPayload = filteredOmittedAccounts.map((acc, i) => {
                  return {
                    creditor_percentage: acc.creditorSettPercentage,
                    omitted_accounts_id: acc.omittedAccountId,
                    next_draft_date: format('MM/dd/yy', nextDraftDate),
                    alert_id: omittedAccountAlertMap[acc.omittedAccountId],
                    authorized_enrollment: selectedAccounts.includes(
                      acc.omittedAccountId
                    ),
                    client_id: csClientId,
                    creditor_prioritization: acc.creditorPrioritization,
                    decline_reason: !selectedAccounts.includes(
                      acc.omittedAccountId
                    )
                      ? unSelectedReasons[acc.omittedAccountId]
                      : null,
                    omitted_account_id: acc.omittedAccountId,
                    enrollment_status: acc.enrollmentStatus,
                    added_account_drafts_increase:
                      acc.addedAccountDraftsIncrease,
                    original_meta_creditor: acc.originalMetaCreditorId,
                    unenrollment_reason: acc.unEnrollmentReason,
                    opportunity_source: acc.opportunitySource,
                    original_creditor: acc.originalCreditor,
                    creditor: acc.creditorName,
                    balance: acc.balance,
                    account_number: acc.accountNumber,
                    client_name: `${client.firstName} ${client.lastName}`,
                    remaining_months: remainingMonths,
                    omitted_accounts_name: acc.name
                  };
                });

                await alertUpdate(apiPayload);
                analyticsTrackEvent(
                  {
                    category: 'alert',
                    action: 'success',
                    label: 'aao'
                  },
                  'Add Account Opportunity Success'
                );

                setStep('confirm');
                setError('');

                // Wait 12 seconds to give time for the success message to be read and then refresh notifications data in Firestore, which
                // should clear any notifications that prompted the user to take this action.
                setTimeout(refreshNotifications, 12000);
              } catch (error) {
                analyticsTrackEvent(
                  {
                    category: 'alert',
                    action: 'failure',
                    label: 'aao'
                  },
                  'Add Account Opportunity Failure'
                );
                setError(error.message);
              } finally {
                formikBag.setSubmitting(false);
              }
            }}
            onReset={() => setStep('form')}
          >
            {props => {
              return (
                <DashForm>
                  {error && (
                    <FormMessage variant="error">
                      {error === 'ALERT_UPDATE_FAILURE' ? (
                        <>
                          {t(
                            'alerts.addAccountOpportunity.thereWasAnErrorProcessing'
                          )}{' '}
                          <PhoneNumber
                            value={brand('contact.customer-service.phone')}
                          />
                        </>
                      ) : (
                        <>{t('common', error)}</>
                      )}
                    </FormMessage>
                  )}
                  {step === 'form' && (
                    <AccountForm
                      omittedAccounts={filteredOmittedAccounts}
                      sharedColumns={sharedColumns}
                      setError={setError}
                      setStep={setStep}
                      selectedAccounts={selectedAccounts}
                      setUnSelectedReasons={setUnSelectedReasons}
                      unSelectedReasons={unSelectedReasons}
                      introText={<IntroText isFDR={isFDR} />}
                    />
                  )}
                  {step === 'review' && (
                    <Review
                      client={client}
                      sharedColumns={sharedColumns}
                      omittedAccounts={filteredOmittedAccounts}
                      setStep={setStep}
                      setError={setError}
                      selectedAccounts={selectedAccounts}
                      unSelectedReasons={unSelectedReasons}
                    />
                  )}
                  {step === 'confirm' && (
                    <Success
                      sharedColumns={sharedColumns}
                      omittedAccounts={filteredOmittedAccounts}
                      selectedAccounts={selectedAccounts}
                    />
                  )}
                </DashForm>
              );
            }}
          </Formik>
        )}
      </Box>
    </PlainBox>
  );
}
