import React from 'react';
import { NavLink } from 'react-router-dom';
import 'styled-components/macro';

export function NavCard({ icon, label, description, href, ...props }) {
  return (
    <NavLink
      to={href}
      css={`
        width: 100%;
        font-weight: 600;
        padding-top: 30px;
        padding-bottom: 30px;
        border: 1px solid ${props => props.theme.colors.secondaryBlue};
        color: ${props => props.theme.colors.secondaryBlue};
        text-align: center;
        background-color: #fff;
        text-decoration: none;
        svg {
          margin-bottom: 15px;
        }
      `}
      {...props}
    >
      {icon}
      <div>{label}</div>
      <span
        css={`
          color: #4a4a4a;
          box-sizing: border-box;
          padding: 0 8%;
          text-align: center;
          width: 100%;
          display: inline-block;
          font-size: 12px;
          margin-top: 25px;
        `}
      >
        {description}
      </span>
    </NavLink>
  );
}
