import React from 'react';
import 'styled-components/macro';
import { Composition } from 'atomic-layout';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';

import { Icon, Button } from '@ffn/ui';

import { FeatureFlag } from 'shared-components';
import { CustomPanel } from '../CustomTabs';
import { NavLink } from 'react-router-dom';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useSelector } from 'react-redux';
import { getFutureDrafts } from 'shared-selectors/draftFees';
import UnableToPerformRequest from '../UnableToPerformRequest';
import { useTranslation } from 'lib/hooks';

export function Deposits() {
  const { t } = useTranslation();
  const futureDrafts = useSelector(getFutureDrafts);
  return (
    <CustomPanel heading={t('customerService.manageDedicatedAccount')} close>
      {futureDrafts.length > 0 ? (
        <Composition templateCols="1fr" templateColsLg="1fr 1fr 1fr" gap={24}>
          <FeatureFlag pathKey="/customer-service/deposits/one-time-deposit">
            <NavLink
              to="one-time-deposit"
              style={{ textDecoration: 'none', width: '100%' }}
            >
              <Button
                data-testid="one-time-deposit-button"
                onClick={({ target: { innerText } }) =>
                  analyticsTrackEvent(
                    {
                      category: 'csr',
                      label: 'additional_deposit',
                      action: 'select',
                      innerText
                    },
                    'Additional Deposit Selected'
                  )
                }
                variant="tertiary"
                height={40}
                width="100%"
                rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
              >
                {t('changeDepositWizard.oneTimeDeposit')}
              </Button>
            </NavLink>
          </FeatureFlag>
          <FeatureFlag pathKey="/customer-service/deposits/increase-program-deposit">
            <NavLink
              to="increase-program-deposit"
              style={{ textDecoration: 'none', width: '100%' }}
            >
              <Button
                data-testid="increase-program-deposit-button"
                onClick={({ target: { innerText } }) =>
                  analyticsTrackEvent(
                    {
                      category: 'csr',
                      label: 'increase_deposit',
                      action: 'select',
                      innerText
                    },
                    'Increase Deposit Selected'
                  )
                }
                variant="tertiary"
                height={40}
                width="100%"
                rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
              >
                {t('changeDepositWizard.increaseProgramDeposit')}
              </Button>
            </NavLink>
          </FeatureFlag>
          <FeatureFlag pathKey="/customer-service/deposits/change-deposit">
            <NavLink
              to="change-deposit"
              style={{ textDecoration: 'none', width: '100%' }}
            >
              <Button
                data-testid="change-deposit-button"
                onClick={({ target: { innerText } }) =>
                  analyticsTrackEvent(
                    {
                      category: 'csr',
                      label: 'change_deposit',
                      action: 'select',
                      innerText
                    },
                    'Change Deposit Selected'
                  )
                }
                variant="tertiary"
                height={40}
                width="100%"
                rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
              >
                {t('changeDepositWizard.changeSingleDeposit')}
              </Button>
            </NavLink>
          </FeatureFlag>
        </Composition>
      ) : (
        <UnableToPerformRequest />
      )}
    </CustomPanel>
  );
}

export default Deposits;
