import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { getClient } from 'shared-selectors/client';

const options = {
  allowRemoteClick: false,
  allowRemoteScroll: true,
  allowRemoteType: false,
  showUpscopeLink: false
};

export const UpscopeInit = () => {
  const userRecord = useSelector(state => state.userRecord);
  const { firstName, lastName, fdrApplicantId } = userRecord;

  const client = useSelector(getClient);
  const clientId = client?.clientId;

  useEffect(() => {
    const load = (winObj, u, doc) => {
      // need to add this value in bitbucket-pipeline
      const { REACT_APP_UPSCOPE_PUBLIC_API_KEY } = process.env;
      // eslint-disable-next-line func-names
      const i = function() {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments);
      };
      i.q = [];
      i.c = args => {
        i.q.push(args);
      };
      const l = () => {
        const script = doc.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://code.upscope.io/${REACT_APP_UPSCOPE_PUBLIC_API_KEY}.js`;
        const x = doc.getElementsByTagName('script')[0] || doc.body;
        x.parentNode.insertBefore(script, x);
      };
      if (typeof u !== 'function') {
        // eslint-disable-next-line no-param-reassign
        winObj.Upscope = i;
        l();
      }
    };

    if (typeof window !== 'undefined' && !window.Upscope) {
      const onSessionRequestedOrContinued = () => {
        analyticsTrackEvent(
          {
            label: 'Co-browse-start',
            action: 'yes_click',
            category: 'co_browse'
          },
          'Co-browse - Session Start - Client Join'
        );
      };
      const eventHandlers = {
        onSessionEnd() {
          analyticsTrackEvent(
            {
              label: 'Co-browse-end',
              action: 'end_session_click',
              category: 'co_browse'
            },
            'Co-browse - Session End - Client Leave'
          );
        }
      };

      load(window, window.Upscope, document);

      window.Upscope('init', {
        identities: [firstName, lastName, fdrApplicantId],
        uniqueId: clientId,
        ...options,
        ...eventHandlers
      });
      window.Upscope('on', 'sessionRequest', () => {
        analyticsTrackEvent(
          {
            label: 'Co-browse-requested',
            action: 'co_browse_pop_up',
            category: 'co_browse'
          },
          'Co-browse - Session Requested - Agent'
        );
      });
      window.Upscope(
        'on',
        'sessionStart',
        'sessionContinue',
        onSessionRequestedOrContinued
      );
    }
  }, [fdrApplicantId, firstName, lastName, clientId]);

  return null;
};
