import { getAppSubDomain } from 'shared-components';
import { useLocale } from 'lib/hooks';

export function useTermsLink() {
  const { locale } = useLocale();
  const subdomain = getAppSubDomain();
  let termsUrl = '';

  switch (subdomain) {
    case 'achieve':
      termsUrl = 'https://www.achieve.com/policies#Terms-Of-Use';
      break;
    case 'turnbull':
      termsUrl = 'https://www.turnbulllawgroup.com/terms-and-conditions/';
      break;
    case 'fdr':
      termsUrl =
        locale === 'en-US'
          ? 'https://www.freedomdebtrelief.com/terms/'
          : 'https://www.freedomdebtrelief.com/terminos-de-servicio/';
      break;
    default:
      console.error(`incorrect subdomain ${subdomain}`);
      break;
  }

  return termsUrl;
}
