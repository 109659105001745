import axios from 'lib/axios';
import getApiUrl from 'lib/utils/getApiUrl';

export const getUserRecord = async () => {
  try {
    const uri = _buildApiURI();
    let url = String(uri);
    const { data } = await axios.get(url);

    if (!data) return null;

    return data;
  } catch (error) {
    console.error('Error fetching user record', error);
    return null;
  }
};

export const _buildApiURI = () => {
  const apiUrl = getApiUrl();
  return `${apiUrl}/user`;
};
