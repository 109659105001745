import React from 'react';
import { css } from 'styled-components/macro';
import { styles } from '@ffn/ui';

export function Title({ children }) {
  css(``);
  return (
    <span
      css={`
        color: ${styles.colors.primaryBlue};
        border-bottom: 1px solid ${styles.colors.grayRegular};
        padding-bottom: 15px;
        display: block;
        font-size: ${styles.typography.size.medium}px;
        line-height: 33px;
      `}
    >
      {children}
    </span>
  );
}

export function SubTitle({ children }) {
  css(``);
  return (
    <h3
      css={`
        color: ${styles.colors.primaryBlue};
      `}
    >
      {children}
    </h3>
  );
}
