import React, { useEffect } from 'react';
import { Box } from '@achieve/sunbeam';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { getAlertCardContent } from '../assets/alertCardMap';
import styles from './AlertCard.module.scss';
import { logger } from 'shared-components';

/**
 * Render an alert card component based on the provided type and metadata.
 *
 * @param {string} type - The type of the alert card.
 * @param {object} metadata - Additional metadata needed to render the alert card.
 * @returns {JSX.Element} The JSX element representing the alert card component.
 */
export const AlertCard = ({ type, metadata }) => {
  useEffect(() => {
    analyticsTrackEvent(
      {
        action: 'loaded',
        category: 'pts',
        label: type
      },
      `Alert Card ${type} Loaded`
    );
  }, [type]);

  const cardData = getAlertCardContent(type, metadata);

  if (!cardData) {
    logger.info(
      `Attempted to render alert card of (${type}), but no data found.`
    );
  }

  const { id, title, icon, content, contactUs } = cardData;

  return (
    <Box
      className={styles.alertCardContainer}
      component="section"
      data-testid={`alert-card-for-${id}`}
    >
      <Box
        data-testid={`alert-card-title-for-${id}`}
        className={styles.alertCardHeading}
      >
        {icon}
        {title}
      </Box>
      <Box className={styles.alertCardContent} data-testid="alert-card-content">
        {content}
        <Box
          className={styles.alertCardContactBlock}
          data-testid="alert-card-contact-block"
        >
          {contactUs}
        </Box>
      </Box>
    </Box>
  );
};
