import { createSelector } from "reselect";
import { prop, ifElse, pipe } from "ramda";
import {
  isAfter,
  set,
  startOfMinute,
  addDays,
  isWithinInterval,
  startOfDay,
  parseISO
} from 'date-fns/fp';
import { zonedTimeToUtc } from 'date-fns-tz';

export let isAfter830Pacific = pipe(
  set({ hours: 8, minutes: 30 }),
  startOfMinute,
  x => zonedTimeToUtc(x, 'America/Los_Angeles'),
  isAfter
);

export let startDate = pipe(
  ifElse(x => isAfter830Pacific(x)(x), addDays(2), addDays(1)),
  startOfDay
);

export let endDate = addDays(30);

export let isWithin30DaysOfDate = now => {
  const start = startDate(now);
  return isWithinInterval({
    start: start,
    end: endDate(start)
  });
};

export const getAllDraftFees = state => state.models.allDraftFees;

export const getFutureDrafts = createSelector(getAllDraftFees, items =>
  prop('futureDrafts', items) || []
);

export const getFilteredFutureDrafts = createSelector(getFutureDrafts, items =>
  items?.filter(draft => 
    isWithin30DaysOfDate(new Date())(
      parseISO(draft.draftFeeWrapper.draftsFeeDate)
    )
  )
);
