import { axiosInstance as axios } from 'lib/axios';

import { format } from 'date-fns/fp';

export const draftModificationRedraft = async ({
  values,
  clientId,
  allDraftFees
}) => {
  try {
    const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/draftModification`;
    const allFeesData = allDraftFees ? allDraftFees : { futureDrafts: [] };

    const sfObj = {
      draftModificationType: 'redraft',
      // draftModificationType: 'Positive',
      allFeesData,
      amount: Number(values.amount).toFixed(2),
      date: format('yyyy-MM-dd')(values.depositDate),
      dmfReason: 'Redraft Deposit'
    };

    const response = await axios.post(endpoint, sfObj, {
      validateStatus: status => status === 200
    });

    // Handling the case where the response is a 200 but the operation is not allowed because of the client's account status
    if (response.data.success === false) {
      throw new Error(response.data);
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      if (error?.response?.status === 404) {
        const error404 = new Error(error.response.data.error_message);
        error404.code =
          error.response.data.error_code ?? 'DRAFT_MODIFICATION_GENERAL_ERROR';
        throw error404;
      } else {
        throw new Error('DATA_INSERTS_ERROR');
      }
    } else {
      throw error;
    }
  }
};

export default draftModificationRedraft;
