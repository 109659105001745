// Function to calculate comparison percentages
// Returns the larger number as 100 and calculates the other as a percentage of it.
export const calculateComparisonPercentage = (previous, program) => {
  // if program is less than previous, calculate accordingly, else reverse
  const isProgramLess = program < previous;
  return {
    program: Math.floor(isProgramLess ? 100 * (program / previous) : 100),
    previous: Math.floor(isProgramLess ? 100 : 100 * (previous / program))
  };
};
