import React from 'react';

export const PhoneSolidSVG = () => {
  return (
    <svg
      aria-hidden="true"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5874 16.5C11.2644 16.5 11.0167 16.4789 10.8444 16.4367C9.31523 16.1836 7.59223 15.1182 5.67539 13.2407L3.44626 11.1206C1.42173 9.20095 0.291007 7.5344 0.0540943 6.121C-0.204356 4.43336 0.463307 2.79845 2.05708 1.21629C2.40168 0.83657 2.70321 0.615067 2.96166 0.55178C3.41395 0.425207 3.81239 0.530684 4.15699 0.868213L4.22161 0.931499L6.28921 3.52625C6.52612 3.94816 6.61227 4.31733 6.54766 4.63376C6.35382 4.90801 6.14922 5.12951 5.93384 5.29827C5.26618 5.88895 4.96465 6.57455 5.02926 7.35509C5.09388 8.05124 5.42771 8.63137 6.03076 9.09547L7.61377 10.6776C8.21682 11.2683 8.83064 11.5636 9.45523 11.5636C10.2306 11.6269 10.909 11.3 11.4905 10.5827C11.749 10.3085 11.9751 10.1186 12.169 10.0131C12.4705 9.88655 12.8259 9.94984 13.2351 10.203L15.9488 12.2282C16.3149 12.5868 16.4442 12.977 16.3365 13.399C16.2719 13.6732 16.0565 13.9896 15.6903 14.3483C14.4196 15.7828 13.052 16.5 11.5874 16.5Z"
        fill="white"
      />
    </svg>
  );
};
