import React from 'react';
import { css } from 'styled-components/macro';
import { useFormikContext } from 'formik';
import { Currency } from 'shared-components/NumberFormatters';
import { Box, query } from 'atomic-layout';
import { Link } from '@ffn/ui';
import { useTranslation } from 'lib/hooks';
const alignedData = css`
  span,
  strong {
    flex: 1;
  }
  @media ${query({ from: 'md' })} {
    strong {
      flex: 3;
    }
  }
`;

export function RejectMessage({ account }) {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  return (
    <>
      <Box
        flex
        css={`
          ${alignedData}
        `}
        data-testid="rejectMessage"
      >
        <span>{t('alerts.settlementAuth.originalCreditor')}:</span>
        <strong>{account.originalCreditor}</strong>
      </Box>
      <Box
        flex
        css={`
          ${alignedData}
        `}
      >
        <span>{t('alerts.settlementAuth.currentCreditor')}:</span>
        <strong>{account.creditorName}</strong>
      </Box>
      <Box
        flex
        css={`
          ${alignedData}
        `}
      >
        <span>{t('alerts.settlementAuth.accountEndingIn')}:</span>
        <strong>{account.accountNumber}</strong>
      </Box>
      <Box
        flex
        css={`
          ${alignedData}
        `}
      >
        <span>{t('alerts.settlementAuth.currentBalance')}:</span>
        <strong>
          <Currency value={account.currentBalance} />{' '}
        </strong>
      </Box>
      <Box
        flex
        css={`
          ${alignedData}
        `}
      >
        <span>{t('alerts.settlementAuth.settlementAmount')}:</span>
        <strong>
          <Currency value={account.settlementAmount} />
        </strong>
      </Box>
      <Box
        flex
        css={`
          ${alignedData}
        `}
        data-testid="rejectReason"
      >
        <span>{t('alerts.settlementAuth.rejectedReason')}:</span>
        <strong>{values.rejectReason}</strong>
      </Box>
      <Box marginTop={32}>
        <strong>{t('alerts.settlementAuth.whatSNext')}</strong>
        <br />
        <span>
          {t('alerts.settlementAuth.keepAnEyeOutForFutureSettlement')}{' '}
          <Link href="/enrolled-debt/settlement-overview">
            {t('alerts.settlementAuth.settlementOverviewPage')}
          </Link>
          .
        </span>
      </Box>
    </>
  );
}
