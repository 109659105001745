import { analyticsTrackEvent } from 'lib/utils/analytics';
import { analyticsPayloads } from './constants';
import { autoDepositAuth } from '../../../lib/api/autoDepositAuth';

// Helper for submitting the settlement auth request
export const submitDepositAuth = async ({ alertId }) =>
  autoDepositAuth({ alertId });

// Helper for submitting analytics events
export const sendAnalyticsEvent = ({ eventKey, alertId = null, name }) => {
  const payload = analyticsPayloads[eventKey];
  analyticsTrackEvent(
    {
      ...payload,
      nsf_alert_id: alertId
    },
    name
  );
};
