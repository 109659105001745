import { analyticsTrackEvent } from 'lib/utils/analytics';
import { TourArrow } from './TourArrow';
import { TourProgressDots } from './TourDots';
import styles from './productTour.module.scss';

/***
 * Using a custom component here so that we can track analytics on next/prev clicks
 * Unfortunately, that means we had to reconstruct the default tour navigation from scratch
 */
export function CustomTourNavigation({ steps, currentStep, setCurrentStep }) {
  const totalSteps = steps.length;
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === totalSteps - 1;
  const analyticsKey = steps[currentStep].analyticsKey;

  const handlePrevClick = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      callTourAnalytics({
        step_title: analyticsKey,
        event_name: 'Back arrow clicked'
      });
    }
  };

  const handleNextClick = () => {
    setCurrentStep(currentStep + 1);
    if (isFirstStep) {
      analyticsTrackEvent(
        {
          category: 'product_tour',
          label: 'product_tour_start_choice',
          action: 'clicked'
        },
        'Product tour accepted'
      );
    } else if (currentStep < totalSteps - 1) {
      callTourAnalytics({
        step_title: analyticsKey,
        event_name: 'Forward arrow clicked'
      });
    }
  };

  return (
    <div className={styles.navContainer}>
      <TourArrow
        direction="left"
        hide={isFirstStep}
        onClick={handlePrevClick}
      />
      <TourProgressDots currentStep={currentStep} totalSteps={totalSteps} />
      <TourArrow
        direction="right"
        hide={isLastStep}
        onClick={handleNextClick}
      />
    </div>
  );
}

/***
 * Custom close button so that we can track our analytics and customize look
 */
export function CustomTourCloseButton({
  onClick: reactTourOnCloseHandler,
  markCompleteForSession,
  totalSteps,
  stepIndex,
  analyticsKey
}) {
  if (stepIndex === 0 || stepIndex === totalSteps - 1) {
    return <></>;
  } else {
    return (
      <button
        aria-label="Close Tour Button"
        className={styles.closeButton}
        data-testid="tour-close-button"
        id="tour-close-button"
        onClick={() => {
          markCompleteForSession(true);
          callTourAnalytics({
            step_title: analyticsKey,
            event_name: 'Tour closed'
          });
          reactTourOnCloseHandler();
        }}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1L1 13M1 1L13 13"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    );
  }
}

/***
 * Helper for calling analytics on next/prev clicks
 */
function callTourAnalytics({ event_name, step_title }) {
  analyticsTrackEvent(
    {
      category: 'product_tour',
      label: `product_tour_${step_title}`,
      action: 'clicked'
    },
    event_name
  );
}
