import { useTranslation } from 'lib/hooks';

export const FormError = ({ errorCode }) => {
  const { t } = useTranslation();
  const messages = {
    GENERAL_ENVELOPE_URL_ERROR: t('alerts.poaNeeded.couldNotLocateDocuments'),
    NO_DOCUSIGN_URL_RETURNED: t('alerts.poaNeeded.couldNotLocateDocuments'),
    UNHANDLED_ERROR: t('common.form.validationsAndErrors.somethingWentWrong')
  };

  if (messages[errorCode]) {
    return messages[errorCode];
  } else {
    return messages.UNHANDLED_ERROR;
  }
};

export default FormError;
