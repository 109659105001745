import React from 'react';
import i18next from 'i18next';
import { HeartWithHeadsetSvg } from './HeartSvg';
import { PlanSvg } from './PlanSvg';
import { InfoCardTitle } from '../InformationCard/CardTitle';
import { InfoCardParagraph } from '../InformationCard/CardContent';

const { t } = i18next;

const INFO_CARD_MAP = {
  your_success: {
    id: 'your_success',
    icon: <HeartWithHeadsetSvg />,
    title: () => (
      <InfoCardTitle>{t('pts.yourSuccessIsOurPriority')}</InfoCardTitle>
    ),
    content: () => (
      <>
        <InfoCardParagraph variant="ascendBodyXs">
          {t('pts.ourGoalIsToGetYouTheBestPossibleSettlements')}
        </InfoCardParagraph>
      </>
    )
  },
  order_change: {
    id: 'order_change',
    icon: <PlanSvg />,
    title: () => (
      <InfoCardTitle>{t('pts.changeInSettlementOrder')}</InfoCardTitle>
    ),
    content: () => (
      <>
        <InfoCardParagraph>
          {t('pts.weIdentifiedAnotherCreditor')}
        </InfoCardParagraph>
      </>
    )
  }
};

export function getInfoCardContent(cardType, metadata) {
  const cardTypes = Object.keys(INFO_CARD_MAP);

  if (!cardTypes.includes(cardType)) {
    return null;
  }
  const cardData = INFO_CARD_MAP[cardType];
  const cardContent = cardData.content(metadata);
  const cardTitle = cardData.title(metadata);

  return {
    ...cardData,
    content: cardContent,
    title: cardTitle
  };
}
