import axios from 'axios';

export const leadDocSendDcEmail = async ({
  oobCode,
  notes,
  fileNames
}) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/leadDocSendDcEmail`;
  try {
    const response = await axios.post(
      `${apiUrl}?code=${oobCode}`,
      {
        notes,
        fileNames
      },
      {
        headers: {
          'Content-type': 'application/json'
        },
        validateStatus: status => status === 200
      }
    );

    if (response.status !== 200) {
      const message = 'LEAD_DOC_DC_EMAIL_ERROR';
      throw new Error(message);
    }

    return await response.data;
  } catch (error) {
    throw error;
  }
};

export default leadDocSendDcEmail;
