import React from 'react';
import { Outlet } from 'react-router-dom';
import { useIsFetching } from 'react-query';
import { Box } from 'atomic-layout';
import { Panel, Loader } from '@ffn/ui';
import { PageLayout } from 'shared-components';
import { useTranslation } from 'lib/hooks';

export function Notifications() {
  const isFetchingNofications = useIsFetching(['notifications']);
  const { t } = useTranslation();

  if (isFetchingNofications) {
    return (
      <Box
        width="100%"
        hieght="100%"
        flex
        alignItems="center"
        justifyContent="center"
      >
        <Loader color="primaryBlue" size={60} />
      </Box>
    );
  }

  return (
    <PageLayout pageTitle={t('nav.notifications')}>
      <Panel>
        <Outlet />
      </Panel>
    </PageLayout>
  );
}
