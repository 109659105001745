import React from 'react';

import { IconButton, Typography } from '@achieve/sunbeam';
import { ArrowDown, ArrowUp } from 'react-feather';

import styles from './Accordion.module.scss';
/**
 * Accordion Component
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.buttonColor - The color of the toggle button.
 * @param {React.ReactNode} props.children - The content to be displayed within the accordion.
 * @param {string} props.collapseButtonAlt - The alt text for the collapse button.
 * @param {string} props.expandButtonAlt - The alt text for the expand button.
 * @param {string} props.heading - The heading text for the accordion.
 * @param {React.Component} props.IconCollapsed - The icon to display when the accordion is collapsed.
 * @param {React.Component} props.IconExpanded - The icon to display when the accordion is expanded.
 * @param {(string|number)} props.id - The unique identifier for the accordion element.
 * @param {boolean} props.isExpanded - The current state of the accordion (expanded or collapsed).
 * @param {Function} props.setIsExpanded - The function to set the state of the accordion.
 * @param {Function} props.onToggle - Callback function that is called when the accordion is toggled.
 */
export const Accordion = ({
  buttonColor = '#fff',
  children,
  collapseButtonAlt = 'close accordion',
  expandButtonAlt = 'open accordion',
  heading,
  IconCollapsed = <ArrowDown />,
  IconExpanded = <ArrowUp />,
  id,
  isExpanded,
  onToggle,
  setIsExpanded
}) => {
  /**
   * handleToggle
   *
   * Toggles the accordion's state between expanded and collapsed.
   * Calls the onToggle callback with the new state of isExpanded, if provided.
   */
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    if (onToggle && typeof onToggle === 'function') {
      onToggle(!isExpanded);
    }
  };

  return (
    <div className={styles.accordion}>
      <div
        aria-hidden={!isExpanded}
        aria-live="polite"
        id={`accordion-content-${id}`}
        className={
          isExpanded ? styles.accordionContentExpanded : styles.accordionContent
        }
      >
        {children}
      </div>
      <div className={styles.heading} data-testid={`accordion-heading-${id}`}>
        <Typography variant="ascendLabelMd">{heading}</Typography>
        <div data-testid={`accordion-button-${id}`} className={styles.button}>
          <IconButton
            aria-controls={`accordion-content-${id}`}
            aria-label={isExpanded ? collapseButtonAlt : expandButtonAlt}
            aria-expanded={isExpanded}
            color="primary"
            style={{ backgroundColor: buttonColor }}
            className={styles.button}
            onClick={handleToggle}
          >
            {isExpanded ? <IconExpanded /> : <IconCollapsed />}
          </IconButton>
        </div>
      </div>
    </div>
  );
};
