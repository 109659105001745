import { useState, useEffect, useMemo } from 'react';
import { TourProvider } from '@reactour/tour';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { getTourSteps } from './steps';
import { customPopover } from './components/customPopover';
import { useProductTour } from 'lib/hooks';
import {
  CustomTourCloseButton,
  CustomTourNavigation
} from './components/TourNavigation';
import { analyticsHotjarEvent } from 'lib/utils/analytics';
import FallBackLoader from 'shared-components/FallBackLoader';

/***
 * Using @reactour/tour to create a product tour provider
 * https://reactour.js.org/tour
 */
export function ReactTourProvider({ children }) {
  const {
    tourCount,
    incrementProductTourCount,
    markCompleteForSession,
    checkedForExtendedProductTour,
    tourType // 'abbreviated', 'disabled', 'extended', 'none',
  } = useProductTour();
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const tourSteps = useMemo(() => {
    const steps = getTourSteps({
      isExtended: tourType === 'extended'
    });

    return steps;
  }, [tourType]);

  const setCurrentStep = stepIndexNumber => {
    const url = tourSteps[stepIndexNumber].url;
    /***
     * Integrating with React Router
     * https://codesandbox.io/s/tour-demo-using-react-router-dom-with-automatic-route-switching-fhdnxb?file=/src/App.js
     */
    if (url) navigate(url, { replace: true });
    setStep(stepIndexNumber);
  };

  const totalSteps = tourSteps.length;
  const startTourHandler = target => {
    analyticsTrackEvent(
      {
        category: 'product_tour',
        label: `product_tour_first_step_attempt_${tourCount || 1}`, //a default value of 1 in case of undefined for new users
        action: 'view'
      },
      'First step view'
    );
    disableBodyScroll(target);
    hideAlertAndOrientationButton();
    incrementProductTourCount();
  };

  useQuery(
    ['hotjarEventProductTour'],
    () => analyticsHotjarEvent('product_tour_first_step_attempt'),
    {
      enabled: tourType === 'abbreviated' || tourType === 'extended',
      staleTime: Infinity,
      retry: 1
    }
  );

  const endTourHandler = target => {
    enableBodyScroll(target);
    showAlertAndOrientationButton();
  };

  useEffect(() => {
    if (step === totalSteps - 1) {
      analyticsTrackEvent(
        {
          category: 'product_tour',
          label: 'product_tour_end',
          action: 'view'
        },
        'Product tour completed'
      );
    }
  }, [step, totalSteps]);

  /***
   * Because this wraps most of the app, we need to return some UI
   * immediately to avoid a blank screen while checking whether we need to
   * serve the extended product tour.
   */
  if (tourType === null) {
    console.info(
      `PRODUCT TOUR :: Rendering FallBackLoader :: checkedForExtendedProductTour (${checkedForExtendedProductTour}), tourType (${tourType})})`
    );
    return <FallBackLoader isPage />;
  }

  if (tourType === 'none' || tourType === 'disabled') {
    console.info(
      `PRODUCT TOUR :: Rendering only children :: tourType(${tourType})`
    );
    return children;
  }

  console.info(`PRODUCT TOUR :: Render tour provider :: tourType(${tourType})`);

  return (
    <TourProvider
      afterOpen={startTourHandler}
      badgeContent={{ totalSteps }}
      beforeClose={endTourHandler}
      disableInteraction
      disableKeyboardNavigation
      components={{
        Close: props => (
          <CustomTourCloseButton
            totalSteps={totalSteps}
            stepIndex={step}
            analyticsKey={tourSteps[step].analyticsKey}
            markCompleteForSession={markCompleteForSession}
            {...props}
          />
        ),
        Navigation: CustomTourNavigation
      }}
      currentStep={step}
      inViewThreshold={50}
      onClickMask={() => {
        /** No-op to prevent closing product tour on blur */
        return null;
      }}
      /** padding between the mask highlight and the target, default is 10 */
      padding={5}
      setCurrentStep={setCurrentStep}
      scrollSmooth
      showBadge={false}
      showPrevNextButtons
      steps={tourSteps.map(({ step }) => step)}
      styles={{
        ...customPopover,
        maskWrapper: (base, _state) => {
          return {
            ...base,
            opacity: 0.4
          };
        }
      }}
    >
      {children}
      <TargetNodeForSampleSettlementImage />
    </TourProvider>
  );
}

/***
 * We're using this is a place we can add a temporary node for a tour step
 * This uses just plain Vanilla JS to create/destroy a node to this container element
 * Remember how much jQuery used to suck? This is why.
 */
function TargetNodeForSampleSettlementImage() {
  return (
    <div
      id="sample-settlement-target"
      data-tour="static-tour-target"
      style={{
        position: 'absolute',
        top: '50px',
        right: '50px',
        zIndex: '1000'
      }}
    />
  );
}

function hideAlertAndOrientationButton() {
  const alertBar = document.querySelector(
    "[data-testid='alert-ribbon-container']"
  );
  const orientationButton = document.querySelector(
    "[data-testid='orientation-btn']"
  );

  if (alertBar) {
    alertBar.style = 'visibility: hidden';
  }

  if (orientationButton) {
    orientationButton.style = 'visibility: hidden';
  }
}

function showAlertAndOrientationButton() {
  const alertBar = document.querySelector(
    "[data-testid='alert-ribbon-container']"
  );
  const orientationButton = document.querySelector(
    "[data-testid='orientation-btn']"
  );
  if (alertBar) {
    alertBar.style = 'visibility: visible';
  }

  if (orientationButton) {
    orientationButton.style = 'visibility: visible';
  }
}
