import React from 'react';
import { Container, Panel, PanelTitle, PanelTitleHeading, Text } from '@ffn/ui';
import 'styled-components/macro';
// import { useTranslation } from 'lib/hooks';
import { Button } from '@ffn/ui/dist/Buttons';
import { CustomerServiceHours } from 'shared-components';
import { useBrandAwareNavigation } from 'lib/hooks';

export function LoginError() {
  const assignBrandAwareRoute = useBrandAwareNavigation();
  return (
    <Container style={{ marginTop: '20px', maxWidth: '500px' }}>
      <Panel>
        <PanelTitle>
          <PanelTitleHeading>Login Error</PanelTitleHeading>
        </PanelTitle>
        <Text>
          We're sorry! Something went wrong logging in to your dashboard.
        </Text>
        <Container>
          <Button
            variant="primary"
            onClick={() => {
              // TODO: Fire analytics event here that user had to manually try again to log in.
              assignBrandAwareRoute('/');
            }}
          >
            Try Again
          </Button>
        </Container>
        <Container style={{ textAlign: 'center' }}>
          <h3 style={{ fontWeight: 600 }}>OR</h3>
        </Container>
        <CustomerServiceHours hideChat />
      </Panel>
    </Container>
  );
}
