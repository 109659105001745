import {
  useContext,
  useMemo,
  createContext,
  useState,
  useCallback
} from 'react';

const ChangePasswordModalContext = createContext();

export const ChangePasswordModalProvider = ({ children }) => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const openChangePasswordModal = useCallback(
    () => setShowChangePasswordModal(true),
    []
  );

  const closeChangePasswordModal = useCallback(
    () => setShowChangePasswordModal(false),
    []
  );

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(
    () => ({
      showChangePasswordModal,
      openChangePasswordModal,
      closeChangePasswordModal
    }),
    [showChangePasswordModal, openChangePasswordModal, closeChangePasswordModal]
  );

  return (
    <ChangePasswordModalContext.Provider value={value}>
      {children}
    </ChangePasswordModalContext.Provider>
  );
};

export function useChangePasswordModal() {
  return useContext(ChangePasswordModalContext);
}

export default useChangePasswordModal;
