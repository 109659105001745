import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { Box, Card, Typography, useTheme } from '@achieve/sunbeam';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'lib/hooks';

import { MugWithHeartSVG } from '../assets/MugSvg';
import { InformationCard } from '../InformationCard';
import { CoreQuestions } from '../CoreQuestions';
import { AlertCard } from '../AlertCard';

import styles from './EstimatedSettlements.module.scss';
import { getAbbrMonthOnlySettlementRange } from 'lib/utils';
import { PTS_VARIATIONS, EST_SETTLEMENT_THRESHOLD } from '../assets/constants';
import { SettlementTooltip } from 'shared-components';

//  Custom hook for firing and tracking component load analytics events once.
// accountId, enrolledBalance, and estimatedSettlementRange are optional
function useEstimatedSettlementCardLoadEvent({
  label,
  eventName,
  accountId,
  enrolledBalance,
  estimatedSettlementRange,
  estimatedAmount,
  currentBalance
}) {
  const abbrSettlementRange = getAbbrMonthOnlySettlementRange(
    estimatedSettlementRange
  );
  useEffect(() => {
    try {
      // Prevent event from firing if accounts are already present in session
      // and accountId is not present (no account scenario)
      if (sessionStorage.getItem('accountsPresent') === 'true' && !accountId) {
        return;
      }

      analyticsTrackEvent(
        {
          action: 'loaded',
          category: 'pts',
          label,
          account_key: accountId,
          enrolled_balance: Math.round(enrolledBalance), // rounding down as BI requested an integer
          estimated_settlement_date: abbrSettlementRange,
          estimated_settlement_amount: Math.round(estimatedAmount) || undefined,
          current_balance: Math.round(currentBalance) || undefined
        },
        eventName
      );
      // Set 'accountsPresent' in sessionStorage if accountId is present
      if (accountId) {
        sessionStorage.setItem('accountsPresent', 'true');
      }
    } catch (error) {
      console.error(
        'Error accessing sessionStorage or firing analytics event:',
        error
      );
    }
  }, [
    accountId,
    enrolledBalance,
    eventName,
    abbrSettlementRange,
    label,
    estimatedAmount,
    currentBalance
  ]);
}

export function EstimatedSettlements({
  activeCoreQuestions,
  activeAlertCards,
  activeInfoCards,
  missingAccountNumberOrDate,
  nextSettlements,
  ptsFlag
}) {
  const { t } = useTranslation();

  const hasEstimatedSettlements =
    Array.isArray(nextSettlements) && nextSettlements.length !== 0;
  const canRenderSettlements =
    hasEstimatedSettlements && !missingAccountNumberOrDate;

  const headingText = canRenderSettlements
    ? t('pts.estimatedDebtToNegotiate')
    : t('pts.yourSettlementSnapshotIsComingSoon');

  return (
    <>
      <PrimaryBox headingText={headingText}>
        {canRenderSettlements ? (
          <EstimatedSettlementCard
            debt={nextSettlements[0]}
            place="First"
            ptsFlag={ptsFlag}
          >
            {activeInfoCards?.map(infoCard => (
              <InformationCard
                key={infoCard.type}
                type={infoCard.type}
                metadata={infoCard?.meta}
              />
            ))}
            {activeAlertCards?.map(alertCard => (
              <AlertCard
                key={alertCard.type}
                type={alertCard.type}
                metadata={alertCard?.meta}
              />
            ))}
            {activeCoreQuestions?.length > 0 && (
              <CoreQuestions activeCoreQuestions={activeCoreQuestions} />
            )}
          </EstimatedSettlementCard>
        ) : (
          <NoEstimatedSettlementsCard>
            <CoreQuestions activeCoreQuestions={activeCoreQuestions} />
          </NoEstimatedSettlementsCard>
        )}
      </PrimaryBox>
      {canRenderSettlements && nextSettlements[1] && (
        <Box
          data-testid="pts-next-est-debt"
          display="flex"
          flexDirection="column"
          gap="24px"
        >
          <Typography
            data-testid="pts-next-est-debt-heading"
            variant="ascendLabelLg"
            title={t('pts.nextEstDebtToNegotiate')}
            component="h3"
          >
            {t('pts.nextEstDebtToNegotiate')}
          </Typography>
          <EstimatedSettlementCard
            debt={nextSettlements[1]}
            place="Second"
            ptsFlag={ptsFlag}
          />
        </Box>
      )}
    </>
  );
}

function EstimatedSettlementCard({ debt, children, place, ptsFlag }) {
  const {
    accountId,
    accountNumber,
    creditor,
    enrolledBalance,
    estimatedSettlementRange,
    estimatedAmount,
    currentBalance
  } = debt;

  const { t } = useTranslation();

  const eventData = {
    eventName: `${place} Settlement Component Loaded`,
    label: 'settlement_snapshot',
    accountId,
    enrolledBalance,
    estimatedSettlementRange,
    estimatedAmount,
    currentBalance
  };
  useEstimatedSettlementCardLoadEvent(eventData);

  const abbrSettlementRange = getAbbrMonthOnlySettlementRange(
    estimatedSettlementRange
  );
  const showPtsTest2 =
    place === 'First' && ptsFlag === PTS_VARIATIONS.SEE_PTS_TEST_2;
  const showCurrentBalance = Number(enrolledBalance) !== Number(currentBalance);
  const showEstSettlementAmount =
    Number(estimatedAmount) <=
    EST_SETTLEMENT_THRESHOLD * Number(currentBalance);

  return showPtsTest2 ? (
    <Card
      component="section"
      data-testid="pts-tradeline-card"
      sx={{ borderRadius: '8px !important' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        className={styles.tradelineCard}
      >
        <Typography
          data-testid="pts-tradeline-acct-num"
          variant="ascendHeadingSm"
        >
          {creditor} ****{accountNumber}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" gap="4px" alignItems="center">
            <Typography
              data-testid="pts-tradeline-enrolled-balance-label"
              variant="ascendBodySm"
            >
              {t('pts.yourEnrolledBalance')}
            </Typography>
            <SettlementTooltip tipKey="enrolledBalance" />
          </Box>
          <NumberFormat
            data-testid="pts-tradeline-enrolled-balance"
            value={enrolledBalance}
            displayType="text"
            thousandSeparator={true}
            decimalScale={0}
            prefix={'$'}
            renderText={value => (
              <Typography variant="ascendLabelSm">{value}</Typography>
            )}
          />
        </Box>
        {showCurrentBalance && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap="4px" alignItems="center">
              <Typography
                data-testid="pts-tradeline-current-balance-label"
                variant="ascendBodySm"
              >
                {t('pts.yourCurrentBalance')}
              </Typography>
              <SettlementTooltip tipKey="currentBalance" />
            </Box>
            <NumberFormat
              data-testid="pts-tradeline-current-balance"
              value={currentBalance}
              displayType="text"
              thousandSeparator={true}
              decimalScale={0}
              prefix={'$'}
              renderText={value => (
                <Typography variant="ascendLabelSm">{value}</Typography>
              )}
            />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          data-testid="pts-tradeline-est-date"
        >
          <Typography
            data-testid="pts-tradeline-est-date-label"
            variant="ascendBodySm"
          >
            {t('pts.estSettlementDate')}
          </Typography>
          <Typography variant="ascendLabelSm">{abbrSettlementRange}</Typography>
        </Box>

        {showEstSettlementAmount && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap="4px" alignItems="center">
              <Typography
                data-testid="pts-tradeline-estimated-settlement-amount-label"
                variant="ascendBodySm"
              >
                {t('pts.estSettlementAmount')}
              </Typography>
              <SettlementTooltip tipKey="estimatedAmount" />
            </Box>
            <NumberFormat
              data-testid="pts-tradeline-estimated-settlement-amount"
              value={estimatedAmount}
              displayType="text"
              thousandSeparator={true}
              decimalScale={0}
              prefix={'$'}
              renderText={value => (
                <Typography variant="ascendLabelSm">{value}</Typography>
              )}
            />
          </Box>
        )}
      </Box>
      {children ?? null}
    </Card>
  ) : (
    <Card
      component="section"
      data-testid="pts-tradeline-card"
      sx={{ borderRadius: '8px !important' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        className={styles.tradelineCard}
      >
        <Typography
          data-testid="pts-tradeline-acct-num"
          variant="ascendHeadingSm"
        >
          {creditor} ****{accountNumber}
        </Typography>
        {enrolledBalance && (
          <Typography
            data-testid="pts-tradeline-enrolled-balance"
            variant="ascendBodySm"
          >
            <span>{t('pts.yourEnrolledBalance')}:</span>{' '}
            <NumberFormat
              value={enrolledBalance}
              displayType="text"
              thousandSeparator={true}
              fixedDecimalScale={false}
              prefix={'$'}
              renderText={value => (
                <Typography variant="ascendLabelSm">{value}</Typography>
              )}
            />
          </Typography>
        )}
        <Typography data-testid="pts-tradeline-est-date" variant="ascendBodySm">
          {t('pts.estSettlementDate')}:{' '}
          <Typography variant="ascendLabelSm">{abbrSettlementRange}</Typography>
        </Typography>
      </Box>
      {children ?? null}
    </Card>
  );
}

// PrimaryBox is the blue box that contains the first estimated settlement and info or alert cards
function PrimaryBox({ headingText, children }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main
      }}
      data-testid="pts-primary-heading-box"
      paddingX="16px"
      paddingY="24px"
      maxWidth="335px"
      margin="8px auto 24px"
    >
      <Box marginBottom="16px">
        <Typography
          data-testid="pts-primary-heading-box-heading"
          variant="ascendLabelLg"
          component="h2"
          title={headingText}
          color={theme.palette.primary.contrastText}
        >
          {headingText}
        </Typography>
      </Box>
      {children}
    </Box>
  );
}

function NoEstimatedSettlementsCard({ children }) {
  const { t } = useTranslation();
  const eventData = {
    eventName: 'Your Settlement snapshot is coming soon! Card Loaded',
    label: 'your_settlement_snapshot_is_coming_soon'
  };
  useEstimatedSettlementCardLoadEvent(eventData);

  return (
    <Card
      data-testid="pts-no-estimated-settlements-card"
      sx={{ borderRadius: '8px !important' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        component="section"
        sx={{ marginBottom: '16px' }}
      >
        <Box marginBottom="8px">
          <MugWithHeartSVG />
        </Box>
        <Typography variant="ascendBodySm" component="p">
          {t('pts.thanksForYourPatience')}
        </Typography>
      </Box>
      {children}
    </Card>
  );
}
