import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@achieve/sunbeam';

import { useAuth } from 'lib/hooks';
import { FallBackLoader, logger } from 'shared-components';
import { MessageContainer } from 'shared-components/BootstrapLoader';
import { redirectToBrandSpecificLogin } from 'lib/utils';

const LOGGING_PREFIX = 'RouteGuard ::';
const AUTH0_LOGIN_REQUIRED_ERROR = 'Login required';

/***
 * The RouteGuard component ensures that a user is authenticated (and has a valid token)
 * before rendering protected routes.
 *
 * RouteGuard works in tandem with useAuth, which determines the user's authentication
 * state. RouteGuard is responsible for triggering the validation process when necessary.
 *
 * If the user is authenticated, RouteGuard renders the Outlet (child routes).
 *
 * If authentication is required, and useAuth detects an issue (indicated by authenticationError),
 * RouteGuard will redirect the user to the login page.
 */
function RouteGuard({ wrapper: Wrapper, isDashboard }) {
  const {
    authenticationError,
    validateUserForProtectedRoutes,
    isAuthenticated, // true if user has a token
    isLoading
  } = useAuth();

  /**
   * If a user does not have a token, this effect will validate a
   * user is authenticated with validateUserForProtectedRoutes call.
   */
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      validateUserForProtectedRoutes();
    }
  }, [validateUserForProtectedRoutes, isAuthenticated, isLoading]);

  /**
   * Redirects users who need to login based on useAuth's authenticationError.
   * It differentiates between ‘Login required’ and other errors for appropriate logging.
   */
  useEffect(() => {
    if (!authenticationError) {
      return;
    }
    if (authenticationError?.message === AUTH0_LOGIN_REQUIRED_ERROR) {
      logger.info(`${LOGGING_PREFIX} No token found. Redirecting to login...`);
    } else {
      logger.error(`${LOGGING_PREFIX} Final attempt failed. Redirecting...`);
    }
    redirectToBrandSpecificLogin();
  }, [authenticationError]);

  // Render loading state UI when authentication status is being fetched
  if (isLoading) {
    logger.info(`${LOGGING_PREFIX} Fetching auth status`);

    return (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="20px"
      >
        <Box width="375px" display="flex" alignItems="center">
          <MessageContainer content="Initializing" />
        </Box>
        <FallBackLoader />
      </Box>
    );
  }

  // Render the Outlet or the Wrapper with Outlet for authenticated users
  if (isAuthenticated) {
    if (!Wrapper) return <Outlet />;
    return (
      <Wrapper renderTourProvider={isDashboard}>
        <Outlet />
      </Wrapper>
    );
  }

  // Return null to prevent rendering if redirection is happening
  return null;
}

export { RouteGuard };
