import { Image } from '@ffn/ui';
import { useBrand } from 'lib/hooks';

export const TLGLogo = ({ isDesktop }) => {
  const brand = useBrand();

  return (
    <Image
      alt="Turnbull Law Group logo"
      src={brand('logo.main')}
      css={`
        max-height: 48px;
        max-width: 180px;
        vertical-align: middle;
      `}
    />
  );
};
