export const ChangeSingleDeposit = {
  select_deposit_table: 'csdSelectDepositTable',
  form_input_current_deposit_amount: 'csdFormInputCurrentDepositAmount',
  form_input_new_deposit_amount: 'csdFormInputNewDepositAmount',
  form_input_slider: 'csdFormInputSlider',
  form_input_current_deposit_date: 'csdFormInputCurrentDepositDate',
  form_input_new_deposit_date: 'csdFormInputNewDepositDate',
  form_input_cancel_deposit_checkbox: 'csdFormInputCancelDepositCheckbox',
  form_input_dmf_reason_select: 'csdFormInputDmfReasonSelect',
  form_input_covid_19_impact_checkbox: 'csdFormInputCovid19ImpactCheckbox',
  form_submit_button: 'csdFormSubmitButton',
  options_increase_amount_date: 'csdOptionsIncreaseAmountDate',
  options_increase_radio: 'csdOptionsIncreaseAmountRadio',
  options_additional_radio: 'csdOptionsAdditionalRadio',
  options_additional_date: 'csdOptionsAdditionalDate',
  options_review_radio: 'csdOptionsReviewRadio',
  options_submit_button: 'csdOptionsSubmitButton',
  review_etfa_checkbox: 'csdReviewEtfaCheckbox',
  review_failure_acceptance_checkbox: 'csdReviewFailureAcceptanceCheckbox',
  review_submit_button: 'csdReviewSubmitButton',
  warning_submit_button: 'csdWarningSubmitButton',
  confirmed_done_button: 'csdConfirmedDoneButton'
};

export default ChangeSingleDeposit;
