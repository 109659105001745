import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Box } from 'atomic-layout';
import 'styled-components/macro';
import {
  acceptSettlement,
  refreshNotifications,
  rejectSettlement
} from 'lib/api';
import { useNotificationTracking, useTranslation } from 'lib/hooks';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { SettlementAuthForm } from './SettlementAuthForm';
import { AcceptMessage } from './AcceptMessage';
import { RejectMessage } from './RejectMessage';

export function SettlementAuth({ notification, viewLocation, isVisible }) {
  const [status, setStatus] = useState('form');
  const [error, setError] = useState();
  const [showReject, setShowReject] = useState(false);
  const csClientId = useSelector(state => state.userRecord?.csClientId);
  const trackNotificationView = useNotificationTracking();
  const { t } = useTranslation();

  const settlementId = notification?.payload?.settlementId;
  const account = notification.payload;

  useEffect(() => {
    trackNotificationView({
      trackingLabel: 'ras',
      viewLocation,
      isVisible,
      settlement_key: settlementId
    });
  }, [viewLocation, isVisible, settlementId, trackNotificationView]);

  return (
    csClientId &&
    account && (
      <Box
        padding={16}
        css={`
          background: ${({ theme }) => theme.colors.white};
        `}
        data-testid="settlementAuthAlert"
      >
        <Formik
          initialValues={{}}
          validationSchema={Yup.object().shape({
            rejectSettlement: Yup.boolean().required(
              t('common.form.validationsAndErrors.required')
            ),
            rejectReason: Yup.string()
              .max(
                255,
                t(
                  'alerts.settlementAuth.rejectionReasonMustBeShorterThan_255Characters'
                )
              )
              .when('rejectSettlement', {
                is: true,
                then: Yup.string().required(
                  t('alerts.settlementAuth.aRejectionReasonIsRequired')
                )
              })
          })}
          onSubmit={async (values, formikBag) => {
            try {
              formikBag.setSubmitting(true);
              if (values.rejectSettlement === true) {
                await rejectSettlement({
                  data: { settlementId, rejectReason: values.rejectReason }
                });
                analyticsTrackEvent(
                  {
                    category: 'alert',
                    action: 'reject',
                    label: 'ras',
                    settlement_key: settlementId
                  },
                  'Settlement authorization reject'
                );
                setStatus('rejected');
              } else {
                await acceptSettlement({
                  data: { settlementId }
                });
                analyticsTrackEvent(
                  {
                    category: 'alert',
                    action: 'success',
                    label: 'ras',
                    settlement_key: settlementId
                  },
                  'Settlement authorization success'
                );
                setStatus('accepted');
              }
              setShowReject(false);

              // We need to reload data to make sure that our notification clears
              refreshNotifications();
            } catch (error) {
              console.log(error);
              analyticsTrackEvent(
                {
                  category: 'alert',
                  action: 'failure',
                  label: 'ras',
                  settlement_key: settlementId
                },
                'Settlement authorization failure'
              );
              setError(error);
            } finally {
              formikBag.setSubmitting(false);
            }
          }}
        >
          <>
            {status === 'form' && (
              <SettlementAuthForm
                showReject={showReject}
                setShowReject={setShowReject}
                error={error}
                account={account}
              />
            )}
            {status === 'accepted' && <AcceptMessage account={account} />}
            {status === 'rejected' && <RejectMessage account={account} />}
          </>
        </Formik>
      </Box>
    )
  );
}
