import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'styled-components/macro';
import { Box } from 'atomic-layout';
import { Icon, FormMessage } from '@ffn/ui';
import { DashForm } from 'shared-components';
import { Formik } from 'formik';
import { find } from 'ramda';
import { addBusinessDays, isAfter, isSameDay } from 'date-fns/fp';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { createSelector } from 'reselect';
import { getLatestPrenoteDate } from 'shared-selectors/transactions';
import {
  getFutureDraftDates,
  getFutureDrafts
} from 'shared-selectors/draftFees';
import { getClient } from 'shared-selectors/enrolled-debt';
import { getAllDraftFees } from 'shared-selectors/allDraftFees';
import { isNotNil, getEventExtension } from 'lib/utils';
import { CustomPanel } from '../../../shared-pages/dashboard/CustomerService/components/OnlineServices/CustomTabs';

import * as constants from '../../../constants';
import { CheckmarkCircle } from '@ffn/icons';
import { draftModificationRedraft } from 'lib/api/draftModificationRedraft';
import { sendOneTimeGCSemail } from 'lib/api/generalCsRequest';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import UnableToPerformRequest from '../../../shared-pages/dashboard/CustomerService/components/OnlineServices/UnableToPerformRequest';
import { USD } from 'lib/utils';
import { refreshNotifications } from 'lib/api';
import { useTranslation } from 'lib/hooks';

import { FormStep } from './FormStep';
import { SuccessStep } from './Success';

const MIN_DEPOSIT = 1;
const MAX_DEPOSIT = 10000;

const today = new Date();

const getDefaultDate = createSelector(
  [getClient, getFutureDraftDates, getLatestPrenoteDate],
  (client, futureDraftDates, latestPrenoteDate) => {
    if (client.spa === constants.STATEMENT_PROVIDER.GCS) {
      return addBusinessDays(7)(today);
    } else {
      let cftDefaultDate = addBusinessDays(3)(today);

      let matchDraftDate = find(isSameDay(cftDefaultDate), futureDraftDates);
      cftDefaultDate = matchDraftDate
        ? addBusinessDays(1, matchDraftDate)
        : cftDefaultDate;

      if (
        isNotNil(latestPrenoteDate) &&
        isAfter(cftDefaultDate, addBusinessDays(7, latestPrenoteDate))
      ) {
        return addBusinessDays(7, latestPrenoteDate);
      }

      return cftDefaultDate;
    }
  }
);

export const FormError = ({ errorCode }) => {
  const { t } = useTranslation();
  const messages = {
    UNHANDLED_ERROR: t(
      'alerts.insufficientFunds.somethingWentWrongIfThisProblemPersists'
    )
  };

  return messages[errorCode] ? messages[errorCode] : messages.UNHANDLED_ERROR;
};

function RedraftForm({
  step,
  setStep,
  initialAmount,
  setSuccessData,
  successData
}) {
  const defaultDate = useSelector(getDefaultDate);
  const userRecord = useSelector(state => state.userRecord);
  const allDraftFees = useSelector(getAllDraftFees);
  const client = useSelector(getClient);
  const [error, setError] = useState();
  const location = useLocation();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    amount: yup
      .number(t('common.form.validationsAndErrors.mustBeANumber'))
      .min(
        MIN_DEPOSIT,
        `${t('common.form.validationsAndErrors.mustBeAtLeast')} ${USD.format(
          MIN_DEPOSIT
        )}`
      )
      .max(
        MAX_DEPOSIT,
        `${t('common.form.validationsAndErrors.mustBe')} ${USD.format(
          MAX_DEPOSIT
        )} ${t('common.form.validationsAndErrors.orLess')}`
      )
      .required(t('common.form.validationsAndErrors.amountIsRequired'))
      .label(t('common.form.validationsAndErrors.amount')),
    depositDate: yup
      .date()
      .required(t('common.form.validationsAndErrors.depositDateRequired'))
      .label(t('common.form.validationsAndErrors.depositDate')),
    eftaAgreed: yup
      .boolean()
      .oneOf(
        [true],
        t('common.form.validationsAndErrors.youMustAgreeToTheEfta')
      )
      .required(t('common.form.validationsAndErrors.youMustAgreeToTheEfta'))
  });

  return (
    <Formik
      initialValues={{
        amount: initialAmount,
        depositDate: defaultDate,
        eftaAgreed: false
      }}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={async values => {
        try {
          analyticsTrackEvent(
            {
              category: 'mdrd',
              label: 'alert' + getEventExtension(location),
              action: 'submit',
              value: values.amount
            },
            'Redraft Form Submit'
          );
          if (client?.spa === constants.STATEMENT_PROVIDER.GCS) {
            await sendOneTimeGCSemail({ ...values, user: userRecord });
          }
          await draftModificationRedraft({
            values,
            clientId: userRecord.csClientId,
            allDraftFees
          });
          analyticsTrackEvent(
            {
              category: 'mdrd',
              label: 'alert' + getEventExtension(location),
              action: 'success',
              value: values.amount
            },
            'Redraft Form Success'
          );

          setSuccessData(values);
          setStep('success');

          // Wait five seconds and then refresh notifications to clear any applicable alert(s)
          setTimeout(() => {
            refreshNotifications(['alertTasks']);
            setStep('form');
            setError(null);
          }, 5000);
        } catch (err) {
          analyticsTrackEvent(
            {
              category: 'mdrd',
              label: 'alert' + getEventExtension(location),
              action: 'failure',
              value: values.amount
            },
            'Redraft Form Failure'
          );
          console.log(err);
          setError(err);
        }
      }}
    >
      {({ isValid, isSubmitting, setFieldValue }) => {
        return (
          <DashForm id="one-time-deposit" gap={24}>
            {error && (
              <FormMessage variant="error">
                <FormError errorCode={error.code} />
              </FormMessage>
            )}
            {step === 'form' && (
              <FormStep
                isValid={isValid}
                isSubmitting={isSubmitting}
                defaultDate={defaultDate}
                setFieldValue={setFieldValue}
              />
            )}
          </DashForm>
        );
      }}
    </Formik>
  );
}

export function RedraftDeposit({
  goToSelect,
  close,
  initialAmount,
  hideInitialNavSteps
}) {
  const [step, setStep] = useState('form');
  const [successData, setSuccessData] = useState();
  const client = useSelector(getClient);
  const futureDrafts = useSelector(getFutureDrafts);
  const { t } = useTranslation();

  const headings = {
    form: t('alerts.insufficientFunds.addFunds'),
    successFromAlert: (
      <Box flex alignItems="center">
        <Icon icon={CheckmarkCircle} size={24} color="secondaryGreen" />
        <Box marginLeft={8}>
          {t('alerts.insufficientFunds.thanksYourDepositWasScheduled')}
        </Box>
      </Box>
    )
  };

  const headingComponentToUse = (() => {
    if (step === 'success') {
      return headings.successFromAlert;
    } else {
      return headings[step];
    }
  })();

  return (
    <CustomPanel heading={headingComponentToUse} close={close}>
      {futureDrafts.length > 0 ? (
        step === 'success' ? (
          <SuccessStep
            client={client}
            successData={successData}
            initialAmount={initialAmount}
            handleMakeAnotherDepositClick={() => setStep('form')}
          ></SuccessStep>
        ) : (
          <Box width="100%">
            <RedraftForm
              goToSelect={goToSelect}
              spa={client.spa}
              step={step}
              setStep={setStep}
              setSuccessData={setSuccessData}
              initialAmount={initialAmount}
              hideInitialNavSteps={hideInitialNavSteps}
            />
          </Box>
        )
      ) : (
        <UnableToPerformRequest goToSelect={goToSelect} />
      )}
    </CustomPanel>
  );
}

export default RedraftDeposit;
