import React from 'react';
import { Grid, IconButton } from '@achieve/sunbeam';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Nav(props) {
  const { title, backAction, testId } = props;
  return (
    <Grid
      container
      sx={{ p: '24px 16px', color: '#FFF', backgroundColor: '#041B93' }}
    >
      <Grid item xs={1} justifyContent="center" alignItems="center">
        <IconButton
          data-testid={testId}
          size="small"
          sx={{
            backgroundColor: 'transparent !important',
            boxShadow: 'none !important'
          }}
          onClick={() => backAction(false)}
        >
          <ArrowBackIcon
            sx={{
              color: '#fff',
              transition: 'opacity ease-in .1s',
              '&:hover': {
                opacity: 0.5
              }
            }}
          />
        </IconButton>
      </Grid>
      <Grid
        item
        xs
        container
        justifyContent="center"
        alignItems="center"
        px="16px"
      >
        {title}
      </Grid>
    </Grid>
  );
}

export default Nav;
