import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box } from 'atomic-layout';
import { Icon } from '@ffn/ui';
import { CheckmarkCircle } from '@ffn/icons';
import { useNotificationTracking } from 'lib/hooks';
import { useTranslation } from 'lib/hooks';

import { InformationRequestForm } from './InformationRequestForm';

const Message = styled.p`
  font-weight: bold;
`;

const ContentBox = styled.div`
  padding: 16px;
  background-color: white;
`;

const FormContainer = styled.div`
  margin-top: 16px;
`;

const PlainBox = styled.div`
  background-color: ${props => props.theme.colors.plain};
  padding: ${props => (props.isResolved ? '0px' : '16px')};
  padding-top: 0;
  border: ${props =>
    props.isResolved && !props.isFromAlertBox
      ? `1px solid ${props.theme.colors.secondaryGreen}`
      : 'none'};
`;

const HeaderContainer = styled.div`
  color: ${props => props.theme.colors.secondaryGreen};
  background-color: white;
  padding: 16px;
  span {
    font-weight: bold;
    margin-left: 24px;
  }
`;

const SuccessMessage = ({ isFromAlertBox, acctNum, creditorLanguage }) => {
  const { t } = useTranslation();
  return (
    <>
      {!isFromAlertBox ? (
        <HeaderContainer key={0}>
          <Icon icon={CheckmarkCircle} size={20}></Icon>
          <span>{t('alerts.informationRequest.success')}</span>
        </HeaderContainer>
      ) : null}
      <Box paddingHorizontal={!isFromAlertBox ? 16 : 0}>
        <p>
          {t('alerts.informationRequest.thanksYourInformationOnYour')}{' '}
          {creditorLanguage} {t('alerts.informationRequest.endingIn')} {acctNum}{' '}
          {t('alerts.informationRequest.hasBeenUpdated')}
        </p>
      </Box>
    </>
  );
};

export function InformationRequest({
  notification,
  setIsResolved,
  isResolved,
  isFromAlertBox,
  viewLocation,
  isVisible
}) {
  const trackNotificationView = useNotificationTracking();
  const [isSuccess, setIsSuccess] = useState(false);
  const { payload, salesforceClientId: clientId } = notification;

  const {
    originalCreditor,
    accountNumber,
    currentCreditor,
    requestReasons,
    accountId
  } = payload;
  const { t } = useTranslation();
  const successCallback = () => {
    if (setIsResolved && typeof setIsResolved === 'function') {
      setIsResolved(true);
    }

    setIsSuccess(true);
  };

  useEffect(() => {
    trackNotificationView({
      trackingLabel: 'ain',
      viewLocation,
      isVisible
    });
  }, [viewLocation, isVisible, trackNotificationView]);

  const creditorLanguage =
    currentCreditor && currentCreditor?.toLowerCase() !== 'unknown'
      ? currentCreditor + ' ' + t('alerts.informationRequest.account')
      : t('alerts.informationRequest.accountOriginallyOpenedWith') +
        ' ' +
        originalCreditor;

  return (
    <PlainBox
      isFromAlertBox={isFromAlertBox}
      isResolved={isResolved || isSuccess}
    >
      {isSuccess ? (
        <SuccessMessage
          isFromAlertBox={isFromAlertBox}
          creditorLanguage={creditorLanguage}
          acctNum={accountNumber}
        />
      ) : (
        <>
          <Message>
            {t('alerts.informationRequest.weReWorkingToNegotiate')}{' '}
            {creditorLanguage} {t('alerts.informationRequest.endingIn')}{' '}
            {accountNumber}{' '}
            {t('alerts.informationRequest.butCanTObtainASettlement')}
          </Message>
          <ContentBox>
            <FormContainer>
              <InformationRequestForm
                successCallback={successCallback}
                requestReasons={requestReasons}
                clientId={clientId}
                sfAccountId={accountId}
              />
            </FormContainer>
          </ContentBox>
        </>
      )}
    </PlainBox>
  );
}
