import * as R from 'ramda';

export function getAvgPercentage(settlements) {
  if (R.isEmpty(settlements)) {
    return null;
  } else {
    return R.pipe(R.map(R.prop('settlementPercentage')), R.sum, x =>
      R.isNil(x) ? null : x / settlements.length
    )(settlements);
  }
}
