import { getAppSubDomain, logger } from 'shared-components';

const LOGGING_PREFIX = 'redirectToBrandSpecificLogin ::';

export function redirectToBrandSpecificLogin() {
  const subdomain = getAppSubDomain();
  let rootUrl = null;
  let path = '/login';

  switch (subdomain) {
    case 'fdr':
      rootUrl = process.env.REACT_APP_FDR_ROOT_URL;
      break;
    case 'turnbull':
      rootUrl = process.env.REACT_APP_TURNBULL_ROOT_URL;
      break;
    case 'achieve':
      rootUrl = process.env.REACT_APP_ACHIEVE_ROOT_URL;
      path = '/signin';
      break;
    default: {
      // If our subdomain doesn't match any of the above
      // we'll want to throw here to be extra certain we don't
      // try to redirect the user to the wrong app or brand's signin page
      const errorMessage = `Cannot redirect user to sign-in app. Invalid subdomain ${subdomain}`;
      logger.error(
        `${LOGGING_PREFIX} redirectToBrandSpecificLogin :: ${errorMessage}`
      );
      throw new Error(errorMessage);
    }
  }
  // If this is falsy, we also want to throw here because we couldn't find the rootUrl
  // Could have been an unset .env variable or a bad lawFirm subdomain.
  if (!rootUrl) {
    const errorMessage = `Cannot redirect user to sign-in app. Invalid rootUrl ${rootUrl}`;
    logger.error(
      `${LOGGING_PREFIX} redirectToBrandSpecificLogin :: ${errorMessage}`
    );
    throw new Error(errorMessage);
  }

  logger.info(`${LOGGING_PREFIX} Redirecting user to ${rootUrl}${path}`);
  window.location.assign(`${rootUrl}${path}?url=${window.location.href}`);
}

export default redirectToBrandSpecificLogin;
