import { uploadToECM } from './uploadToECM';

/**
 * Uploads a file to Firebase Storage under a specific path, including custom metadata if provided.
 * @param {Object} params - The parameters object.
 * @param {string} params.applicantId - The ID of the applicant.
 * @param {File} params.file - The file object to be uploaded.
 * @param {string} params.uniqueId - A unique identifier to distinguish the file.
 */
export const leadDocUploadDocument = async (file, oobCode) => {
  const { fileObj } = file;
  const { metadata: rawMetadata } = fileObj;

  const metadata = {
    ...rawMetadata,
    document_type: rawMetadata?.document_type || 'customer_upload'
  };

  try {
    const payload = {
      file: fileObj,
      attributes: JSON.stringify(metadata)
    };

    return await uploadToECM(payload, oobCode);
  } catch (error) {
    throw error;
  }
};

export default leadDocUploadDocument;
