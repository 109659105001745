import React from 'react';
import 'styled-components/macro';
import { Box } from 'atomic-layout';
import { Text } from '@ffn/ui';
import { useTranslation, useBrand } from 'lib/hooks';

const DisputeMessage = () => {
  const { t } = useTranslation();

  const brand = useBrand();
  const abbr = brand('business-name.abbr');

  return (
    <Box>
      <Text>
        {t('alerts.accountRemovalRequest.weHaveReceivedYourRequestTo')} {abbr}{' '}
        {t('alerts.accountRemovalRequest.program')}
      </Text>
      <Text>
        <span>
          <strong>{t('alerts.accountRemovalRequest.whatSNext')}</strong>
        </span>
        <br />
        {t('alerts.accountRemovalRequest.ifYourDisputeRequires')} {abbr}.
      </Text>
    </Box>
  );
};

const RemovalMessage = () => {
  const brand = useBrand();
  const abbr = brand('business-name.abbr');
  const { t } = useTranslation();

  return (
    <Box>
      <Text>
        {t('alerts.accountRemovalRequest.weHaveReceivedConfirmation')} {abbr}{' '}
        {t('alerts.accountRemovalRequest.program')}
      </Text>
    </Box>
  );
};

export function SuccessStep({ requestType }) {
  if (requestType === 'dispute') {
    return <DisputeMessage />;
  } else {
    return <RemovalMessage />;
  }
}
