import {
  Card,
  CardContent,
  useTheme,
  Button,
  Box,
  Chip,
  Typography
} from '@achieve/sunbeam';
import { useSelector } from 'react-redux';
import { getUserEduData } from 'shared-selectors/userRecord';

export function LessonPreviewCard({ size, lesson, index, handleOpenClick }) {
  const theme = useTheme();
  const eduUserData = useSelector(getUserEduData);
  const { title, trackingId, cardImage, readTime } = lesson;
  const cardImg = cardImage?.fields?.file?.url;

  // Default - Not Started
  let statusMsg = 'Not Started';
  let chipColor = 'info';
  if (eduUserData?.lessons && eduUserData?.lessons[trackingId]) {
    if (eduUserData?.lessons[trackingId]?.status === 1) {
      // Started
      statusMsg = 'In Progress';
      chipColor = 'warning';
    } else if (eduUserData?.lessons[trackingId]?.status === 2) {
      // Completed
      statusMsg = 'Completed';
      chipColor = 'success';
    }
  }

  const sizes = {
    lg: {
      width: '350px',
      height: '378px'
    },
    sm: {
      width: '190px',
      height: '213px'
    }
  };

  const isSmall = size === 'sm';
  const cardSize = isSmall ? sizes.sm : sizes.lg;

  return (
    <Card
      sx={{
        mb: `${isSmall ? '18px' : '24px'}`,
        width: cardSize.width,
        height: cardSize.height,
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${cardImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        color: theme.sb.colors.neutral.white,
        cursor: isSmall ? 'pointer' : 'inherit'
      }}
      data-testid={isSmall ? `start-lesson-btn${index}` : null}
      onClick={() => {
        if (isSmall) {
          handleOpenClick();
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="flex-end"
        px={isSmall ? '18px' : '24px'}
      >
        <CardContent sx={{ p: 0 }}>
          {readTime ? (
            <Typography
              variant={isSmall ? 'displayXS10' : 'displayXS30'}
              fontWeight="regular"
              display="block"
            >
              {readTime} min read
            </Typography>
          ) : null}
        </CardContent>
        <CardContent sx={{ p: 0 }}>
          <Typography
            variant={isSmall ? 'displayXS30' : 'displayS20'}
            fontWeight={isSmall ? 'medium' : 'bold'}
            display="block"
          >
            {title}
          </Typography>
        </CardContent>
        <CardContent sx={{ px: 0 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Chip
              label={statusMsg}
              size="small"
              color={chipColor}
              data-testid={`lesson-progress-chip${index}`}
            />
            {!isSmall ? (
              <Button
                data-testid={`start-lesson-btn${index}`}
                size="small"
                onClick={handleOpenClick}
                sx={{
                  marginLeft: '20px'
                }}
              >
                Start Lesson
              </Button>
            ) : null}
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
}
