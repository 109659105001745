import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Icon } from '@ffn/ui';

import { useElementIsVisible } from 'lib/hooks';
import { TaskComponent } from 'shared-pages/dashboard/Notifications/NotificationsList/NotificationContent/components/TaskComponent';
import { useTranslation } from 'lib/hooks';
import {
  AlertTriangle,
  CheckCircle,
  ChevronUp,
  ChevronDown
} from 'react-feather';

const StyledAlertBox = styled.div`
  border: 1px solid
    ${props =>
      props.isResolved
        ? props.theme.colors.secondaryGreen
        : props.theme.colors.secondaryRed};
  margin-bottom: 24px;
  border-radius: 8px;
  max-height: fit-content;
  transition: max-height 0.3s ease-in-out;
  background-color: ${props => props.theme.colors.plain};
`;
const HeaderContainer = styled.div`
  color: ${props =>
    props.isResolved
      ? props.theme.colors.secondaryGreen
      : props.theme.colors.secondaryRed};
  background-color: white;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  span {
    font-size: 16px;
    font-weight: bold;
    margin-left: 24px;
  }
`;
const AlertBodyContainer = styled.div`
  padding: 16px;
`;

export const CollapsibleAlertBox = ({
  notification,
  viewLocation,
  defaultOpen
}) => {
  const { t, alertsTitleNameTranslator } = useTranslation();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isResolved, setIsResolved] = useState(false);
  const alertBox = useRef(null);
  // Checking that alertBox DOM element is at least 50 percent visible within the viewport
  const isVisible = useElementIsVisible(alertBox, 0.5);
  // Using a ref here so that we don't re-render the entire component when the alert is fired, which could cause a second event to be fired
  const elementViewRecorded = useRef(false);
  const title = alertsTitleNameTranslator(notification.title, 'title');

  const iconToShow = isResolved ? CheckCircle : AlertTriangle;
  const titleToShow = isResolved ? t('alerts.success') : title;

  // If the alert is open (not collapsed), the CollapsibleAlertBox is visible within the viewport
  // and a view event has not yet been recorded, fire an analytics event that the alert has been seen by the user
  if (isVisible && isOpen && !elementViewRecorded.current) {
    elementViewRecorded.current = true;
  }

  return (
    <StyledAlertBox isOpen={isOpen} isResolved={isResolved} ref={alertBox}>
      <HeaderContainer isResolved={isResolved} data-testid="alert-header">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon icon={iconToShow} size={20}></Icon>
          <span data-testid="alert-title">{titleToShow}</span>
        </div>
        {isResolved ? null : (
          <Icon
            data-testid={
              isOpen ? 'alert-ribbon-arrow-up' : 'alert-ribbon-arrow-down'
            }
            icon={isOpen ? ChevronUp : ChevronDown}
            size={30}
            onClick={() => setIsOpen(prevState => !prevState)}
          />
        )}
      </HeaderContainer>
      {isOpen ? (
        <AlertBodyContainer data-testid="alert-body">
          <TaskComponent
            notification={notification}
            viewLocation={viewLocation}
            isVisible={isVisible && isOpen}
            setIsResolved={setIsResolved}
            isResolved={isResolved}
            isFromAlertBox
          />
        </AlertBodyContainer>
      ) : null}
    </StyledAlertBox>
  );
};
