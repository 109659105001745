import React from 'react';
import { useTranslation } from 'lib/hooks';
import { Box } from '@achieve/sunbeam';
import { Link } from '@ffn/ui';
import { NavLink } from 'react-router-dom';

import styles from './CreditBureauReviewMessage.module.scss';

/**
 * Renders a message related to the credit bureau review process, including a list of creditors and specific actions the user can take.
 * This component is part of the client contact family alerts and displays detailed information based on the user's credit report.
 * The parent component is expected to handle the loading state, ensuring this component is rendered only when the data is available.
 *
 * @component
 * @param {Object} props The component props.
 * @param {Array<Object>} props.creditorDetails An array of objects containing creditor names and account numbers.
 * Each object in the array has a dynamic key (creditor name) and its value (account number).
 * @param {string} props.discussCreditBureauReviewCreatedDate The date of the credit bureau review discussion, used to provide context to the message.
 *
 * @example
 * const creditorDetails = [{ "Creditor A": "1234" }, { "Creditor B": "5678" }];
 * const discussCreditBureauReviewCreatedDate = "Feb 3, 2024";
 *
 * <CreditBureauReviewMessage
 *   creditorDetails={creditorDetails}
 *   discussCreditBureauReviewCreatedDate={discussCreditBureauReviewCreatedDate}
 * />
 *
 * @returns {JSX.Element} The CreditBureauReviewMessage component, rendering creditor details and actionable advice based on credit bureau review.
 */
const CreditBureauReviewMessage = ({
  creditorDetails,
  discussCreditBureauReviewCreatedDate
}) => {
  const { t, pureT } = useTranslation();

  if (!creditorDetails) return null;

  const singleCreditor = creditorDetails?.length === 1;

  // for multiple creditors prepare JSX of creditor list
  const creditorList = !singleCreditor
    ? creditorDetails?.map(detail => {
        const [creditorName, accountNumber] = Object.entries(detail)[0];
        return (
          <li key={creditorName + accountNumber}>
            {creditorName} {t('alerts.clientContactFamily.endingIn')}{' '}
            {accountNumber}
          </li>
        );
      })
    : [];

  // for single creditors get creditor name
  const creditorName = singleCreditor
    ? Object.keys(creditorDetails[0])[0]
    : null;

  return (
    <div
      className={styles.main}
      data-testid="client-contact-family-creditor-bureau-review-message"
    >
      {singleCreditor ? (
        <div>
          {pureT(
            'alerts.clientContactFamily.basedOnYourCreditReportDateCreditReportSingleCreditor',
            {
              creditReportDate: discussCreditBureauReviewCreatedDate ?? '',
              creditorName
            }
          )}
        </div>
      ) : (
        <div>
          {pureT(
            'alerts.clientContactFamily.basedOnYourCreditReportDateCreditReport',
            { creditReportDate: discussCreditBureauReviewCreatedDate ?? '' }
          )}
          <ul
            className={styles.noDecorationLastItem}
            data-testid="client-contact-family-creditor-bureau-review-message-list"
          >
            {creditorList}
            <li>
              {t('alerts.clientContactFamily.ifYouVeAlreadyStoppedPaying')}
            </li>
          </ul>
        </div>
      )}
      <div className={styles.indentedSection}>
        <Box data-testid="client-contact-family-creditor-bureau-review-message-why">
          <h3>{t('alerts.clientContactFamily.whyAmIGettingThisAlert')}</h3>
          <span>
            {t('alerts.clientContactFamily.choosingToStopPaymentsAndLetting')}
          </span>
        </Box>
        <Box
          className={styles.closerUl}
          data-testid="client-contact-family-creditor-bureau-review-message-what-you-can-do"
        >
          <h3>{t('alerts.clientContactFamily.hereSWhatYouCanDo')}</h3>
          {t(
            'alerts.clientContactFamily.cancelAutopayToCreditorsInOneOfTheseWays'
          )}
          <ul>
            <li>
              {t(
                'alerts.clientContactFamily.logIntoYourCreditorWebsiteToCancel'
              )}
            </li>
            <li>
              {t(
                'alerts.clientContactFamily.ifYouSetUpAutomaticBillPaymentsYouCanRemoveThese'
              )}
            </li>
            <li>
              {t('alerts.clientContactFamily.orDownloadAndCompleteThe')}{' '}
              <Link
                href="https://assets.ctfassets.net/b32zuu6bt176/Pk43tKz4PimpJ3l5UIIjd/c2180111a1deb7a223a6297bd0816abc/stop-auto-pay-letter_template.docx"
                target="_blank"
              >
                {t('alerts.clientContactFamily.stopAutopayLetter')}
              </Link>{' '}
              {t('alerts.clientContactFamily.andMailItToYourCreditor')}
            </li>
          </ul>
        </Box>
        <Box className={styles.faqSection}>
          <h3>{t('alerts.clientContactFamily.faQs')}</h3>
          <p>
            <Link
              as={NavLink}
              data-testid="client-contact-family-creditor-bureau-review-message-creditor-calls"
              to="/program-resources/creditor-calls"
            >
              {t('alerts.clientContactFamily.howDoIDealWithCreditorCalls')}
            </Link>
          </p>
          <p>
            <Link
              as={NavLink}
              data-testid="client-contact-family-creditor-bureau-review-message-settlement-overview"
              to="/enrolled-debt/settlement-overview"
            >
              {t(
                'alerts.clientContactFamily.whatInfluencesYourSettlementTiming'
              )}
            </Link>
          </p>
          <p>
            <Link
              as={NavLink}
              data-testid="client-contact-family-creditor-bureau-review-message-tell-my-creditors"
              to="/program-resources/faq#should-i-tell-my-creditors-i-wont-be-paying-them"
            >
              {t(
                'alerts.clientContactFamily.shouldITellMyCreditorsIWonTBePayingThem'
              )}
            </Link>
          </p>
        </Box>
      </div>
    </div>
  );
};

export default CreditBureauReviewMessage;
