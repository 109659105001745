import React from 'react';
import { Grid, IconButton, useTheme } from '@achieve/sunbeam';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Nav(props) {
  const { title, backAction, testId } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{ p: '24px 16px' }}
      bgcolor={theme.sb.colors.neutral.white}
    >
      <Grid item xs={1} justifyContent="center" alignItems="center">
        <IconButton
          data-testid={testId}
          size="small"
          color="primary"
          sx={{
            backgroundColor: 'transparent !important',
            boxShadow: 'none !important'
          }}
          onClick={() => backAction(false)}
        >
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        xs
        container
        justifyContent="center"
        alignItems="center"
        px="16px"
      >
        {title}
      </Grid>
    </Grid>
  );
}

export default Nav;
