import React, { useEffect } from 'react';
import { Box, useResponsiveQuery } from 'atomic-layout';
import { useSelector } from 'react-redux';
import { useLocale, useNotificationTracking, useTranslation } from 'lib/hooks';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { aalInterest, markActioned, markRead } from 'lib/api';
import 'styled-components/macro';
import { Image, Link } from '@ffn/ui';
import { NavLink } from 'react-router-dom';
import { getAppSubDomain, logger } from 'shared-components';
import { ACHIEVE_ROOT_URL } from 'constants';

import { Alert, AlertTitle, Button, Typography } from '@achieve/sunbeam';

const LOGGING_PREFIX = 'AAL INTEREST ::';

export const AalEligible = ({ notification, viewLocation, isVisible }) => {
  const subdomain = getAppSubDomain();
  const trackNotificationView = useNotificationTracking();
  const clientData = useSelector(state => state?.models?.client ?? {});
  const isMd = useResponsiveQuery({ from: 'md' });

  const { t, pureT } = useTranslation();
  const { locale } = useLocale();

  const { isActioned, trackingLabel } = notification;

  const language = clientData?.language ?? 'english';
  const firstName = clientData?.firstName;

  const { clientEmail, cplusLoanId, opportunityId } = notification.payload;

  const aalUrl = `${ACHIEVE_ROOT_URL}/acceleration-loan/learn?appId=${opportunityId}&language=${language}`;
  const promoVideoLinkEnglish = 'https://www.youtube.com/watch?v=bQDnuf66jOI';

  // Assign businessName and congratulationsCopy based on the subdomain.
  // 'Achieve' uses the full name and a specific message, while 'FDR' uses an abbreviation and a template message.
  const brandingInfo = {
    achieve: {
      businessName: 'Achieve Resolution',
      congratulationsCopy: t(
        'alerts.aalEligible.congratulationsBecauseOfYourCommitmentToYourAchieveResolution'
      )
    },
    fdr: {
      businessName: 'FDR',
      congratulationsCopy: t(
        'alerts.aalEligible.congratulationsBecauseOfYourCommitmentTo'
      )
    }
  };

  const { businessName, congratulationsCopy } = brandingInfo[subdomain] || {};

  if (!businessName || !congratulationsCopy) {
    logger.error(
      `${LOGGING_PREFIX} subdomain: (${subdomain}) does not belong to an implemented brand`
    );
  }

  useEffect(() => {
    trackNotificationView({
      trackingLabel,
      viewLocation,
      isVisible
    });
  }, [trackingLabel, viewLocation, isVisible, trackNotificationView]);

  useEffect(() => {
    if (!notification?.isRead) {
      const { id: notificationId } = notification;
      // Marks the notification as read in Dashboard backing store
      markRead(notificationId);
    }
  }, [notification]);

  // Don't render until the payload is available
  if (!notification.payload) {
    return <></>;
  }

  const handleInterest = async () => {
    try {
      // Opens the AAL url in a new tab
      window.open(aalUrl, '_blank', 'noopener');
      // Sends the AAL Interest Email to the AAL team
      const response = await aalInterest({
        clientName: `${clientData?.firstName} ${clientData?.lastName}`,
        clientEmail: clientEmail,
        cplusLoanId: cplusLoanId,
        opportunityId: opportunityId
      });

      if (response?.success !== true && response?.error) {
        throw new Error(response?.error);
      }

      // Marks the notification as actioned in Dashboard backing store
      await markActioned(notification?.id);

      await analyticsTrackEvent(
        {
          category: 'alert',
          action: 'success',
          label: trackingLabel
        },
        'AAL Interest Success'
      );
    } catch (error) {
      logger.error(`${LOGGING_PREFIX} An error occurred: `, error);
      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'failure',
          label: trackingLabel
        },
        'AAL Interest Failure'
      );
    }
  };

  return (
    <div data-testid="aalEligibleMessage">
      <Typography variant="displayS10" fontWeight="bold">
        {pureT(
          'alerts.aalEligible.interestedInResolvingAllOfYourEnrolledDebts',
          {
            firstName
          }
        )}
      </Typography>
      <Typography sx={{ fontSize: '15px' }} variant="XS30">
        <p>
          <span
            css={`
              float: right;
              width: ${isMd ? 165 : 100}px;
              height: auto;
              display: inline-block;
              margin-left: 16px;
              margin-right: 16px;
              margin-bottom: 16px;
            `}
          >
            <Image
              src="https://images.ctfassets.net/80jeq2aeq4l1/snXdHWdNXIfHnpb7YsJEI/af1efdb0ab2cd64ea232ec9f60dfb3b6/2022_Achieve_Logo_RGB.svg"
              alt="Achieve logo"
              width={isMd ? 165 : 100}
            />
          </span>
        </p>
        {congratulationsCopy}
        <div
          css={`
            text-align: center;
          `}
        >
          <Box marginBottom={32} marginTop={28}>
            <Button
              color="primary"
              data-testid="aalEligibleMessage-InterestButton"
              onClick={() => handleInterest(true)}
              sx={{ maxWidth: '343px' }}
            >
              {t('alerts.aalEligible.learnMore')}
            </Button>
          </Box>
        </div>
        {isActioned ? (
          <Box>
            <Alert data-testid="aalEligibleMessage-ActionedMessage">
              <AlertTitle>{t('alerts.success')}</AlertTitle>
              <Typography>
                {t('alerts.aalEligible.yourInformationHasBeenSentToAchieve')}
              </Typography>
            </Alert>
          </Box>
        ) : (
          ''
        )}
        <p>
          {t('alerts.aalEligible.thereIsNoCostToApplyAndYouReNot')}{' '}
          <strong>{t('alerts.aalEligible.ifYouApplyAndQualify')}:</strong>
        </p>
        <ul>
          <li>
            {pureT('alerts.aalEligible.enableToSettleAllYour', {
              businessName
            })}
          </li>
          <li>
            {t(
              'alerts.aalEligible.stopInterestAndFeesFromAccruingOnYourEnrolledDebts'
            )}
          </li>
          <li>
            {t(
              'alerts.aalEligible.easeYourStressByStoppingCreditorCallsFaster'
            )}
            <sup style={{ lineHeight: 0 }}>*</sup>
          </li>
        </ul>
        <strong>
          <p>{t('alerts.aalEligible.nextSteps')}:</p>
        </strong>
        <ul>
          <li>
            {t(
              'alerts.aalEligible.makeSureWeHaveYourCurrentContactInformation'
            )}{' '}
            <Link as={NavLink} to="/settings/contact">
              {t('alerts.aalEligible.here')}
            </Link>
            .
          </li>
          <li>
            <Link
              href={promoVideoLinkEnglish}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('alerts.aalEligible.watchThisVideo')}
            </Link>{' '}
            {t('alerts.aalEligible.toLearnMoreAboutThisOpportunity')}
          </li>
          {locale === 'en-US' ? (
            <li>
              {t('alerts.aalEligible.startAFreeNoObligation')}{' '}
              <Link onClick={() => handleInterest(true)}>
                {t('alerts.aalEligible.application')}.
              </Link>
            </li>
          ) : (
            ''
          )}
        </ul>
      </Typography>
      <Typography sx={{ fontSize: '13px' }} variant="XS20">
        <p
          css={`
            color: ${props => props.theme.colors.textGray};
          `}
        >
          <sup>*</sup>
          {pureT('alerts.aalEligible.cannotMakeAnyGuaranteesAbout', {
            businessName
          })}
        </p>
        <p
          css={`
            color: ${props => props.theme.colors.textGray};
          `}
        >
          {t('alerts.aalEligible.loansAvailableThroughAchieve')}
        </p>
      </Typography>
    </div>
  );
};

export default AalEligible;
