import { axiosInstance as axios } from 'lib/axios';
import getApiUrl from 'lib/utils/getApiUrl';

export const fetchLaunchDarklyFlags = async (
  rawFeatureKeys,
  customAttributes
) => {
  const apiUrl = getApiUrl();
  const uri = `${apiUrl}/features`;
  const featureKeys = Array.isArray(rawFeatureKeys)
    ? rawFeatureKeys
    : [rawFeatureKeys];
  const body = {
    featureKeys,
    withDetails: true,
    userAttributes: {
      ...customAttributes
    },
    customAttributes
  };

  try {
    const response = await axios.post(uri, body);
    return response?.data;
  } catch (err) {
    if (err.response) {
      throw new Error(
        err.response.message || 'Fetch Launch Darkly flags failed.'
      );
    } else {
      throw err;
    }
  }
};
