import React from 'react';
import { Card, CardContent, CardActions, Typography } from '@achieve/sunbeam';
import { useBrand } from 'lib/hooks';
import AddToCalendar from './Calendar/index';
import { getAppSubDomain } from 'shared-components';
import CopyTextPhoneCode from './CopyCode/index';
import useStyles from './TaskList.styles';
import InfoCard from '../InfoCard';

export const TaskList = () => {
  const subDomain = getAppSubDomain();
  const brand = useBrand();
  const { classes } = useStyles();
  const customerCareNumber = brand('contact.customer-service.phone');
  const brandContentMapping = {
    fdr: {
      phoneNumberSaveMsg: `Save the FDR Phone Number ${customerCareNumber} in your phone`,
      phoneNumber: customerCareNumber,
      infoCardTitle: '83%',
      infoCardSubTitle:
        'of FDR graduates feel they can take action to improve their quality of life.',
      infoCardBodyText:
        'Source: Assessing the Social Impact of Freedom Debt Relief, Arizona State University, Seidman Research Institute 2019'
    },
    achieve: {
      phoneNumberSaveMsg: `Save the Achieve Resolution number ${customerCareNumber} in your phone`,
      phoneNumber: customerCareNumber,
      infoCardTitle: 'A leader in debt relief',
      infoCardSubTitle:
        'Since 2002, Achieve Resolution has settled over 4 million accounts.',
      infoCardBodyText:
        'Statistics reflect the results of the members we and our affiliates have served since 2002 (as of October 2023).'
    }
  };
  return (
    <>
      <div className={classes.container}>
        <Typography
          variant="displayM10"
          fontWeight="medium"
          mb="8px"
          display="block"
        >
          Take action
        </Typography>
        <Typography variant="bodyS30" display="block">
          Recommended action items to start strong.
        </Typography>
        <Card className={classes.cardContainer} variant="outlined">
          <CardContent>
            <Typography variant="displayS10" fontWeight="bold">
              Add deposit dates to your calendar
            </Typography>
            <Typography variant="bodyS30" component="div">
              Helpful reminders for every deposit date.
            </Typography>
          </CardContent>
          <CardActions>
            <AddToCalendar />
          </CardActions>
        </Card>
        <Card className={classes.cardContainer} variant="outlined">
          <CardContent>
            <Typography variant="displayS10" fontWeight="bold">
              {brandContentMapping[subDomain].phoneNumberSaveMsg}
            </Typography>
            <Typography variant="bodyS30" component="div">
              So you never miss a call.
            </Typography>
          </CardContent>
          <CardActions>
            <CopyTextPhoneCode
              code={brandContentMapping[subDomain].phoneNumber}
              btnName=" Copy Phone Number"
              dataTestId="phone-code-btn"
            />
          </CardActions>
        </Card>
      </div>
      <InfoCard
        title={brandContentMapping[subDomain].infoCardTitle}
        subTitle={brandContentMapping[subDomain].infoCardSubTitle}
        bodyText={brandContentMapping[subDomain].infoCardBodyText}
      />
    </>
  );
};
