import { useDisabledRouteObject, useSiteData } from "lib/hooks";
import * as R from "ramda";
import { matchRoutes } from "react-router-dom";

export function useFeatureFlag() {
	const { features } = useSiteData();
	const disabledRoutesObject = useDisabledRouteObject();

	function path(pathKey) {
		return R.isNil(pathKey)
			? true
			: R.isNil(matchRoutes(disabledRoutesObject, pathKey));
	}

	function feature(flagKey) {
		return R.isNil(flagKey)
			? true
			: Boolean(R.path(flagKey.split("."), features));
	}

	function featureFlag({ pathKey, flagKey }) {
		return path(pathKey) && feature(flagKey);
	}

	return { path, feature, featureFlag };
}
