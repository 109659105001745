import { createSelector } from 'reselect';
import { parseISO, format, addBusinessDays } from 'date-fns/fp';
import i18next from 'i18next';

export const getClient = state => state?.models.client;

export const addDaysBySpa = spa =>
  /gcs/i.test(spa) ? addBusinessDays(7) : addBusinessDays(3);

export const getEarliestDraftChangeDate = createSelector(getClient, client =>
  addDaysBySpa(client?.spa)(new Date())
);

export const getDedicatedAccountBalance = createSelector(getClient, client => {
  if (!client) {
    return null;
  }

  const { spaCurrentBalance } = client;

  return spaCurrentBalance;
});

export const getAmountLimits = createSelector(getClient, client => ({
  minAmount: client.monthlyDraftsFeeAmount,
  maxAmount: client.monthlyDraftsFeeAmount + 1000
}));

export const getClientFees = createSelector(getClient, client => {
  const { t } = i18next;

  let fees = 0;
  let feeLines = [];
  if (
    client !== undefined &&
    client.nllgFee !== null &&
    client.nllgFee !== undefined &&
    client.nllgFee > 0 &&
    client.nllgEnrolled === true &&
    client.monthlyFeeDate !== null
  ) {
    fees += parseFloat(client.nllgFee);
    feeLines.push({
      date: format('yyyy-MM-dd', parseISO(client.monthlyFeeDate)),
      amount: parseFloat(client.nllgFee),
      desc: `${t('dedicatedAccount.nllgSubscription')}`
    });
  }
  if (
    client !== undefined &&
    client.monthlyFee !== null &&
    client.monthlyFeeDate !== null
  ) {
    fees += parseFloat(client.monthlyFee);
    feeLines.push({
      date: format('yyyy-MM-dd', parseISO(client.monthlyFeeDate)),
      amount: parseFloat(client.monthlyFee),
      desc: `${t('dedicatedAccount.monthlyServiceFee')}`
    });
  }
  return {
    fees,
    feeLines
  };
});

export const getClientLanguage = createSelector(getClient, client => {
  if (client?.language === 'Spanish') {
    return 'es-us';
  } else if (client?.language === 'English' || client?.language !== undefined) {
    return 'en-us';
  } else {
    return;
  }
});
