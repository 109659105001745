import React from 'react';
import { Box, Typography } from '@achieve/sunbeam';
import { CallUsSVG } from '../assets/CallUs';
import { CustomerServiceHours } from './CustomerServiceHours';
import { PhoneLink } from './PhoneLink';
import { useTranslation } from 'lib/hooks';

export const ContactAgent = ({ children, testIdPrefix }) => {
  const { t } = useTranslation();
  console.log({ children, testIdPrefix });

  return (
    <Box
      data-testid={`${testIdPrefix}-contact-agent-container`}
      display="flex"
      flexDirection="column"
      gap="48px"
      alignItems="center"
      maxWidth="365px"
    >
      <CallUsSVG />
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        <Typography
          variant="displayM30"
          fontWeight="500"
          fontSize="28px"
          textAlign="center"
          lineHeight="33.6px"
        >
          {t('oneClick.pleaseCallUs')}
        </Typography>
        {children}
        <PhoneLink testIdPrefix={testIdPrefix} />
      </Box>
      <CustomerServiceHours />
    </Box>
  );
};
