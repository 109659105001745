import axios from 'axios';
import getApiUrl from 'lib/utils/getApiUrl';
import { logger } from 'shared-components';

export const leadDocUploadSendNotification = async (oobCode, description) => {
  const apiUrl = `${getApiUrl()}/lead/leadDocNotification`;

  try {
    await axios.post(
      apiUrl,
      { description },
      {
        headers: { 'x-oob-code': oobCode }
      }
    );

    // logs positive outcome for any 2xx response
    logger.info(
      'DOCUMENT UPLOAD :: SEND NOTIFICATION :: Notification sent successfully'
    );
  } catch (error) {
    // the promise returned by axios for all non 2xx responses will be rejected

    const status = error?.response?.status || 'unknown';
    logger.error(
      `DOCUMENT UPLOAD :: SEND NOTIFICATION :: Error sending document upload notification - status: (${status})`
    );
  }
};
