import React from 'react';
import i18next from 'i18next';
import { Typography } from '@achieve/sunbeam';
import { useBrand } from 'lib/hooks';

/**
 * React component for displaying contact information.
 *
 * @param {Object} props - The props object containing children.
 * @returns {JSX.Element} A React component with contact information.
 */
export const ContactUsComponent = ({ children }) => {
  const { t } = i18next;
  const brand = useBrand();
  const phone = brand('contact.customer-service.phone');
  return (
    <>
      <Typography variant="ascendBodyXs" component="p">
        {children}
      </Typography>
      <Typography variant="ascendBodyXs" component="p">
        {t('pts.callUsAt')} {phone}
      </Typography>
    </>
  );
};
