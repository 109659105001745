import { getAvgPercentage } from './getAvgPercentage';
import * as R from 'ramda';

const getUserLanguage = () => {
  const lng = localStorage.getItem('i18nextLng');
  if (lng === 'es') {
    return 'es';
  } else {
    return 'en-US';
  }
};

export function getCustomDimensions({
  client,
  suipOpportunity,
  userRecord,
  settlements
}) {
  const avgSettlementPercentage = getAvgPercentage(settlements) ?? '';
  const customDimensions = R.pipe(
    R.pickAll([
      'lawFirmId',
      'language',
      'lifecycleMarketingSegment',
      'cplusLeadPhase',
      'clientTotalBeginningDebt',
      'status',
      'accountId',
      'npsSurveyScore',
      'monthlyDraftsFeeAmount',
      'monthsInProgram'
    ]),
    x => ({
      userRole: 'Client', // will always be client as 'agent' mode has been removed
      avgSettlementPercentage,
      ...x,
      clientId: x.accountId ? x.accountId : '',
      // purposely after the spread to overwrite client/x object
      // need to populate values for onboarding users
      lawFirmId: x.lawFirmId ? x.lawFirmId : userRecord.lawFirmId,
      language: getUserLanguage(),
      // data layer should have empty string for undefined or null attributes
      cplusLeadPhase: x.cplusLeadPhase ? x.cplusLeadPhase : '',
      status: x.status ? x.status : suipOpportunity.id ? 'Opportunity' : '',
      npsSurveyScore: x.npsSurveyScore ? x.npsSurveyScore : '',
      clientTotalBeginningDebt: x.clientTotalBeginningDebt
        ? x.clientTotalBeginningDebt
        : suipOpportunity.estimatedTotalDebt,
      monthlyDraftsFeeAmount: x.monthlyDraftsFeeAmount
        ? x.monthlyDraftsFeeAmount
        : suipOpportunity.draftAmount
    }),
    R.omit(['accountId'])
  )(client);

  return customDimensions;
}
