import React from 'react';
import { Box } from 'atomic-layout';
import { CheckmarkCircle } from '@ffn/icons';
import { Icon } from '@ffn/ui';
import { useTranslation } from 'lib/hooks';

export function Success(params) {
  const { t } = useTranslation();
  return (
    <>
      <Box
        flex
        alignItems="center"
        paddingBottom={8}
        style={{ fontWeight: 600 }}
      >
        <Icon icon={CheckmarkCircle} size={24} color="secondaryGreen" />
        <Box
          marginLeft={8}
          css={`
            color: ${({ theme }) => theme.colors.secondaryGreen};
          `}
        >
          {t('changeDepositWizard.niceGoing')}
        </Box>
      </Box>
      <Box>
        {t('changeDepositWizard.youreEstimatedToGraduate')}{' '}
        {t('changeDepositWizard.monthsEarlier')}
      </Box>
    </>
  );
}
