import * as React from 'react';
import Modal from 'react-modal';
import { Button } from '@ffn/ui';
import { useBrand } from 'lib/hooks';
import { useTranslation } from 'lib/hooks';

const modalStyles = {
  overlay: {
    position: 'fixed',
    zIndex: '999999',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    top: '0',
    left: '0',
    width: '50rem',
    maxWidth: 'calc(100vw - 2rem)',
    maxHeight: 'calc(100vh - 2rem)',
    overflowY: 'auto',
    position: 'relative'
  }
};

const buttonContainerStyle = {
  textAlign: 'center',
  marginTop: '20px'
};

Modal.setAppElement('#root');

export function EFTAModal({ isOpen, closeModal }) {
  const brand = useBrand();
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Electronic Funds Transfer Authorization"
    >
      {' '}
      <h2>{t('customerService.oneTimeDeposit.agreement1')}</h2>
      <div className="Modal-disclaimer">
        <div>
          (1) {t('efta.eftaText0')} {brand('business-name.long')},{' '}
          {t('efta.eftaText1')}
          <br />
          (2) {t('efta.eftaText2')} {brand('business-name.long')}{' '}
          {t('efta.eftaText3')}
          <br />
          (3) {t('efta.eftaText4')} {brand('business-name.long')}{' '}
          {t('efta.eftaText5')} {brand('contact.customer-service.phone')}{' '}
          {t('efta.eftaText6')} {brand('business-name.long')}{' '}
          {t('efta.eftaText7')}
          <br />
          (4) {t('efta.eftaText8')} (5) {t('efta.eftaText9')} (6){' '}
          {t('efta.eftaText10')}
        </div>
        {t('efta.eftaText11')}
        <div style={buttonContainerStyle}>
          <Button variant="primary" height={40} onClick={closeModal}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EFTAModal;
