import { useTranslation as UT } from 'react-i18next';
import camelcase from 'camelcase';
import { format, parse } from 'date-fns';
import { logger as libLogger } from 'shared-components';

/** useTranslation hook
 * using react-18next and handles
 * 1) simple path.key translations - t
 * 2) may convert a string to a key for a "dynamic"
 *    translation with the original message as a fall back -t
 * 3) handles conditional singular or plural translations
 *    with count parameter
 *    (keys formatted as "key_one" & "key_other") - pluralTranslate
 * 4) translates "MMM YYYY" date strings - translateAbbrDate
 * 5) translates the alert name and title fields - alertsTitleNameTranslator
 * 6) pureT - the original t function from react-i18next, can be used with interpolation or other features not contained in the above functions
 * 7) formatDate - formats a given date string according to the specified format and date-fns locale
 * @component
 */
export const useTranslation = () => {
  const { t } = UT();
  /**
   *
   * @param {string} path - static = path.key, dynamic = path (key derived from message)
   * @param {string} message - plain text to convert into key
   * @returns string - translated text
   */
  const translate = function(path, message) {
    if (!message) {
      // if message is null or path.key cannot be found return empty string
      const translation = t(path, { returnObjects: true, defaultValue: '' });
      return typeof translation === 'object' ? '' : translation;
    } else {
      if (typeof message === 'string') {
        // regex for non alpha numeric characters
        return t(`${path}.${camelcase(message.replace(/\W/g, ' '))}`, {
          defaultValue: message
        });
      }
    }
  };

  /**
   *
   * @param {string} path - path.key
   * @param {number} count - the quantity the key references
   * @returns string - translated text
   */
  const pluralTranslate = (path, count) => {
    return t(path, { count: count });
  };

  /**
   * @param {string} dateString - "MMM YYYY"
   * @returns {string} - localized
   */
  const translateAbbrDate = dateString => {
    if (typeof dateString !== 'string') {
      return '';
    }

    let [month, year] = dateString.split(' ');

    if (!month || !year) {
      return '';
    } else {
      return translate('common.datetime.months', month) + ' ' + year;
    }
  };

  /** This function translates the alert name and title fields.
   * @param {array} array of strings, either a key, creditor name, or the amount of alerts in a string number
   * @param {string} nameOrTitle ('name' || 'title')
   * @returns
   */
  const alertsTitleNameTranslator = (messageKeysArray, nameOrTitle) => {
    if (Array.isArray(messageKeysArray)) {
      return messageKeysArray
        .map(messagePart => translate(`alerts.${nameOrTitle}`, messagePart))
        .join(' ');
    } else {
      return messageKeysArray;
    }
  };

  /**
   * Formats a given date string according to the specified format and date-fns locale.
   * If the date string or format is invalid, or if an error occurs during parsing or formatting,
   * the function logs the error using the provided logger and returns an empty string.
   *
   * @param {string} dateString - The date string to format.
   * @param {string} dateFormat - The format string defining how the date should be formatted.
   * @param {Object} dateFnsLocale - The locale object from `date-fns` to use for formatting.
   * @param {Object} logger - (Optional) The logging object with an `error` method for logging errors.
   *                           Defaults to `libLogger` if not provided.
   * @returns {string} The formatted date string according to the specified format and locale,
   *                   or an empty string if an error occurs or if inputs are invalid.
   *
   * @example
   * // Example for formatting a date string in the U.S. English locale
   * import { useLocale } from 'lib/hooks';
   * const { dateFnsLocale } = useLocale();
   *
   * const formattedDate = formatDate('Mar 11, 2025', 'MMM dd, yyyy', dateFnsLocale);
   * console.log(formattedDate); // Outputs: 'Mar 11, 2025' in U.S. English locale
   */
  const formatDate = (
    dateString,
    dateFormat,
    dateFnsLocale,
    logger = libLogger
  ) => {
    try {
      if (
        typeof dateString !== 'string' ||
        typeof dateFormat !== 'string' ||
        !dateString ||
        !dateFormat ||
        !dateFnsLocale
      ) {
        throw new Error();
      }
      // Parse the input date string into a Date object based on the specified format
      const parsedDate = parse(dateString, dateFormat, new Date());
      // Format the parsed Date object into a string according to the specified locale
      const formattedDate = format(parsedDate, dateFormat, {
        locale: dateFnsLocale
      });
      return formattedDate;
    } catch (error) {
      logger.error(
        `Error formatting date "${dateString}" with format "${dateFormat}": and dateFnsLocale: "${dateFnsLocale}"`,
        error
      );

      return '';
    }
  };

  return {
    formatDate,
    t: translate,
    tCount: pluralTranslate,
    translateAbbrDate,
    alertsTitleNameTranslator,
    pureT: t
  };
};
