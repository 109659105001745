const { version } = require('../../../package.json');

export const DD_CONFIG_SITE = 'datadoghq.com';

export const APP_VERSION = version;

const {
  REACT_APP_DATADOG_SERVICE: DD_SERVICE,
  REACT_APP_DATA_DOG_APP_ENV: APP_ENV, // Dashboard uses REACT_APP_TEALIUM_ENV for DEV, STG, PRD
  REACT_APP_DATADOG_ACX_RUM_APP_ID,
  REACT_APP_DATADOG_ACX_RUM_CLIENT_TOKEN,
  REACT_APP_DATADOG_RUM_APP_ID,
  REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
  REACT_APP_DATA_DOG_RUM_ENABLED: DD_RUM_ENABLED,
  REACT_APP_DATA_DOG_SESSION_REPLAY_SAMPLE_RATE: DD_SESSION_REPLAY_SAMPLE_RATE
} = process.env;

const DATADOG_RUM = {
  DATADOG_APPLICATION_ID: REACT_APP_DATADOG_RUM_APP_ID,
  DATADOG_CLIENT_TOKEN: REACT_APP_DATADOG_RUM_CLIENT_TOKEN
};

const DATADOG_ACX_RUM = {
  DATADOG_APPLICATION_ID: REACT_APP_DATADOG_ACX_RUM_APP_ID,
  DATADOG_CLIENT_TOKEN: REACT_APP_DATADOG_ACX_RUM_CLIENT_TOKEN
};

export const CLIENT_ID_APP_ID_MAP = {
  achieve: DATADOG_ACX_RUM,
  fdr: DATADOG_RUM,
  turnbull: DATADOG_RUM
};

export { DD_SERVICE, APP_ENV, DD_RUM_ENABLED, DD_SESSION_REPLAY_SAMPLE_RATE };
