import React from 'react';
import { Currency } from 'shared-components/NumberFormatters';
import { getTestIds } from 'lib/utils/dataTestIds';
import { useTranslation } from 'lib/hooks';
const notifyTestIDs = getTestIds('notifications');

export function AcceptMessage({ account }) {
  const { t } = useTranslation();
  return (
    <>
      <p data-testid={notifyTestIDs.notification_content_success_message}>
        {t('alerts.settlementAuth.congratulationsYour')}{' '}
        {account.originalCreditor}{' '}
        {t('alerts.settlementAuth.settlementHasBeenAuthorized')}{' '}
        <Currency value={account.currentBalance} />{' '}
        {t('alerts.settlementAuth.andWasSettledFor')}{' '}
        <Currency value={account.settlementAmount} />.
      </p>
      <p>{t('alerts.settlementAuth.toAvoidPuttingThisSettlementAt')}</p>
    </>
  );
}
