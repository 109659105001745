import React from 'react';
import { Tooltip, Icon } from '@ffn/ui';
import { useBrand } from 'lib/hooks';
import i18next from 'i18next';
import { Info } from 'react-feather';
const activity = abbrName => {
  const { t } = i18next;
  return {
    'Action Needed': t('tooltip.actionNeeded'),
    'Advanced Negotiation': t('tooltip.advancedNegotiation'),
    'Authorization Needed': t('tooltip.authorizationNeeded'),
    'Paid Off': t('tooltip.paidOff'),
    'Pending Available Funds': t('tooltip.pendingAvailableFunds'),
    'Stipulated Agreement': t('tooltip.stipulatedAgreement'),
    'Structured Settlement': t('tooltip.structuredSettlement'),
    'Written Off': t('tooltip.writtenOff'),
    'Account Not Ready': t('tooltip.accountNotReady'),
    'Bulk Negotiation':
      t('tooltip.bulkNegotiationPart1') +
      ' ' +
      abbrName +
      ' ' +
      t('tooltip.bulkNegotiationPart2'),
    'Strategic Hold': abbrName + ' ' + t('tooltip.strategicHold'),
    'Targeted Negotiation':
      t('tooltip.targetedNegotiationPart1') +
      ' ' +
      abbrName +
      ' ' +
      t('tooltip.targetedNegotiationPart2')
  };
};

export function ActivityTooltip({ tipKey }) {
  const brand = useBrand();
  return (
    <>
      <Icon
        icon={Info}
        color="primaryBlue"
        size="15"
        data-tip
        data-for={tipKey}
        onClick={ev => {
          ev.stopPropagation();
        }}
      />
      <Tooltip id={tipKey} place="bottom" delayHide={3000}>
        {activity(brand('business-name.abbr'))[tipKey]}
      </Tooltip>
    </>
  );
}
