import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@ffn/ui';
import 'styled-components/macro';
import { RefreshCw } from 'react-feather';
import { Box, Typography, useTheme } from '@achieve/sunbeam';
import { useBrand, useTranslation } from 'lib/hooks';

export function ErrorHeading({ error }) {
  const brand = useBrand();
  const { t, pureT } = useTranslation();
  const phone = brand('contact.customer-service.phone');
  const email = brand('contact.customer-service.email');
  const errorFilesUploadedMessage = t('docUpload.errorFilesUploadedMessage');
  const errorFilesUploadedPhoneEmail = pureT(
    'docUpload.errorFilesUploadedPhoneEmail',
    { phone, email }
  );
  const theme = useTheme();
  const textColor = theme?.ascend?.colors?.content?.primary;
  const path = '/customer-service/document-upload';
  const errorMessageHeading = error ?? t('docUpload.submissionFailed');

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="16px"
      data-testid="document-upload-failure"
      maxWidth="365px"
    >
      <NavLink
        to={path}
        css={`
          color: ${({ theme }) => theme.colors.primaryGray};
          display: flex;
        `}
      >
        <Icon icon={RefreshCw} size={32} />
      </NavLink>
      <Typography color={textColor} variant="ascendHeadingSm" component="h2">
        <strong>{errorMessageHeading}</strong>
      </Typography>
      <Typography
        color={textColor}
        variant="bodyS30"
        component="p"
        textAlign="center"
      >
        {errorFilesUploadedMessage}
      </Typography>
      <Typography
        color={textColor}
        variant="bodyS30"
        component="p"
        textAlign="center"
      >
        {errorFilesUploadedPhoneEmail}
      </Typography>
    </Box>
  );
}

export default ErrorHeading;
