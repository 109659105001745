import React, { useState } from 'react';
// import { NormalBankAccountChangeForm } from 'shared-components';
// import { useSelector } from 'react-redux';
import { CustomPanel } from '../CustomTabs';
import UnableToPerformRequest from '../UnableToPerformRequest';
import 'styled-components/macro';
// import { isNil } from 'ramda';
// import { Icon } from '@ffn/ui';
// import { Box } from 'atomic-layout';
// import { CheckmarkCircle } from '@ffn/icons';
import { useTranslation } from 'lib/hooks';

export function BankAccountChange() {
  // const client = useSelector(state => state?.models?.client);
  // const [state, setState] = useState('form');
  const [state] = useState('form');
  const { t } = useTranslation();

  // const successCallback = () => setState('success');

  return (
    // <>
    //   <CustomPanel
    //     heading={
    //       <>
    //         {state === 'form' &&
    //           t('bankAccountChange.updateYourConnectedBankAccount')}
    //         {state === 'success' && (
    //           <Box
    //             flex
    //             alignItems="center"
    //             data-testid="bank-account-change-success-message"
    //           >
    //             <Icon icon={CheckmarkCircle} size={24} color="secondaryGreen" />
    //             <Box
    //               marginLeft={8}
    //               css={`
    //                 color: ${({ theme }) => theme.colors.secondaryGreen};
    //               `}
    //             >
    //               {t('bankAccountChange.successText')}
    //             </Box>
    //           </Box>
    //         )}
    //       </>
    //     }
    //     close
    //   >
    //     {client.spa === 'GCS' || isNil(client.nextScheduledDraftDate) ? (
    //       <UnableToPerformRequest />
    //     ) : (
    //       <NormalBankAccountChangeForm successCallback={successCallback} />
    //     )}
    //   </CustomPanel>
    // </>
    <CustomPanel
      heading={
        <>
          {state === 'form' &&
            t('bankAccountChange.updateYourConnectedBankAccount')}
        </>
      }
      close
    >
      <UnableToPerformRequest />
    </CustomPanel>
  );
}

export default BankAccountChange;
