import React from 'react'
import { Panel, PanelTitle, PanelTitleHeading } from '@ffn/ui';

const ErrorView = ({ children }) => (
  <Panel data-testid='error-view'>
    <PanelTitle>
      <PanelTitleHeading>Error</PanelTitleHeading>
      {children}
    </PanelTitle>
  </Panel>
)

ErrorView.defaultProps = {
  children: <div data-testid='error-message'>Something went wrong loading content for this page</div>
}

export { ErrorView }