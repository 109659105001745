import React from 'react';
import { SelectTable, Link, Icon, Button } from '@ffn/ui';
import { DashSelect } from 'shared-components';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { Box } from 'atomic-layout';
import { useBrand } from 'lib/hooks';
import { getTestIds } from 'lib/utils/dataTestIds';
import { useTranslation } from 'lib/hooks';

const testIds = getTestIds('add_account_opportunity');

export function AccountForm({
  introText,
  omittedAccounts,
  sharedColumns,
  setError,
  setStep,
  selectedAccounts,
  setUnSelectedReasons,
  unSelectedReasons
}) {
  const { t } = useTranslation();
  const brand = useBrand();

  const creditor = sharedColumns.originalCreditor
    ? sharedColumns.originalCreditor
    : sharedColumns.creditor;
  const columns = React.useMemo(
    () => [
      sharedColumns.checkbox,
      creditor,
      sharedColumns.accountNumber,
      sharedColumns.reportingDate,
      sharedColumns.currentBalance
    ],
    [sharedColumns, creditor]
  );

  const onContinue = () => {
    setError('');
    const deSelectedAccounts = omittedAccounts
      .filter(acct => {
        return !selectedAccounts.includes(acct.omittedAccountId);
      })
      .map(acct => {
        return acct.omittedAccountId;
      });

    let hasMissingReason = false;
    deSelectedAccounts.forEach(acct => {
      if (
        unSelectedReasons[acct] === undefined ||
        unSelectedReasons[acct] === ''
      ) {
        hasMissingReason = true;
      }
    });

    if (hasMissingReason) {
      setError(
        t('alerts.addAccountOpportunity.allAccountsNotSelectedRequireAReason')
      );
    } else {
      setStep('review');
    }
  };

  const renderDetails = React.useCallback(
    ({ row }) => {
      if (!selectedAccounts.includes(row.original.omittedAccountId)) {
        return (
          <Box
            className="row-cell"
            flex
            flexDirection="column"
            flexDirectionMd="row"
            justifyContent="center"
            alignItems="center"
            padding={10}
            gap={10}
          >
            <p>
              {t(
                'alerts.addAccountOpportunity.selectAReasonForNotEnrollingThisAccount'
              )}
            </p>
            <DashSelect
              data-testid={testIds.not_enrolled_reason}
              id="notEnrolledReason"
              name="notEnrolledReason"
              value={unSelectedReasons[row.original.omittedAccountId] || ''}
              onChange={event => {
                const item = row.original;
                const declineReason = event.target.value;
                setUnSelectedReasons({
                  ...unSelectedReasons,
                  [item.omittedAccountId]: declineReason
                });
              }}
            >
              <option value="">
                {t('alerts.addAccountOpportunity.selectReason')}
              </option>
              <option value="Account is Closed">
                {t('alerts.addAccountOpportunity.thisAccountIsClosed')}
              </option>
              <option value="Already enrolled">
                {t(
                  'alerts.addAccountOpportunity.thisAccountIsAlreadyEnrolledInMyProgram'
                )}
              </option>
              <option value="Don't recognize this account">
                {t('alerts.addAccountOpportunity.iDonTRecognizeThisAccount')}
              </option>
              <option value="Don't want to extend program">
                {t('alerts.addAccountOpportunity.iDonTWantToExtendMyProgram')}
              </option>
              <option value="Need to use this card">
                {t('alerts.addAccountOpportunity.iNeedToUseThisCard')}
              </option>
              <option value="Not my account">
                {t('alerts.addAccountOpportunity.thisIsNotMyAccount')}
              </option>
              <option value="Paying off outside of program">
                {t(
                  'alerts.addAccountOpportunity.iMPayingOffThisAccountOutsideOfMyProgram'
                )}
              </option>
              <option value="Unable to increase program deposit">
                {t(
                  'alerts.addAccountOpportunity.iAmUnableToIncreaseMyProgramDeposit'
                )}
              </option>
            </DashSelect>
          </Box>
        );
      } else {
        return null;
      }
    },
    [selectedAccounts, setUnSelectedReasons, unSelectedReasons, t]
  );

  return (
    <Box paddingVertical={12}>
      <strong>{introText}</strong>
      <Box paddingVertical={12}>
        <SelectTable
          columns={columns}
          data={omittedAccounts}
          renderDetails={renderDetails}
          showHeaders={true}
          selectedRows={selectedAccounts}
        />
      </Box>
      <Box flex justifyContent="space-between" gap={16}>
        <Box flex flexDirection="column">
          <strong>{t('alerts.addAccountOpportunity.questions')} </strong>
          <Link href={`tel:${brand('contact.customer-service.phone')}`}>
            {t('alerts.addAccountOpportunity.contactCustomerService')}
          </Link>
        </Box>
        <Box flex gap={16}>
          <Button
            data-testid={testIds.continue_button}
            type="button"
            onClick={onContinue}
            variant="primary"
            height={40}
            width="100%"
            rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
          >
            {t('alerts.addAccountOpportunity.continue')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
