export const CORE_QUESTION_MAP = {
  deposit_reminder: ['how_can_i_succeed'],
  order_change: ['why_did_the_order_change']
};

export const LOGGING_PREFIX = 'PATH TO SETTLEMENT (PtS) ::';

// include all possible card types that the frontend should support, alert or info
// the backend may send a card type that is not yet supported, the app would not throw
// but an analytic event may fire if the Card components partially render
export const ALL_POSSIBLE_CARD_TYPES = [
  'deposit_reminder',
  'your_success',
  'order_change'
];

export const EST_SETTLEMENT_THRESHOLD = 0.6; // 60% threshold

// Constant that matches the feature flag value that should show PtS experiment
// Note the lowercase 's' in 'Pts' varies from how we're treating it in code.
// String must match exactly if we want the component to behave appropriately.
export const PTS_VARIATIONS = {
  SEE_PTS_TEST_1: 'seePts',
  SEE_PTS_TEST_2: 'seePtsTest2'
};
