import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FallBackLoader, PageLayout } from 'shared-components';
import { Container } from '@ffn/ui';
import { Summary } from './components/Summary';
import { TabNav } from './components/TabNav/index.js';
import { DebtConsultant } from './components/DebtConsultant';
import { useBrand } from 'lib/hooks';

export function Home() {
  const { sub } = useParams();
  const brand = useBrand();
  const debtConsultantState = useSelector(
    state => state?.models?.suipUser ?? {}
  );
  const phone = brand('contact.sales.phone');
  const phoneAvailability = brand('contact.sales.phone-hours');
  let debtConsultant = {};
  if (!debtConsultantState.phone) {
    debtConsultant = {
      phone,
      ...debtConsultantState
    };
  }
  if (!debtConsultantState.phoneAvailability) {
    debtConsultant = {
      phoneAvailability,
      ...debtConsultantState
    };
  }

  return (
    <PageLayout wrapper={Container} paddingVertical={40}>
      <Suspense fallback={FallBackLoader}>
        <Summary />
        <DebtConsultant {...debtConsultant} />
      </Suspense>
      <TabNav sub={sub} />
    </PageLayout>
  );
}

export default Home;
