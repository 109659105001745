export function MugWithHeartSVG() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <g clipPath="url(#clip0_4298_4319)">
        <mask
          id="mask0_4298_4319"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="9"
          y="22"
          width="46"
          height="33"
        >
          <path
            d="M9.07373 30.4505V37.3853C9.07373 41.6926 12.5853 45.2042 16.8927 45.2042H19.8864C20.6822 50.6232 25.3432 54.7916 30.9769 54.7916H43.0021C49.2043 54.7916 54.2316 49.7642 54.2316 43.5621V23.8947C54.2316 23.1621 53.6379 22.5684 52.9053 22.5684H21.0737C20.96 22.5684 20.8716 22.6063 20.7706 22.6316C20.72 22.6316 20.6695 22.6316 20.619 22.6316H16.8927C12.5853 22.6316 9.07373 26.1432 9.07373 30.4505ZM13.179 30.4505C13.179 28.4042 14.8464 26.7368 16.8927 26.7368H19.7474V41.0989H16.8927C14.8464 41.0989 13.179 39.4316 13.179 37.3853V30.4505Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_4298_4319)">
          <path
            d="M19.7598 26.7368H13.1914V41.0989H19.7598V26.7368Z"
            fill="#3B6AF5"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3B6AF5"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3A6BF5"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3A6CF4"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#396DF4"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#396EF4"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#386FF3"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3870F3"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3771F3"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3772F3"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3673F2"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3674F2"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3575F2"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3576F1"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3477F1"
          />
          <path
            d="M21.023 25.4737H11.9282V42.3621H21.023V25.4737Z"
            fill="#3478F1"
          />
          <path
            d="M21.0103 42.0842V25.4737H11.9282V42.3621H21.0103V42.0842Z"
            fill="#3379F0"
          />
          <path
            d="M21.0103 41.68V25.4737H11.9282V42.3621H21.0103V41.68Z"
            fill="#337AF0"
          />
          <path
            d="M21.0103 41.2884V25.4737H11.9282V42.3621H21.0103V41.2884Z"
            fill="#327BF0"
          />
          <path
            d="M21.0103 40.8842V25.4737H11.9282V42.3621H21.0103V40.8842Z"
            fill="#327CEF"
          />
          <path
            d="M21.0103 40.48V25.4737H11.9282V42.3621H21.0103V40.48Z"
            fill="#317DEF"
          />
          <path
            d="M21.0103 40.0884V25.4737H11.9282V42.3621H21.0103V40.0884Z"
            fill="#317EEF"
          />
          <path
            d="M21.0103 39.6842V25.4737H11.9282V42.3621H21.0103V39.6842Z"
            fill="#307FEF"
          />
          <path
            d="M21.0103 39.28V25.4737H11.9282V42.3621H21.0103V39.28Z"
            fill="#3080EE"
          />
          <path
            d="M21.0103 38.8884V25.4737H11.9282V42.3621H21.0103V38.8884Z"
            fill="#2F81EE"
          />
          <path
            d="M21.0103 38.4842V25.4737H11.9282V42.3621H21.0103V38.4842Z"
            fill="#2F82EE"
          />
          <path
            d="M21.0103 38.08V25.4737H11.9282V42.3621H21.0103V38.08Z"
            fill="#2E83ED"
          />
          <path
            d="M21.0103 37.6884V25.4737H11.9282V42.3621H21.0103V37.6884Z"
            fill="#2E84ED"
          />
          <path
            d="M21.0103 37.2842V25.4737H11.9282V42.3621H21.0103V37.2842Z"
            fill="#2D85ED"
          />
          <path
            d="M21.0103 36.88V25.4737H11.9282V42.3621H21.0103V36.88Z"
            fill="#2D86EC"
          />
          <path
            d="M21.0103 36.4884V25.4737H11.9282V42.3621H21.0103V36.4884Z"
            fill="#2C87EC"
          />
          <path
            d="M21.0103 36.0842V25.4737H11.9155V42.3621H15.5913V44.5726C18.6734 44.3074 21.705 43.4611 24.5092 42.0463H21.0103V36.0842Z"
            fill="#2C88EC"
          />
          <path
            d="M21.0103 35.68V25.4737H11.9155V42.3621H15.5282V43.9537C18.5345 43.7011 21.4903 42.88 24.2187 41.4905H20.9976V35.68H21.0103Z"
            fill="#2B89EB"
          />
          <path
            d="M21.0103 35.2884V25.4863H11.9155V42.3747H15.4776V43.36C18.4082 43.1074 21.2882 42.3116 23.9408 40.96H21.0103V35.3011V35.2884Z"
            fill="#2B8AEB"
          />
          <path
            d="M21.0105 34.8842V25.4863H11.9157V41.8063H6.92627C9.69259 42.6653 12.5852 42.9684 15.44 42.7284C18.2821 42.4884 21.0863 41.7053 23.6757 40.3916H21.0231V34.8842H21.0105Z"
            fill="#2A8BEB"
          />
          <path
            d="M21.0104 34.48V25.4737H11.9157V41.2H7.10303C9.79355 42.0337 12.6104 42.3368 15.3767 42.0968C18.143 41.8695 20.8714 41.0989 23.3851 39.8232H21.0104V34.4674V34.48Z"
            fill="#2A8DEA"
          />
          <path
            d="M21.0104 34.0884V25.4863H11.9156V40.6189H7.29248C9.90722 41.4274 12.6356 41.7179 15.3262 41.4905C20.7072 41.0358 25.9114 38.5347 29.663 34.0884H21.0104Z"
            fill="#298EEA"
          />
          <path
            d="M21.0103 33.6842V25.4863H11.9156V40.0253H7.46924C10.0082 40.8084 12.6608 41.0863 15.2629 40.8716C20.4798 40.4295 25.5324 38.0042 29.1829 33.6842H20.9977H21.0103Z"
            fill="#298FEA"
          />
          <path
            d="M21.0103 33.28V25.4737H11.9155V39.4316H7.65869C10.1218 40.2021 12.6861 40.4674 15.225 40.2526C20.2903 39.8232 25.1787 37.4737 28.7155 33.28H21.0103Z"
            fill="#2890EA"
          />
          <path
            d="M21.0102 32.8884V25.4863H11.9155V38.8505H7.84814C10.2355 39.5958 12.7239 39.8484 15.1745 39.6463C20.0755 39.2295 24.825 36.9558 28.2481 32.8884H21.0102Z"
            fill="#2891E9"
          />
          <path
            d="M21.0102 32.4842V25.4863H11.9154V38.2568H8.0249C10.3365 38.9768 12.7491 39.2295 15.1112 39.0274C19.8607 38.6232 24.446 36.4126 27.7681 32.4968H21.0102V32.4842Z"
            fill="#2792E9"
          />
          <path
            d="M21.0101 32.08V25.4737H11.9154V37.6505H8.21436C10.4501 38.3453 12.7744 38.5853 15.0733 38.3958C19.6586 38.0042 24.0923 35.8695 27.3007 32.08H21.0101Z"
            fill="#2793E9"
          />
          <path
            d="M21.0105 31.6884V25.4863H11.9158V37.0695H8.3916C10.5516 37.7389 12.8 37.9789 15.0105 37.7895C19.4442 37.4105 23.7263 35.3516 26.8211 31.6884H21.0105Z"
            fill="#2694E8"
          />
          <path
            d="M21.0105 31.2842V25.4863H11.9158V36.4758H8.58105C10.6526 37.12 12.8253 37.3474 14.96 37.1705C19.2295 36.8168 23.36 34.821 26.3411 31.2842H20.9979H21.0105Z"
            fill="#2695E8"
          />
          <path
            d="M21.0105 30.88V25.4737H11.9158V32.7874H3.16211C9.97053 38.5347 20.139 37.6758 25.8863 30.8674H21.0232L21.0105 30.88Z"
            fill="#2596E8"
          />
          <path
            d="M21.0106 30.4884V25.4863H11.9158V32.3326H3.55371C10.0969 37.8526 19.8737 37.0316 25.4063 30.4884H21.0106Z"
            fill="#2597E7"
          />
          <path
            d="M21.0102 30.0842V25.4863H11.9154V31.8526H3.95752C10.2354 37.1579 19.6333 36.3621 24.9259 30.0842H21.0102Z"
            fill="#2498E7"
          />
          <path
            d="M21.0102 29.68V25.4737H11.9154V31.3726H4.34912C10.3618 36.4505 19.3681 35.6926 24.446 29.68H20.9975H21.0102Z"
            fill="#2499E7"
          />
          <path
            d="M21.0103 29.2758V25.4737H11.9155V30.8926H4.75342C10.5134 35.7558 19.1155 35.0232 23.9787 29.2758H21.0103Z"
            fill="#239AE6"
          />
          <path
            d="M21.0103 28.8842V25.4863H11.9156V30.4379H5.15771C10.6525 35.0737 18.863 34.3916 23.5114 28.8968H21.0103V28.8842Z"
            fill="#239BE6"
          />
          <path
            d="M21.0104 28.48V25.4737H11.9156V29.9453H5.54932C10.7788 34.3663 18.6104 33.7095 23.0314 28.4674H21.0104V28.48Z"
            fill="#229CE6"
          />
          <path
            d="M21.0105 28.0758V25.4737H11.9157V29.4779H5.95361C10.9305 33.6716 18.3578 33.0526 22.5515 28.0758H20.9978H21.0105Z"
            fill="#229DE6"
          />
          <path
            d="M21.0105 27.6842V25.4863H11.9158V29.0105H6.35791C11.0695 32.9895 18.1053 32.3958 22.0842 27.6842H21.0105Z"
            fill="#219EE5"
          />
          <path
            d="M21.0106 27.28V25.4737H11.9158V28.5305H6.74951C11.1958 32.2821 17.8527 31.7263 21.6042 27.28H20.9979H21.0106Z"
            fill="#219FE5"
          />
          <path
            d="M21.0102 26.8758V25.4737H11.9154V28.0505H7.15332C11.3344 31.5874 17.5996 31.0568 21.1365 26.8758H21.0102Z"
            fill="#20A0E5"
          />
          <path
            d="M20.6692 26.4842C22.3239 24.5263 23.0313 22.0758 22.8292 19.701L21.0102 25.4863H11.9155V27.5958H7.55762C11.486 30.9053 17.3471 30.4126 20.6692 26.4968V26.4842Z"
            fill="#20A1E4"
          />
          <path
            d="M20.1892 26.08C21.7303 24.2484 22.3997 21.9621 22.2103 19.7516L21.0103 25.4863H11.9155V27.1158H7.94922C11.6124 30.2105 17.0945 29.7432 20.1892 26.08Z"
            fill="#1FA2E4"
          />
          <path
            d="M19.7219 25.6758C21.1619 23.9705 21.7683 21.8484 21.5914 19.8021L21.0104 25.4737H11.9156V26.6358H8.35352C11.7514 29.5032 16.8419 29.0863 19.7093 25.6758H19.7219Z"
            fill="#1FA3E4"
          />
          <path
            d="M19.242 25.4737H11.9157V26.1558H8.75781C11.9031 28.8084 16.5894 28.4168 19.242 25.2716V25.4737Z"
            fill="#1EA4E3"
          />
          <path
            d="M18.7747 25.4737H11.9284V25.6884H9.16211C12.0421 28.1137 16.3495 27.76 18.7747 24.88V25.4737Z"
            fill="#1EA5E3"
          />
          <path
            d="M18.2948 25.4737H11.9158L9.55371 25.2084C12.1684 27.4189 16.0842 27.0905 18.2948 24.4758V25.4737Z"
            fill="#154199"
          />
          <path
            d="M17.8271 25.4737H11.9281L9.97021 24.7411C12.3197 26.7242 15.8439 26.4337 17.8397 24.0842V25.4863L17.8271 25.4737Z"
            fill="#1DA7E2"
          />
          <path
            d="M17.347 25.4737H11.9154L10.3491 24.2611C12.446 26.0295 15.5786 25.7642 17.3344 23.6674V25.4611L17.347 25.4737Z"
            fill="#154199"
          />
          <path
            d="M54.2316 22.5811H9.07373V54.8042H54.2316V22.5811Z"
            fill="#3B6AF5"
          />
          <path
            d="M46.2484 48.0842C53.8779 39.0526 57.1116 27.7979 56.2021 16.8842L55.5074 21.3179H7.81055V56.0674H17.1579V63.1158C28.0716 62.1937 38.6316 57.1158 46.2611 48.0842H46.2484Z"
            fill="#3B6AF5"
          />
          <path
            d="M45.7811 47.6926C53.2969 38.8 56.4926 27.6968 55.5832 16.9474L55.5074 21.3305H7.81055V56.08H17.0948V62.5095C27.8442 61.6 38.2527 56.5979 45.7684 47.7053L45.7811 47.6926Z"
            fill="#3A6BF5"
          />
          <path
            d="M45.3011 47.2884C52.7032 38.5221 55.8484 27.5832 54.9642 16.9853V21.3053H7.81055V56.0547H17.0442V61.8779C27.6421 60.981 37.899 56.0547 45.3011 47.2884Z"
            fill="#3A6CF4"
          />
          <path
            d="M44.8337 46.8842C52.1221 38.2442 55.2295 27.4821 54.3453 17.0358V21.3053H7.81055V56.0547H16.9937V61.2589C27.4274 60.3747 37.5327 55.5242 44.8211 46.8842H44.8337Z"
            fill="#396DF4"
          />
          <path
            d="M44.3537 46.4926C51.5411 37.9916 54.5853 27.3811 53.7263 17.0989V21.3179H7.81055V56.0674H16.9432V60.6526C27.2253 59.781 37.1663 54.9937 44.3537 46.4926Z"
            fill="#396EF4"
          />
          <path
            d="M43.8863 46.0884C50.96 37.7137 53.9663 27.2674 53.1074 17.1495V21.3179H7.81055V56.0674H16.8927V60.0337C27.0105 59.1747 36.8127 54.4632 43.8863 46.0884Z"
            fill="#386FF3"
          />
          <path
            d="M43.4063 45.6842C50.3663 37.4484 53.3348 27.1537 52.4884 17.2V21.3179H7.81055V56.0674H16.8421V59.4147C26.8084 58.581 36.4463 53.9326 43.4063 45.6968V45.6842Z"
            fill="#3870F3"
          />
          <path
            d="M42.939 45.2926C49.7853 37.1832 52.7032 27.0653 51.8821 17.2505V21.3179H7.81055V56.0674H16.7916V58.7958C26.5937 57.9747 36.08 53.4021 42.939 45.2926Z"
            fill="#3771F3"
          />
          <path
            d="M42.459 44.8884C49.2042 36.9053 52.0716 26.9516 51.2505 17.3011V21.3179H7.81055V56.0674H16.7411V58.1768C26.3916 57.3684 35.7263 52.8716 42.459 44.8884Z"
            fill="#3772F3"
          />
          <path
            d="M41.9912 44.4842C48.6228 36.64 51.4396 26.8379 50.6438 17.3516V21.3179H7.81015V56.0168H2.50488C7.11541 57.4568 11.9407 57.9621 16.6901 57.5579C26.1765 56.7621 35.3596 52.341 41.9912 44.4842Z"
            fill="#3673F2"
          />
          <path
            d="M41.5112 44.0926C48.0291 36.3747 50.808 26.7495 50.0122 17.4147V21.3179H7.81013V55.4232H2.69434C7.22907 56.8379 11.9785 57.3305 16.6396 56.9389C25.9743 56.1558 35.0059 51.8105 41.5238 44.0926H41.5112Z"
            fill="#3674F2"
          />
          <path
            d="M41.0442 43.6884C47.4484 36.0968 50.1768 26.6358 49.4063 17.4653V21.3179H7.81053V54.8295H2.87158C7.33053 56.2189 11.9916 56.6989 16.5768 56.32C25.7474 55.5495 34.6274 51.28 41.0316 43.6884H41.0442Z"
            fill="#3575F2"
          />
          <path
            d="M40.5642 43.2842C46.8674 35.8316 49.5452 26.5221 48.7874 17.5032V21.3053H7.81051V54.2232H3.06104C7.44419 55.5874 12.0295 56.0674 16.5263 55.6884C25.5452 54.9305 34.261 50.7368 40.5642 43.2716V43.2842Z"
            fill="#3576F1"
          />
          <path
            d="M40.0967 42.8926C46.2862 35.5663 48.9136 26.4211 48.1683 17.5663V21.3179H7.81042V53.6547H3.23779C7.54516 54.9937 12.042 55.4611 16.4757 55.0947C25.3304 54.3495 33.9073 50.2189 40.0967 42.8926Z"
            fill="#3477F1"
          />
          <path
            d="M39.6167 42.4884C45.6925 35.2884 48.282 26.32 47.5494 17.6168V21.3179H7.8104V53.0611H3.42725C7.65882 54.3747 12.0799 54.8421 16.4251 54.4758C25.1283 53.7432 33.5409 49.6884 39.6167 42.5011V42.4884Z"
            fill="#3478F1"
          />
          <path
            d="M39.1493 42.0842C45.1114 35.0232 47.6503 26.2063 46.9303 17.6674V21.3179H7.81032V52.4674H3.604C7.75979 53.7558 12.0924 54.2105 16.3619 53.8568C24.9008 53.1368 33.1619 49.1579 39.1366 42.0968L39.1493 42.0842Z"
            fill="#3379F0"
          />
          <path
            d="M38.6692 41.68C44.5303 34.7453 47.0187 26.0926 46.3114 17.7179V21.3179H7.8103V51.8737H3.79346C7.87346 53.1368 12.1303 53.5916 16.324 53.2379C24.7114 52.5305 32.8208 48.6274 38.6692 41.6926V41.68Z"
            fill="#337AF0"
          />
          <path
            d="M38.2019 41.2884C43.9492 34.48 46.3871 25.9916 45.705 17.7684V21.3179H7.81028V51.28H3.98291C7.98712 52.5179 12.1555 52.96 16.2734 52.6189C24.4966 51.9242 32.4545 48.0968 38.2019 41.2884Z"
            fill="#327BF0"
          />
          <path
            d="M37.7218 40.8842C43.3555 34.2147 45.7555 25.8905 45.0734 17.8189V21.3053H7.81019V50.6737H4.15967C8.08809 51.8863 12.1807 52.3158 16.2102 51.9874C24.2818 51.3053 32.0755 47.5537 37.7218 40.8842Z"
            fill="#327CEF"
          />
          <path
            d="M37.2544 40.48C42.7744 33.9368 45.1239 25.7768 44.467 17.8695V21.3053H7.81017V50.08H4.34912C8.20175 51.28 12.2186 51.6968 16.1723 51.3684C24.0796 50.6989 31.7344 47.0232 37.2544 40.48Z"
            fill="#317DEF"
          />
          <path
            d="M36.7748 40.0884C42.1937 33.6842 44.4927 25.6758 43.8358 17.9326V21.3179H7.81058V49.4989H4.52637C8.29058 50.6737 12.2316 51.0779 16.1095 50.7621C23.8527 50.1053 31.3558 46.5053 36.7748 40.0884Z"
            fill="#317EEF"
          />
          <path
            d="M36.3074 39.6842C41.6127 33.4063 43.8611 25.5747 43.2295 17.9832V21.3179H7.81056V48.9053H4.71582C8.40424 50.0547 12.2695 50.4589 16.059 50.1305C23.6506 49.4863 31.0021 45.9495 36.2948 39.6716L36.3074 39.6842Z"
            fill="#307FEF"
          />
          <path
            d="M35.8274 39.28C41.019 33.1284 43.2295 25.461 42.5979 18.021V21.3053H7.81054V48.3116H4.90527C8.5179 49.4358 12.2947 49.8274 16.0084 49.5116C23.4358 48.88 30.6358 45.4189 35.8274 39.28Z"
            fill="#3080EE"
          />
          <path
            d="M35.3599 38.8884C40.4378 32.8758 42.6105 25.36 41.9915 18.0842V21.3179H7.81045V47.7305H5.08203C8.61887 48.8295 12.3199 49.2211 15.9578 48.9053C23.2336 48.2863 30.2694 44.9011 35.3599 38.8884Z"
            fill="#2F81EE"
          />
          <path
            d="M34.8799 38.4842C39.8568 32.5979 41.9662 25.2463 41.3725 18.1347V21.3179H7.81043V47.1368H5.27148C8.73254 48.2105 12.3452 48.5895 15.9073 48.2863C23.0189 47.6926 29.9157 44.3705 34.8799 38.4842Z"
            fill="#2F82EE"
          />
          <path
            d="M34.4125 38.08C39.2756 32.32 41.3472 25.1453 40.7535 18.1853V21.3179H7.81035V46.5432H5.44824C8.83351 47.5916 12.3703 47.9705 15.844 47.6674C22.804 47.0863 29.5367 43.84 34.3998 38.08H34.4125Z"
            fill="#2E83ED"
          />
          <path
            d="M33.9324 37.6884C38.6819 32.0674 40.703 25.0442 40.1345 18.2484V21.3179H7.81033V45.9495H5.6377C8.94717 46.9726 12.3956 47.3389 15.8061 47.0484C22.6019 46.48 29.183 43.3095 33.9451 37.6884H33.9324Z"
            fill="#2E84ED"
          />
          <path
            d="M33.465 37.2842C38.1008 31.7895 40.0839 24.9305 39.5155 18.2863V21.3053H7.81024V45.3432H5.81445C9.04814 46.3411 12.4208 46.6947 15.7429 46.4168C22.3871 45.8611 28.8166 42.7663 33.4523 37.2716L33.465 37.2842Z"
            fill="#2D85ED"
          />
          <path
            d="M32.985 36.88C37.5197 31.5116 39.4397 24.8295 38.8965 18.3368V21.3053H7.81022V44.7495H6.00391C9.1618 45.7347 12.446 46.0758 15.6923 45.7979C22.1723 45.2547 28.4502 42.2358 32.985 36.8674V36.88Z"
            fill="#2D86EC"
          />
          <path
            d="M32.5176 36.4884C36.9386 31.2589 38.8207 24.7284 38.2902 18.4V21.3179H7.8102V44.1684H6.19336C9.27546 45.1284 12.4839 45.4568 15.6418 45.1916C21.9702 44.6611 28.0839 41.7179 32.5049 36.4758L32.5176 36.4884Z"
            fill="#2C87EC"
          />
          <path
            d="M32.0375 36.0842C36.3449 30.981 38.1764 24.6147 37.6585 18.4505V21.3179H7.81012V43.5747H6.37012C9.37643 44.5095 12.5091 44.8379 15.5912 44.5726C21.7554 44.0547 27.7301 41.1747 32.0375 36.0842Z"
            fill="#2C88EC"
          />
          <path
            d="M31.5706 35.68C35.7643 30.7032 37.558 24.5011 37.0527 18.5011V21.3179H7.81058V42.9937H6.56006C9.47795 43.9032 12.5348 44.2189 15.5411 43.9663C21.5537 43.4611 27.3643 40.6568 31.5706 35.6926V35.68Z"
            fill="#2B89EB"
          />
          <path
            d="M31.0905 35.2884C35.1831 30.4505 36.9137 24.4126 36.421 18.5516V21.3179H7.8105V42.4H6.73682C9.57892 43.2842 12.56 43.6 15.4779 43.3474C21.3263 42.8547 26.9979 40.1263 31.0779 35.2884H31.0905Z"
            fill="#2B8AEB"
          />
          <path
            d="M30.623 34.8842C34.6019 30.1726 36.2946 24.2989 35.8146 18.6021V21.3179H7.81035V37.5368H-0.842285C8.58087 45.4947 22.6651 44.3074 30.623 34.8842Z"
            fill="#2A8BEB"
          />
          <path
            d="M30.1431 34.48C34.0083 29.9074 35.6504 24.1853 35.1831 18.6526V21.3179H7.81043V37.0568H-0.437988C8.71991 44.7874 22.4125 43.6379 30.1431 34.48Z"
            fill="#2A8DEA"
          />
          <path
            d="M29.6757 34.0884C33.4273 29.6421 35.0315 24.0968 34.5768 18.7158V21.3179H7.81045V36.5895H-0.0463867C8.84624 44.1053 22.1473 42.9811 29.6631 34.0884H29.6757Z"
            fill="#298EEA"
          />
          <path
            d="M29.1958 33.6842C32.8463 29.3642 34.3874 23.9832 33.9579 18.7663V21.3179H7.81054V36.1095H0.35791C8.99791 43.3979 21.9074 42.3116 29.1958 33.6842Z"
            fill="#298FEA"
          />
          <path
            d="M28.728 33.28C32.2649 29.0989 33.768 23.8695 33.3386 18.8042V21.3053H7.81014V35.6295H0.761719C9.13646 42.7032 21.6544 41.6421 28.728 33.2674V33.28Z"
            fill="#2890EA"
          />
          <path
            d="M28.2481 32.8884C31.6712 28.8337 33.1365 23.7684 32.7196 18.8674V21.3179H7.81016V35.1621H1.15332C9.26279 42.0084 21.3891 40.9853 28.2481 32.8758V32.8884Z"
            fill="#2891E9"
          />
          <path
            d="M27.7808 32.4842C31.0902 28.5558 32.505 23.6674 32.1008 18.9179V21.3179H7.81025V34.6947H1.55762C9.40183 41.3263 21.1492 40.341 27.7681 32.4842H27.7808Z"
            fill="#2792E9"
          />
          <path
            d="M27.3009 32.08C30.5093 28.2905 31.8735 23.5537 31.4819 18.9684V21.3179H7.81034V34.2147H1.96191C9.55349 40.6189 20.8967 39.6716 27.3009 32.08Z"
            fill="#2793E9"
          />
          <path
            d="M26.8335 31.6884C29.9283 28.0253 31.2419 23.4526 30.8756 19.0316V21.3305H7.81036V33.76H2.35352C9.67983 39.9495 20.6314 39.0274 26.8209 31.7011L26.8335 31.6884Z"
            fill="#2694E8"
          />
          <path
            d="M26.3536 31.2842C29.3347 27.7474 30.6104 23.3389 30.2441 19.0695V21.3179H7.81044V33.2674H2.75781C9.81886 39.2295 20.3915 38.3453 26.3536 31.2842Z"
            fill="#2695E8"
          />
          <path
            d="M25.8863 30.88C28.7537 27.4821 29.9789 23.2379 29.6379 19.12V21.3053H7.81053V32.7874H3.16211C9.97053 38.5347 20.139 37.6758 25.8863 30.8674V30.88Z"
            fill="#2596E8"
          />
          <path
            d="M25.4063 30.4884C28.1727 27.2168 29.3474 23.1368 29.0063 19.1832V21.3179H7.81055V32.32H3.55371C10.0969 37.84 19.8737 37.0189 25.4063 30.4758V30.4884Z"
            fill="#2597E7"
          />
          <path
            d="M24.9386 30.0842C27.5912 26.9389 28.7154 23.0232 28.3996 19.2337V21.3179H7.81015V31.8526H3.95752C10.2354 37.1579 19.6333 36.3621 24.9259 30.0842H24.9386Z"
            fill="#2498E7"
          />
          <path
            d="M24.4587 29.68C26.9976 26.6737 28.0839 22.9221 27.7808 19.2842V21.3179H7.81024V31.3726H4.36182C10.3744 36.4505 19.3808 35.6926 24.4587 29.68Z"
            fill="#2499E7"
          />
          <path
            d="M23.9913 29.2758C26.4166 26.3958 27.4524 22.8084 27.1618 19.3221V21.3053H7.81026V30.8926H4.75342C10.5134 35.7558 19.1155 35.0232 23.9787 29.2758H23.9913Z"
            fill="#239AE6"
          />
          <path
            d="M23.5114 28.8842C25.8356 26.1432 26.8209 22.7074 26.543 19.3853V21.3179H7.81035V30.4253H5.15771C10.6525 35.0611 18.863 34.3789 23.5114 28.8842Z"
            fill="#239BE6"
          />
          <path
            d="M23.0315 28.48C25.242 25.8653 26.1768 22.5937 25.9115 19.4358V21.3179H7.81043V29.9579H5.56201C10.7915 34.3789 18.6231 33.7221 23.0441 28.48H23.0315Z"
            fill="#229CE6"
          />
          <path
            d="M22.5641 28.0758C24.661 25.5874 25.5578 22.4926 25.3052 19.4863V21.3179H7.81046V29.4779H5.95361C10.9305 33.6716 18.3578 33.0526 22.5515 28.0758H22.5641Z"
            fill="#229DE6"
          />
          <path
            d="M22.0842 27.6842C24.0674 25.3347 24.9137 22.3916 24.6737 19.5495V21.3179H7.81054V29.0105H6.35791C11.0695 32.9895 18.1053 32.3958 22.0842 27.6842Z"
            fill="#219EE5"
          />
          <path
            d="M21.6165 27.28C23.4986 25.0568 24.2943 22.2779 24.067 19.5874V21.3053H7.81014V28.5179H6.76172C11.208 32.2695 17.8649 31.7137 21.6165 27.2674V27.28Z"
            fill="#219FE5"
          />
          <path
            d="M21.1365 26.8758C24.6733 22.6947 24.1428 16.4295 19.9617 12.8926V21.3053H7.81016V28.0505H7.15332C11.3344 31.5874 17.5996 31.0568 21.1365 26.8758Z"
            fill="#20A0E5"
          />
          <path
            d="M20.6692 26.4842C23.9787 22.5558 23.486 16.6947 19.5702 13.3726V21.3179H7.81025V27.5832H7.55762C11.486 30.8926 17.3471 30.4 20.6692 26.4842Z"
            fill="#20A1E4"
          />
          <path
            d="M20.1892 26.08C23.2839 22.4168 22.8165 16.9347 19.1534 13.84V21.3053H7.81023L6.92602 14.8632C3.83128 18.5263 4.29865 24.0084 7.96181 27.1032C11.625 30.1979 17.1071 29.7305 20.2018 26.0674L20.1892 26.08Z"
            fill="#1FA2E4"
          />
          <path
            d="M19.7222 25.6758C22.5895 22.2779 22.1727 17.1874 18.7622 14.32V21.3179H7.81059L7.40638 15.28C4.53901 18.6779 4.95585 23.7684 8.36638 26.6358C11.7643 29.5032 16.8548 29.0863 19.7222 25.6758Z"
            fill="#1FA3E4"
          />
          <path
            d="M19.242 25.2842C21.8947 22.139 21.5031 17.4526 18.3578 14.8V21.3179H7.87361V15.6842C5.22098 18.8295 5.61256 23.5158 8.75782 26.1684C11.9031 28.8211 16.5894 28.4295 19.242 25.2842Z"
            fill="#1EA4E3"
          />
          <path
            d="M18.7748 24.88C21.2 22 20.8463 17.6926 17.9663 15.2674V21.3179H8.35372V16.0758C5.92845 18.9558 6.28214 23.2632 9.16214 25.6884C12.0421 28.1137 16.3495 27.76 18.7748 24.88Z"
            fill="#1EA5E3"
          />
          <path
            d="M18.2946 24.4758C20.5052 21.861 20.1767 17.9453 17.562 15.7347V21.3053H8.82095V16.4674C6.61042 19.0821 6.93884 22.9979 9.55358 25.2084C12.1683 27.4189 16.0841 27.0905 18.2946 24.4758Z"
            fill="#154199"
          />
          <path
            d="M17.8274 24.0842C19.8105 21.7347 19.52 18.2105 17.1705 16.2147V21.3179H9.30105V16.8842C7.31789 19.2337 7.60842 22.7579 9.9579 24.7537C12.3074 26.7368 15.8316 26.4463 17.8274 24.0968V24.0842Z"
            fill="#1DA7E2"
          />
          <path
            d="M17.3471 23.68C19.1155 21.5832 18.8503 18.4505 16.7534 16.6947V21.3179H9.76815V17.2758C7.99973 19.3726 8.26499 22.5053 10.3618 24.2611C12.4587 26.0168 15.5913 25.7642 17.3471 23.6674V23.68Z"
            fill="#154199"
          />
          <path
            d="M16.8798 23.2758C18.4209 21.4442 18.1935 18.7032 16.3619 17.1621V21.3179H10.2483V17.68C8.7072 19.5116 8.93457 22.2526 10.7661 23.7937C12.5977 25.3347 15.3388 25.1074 16.8798 23.2758Z"
            fill="#1CA9E2"
          />
          <path
            d="M16.3997 22.8842C17.726 21.3179 17.5239 18.9684 15.9576 17.6421V21.3179H10.7155V18.0842C9.38917 19.6505 9.59127 22 11.1576 23.3263C12.7239 24.6526 15.0734 24.4505 16.3997 22.8842Z"
            fill="#1BAAE2"
          />
          <path
            d="M15.9324 22.48C17.0314 21.1663 16.8672 19.2211 15.5661 18.1095V21.3179H11.1956V18.4758C10.0966 19.7895 10.2609 21.7347 11.5619 22.8463C12.8756 23.9453 14.8209 23.7811 15.9324 22.48Z"
            fill="#1BABE1"
          />
          <path
            d="M15.4526 22.0758C16.3368 21.0274 16.2105 19.4611 15.162 18.5768V21.3053H11.6631V18.8674C10.7789 19.9158 10.9052 21.4821 11.9536 22.3663C13.002 23.2505 14.5683 23.1242 15.4526 22.0758Z"
            fill="#1AACE1"
          />
        </g>
        <path
          d="M42.9518 38.3705H44.9097V22.5684H42.9518V38.3705Z"
          fill="white"
        />
        <path
          d="M39.7811 47.0863H48.08C49.0021 47.0863 49.76 46.341 49.76 45.4063V34.0379C49.76 33.5958 49.5832 33.1789 49.2927 32.8884C48.9895 32.5726 48.56 32.3705 48.08 32.3705H39.7811C38.859 32.3705 38.1011 33.1158 38.1011 34.0505V45.4189C38.1011 46.341 38.8463 47.0989 39.7811 47.0989V47.0863Z"
          fill="white"
        />
        <path
          d="M29.3728 20.7495C28.3243 20.7495 27.478 19.9032 27.478 18.8547V14.9895C27.478 13.941 28.3243 13.0947 29.3728 13.0947C30.4212 13.0947 31.2675 13.941 31.2675 14.9895V18.8547C31.2675 19.9032 30.4212 20.7495 29.3728 20.7495Z"
          fill="#154199"
        />
        <path
          d="M36.7619 20.7495C35.7135 20.7495 34.8672 19.9032 34.8672 18.8547V11.8947C34.8672 10.8463 35.7135 10 36.7619 10C37.8103 10 38.6567 10.8463 38.6567 11.8947V18.8547C38.6567 19.9032 37.8103 20.7495 36.7619 20.7495Z"
          fill="#154199"
        />
        <path
          d="M44.1643 20.7495C43.1158 20.7495 42.2695 19.9032 42.2695 18.8547V14.9895C42.2695 13.941 43.1158 13.0947 44.1643 13.0947C45.2127 13.0947 46.059 13.941 46.059 14.9895V18.8547C46.059 19.9032 45.2127 20.7495 44.1643 20.7495Z"
          fill="#154199"
        />
        <path
          d="M48.0422 36.8168C47.0948 35.8821 45.5664 35.8947 44.6317 36.8421L43.9622 37.5116L43.2927 36.8547C42.3454 35.9326 40.8169 35.9453 39.8822 36.8926C38.9475 37.84 38.9475 39.3558 39.8822 40.2905L43.9875 44.32L48.0548 40.2147C48.9896 39.2674 48.9769 37.7389 48.0296 36.8168H48.0422Z"
          fill="#154199"
        />
      </g>
      <defs>
        <clipPath id="clip0_4298_4319">
          <rect
            width="46"
            height="45"
            fill="white"
            transform="translate(9 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
