/**
 * Extracts the fdrApplicantId from the auth0 user object.
 * The fdrApplicantId is nested within a metadata key, which is dependent on both the brand and environment.
 * The key always ends with 'metadata', and the fdrApplicantId is nested under the businessId key.
 *
 * @param {Object} auth0User - The Auth0 user object containing various metadata keys.
 * @param {string} businessId - The business ID used to locate the specific applicant_id.
 * @returns {string} - The fdrApplicantId if found, otherwise an empty string.
 */
export const extractFdrApplicantId = (auth0User, businessId) => {
  if (!auth0User || !businessId) return '';

  // Loop through the keys in auth0User to find the key that includes 'metadata'
  for (let key in auth0User) {
    if (key.includes('metadata')) {
      return (
        auth0User[key]?.[businessId]?.applicant_id ||
        auth0User[key]?.[businessId]?.fdr_applicant_id ||
        ''
      );
    }
  }
  return '';
};

/**
 * Extracts the `auth0_user_id` and `profile_id` from a user object.
 *
 * The function retrieves the `auth0_user_id` from the `sub` property of the user
 * object, if present, after removing the 'auth0 | ' prefix. It also searches
 * for a key containing the string 'profile_id' within the user object and
 * retrieves its value. An example key is "https://auth.dev.fdr.com/profile_ids"
 *
 * @param {Object} user - The user object containing relevant ID properties.
 * @param {string} [user.sub] - The property containing the auth0 user ID with the 'auth0 | ' prefix.
 *
 * @returns {Object} An object containing the extracted `auth0_email`, `auth0_user_id`, and `profile_id`.
 * @returns {string} [Object.auth0_email] - The extracted auth0 email address.
 * @returns {string} [Object.auth0_user_id] - The extracted auth0 user ID.
 * @returns {string} [Object.oprofile_id] - The extracted profile ID.
 */

export const extractIdBundle = user => {
  const auth0_user_id = user?.sub.replace(/^auth0\s*\|\s*/, '');
  const auth0_email = user?.email;

  let profile_id;
  for (let key in user) {
    if (key.includes('profile_id')) {
      profile_id = user[key][0];
      break;
    }
  }

  return {
    auth0_email,
    auth0_user_id,
    profile_id
  };
};
