import React, { useEffect, useState } from 'react';

import { Alert, Box, Link, LoadingButton, Typography } from '@achieve/sunbeam';

import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useBrand, useNotificationTracking, useTranslation } from 'lib/hooks';
import { alertUpdate, refreshNotifications, updateClientInfo } from 'lib/api';

import styles from './SettlementAuthSMSOptIn.module.scss';

// amount of time (ms) until the dashboard is refreshed after a successful update
const afterResolveRefreshPeriod = 5000;

export const SettlementAuthSMSOptIn = ({
  isVisible,
  notification,
  viewLocation
}) => {
  const brand = useBrand();
  const trackNotificationView = useNotificationTracking();
  const { pureT, t } = useTranslation();

  const [loading, setLoading] = useState(false); // conditional for loading state (buttons)
  const [updateError, setUpdateError] = useState(false); // conditional to display error message
  const [isAccepted, setIsAccepted] = useState(null); // conditional to display accepted success message

  // validation of alertId and mobilePhone occurs in the backend so not required here
  const { alertId, mobilePhone } = notification.payload;

  const { trackingLabel } = notification;

  const businessNameLong = brand('business-name.long');
  const customerServicePhoneNumber = brand('contact.customer-service.phone');

  useEffect(() => {
    trackNotificationView({
      trackingLabel,
      viewLocation,
      isVisible
    });
  }, [trackingLabel, viewLocation, isVisible, trackNotificationView]);

  const handleAccept = async () => {
    try {
      setLoading(true);

      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'click',
          label: trackingLabel
        },
        `${trackingLabel} - Alert accepted`
      );

      const updatePayload = {
        settlementAuthSMS: true
      };

      const response = await updateClientInfo(updatePayload);

      if (response?.status !== 200) {
        throw new Error('Error updating client info');
      }

      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'success',
          label: trackingLabel
        },
        `${trackingLabel} - Alert success`
      );

      // clears the error state in the case of a successful attempt after an error
      setUpdateError(false);

      setIsAccepted(true);

      // existing pattern to refresh notifications after a successful update
      setTimeout(() => {
        refreshNotifications(['alertTasks']);
        console.log('refresh alerts called');
      }, afterResolveRefreshPeriod);
    } catch (error) {
      setUpdateError(true);

      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'failure',
          label: trackingLabel
        },
        `${trackingLabel} - Alert failure`
      );

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDefer = async () => {
    try {
      setLoading(true);

      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'click',
          label: trackingLabel
        },
        `${trackingLabel} - Alert deferred`
      );

      const updatePayload = [
        {
          alert_id: alertId,
          alert_status: 'Deferred'
        }
      ];

      const response = await alertUpdate(updatePayload);

      if (response?.error_code || !response?.success) {
        // keeping error opaque as we don't want to expose SF error messages to the client
        throw new Error('Error updating alert');
      }

      // in the case of a successful attempt after an error, clears the error state
      setUpdateError(false);

      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'success',
          label: trackingLabel
        },
        `${trackingLabel} - Alert success`
      );

      // awaiting the call to update the alerts state so the loading UI will spin
      // until the alert is cleared from the screen
      await refreshNotifications(['alertTasks']);
    } catch (error) {
      setUpdateError(true);

      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'failure',
          label: trackingLabel
        },
        `${trackingLabel} - Alert failure`
      );

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={styles.alertWrapper} data-testid="sms-auth-opt-in-alert">
      <Box className={styles.alertHeading}>
        <Typography
          variant="displayS20"
          fontWeight="bold"
          data-testid="sms-auth-opt-in-alert-heading"
        >
          <h4>
            {t(
              'alerts.settlementAuthSMSOptIn.donTMissOutOnAnySettlementOffers'
            )}
          </h4>
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="displayXS30"
          className={styles.alertCopy}
          data-testid="sms-auth-opt-in-alert-copy"
        >
          <p>
            {t('alerts.settlementAuthSMSOptIn.stayOnTopOfTimeSensitiveOffers')}
          </p>
          <p>
            {t(
              'alerts.settlementAuthSMSOptIn.youWonTReceiveOtherTextsThatYouVeAlreadyOptedOut'
            )}
          </p>
        </Typography>
      </Box>
      <Box
        className={styles.alertCurrentPhoneNumber}
        data-testid="sms-auth-opt-in-alert-contact-info"
      >
        <Typography
          variant="displayXS30"
          data-testid="sms-auth-opt-in-alert-contact-info-label"
        >
          <p>{t('alerts.settlementAuthSMSOptIn.currentPhoneNumber')}</p>
        </Typography>
        <Typography
          data-testid="sms-auth-opt-in-alert-contact-info-number"
          variant="displayXS30"
          fontWeight="bold"
        >
          <p>{mobilePhone}</p>
        </Typography>
      </Box>
      <Box
        className={styles.alertEditCopy}
        data-testid="sms-auth-opt-in-alert-edit-copy"
      >
        <Typography variant="displayXS30">
          <p>
            {t('alerts.settlementAuthSMSOptIn.toEditPhoneNumberPleaseCall')}{' '}
            <Link href={`tel:${customerServicePhoneNumber}`}>
              {customerServicePhoneNumber}
            </Link>
          </p>
        </Typography>
      </Box>

      {/* Conditional buttons not rendered after the alert is accepted */}
      {!isAccepted && (
        <Box className={styles.alertButtons}>
          <LoadingButton
            color="secondary"
            data-testid="sms-auth-opt-in-alert-remind-me-later-button"
            loading={loading}
            onClick={handleDefer}
          >
            {t('alerts.settlementAuthSMSOptIn.remindMeLater')}
          </LoadingButton>
          <LoadingButton
            color="primary"
            data-testid="sms-auth-opt-in-alert-opt-in-button"
            loading={loading}
            onClick={handleAccept}
          >
            {t('alerts.settlementAuthSMSOptIn.optIntoSettlementTexts')}
          </LoadingButton>
        </Box>
      )}

      {/* Conditional error state if the resolve alert backend call fails */}
      {updateError && (
        <Alert color="error" data-testid="sms-auth-opt-in-alert-error-message">
          <Typography
            variant="displayXS30"
            data-testid="sms-auth-opt-in-alert-error-message"
          >
            <p>{t('alerts.settlementAuthSMSOptIn.weHadAnIssueUpdating')}</p>
          </Typography>
        </Alert>
      )}

      {/* Conditional message shown when user accepts and alert has resolved successfully, in place of buttons*/}
      {isAccepted && (
        <Alert
          color="success"
          data-testid="sms-auth-opt-in-alert-success-message"
        >
          <p>
            {t(
              'alerts.settlementAuthSMSOptIn.youReNowOptedIntoSettlementAuthorizationTexts'
            )}
          </p>
        </Alert>
      )}

      <Box className={styles.alertFinePrint}>
        <Typography
          variant="displayX20"
          data-testid="sms-auth-opt-in-alert-fine-print"
        >
          <p>
            *{' '}
            {pureT(
              'alerts.settlementAuthSMSOptIn.byOptingIntoSettlementAuthorizationsAbove',
              { businessNameLong }
            )}
          </p>
        </Typography>
      </Box>
    </Box>
  );
};
