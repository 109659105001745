import { useTranslation } from 'lib/hooks';
import { PhoneCall } from 'react-feather';
import styles from './AchieveFooter.module.scss';
import { Typography, useTheme } from '@achieve/sunbeam';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { EVENT_ACTION } from 'lib/utils/eventHorizon';
import AchieveLogo from './SocialIcons/assets/2022_Achieve_Logomark_RGB.svg';
import SocialIcons from './SocialIcons';

const domain = document?.domain ?? '';

const footerLinkAnalyticEvent = (footerLinkName, path) => {
  analyticsTrackEvent(
    {
      nav_link_section: 'Footer',
      event_action: EVENT_ACTION.LINK_CLICK,
      click_type: 'Link Click',
      click_id: footerLinkName,
      click_url: 'https://' + domain + path,
      clickText: footerLinkName,
      track_event: 'global_footer'
    },
    `Footer: ${footerLinkName} Link Click`
  );
};

export const AchieveFooter = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={styles.footer} data-testid="achieve-footer">
      <div className={styles.upperFooter}>
        <div className={styles.branding}>
          <div className={styles.logo} data-testid="achieve-footer-logo">
            <a
              href="/dashboard/resolution"
              onClick={() =>
                footerLinkAnalyticEvent('Homepage', '/dashboard/resolution')
              }
            >
              <img src={AchieveLogo} alt="Achieve A Logo" />
            </a>
          </div>
          <div className={styles.brandingCopy}>
            <Typography
              variant="bodyS30"
              color={theme.palette.grey['900']}
              data-testid="achieve-footer-branding-copy"
            >
              {t(
                'footer.achieve.Achieve is the leader in digital personal finance, built to help everyday people move forward on the path to a better financial future.'
              )}
            </Typography>
          </div>
        </div>
        <div className={styles.contact} data-testid="achieve-footer-contact">
          <Typography
            variant="displayXS30"
            fontWeight="bold"
            color={theme.palette.grey['900']}
          >
            {t('footer.achieve.Need to ask a question? Got a suggestion?')}
          </Typography>
          <div className={styles.phoneLine}>
            <div className={styles.phoneIcon}>
              <PhoneCall data-testid="achieve-footer-contact-phone-icon" />
            </div>
            <Typography className={styles.link} variant="bodyS30">
              <a
                data-testid="achieve-footer-contact-phone-link"
                onClick={() =>
                  footerLinkAnalyticEvent('Contact Us', '/about/contact-us')
                }
                href="/about/contact-us"
              >
                {t('footer.achieve.Contact Us')}
              </a>
            </Typography>
          </div>
        </div>
        <div
          className={styles.linkSection}
          data-testid="achieve-footer-link-section"
        >
          <div
            className={styles.linkSectionHeading}
            data-testid="achieve-footer-link-section-heading"
          >
            <Typography
              variant="displayXS30"
              fontWeight="bold"
              color={theme.palette.grey['900']}
            >
              {t('footer.achieve.Important Info')}
            </Typography>
          </div>
          <div className={styles.linkList} color={theme.palette.common.black}>
            <div
              className={styles.link}
              data-testid="achieve-footer-link-section-link-about"
            >
              <a
                onClick={() => footerLinkAnalyticEvent('About Us', '/about')}
                href="/about"
              >
                <Typography variant="bodyS30">
                  {t('footer.achieve.About Us')}
                </Typography>
              </a>
            </div>
            <div
              className={styles.link}
              data-testid="achieve-footer-link-section-link-terms"
            >
              <a
                onClick={() =>
                  footerLinkAnalyticEvent(
                    'Terms of Service',
                    '/policies#Terms-Of-Use'
                  )
                }
                href="/policies#Terms-Of-Use"
              >
                <Typography variant="bodyS30">
                  {t('footer.achieve.Terms of Service')}
                </Typography>
              </a>
            </div>
            <div
              className={styles.link}
              data-testid="achieve-footer-link-section-link-privacy"
            >
              <a
                href="/policies#Privacy-Policy"
                onClick={() =>
                  footerLinkAnalyticEvent(
                    'Privacy Policy',
                    '/policies#Privacy-Policy'
                  )
                }
              >
                <Typography variant="bodyS30">
                  {t('footer.achieve.Privacy Policy')}
                </Typography>
              </a>
            </div>
            <div
              className={styles.link}
              data-testid="achieve-footer-link-section-link-disclosures"
            >
              <a
                href="/dashboard/resolution/disclosures"
                onClick={() =>
                  footerLinkAnalyticEvent(
                    'Disclosures',
                    '/dashboard/resolution/disclosures'
                  )
                }
              >
                <Typography variant="bodyS30">
                  {t('footer.achieve.Disclosures')}
                </Typography>
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr color="#c0c6d4" size={1} />
      <div className={styles.lowerFooter}>
        <div
          className={styles.copyright}
          data-testid="achieve-footer-copyright"
        >
          <Typography variant="bodyS30" color={theme.palette.grey['700']}>
            © 2023 Achieve Resolution. All rights reserved.
          </Typography>
        </div>
        <SocialIcons />
      </div>
    </div>
  );
};
export default AchieveFooter;
