import FacebookIconGray from './assets/facebook-icon-gray.svg';
import FacebookIconColor from './assets/facebook-icon-color.svg';
import InstagramIconGray from './assets/instagram-icon-gray.svg';
import InstagramIconColor from './assets/instagram-icon-color.svg';
import TikTokIconGray from './assets/tiktok-icon-gray.svg';
import TikTokIconColor from './assets/tiktok-icon-color.svg';
import TwitterIconGray from './assets/twitter-icon-gray.svg';
import TwitterIconColor from './assets/twitter-icon-color.svg';
import YoutubeIconGray from './assets/youtube-icon-gray.svg';
import YoutubeIconColor from './assets/youtube-icon-color.svg';
import LinkedInIconGray from './assets/linkedin-icon-gray.svg';
import LinkedInIconColor from './assets/linkedin-icon-color.svg';

import { analyticsTrackEvent } from 'lib/utils/analytics';
import { EVENT_ACTION } from 'lib/utils/eventHorizon';

import styles from './SocialIcons.module.scss';

const socialIconAnalyticEvent = (socialIconName, url) => {
  analyticsTrackEvent(
    {
      nav_link_section: 'Footer',
      event_action: EVENT_ACTION.LINK_CLICK,
      click_type: 'Link Click',
      click_id: socialIconName,
      click_url: url,
      clickText: socialIconName,

      track_event: 'global_footer'
    },
    `${socialIconName} clicked`
  );
};

const AnchorTrack = ({ href, socialIconName, testid, className, children }) => {
  return (
    <a
      className={className}
      data-testid={testid}
      href={href}
      onClick={() => socialIconAnalyticEvent(socialIconName, href)}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const SocialIcons = () => {
  return (
    <div
      className={styles.socialIconsWrapper}
      data-testid="achieve-footer-social-icon-section"
    >
      <AnchorTrack
        className={styles.socialIcon}
        testid="achieve-footer-social-icon-facebook"
        href="https://www.facebook.com/achievecom"
        socialIconName="Facebook"
      >
        <div className={styles.iconContainer}>
          <div className={styles.iconGray}>
            <img src={FacebookIconGray} alt="Facebook" height={24} width={24} />
          </div>
        </div>
        <div className={styles.iconContainer}>
          <div className={styles.iconColor}>
            <img
              src={FacebookIconColor}
              alt="Facebook"
              height={24}
              width={24}
            />
          </div>
        </div>
      </AnchorTrack>
      <AnchorTrack
        className={styles.socialIcon}
        testid="achieve-footer-social-icon-instagram"
        href="https://www.instagram.com/achievecom"
        socialIconName="Instagram"
      >
        <div className={styles.iconContainer}>
          <div className={styles.iconGray}>
            <img
              src={InstagramIconGray}
              alt="Instagram"
              height={24}
              width={24}
            />
          </div>
        </div>
        <div className={styles.iconContainer}>
          <div className={styles.iconColor}>
            <img
              src={InstagramIconColor}
              alt="Instagram"
              height={24}
              width={24}
            />
          </div>
        </div>
      </AnchorTrack>
      <AnchorTrack
        className={styles.socialIcon}
        testid="achieve-footer-social-icon-tiktok"
        href="https://www.tiktok.com/@achievecom_"
        socialIconName="TikTok"
      >
        <div className={styles.iconContainer}>
          <div className={styles.iconGray}>
            <img src={TikTokIconGray} alt="Tik-Tok" height={24} width={24} />
          </div>
        </div>
        <div className={styles.iconContainer}>
          <div className={styles.iconColor}>
            <img src={TikTokIconColor} alt="Tik-Tok" height={24} width={24} />
          </div>
        </div>
      </AnchorTrack>
      <AnchorTrack
        className={styles.socialIcon}
        testid="achieve-footer-social-icon-twitter"
        href="https://twitter.com/achievecom"
        socialIconName="Twitter"
      >
        <div className={styles.iconContainer}>
          <div className={styles.iconGray}>
            <img src={TwitterIconGray} alt="Twitter" height={24} width={24} />
          </div>
        </div>
        <div className={styles.iconContainer}>
          <div className={styles.iconColor}>
            <img src={TwitterIconColor} alt="Twitter" height={24} width={24} />
          </div>
        </div>
      </AnchorTrack>
      <AnchorTrack
        className={styles.socialIcon}
        testid="achieve-footer-social-icon-youtube"
        href="https://www.youtube.com/@Achievecom"
        socialIconName="Youtube"
      >
        <div className={styles.iconContainer}>
          <div className={styles.iconGray}>
            <img src={YoutubeIconGray} alt="Youtube" height={24} width={24} />
          </div>
        </div>
        <div className={styles.iconContainer}>
          <div className={styles.iconColor}>
            <img src={YoutubeIconColor} alt="Youtube" height={24} width={24} />
          </div>
        </div>
      </AnchorTrack>
      <AnchorTrack
        className={styles.socialIcon}
        testid="achieve-footer-social-icon-linkedin"
        href="https://www.linkedin.com/company/achievecareers"
        socialIconName="LinkedIn"
      >
        <div className={styles.iconContainer}>
          <div className={styles.iconGray}>
            <img
              src={LinkedInIconGray}
              alt="Linked-In"
              height={24}
              width={24}
            />
          </div>
        </div>
        <div className={styles.iconContainer}>
          <div className={styles.iconColor}>
            <img
              src={LinkedInIconColor}
              alt="Linked-In"
              height={24}
              width={24}
            />
          </div>
        </div>
      </AnchorTrack>
    </div>
  );
};

export default SocialIcons;
