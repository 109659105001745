import React from 'react';
import { Pdf, Excel, MicrosoftWord, HelpfulDoc } from '@ffn/icons';
import { Icon } from '@ffn/ui';
// eslint-disable-next-line
import { css } from 'styled-components/macro';

export function DocumentIcon({ docType, size = 30 }) {
  let iconName;
  let color;

  switch (docType) {
    case 'PDF':
      iconName = Pdf;
      color = '#bd252c';
      break;
    case 'Word':
      iconName = MicrosoftWord;
      color = '#00457c';
      break;
    case 'Excel':
      iconName = Excel;
      color = '#45ae57';
      break;
    default:
      iconName = HelpfulDoc;
      color = 'inherit';
      break;
  }

  return (
    <Icon
      data-testid="document-icon"
      icon={iconName}
      size={size}
      css={`
        color: ${color};
      `}
    />
  );
}
