export const DOC_TYPE_METADATA = [
  { document_type: 'power_of_attorney', dashboardCategory: 'Legal' },
  { dashboardCategory: 'Legal', document_type: 'bankruptcy_document' },
  {
    document_type: 'statement_of_account',
    dashboardCategory: 'Creditor Statements'
  },
  { document_type: 'cease_communication', dashboardCategory: 'FDR Program' },
  { document_type: 'scra_request', dashboardCategory: '' },
  {
    dashboardCategory: 'Creditor Statements',
    document_type: 'collection_letter'
  },
  { document_type: 'cft_agreement', dashboardCategory: 'FDR Program' },
  { document_type: 'welcome_packet', dashboardCategory: 'FDR Program' },
  { dashboardCategory: 'FDR Program', document_type: 'student_loan_statement' },
  {
    dashboardCategory: 'Settlements',
    document_type: 'settlement_authorization'
  },
  { document_type: 'cccs_proposal', dashboardCategory: '' },
  { document_type: 'loan_statement', dashboardCategory: 'Creditor Statements' },
  { document_type: 'court_documents', dashboardCategory: 'Legal' },
  { dashboardCategory: 'FDR Program', document_type: 'mortgage_statement' },
  { document_type: 'qa_voice_analyzer', dashboardCategory: '' },
  { document_type: 'judgement_stip_letter', dashboardCategory: 'Legal' },
  { dashboardCategory: 'Settlements', document_type: 'payoff_letter' },
  { document_type: 'graduation_document', dashboardCategory: '' },
  { document_type: 'transition_packet', dashboardCategory: '' },
  { dashboardCategory: '', document_type: 'voice_snippet' },
  { document_type: 'deficiency_statement', dashboardCategory: 'FDR Program' },
  { dashboardCategory: 'FDR Program', document_type: 'fdr_contract' },
  { dashboardCategory: 'FDR Program', document_type: 'add_co_client' },
  {
    document_type: 'authorization_to_communicate',
    dashboardCategory: 'FDR Program'
  },
  { document_type: 'contract_amendment', dashboardCategory: 'FDR Program' },
  { document_type: 'sif_settlement_letter', dashboardCategory: 'Settlements' },
  { dashboardCategory: 'FDR Program', document_type: 'utility_bill' },
  {
    document_type: 'financial_hardship_statement',
    dashboardCategory: 'FDR Program'
  },
  { document_type: 'bank_change', dashboardCategory: 'FDR Program' },
  {
    document_type: 'third_party_authorization',
    dashboardCategory: 'FDR Program'
  },
  { dashboardCategory: 'Creditor Statements', document_type: 'late_notice' },
  {
    document_type: 'draft_modification_form',
    dashboardCategory: 'FDR Program'
  },
  { dashboardCategory: '', document_type: 'general_correspondence' },
  {
    document_type: 'customer_email_authorization',
    dashboardCategory: 'FDR Program'
  },
  {
    dashboardCategory: 'Settlements',
    document_type: 'settlement_offer_from_creditor'
  },
  { document_type: 'waivers', dashboardCategory: 'Legal' },
  { dashboardCategory: 'Legal', document_type: 'summons_and_complaints' },
  { document_type: 'paid_in_full_notice', dashboardCategory: 'Settlements' },
  { dashboardCategory: '', document_type: 'bulk_settlement_spreadsheet' },
  { document_type: 'proof_of_payment', dashboardCategory: 'Settlements' },
  { dashboardCategory: 'FDR Program', document_type: 'authorization_to_debit' },
  { document_type: 'charge_off_notice', dashboardCategory: 'Settlements' },
  { dashboardCategory: '', document_type: 'historic_data' },
  { document_type: 'death_certificate', dashboardCategory: 'FDR Program' },
  { document_type: 'nps_survey_response', dashboardCategory: '' },
  {
    dashboardCategory: 'Creditor Statements',
    document_type: 'billing_statement'
  },
  { document_type: 'credit_report', dashboardCategory: '' },
  { document_type: 'atc_revoke', dashboardCategory: 'FDR Program' },
  { document_type: '1099_c_tax_form', dashboardCategory: 'Settlements' },
  { document_type: 'post_call_survey', dashboardCategory: '' },
  { dashboardCategory: 'Settlements', document_type: 'zero_balance_letter' },
  {
    dashboardCategory: 'Creditor Statements',
    document_type: 'credit_card_statement'
  },
  { dashboardCategory: 'FDR Program', document_type: 'marriage_license' },
  { document_type: 'qa_spreadsheet', dashboardCategory: '' },
  {
    dashboardCategory: '',
    clientStatus: 'pending',
    document_type: 'customer_upload'
  },
  { dashboardCategory: 'Client Settlement', document_type: 'Hidden' },
  { document_type: 'tax_return', dashboardCategory: 'FDR Program' },
  {
    document_type: 'proof_of_identity_document',
    dashboardCategory: 'FDR Program'
  },
  {
    dashboardCategory: 'FDR Program',
    document_type: 'signed_privacy_notice_opt_out'
  },
  { dashboardCategory: 'Creditor Statements', document_type: 'bank_statement' },
  { dashboardCategory: 'Legal', document_type: 'legal_services_contract' },
  { dashboardCategory: 'FDR Program', document_type: 'til_agreement' },
  {
    dashboardCategory: 'FDR Program',
    document_type: 'fdr_contract_amendments'
  },
  {
    document_type: 'deposit_account_authorization_form',
    dashboardCategory: 'FDR Program'
  },
  { document_type: 'ws_credit_report', dashboardCategory: '' },
  { document_type: 'signed_fdr_contract', dashboardCategory: 'FDR Program' },
  { dashboardCategory: '', document_type: 'applicant_profile_copy' }
];
