import { analyticsTrackEvent } from 'lib/utils/analytics';
import { autoSettlementAuth } from 'lib/api/autoSettlementAuth';
import { analyticsPayloads } from './constants';

// Helper for submitting the settlement auth request
export const submitSettlementAuth = async ({ settlementCode }) =>
  await autoSettlementAuth({ settlementCode });

// Helper for submitting analytics events
export const sendAnalyticsEvent = ({ eventKey, settlementId = null, name }) => {
  const payload = analyticsPayloads[eventKey];
  analyticsTrackEvent(
    {
      ...payload,
      settlement_key: settlementId
    },
    name
  );
};
