import { createSelector } from 'reselect';
import { compareAsc, parse } from 'date-fns';

export const getPtSData = state => {
  const ptsData = state.models?.pts;

  if (!ptsData) return {};

  const ptsCopy = { ...ptsData };

  // If we have nextSettlements, we want to go ahead and convert the estimatedSettlementRange to dates
  // We get them in a ['yyyy-MM', 'yyyy-MM'] format from the backend. By passing a date object,
  // we can format it however we want when consuming it in the UI.
  if (ptsData.nextSettlements.length > 0) {
    const newNextSettlements = ptsData.nextSettlements.map(settlement => {
      const dates = settlement.estimatedSettlementRange.map(monthString =>
        parse(monthString, 'yyyy-MM', new Date())
      );
      return {
        ...settlement,
        estimatedSettlementRange: {
          monthsToFirstSettlement: compareAsc(new Date(), dates[0]),
          estStart: dates[0],
          estEnd: dates[1]
        }
      };
    });
    // Overwrite our next settlements with our new one that includes date objects
    ptsCopy.nextSettlements = [...newNextSettlements];
  }
  return { ...ptsCopy };
};

export const getNextSettlementRange = createSelector(getPtSData, pts => {
  if (pts?.nextSettlements?.length > 0) {
    return pts.nextSettlements[0]?.estimatedSettlementRange;
  } else {
    return null;
  }
});
