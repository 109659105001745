export const HeartWithHeadsetSvg = () => {
  return (
    <svg
      aria-hidden="true"
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3232_4210)">
        <path
          d="M22.7032 6.23301H22.3154L22.7983 6.02913C21.24 2.3665 17.6553 0 13.661 0C9.66658 0 6.08189 2.3665 4.53096 6.02913L5.18937 6.30582H4.29686C2.20457 6.30582 0.507324 7.99515 0.507324 10.0777V13.2743C0.507324 15.3568 2.20457 17.0461 4.29686 17.0461H5.24058V6.32767L6.16236 6.71359C7.44261 3.70631 10.3835 1.76214 13.6683 1.76214C16.953 1.76214 19.8939 3.70631 21.1742 6.71359L21.6497 6.51699V16.9733H22.7105C24.8028 16.9733 26.5 15.284 26.5 13.2015V10.0049C26.5 7.92233 24.8028 6.23301 22.7105 6.23301H22.7032Z"
          fill="#3300FF"
        />
        <path
          d="M23.0251 14.4393C25.0882 12.0583 24.9931 8.46118 22.7179 6.19662C20.45 3.93934 16.8287 3.83739 14.4365 5.89079L14.4219 5.87623L13.4782 6.81555L12.571 5.91264L12.5564 5.9272C10.1641 3.8738 6.55018 3.96846 4.275 6.23303C2.00713 8.49031 1.90471 12.0947 3.96774 14.4757L3.95311 14.4903L13.5001 23.9927L22.118 15.4151L22.096 15.3932L23.0398 14.4539L23.0251 14.4393Z"
          fill="url(#paint0_radial_3232_4210)"
        />
        <path
          d="M14.2903 17.2136H12.7101C11.8576 17.2136 11.1665 17.9015 11.1665 18.75V19.6311C11.1665 20.4796 11.8576 21.1675 12.7101 21.1675H14.2903C15.1428 21.1675 15.8339 20.4796 15.8339 19.6311V18.75C15.8339 17.9015 15.1428 17.2136 14.2903 17.2136Z"
          fill="#3300FF"
        />
        <path
          d="M14.7729 19.0413H21.5253C22.7397 19.0413 23.7201 18.0656 23.7201 16.8569V15.0729"
          stroke="#3300FF"
          strokeWidth="1.75"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_3232_4210"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.2525 2.90536) scale(33.3962 33.2403)"
        >
          <stop stopColor="#19AFE0" />
          <stop offset="1" stopColor="#3B6AF5" />
        </radialGradient>
        <clipPath id="clip0_3232_4210">
          <rect
            width="26"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
