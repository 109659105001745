import updateFirestoreUserRecord from '../updateFirestoreUserRecord';

export const saveSigningCompletion = async ({ userData, alertId }) => {
  if (
    !userData?.docuSign ||
    (userData?.docuSign && !userData?.docuSign?.includes(alertId))
  ) {
    const payload =
      userData?.docuSign?.length > 0
        ? [...userData?.docuSign, alertId]
        : [alertId];
    await updateFirestoreUserRecord({
      docuSign: payload
    });
  }
};

export default saveSigningCompletion;
