import { axiosInstance as axios } from 'lib/axios';
import getApiUrl from 'lib/utils/getApiUrl';

/**
 * Retrieves the password reset URL for a given email and law firm ID.
 * @function
 * @async
 * @returns {Promise<Object>} A promise that resolves to an object containing the password reset URL.
 * @throws {Object} Throws an error object if the response status is not 200.
 */

export const requestPasswordResetEmail = async () => {
  const urlStem = getApiUrl();
  const url = `${urlStem}/password-reset`;

  try {
    await axios.post(url, {
      validateStatus: status => status === 200
    });
  } catch (error) {
    throw new Error(error.response.status);
  }
};

export default requestPasswordResetEmail;
