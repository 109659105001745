import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Typography, Card } from '@achieve/sunbeam';
import { useClientEducation } from 'lib/hooks';
import 'styled-components/macro';
import { getUserRecord } from 'shared-selectors/userRecord';

function LessonAckScreen({ lessonPreview, resetCarouselIndex, hideAckScreen }) {
  const { openLesson } = useClientEducation();
  const { firstName } = useSelector(getUserRecord);
  const handleNextLessonClick = useCallback(() => {
    const { trackingId: nextLessonTrackingId } = lessonPreview;
    resetCarouselIndex();
    openLesson({
      trackingId: nextLessonTrackingId,
      invokedFrom: 'lesson_ack_screen'
    });
  }, [resetCarouselIndex, openLesson, lessonPreview]);

  if (hideAckScreen) return null;

  return (
    <Box
      sx={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '70%'
      }}
    >
      <img
        alt="fireworks"
        src={
          'https://images.ctfassets.net/b32zuu6bt176/338CfXJYc7sXV7SzflP0Lp/579159d89d8b76de5651c7f3c353922e/Fireworks_Icon.svg'
        }
        width="100%"
        style={{ padding: '40px' }}
      />
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: '2rem'
        }}
      >
        <Typography variant="displayS20" fontWeight="bold">
          Congrats on finishing your lesson{firstName ? `, ${firstName}` : ''}!
        </Typography>
      </Box>
      <NextLessonPreviewCard
        nextLessonData={lessonPreview}
        onNextLessonClick={handleNextLessonClick}
      />
    </Box>
  );
}

export default LessonAckScreen;

function NextLessonPreviewCard({ nextLessonData, onNextLessonClick }) {
  const { cardImage, title, readTime } = nextLessonData;
  const { url } = cardImage.fields.file;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      marginTop="2rem"
      gap="1rem"
    >
      <Typography variant="displayS10" fontWeight="medium">
        Get started on your next lesson
      </Typography>
      <Card>
        <Box display="flex" flexDirection="row" gap="1rem">
          <Box
            sx={{
              width: '152px',
              height: '170px',
              backgroundImage: `url(${url})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          ></Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="24px"
            justifyContent="center"
          >
            <Box display="flex" flexDirection="column" gap="4px">
              <Typography variant="displayXS10">{readTime} min read</Typography>
              <Typography variant="displayS10" fontWeight="bold">
                {title}
              </Typography>
            </Box>
            <Button
              data-testid={`lesson-ack-screen-start-next-lesson-btn`}
              size="xsmall"
              onClick={onNextLessonClick}
              sx={{
                width: '90%'
              }}
            >
              Start lesson
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
