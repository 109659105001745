import React from 'react';
import {
  SettlementAuth,
  AddAccountOpportunity,
  DASuspendedAlert,
  SettlementConflict,
  InsufficientFundsAlert,
  AccountNumberVerificationAlert,
  ClientContactFamily,
  AcceleratedSettlementOpportunity,
  AccountRemovalRequest,
  MilestoneCallAlert,
  MilestoneCallConfirmationMessage,
  POANeeded,
  InformationRequest,
  SettlementAuthSMSOptIn,
  AalEligible
} from 'shared-components';
import { Box } from 'atomic-layout';
import { Loader } from '@ffn/ui';

// Mapping alertTask subject to the component we want to render
const taskComponentMap = {
  'Account Number Verification': AccountNumberVerificationAlert,
  'Settlement Authorization': SettlementAuth,
  'Add Account Opportunity': AddAccountOpportunity,
  'DA Suspended': DASuspendedAlert,
  'Settlement Conflict': SettlementConflict,
  'Insufficient Funds': InsufficientFundsAlert,
  'Client Contact Family': ClientContactFamily,
  'Accelerated Settlement Opportunity': AcceleratedSettlementOpportunity,
  'Removal Request': AccountRemovalRequest,
  'Information Request': InformationRequest,
  // Not a typo. A 'Last Payment Date Verification' alert should render the same component as an 'Information Request' alert
  'Last Payment Date Verification': InformationRequest,
  'First Milestone Call': MilestoneCallAlert,
  'Second Milestone Call': MilestoneCallAlert,
  'POA Needed': POANeeded,
  'Settlement Authorization SMS Opt-in Reminder': SettlementAuthSMSOptIn
};

const messageComponentMap = {
  'First Milestone Call': MilestoneCallConfirmationMessage,
  'Second Milestone Call': MilestoneCallConfirmationMessage,
  'AAL Eligible': AalEligible
};

export function TaskComponent({
  notification,
  viewLocation,
  isVisible,
  setIsResolved,
  isResolved,
  isFromAlertBox
}) {
  if (!notification) {
    return (
      <Box
        width="100%"
        height="100%"
        flex
        alignItems="center"
        justifyContent="center"
        backgroundColor="#fff"
      >
        <Loader color="primaryBlue" size={60} />
      </Box>
    );
  }

  const AlertComponent = taskComponentMap[notification?.subject] ?? null;
  const MessageComponent = messageComponentMap[notification?.subject] ?? null;

  if (notification.type === 'alert') {
    return AlertComponent ? (
      <AlertComponent
        notification={notification}
        viewLocation={viewLocation}
        isVisible={isVisible}
        isResolved={isResolved}
        isFromAlertBox={isFromAlertBox}
        setIsResolved={setIsResolved}
      />
    ) : null;
  } else if (notification.type === 'message') {
    return MessageComponent ? (
      <MessageComponent
        notification={notification}
        viewLocation={viewLocation}
        isVisible={isVisible}
      />
    ) : null;
  } else {
    return null;
  }
}
