import { format, zonedTimeToUtc } from 'date-fns-tz';
import { parse } from 'date-fns';
import { enUS } from 'date-fns/locale';

export const fromMstToLocal = (date, time, full = false, locale = enUS) => {
  // 'hh:mm aa' is the time format rec'd from SF, output is 24 hour time
  const militaryTime = format(parse(time, 'hh:mm aa', new Date()), 'HH:mm');
  const timeInUtc = zonedTimeToUtc(
    `${date} ${militaryTime}`,
    'America/Phoenix'
  );

  const displayTime = full
    ? format(timeInUtc, "EEEE, LLL d 'at' h:mm a zzzz", {
        locale
      })
    : format(timeInUtc, 'h:mm a zzz');
  return displayTime;
};
