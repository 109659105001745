import React, { Fragment } from 'react';
import {
  Panel,
  PanelTitle,
  PanelTitleHeading,
  Row,
  Column,
  Link
} from '@ffn/ui';
import { css } from 'styled-components/macro';
import { formatPhoneNumberSafe } from 'lib/utils';
import { useTranslation } from 'lib/hooks';

/**
 * @summary Renders the DebtConsultant component.
 * @param {Object} props The component props.
 * @param {String} props.firstName The debt consultant's first name.
 * @param {String} props.lastName The debt consultant's last name.
 * @param {String} props.phone The debt consultant's phone.
 * @param {String} props.email The debt consultant's email.
 * @param {Array} props.phoneAvailability The debt consultant's hours. [{dateRange: '', hours: ''}]
 * @returns {React.Component} The rendered component.
 */
const DebtConsultant = ({
  firstName,
  lastName,
  phone,
  email,
  phoneAvailability = []
}) => {
  const { t } = useTranslation();
  let phoneAvailabilityTranslated = phoneAvailability.map(phoneHour => {
    const { dateRange, hours } = phoneHour;
    let dateRangeSplit = dateRange.split(' - ');
    let phoneAvailabilityStartDay = dateRangeSplit[0].toLowerCase();
    let phoneAvailabilityEndDay = dateRangeSplit[1].toLowerCase();
    let dateStartTranslated = t(
      `common.datetime.weekday.${phoneAvailabilityStartDay}`
    );
    let dateEndTranslated = t(
      `common.datetime.weekday.${phoneAvailabilityEndDay}`
    );
    let dateRangeTranslated = `${dateStartTranslated} - ${dateEndTranslated}`;
    return {
      dateRange: dateRangeTranslated,
      hours: hours.replace('to', t('common.datetime.to'))
    };
  });

  css(``);
  return (
    <Panel>
      <PanelTitle>
        <PanelTitleHeading>
          {t('onboarding.content.debtConsultant.yourCertifiedDebtConsultant')}
        </PanelTitleHeading>
      </PanelTitle>
      <Row
        gap={10}
        css={`
          font-weight: ${props => props.theme.typography.weight.regular};
        `}
      >
        <Column cols={12} colsMd={6}>
          <Row gap={10}>
            <Column cols={12}>
              <b>{t('common.form.name')}:</b> {firstName} {lastName}
            </Column>
            <Column cols={12}>
              <b>{t('common.form.phone')}:</b>{' '}
              <Link href={`tel:${phone} `}>{formatPhoneNumberSafe(phone)}</Link>
            </Column>
            <Column cols={12}>
              <b>{t('common.form.email')}:</b>{' '}
              <Link href={'mailto:' + email}>{email}</Link>
            </Column>
          </Row>
        </Column>
        <Column cols={12} colsMd={6}>
          <Row gap={10}>
            <Column cols={12}>
              {phoneAvailabilityTranslated.map((item, index) => {
                return (
                  <Fragment key={`${item.dateRange}_${item.hours} `}>
                    <b>{item.dateRange}:</b> {item.hours}
                    {index === phoneAvailability.length - 1 ? '' : <br />}
                  </Fragment>
                );
              })}
            </Column>
          </Row>
        </Column>
      </Row>
    </Panel>
  );
};

export { DebtConsultant };
