import { createSelector } from "reselect";
import { parseISO, differenceInDays, isAfter } from "date-fns/fp";
import { prop, propEq, pipe, filter, map, ascend, sortBy, last } from "ramda";

export const getDraftFees = state => state.models.draftFees ?? [];
const today = new Date();

export const getDraftFeesForNext30days = createSelector(getDraftFees, items =>
	items?.filter(draftFee => {
		const diffOfDays = differenceInDays(today, parseISO(draftFee.date));

		return diffOfDays >= 0 && diffOfDays <= 30;
	})
);

export const getDepositsFromNext30days = createSelector(
	getDraftFeesForNext30days,
	draftFees =>
		draftFees?.filter(
			draftFee => draftFee.type === "Draft" && draftFee.includeInDfli
		)
);

export const getTotalDepositsFromNext30days = createSelector(
	getDraftFeesForNext30days,
	draftFees =>
		draftFees
			?.filter(draftFee => draftFee.type === "Draft" && draftFee.includeInDfli)
			.reduce((sum, draftFee) => sum + parseFloat(draftFee.draftAmount), 0)
);

export const getFeesFromNext30days = createSelector(
	getDraftFeesForNext30days,
	draftFees =>
		draftFees?.filter(
			draftFee =>
				draftFee.type === "Fee" &&
				draftFee.includeInDfli &&
				draftFee.settlementClientApprovalStatus === "Client Authorized"
		)
);

export const getFutureDrafts = createSelector(
	getDraftFees,
	pipe(
		filter(pipe(prop("date"), parseISO, isAfter(today))),
		filter(prop("includeInDfli")),
		filter(propEq("type", "Draft")),
		sortBy(ascend(prop("date")))
	)
);

export const getFutureDraftDates = createSelector(
	getFutureDrafts,
	map(pipe(prop("date"), parseISO))
);

export const getLastScheduledDraftDate = createSelector(getFutureDraftDates, last);
