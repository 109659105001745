import React from 'react';
import { useFormikContext } from 'formik';
import { Box, Composition } from 'atomic-layout';
import { Link, Icon, LoadingButton } from '@ffn/ui';
import { Currency } from 'shared-components';
import { format } from 'date-fns/fp';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { getTestIds } from 'lib/utils/dataTestIds';
import { getEventExtension } from '../../../../../../../lib/utils';
import { analyticsTrackEvent } from '../../../../../../../lib/utils/gtmHelper';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'lib/hooks';

const increaseDepositFormIDs = getTestIds('increaseDepositForm');

export function Review({ prevStep, draftTypeLabel }) {
  const { t } = useTranslation();
  const { values, isSubmitting } = useFormikContext();
  const location = useLocation();

  analyticsTrackEvent(
    {
      category: 'csr',
      label: 'increase_deposit' + getEventExtension(location),
      action: 'review'
    },
    'Increase Program Deposit Review'
  );

  return (
    <>
      <Box style={{ fontWeight: 600 }}>
        {t('changeDepositWizard.increasedDepositAmount')}:{' '}
        <Currency value={values.increaseAmount} />
        <br />
        {t('changeDepositWizard.startingDepositDate')}:{' '}
        {format('MMM yyyy', values.startDate)}
        <br />
        {t('changeDepositWizard.endingDepositDate')}:{' '}
        {format('MMM yyyy', values.endDate)}
      </Box>
      <Box>
        {t('changeDepositWizard.byIncreasingYour')} {draftTypeLabel}{' '}
        {t('changeDepositWizard.programDepositEstimatedToGraduate')}{' '}
        {t('changeDepositWizard.monthsEarlier')}.
      </Box>
      <Composition gap={16} widthMd="50%">
        <LoadingButton
          data-testid={increaseDepositFormIDs.increaseDepositButton}
          isLoading={isSubmitting}
          disabled={isSubmitting}
          variant="primary"
          type="submit"
          height={40}
          width="100%"
          rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
        >
          {t('changeDepositWizard.yesIncreaseMyProgramDeposit')}
        </LoadingButton>
      </Composition>
      <Box flex justifyContent="center">
        <Link
          onClick={evt => {
            if (isSubmitting) {
              evt.preventDefault();
            } else {
              prevStep();
            }
          }}
        >
          {t('changeDepositWizard.back')}
        </Link>
      </Box>
    </>
  );
}
