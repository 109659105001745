export const tealiumIncludedFields = [
  'application_version',
  'click_action',
  'click_id',
  'click_position',
  'click_text',
  'click_type',
  'click_url',
  'content_group',
  'content_id',
  'content_type',
  'content_finding_method',
  'full_page_url',
  'mega_menu_column',
  'mega_menu_link',
  'mega_menu_tab',
  'nav_link_section',
  'page_name',
  'page_path',
  'page_section',
  'page_title',
  'page_url',
  'referring_url',
  'scroll',
  'track_event',
  'url_params'
];

// Brand-specific values for analytics
// currently only required for achieve
export const SESSION_ID_KEYS = {
  achieve: 'achieve_session_id'
};
export const BROWSER_ID_KEYS = {
  achieve: 'achieve_browser_id'
};
