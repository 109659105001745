import React from 'react';
import 'styled-components/macro';
import { Panel, PanelTitle, PanelTitleHeading } from '@ffn/ui';
import { CustomerServiceHours } from 'shared-components';
import { useTranslation } from 'lib/hooks';

export function ContactUs() {
  const { t } = useTranslation();
  return (
    <Panel data-testid="csContactUsPanel">
      <PanelTitle
        css={`
          margin-bottom: 12px !important;
        `}
      >
        <PanelTitleHeading>{t('nav.contactUs')}</PanelTitleHeading>
      </PanelTitle>
      <CustomerServiceHours />
    </Panel>
  );
}

export default ContactUs;
