import React from 'react';
import { mergeDeepRight } from 'ramda';
import { styles } from '@ffn/ui';
import { useDashboardType } from 'lib/hooks';
import { ThemeProvider } from 'styled-components';
import { BRAND_COLOR_THEMES } from '../../constants';

export function ProvideBrandTheme({ children }) {
  const { subDomain } = useDashboardType();
  const brandThemes = BRAND_COLOR_THEMES[subDomain];

  return (
    <ThemeProvider theme={mergeDeepRight(styles, brandThemes)}>
      {children}
    </ThemeProvider>
  );
}
