import { updateDebtAccount } from 'lib/api/updateDebtAccount';
import { defaultUpload } from 'lib/api/uploadDocument/defaultUpload';
import { collectionLetterUpload } from 'lib/api/uploadDocument/collectionLetterUpload';
import { legalDocumentUpload } from 'lib/api/uploadDocument/legalDocumentUpload';
import { sendGeneralCsRequest } from 'lib/api/generalCsRequest';
import { craftEmailMessage } from './utils';
import { renameDocument } from './docRenameUtil';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { logger } from 'shared-components';

const DOC_UPLOAD_EVENT_PAYLOAD_BASE = {
  category: 'document',
  label: 'document_upload'
};

const LOGGING_PREFIX = 'DOCUMENT UPLOAD ::';

export const onSubmit = async (
  values,
  actions,
  userInfo,
  analyticsEventDescription = 'Generic File Upload Request',
  isAddAccount = false
) => {
  const { lawFirmId, fullName, email, csClientId, fdrApplicantId } = userInfo;
  const isCollectionLetter =
    values.files[0].metadata.document_type === 'collection_letter';
  const isMiscellaneousUpload =
    values.files[0].metadata.document_type === 'customer_upload';
  const isEnrollNewDebt =
    values.files[0].metadata.document_type === 'statement_of_account' &&
    isAddAccount;
  const isAccountStatement =
    values.files[0].metadata.document_type === 'statement_of_account' &&
    !isAddAccount;
  let newAccountIsPresent = false;

  // upload documents
  for (const [index, file] of Object.entries(values.files)) {
    // Before submitting file to ECM rename them in desired format for ease of Agent and in future utilizing docAI capabilities to process these files.
    let newFileObj = renameDocument(file, lawFirmId, fdrApplicantId, fullName);

    const { name: docName, size: docSize } = newFileObj;

    analyticsTrackEvent(
      {
        ...DOC_UPLOAD_EVENT_PAYLOAD_BASE,
        action: 'submitted',
        doc_name: docName
      },
      `${analyticsEventDescription} Submitted - Status Pending`
    );

    try {
      if (file.status === 'submitted') continue;
      if (isCollectionLetter) {
        if (file.metadata.creditor === 'Other (Not Listed)') {
          newAccountIsPresent = true;
        }
        await collectionLetterUpload(newFileObj);
      } else {
        await defaultUpload(newFileObj);
      }

      analyticsTrackEvent(
        {
          ...DOC_UPLOAD_EVENT_PAYLOAD_BASE,
          action: 'success',
          doc_name: docName
        },
        `${analyticsEventDescription} Success`
      );

      logger.info(`${LOGGING_PREFIX} Upload Successful`, {
        docName,
        docSize
      });

      actions.setFieldValue(`files.${index}.status`, 'submitted');
    } catch (error) {
      logger.error(
        `${LOGGING_PREFIX} Document Upload Error (${error.message})`,
        {
          docName,
          docSize,
          error
        }
      );
      actions.setFieldValue(`files.${index}.status`, 'rejected');

      analyticsTrackEvent(
        {
          ...DOC_UPLOAD_EVENT_PAYLOAD_BASE,
          action: 'failure',
          doc_name: docName
        },
        `${analyticsEventDescription} Failure`
      );

      logger.info(`${LOGGING_PREFIX} Upload Failed`, {
        docName,
        docSize
      });

      throw Error(error);
    }
  }

  const setDocType = () => {
    if (isCollectionLetter) {
      return 'collection_letter';
    } else if (isEnrollNewDebt) {
      return 'enroll_new_debt';
    } else if (isAccountStatement) {
      return 'statement_of_account';
    }
    return 'customer_upload';
  };

  // send email
  const newCollectionLetterAccount = isCollectionLetter && newAccountIsPresent;

  const message = craftEmailMessage({
    values: values,
    doctype: setDocType(),
    fullName,
    email,
    clientId: csClientId
  });

  const setSubject = () => {
    if (isCollectionLetter) {
      return 'COLLECTION LETTER Document Upload';
    } else if (isEnrollNewDebt) {
      return 'Dashboard Request to Add an Account';
    } else {
      return 'Document Upload via Dashboard';
    }
  };

  if (newCollectionLetterAccount || isMiscellaneousUpload || isAddAccount) {
    return await sendGeneralCsRequest({
      subject: setSubject(),
      message,
      lawFirmId,
      isAddAccount
    }).catch(error => {
      throw error;
    });
  }
};

export const legalDocOnSubmit = async (values, actions, userInfo) => {
  const { fdrApplicantId, lawFirmId, fullName, email, csClientId } = userInfo;
  // upload documents
  for (const [index, file] of Object.entries(values.files)) {
    // Calling the util rename function with the required parameters to rename the document
    let newLegalFileObj = renameDocument(
      file,
      lawFirmId,
      fdrApplicantId,
      fullName
    );

    const { name: docName, size: docSize } = newLegalFileObj;

    analyticsTrackEvent(
      {
        ...DOC_UPLOAD_EVENT_PAYLOAD_BASE,
        action: 'submitted',
        doc_name: docName
      },
      `Legal Documents Submitted - Status Pending`
    );

    try {
      if (file.status === 'submitted') continue;
      if (file.status === 'Failed SF update') {
        await updateDebtAccount(file.metadata);
        actions.setFieldValue(`files.${index}.status`, 'submitted');
        continue;
      }
      const isSummonsAndComplaints =
        file.metadata.document_type === 'summons_and_complaints';
      await legalDocumentUpload(newLegalFileObj);
      isSummonsAndComplaints && (await updateDebtAccount(file.metadata));

      analyticsTrackEvent(
        {
          ...DOC_UPLOAD_EVENT_PAYLOAD_BASE,
          action: 'success',
          doc_name: docName
        },
        `Legal Documents Document Upload Success`
      );

      logger.info(`${LOGGING_PREFIX} Upload Successful`, {
        docName,
        docSize
      });

      actions.setFieldValue(`files.${index}.status`, 'submitted');
    } catch (error) {
      if (error.message === 'Failed upload') {
        actions.setFieldValue(`files.${index}.status`, 'rejected');
      }
      if (error.message === 'Failed SF update') {
        actions.setFieldValue(`files.${index}.status`, 'Failed SF update');
      }

      analyticsTrackEvent(
        {
          ...DOC_UPLOAD_EVENT_PAYLOAD_BASE,
          action: 'failure',
          doc_name: docName
        },
        `Legal Documents Document Upload Failure`
      );

      logger.info(`${LOGGING_PREFIX} Upload Failed`, {
        docName,
        docSize
      });

      // Need to re-throw an error here so that the appropriate UI will render
      throw error;
    }
  }
  // send email
  const message = craftEmailMessage({
    values,
    doctype: 'legal',
    fullName,
    email,
    clientId: csClientId
  });
  await sendGeneralCsRequest({
    subject: 'LEGAL Document Upload',
    message,
    lawFirmId
  }).catch(e => console.error(e));
};
