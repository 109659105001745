import React from 'react';
import { Composition } from 'atomic-layout';
import {
  Container,
  Panel,
  PanelTitle,
  PanelTitleHeading,
  Text,
  Footer as FDRFooter,
  FooterItem,
  FooterSeparator,
  Link,
  Column,
  Row,
  Icon
} from '@ffn/ui';
import { NavLink } from 'react-router-dom';
import 'styled-components/macro';
import { FeatureFlag } from 'shared-components';
import { useBrand } from 'lib/hooks';
import { FacebookWithCircle } from '@styled-icons/entypo-social/FacebookWithCircle';
import { TwitterWithCircle } from '@styled-icons/entypo-social/TwitterWithCircle';
import { YoutubeWithCircle } from '@styled-icons/entypo-social/YoutubeWithCircle';
import { LinkedinWithCircle } from '@styled-icons/entypo-social/LinkedinWithCircle';
import parse from 'url-parse';
import { useTranslation } from 'lib/hooks';

function Footer() {
  const brand = useBrand();
  const now = new Date();
  const currentYear = now.getFullYear();

  return (
    <FDRFooter>
      <Container>
        <Row>
          <Column cols={6}>
            <FeatureFlag pathKey="/about">
              <FooterItem inline>
                <Link as={NavLink} to="/about">
                  About Us
                </Link>
              </FooterItem>
            </FeatureFlag>
            <FeatureFlag pathKey="/disclosures">
              <FooterItem inline separator>
                <Link as={NavLink} to="/disclosures">
                  Disclosures
                </Link>
              </FooterItem>
            </FeatureFlag>
            {brand('site.url') && (
              <FooterItem inline separator>
                <Link href={brand('site.url')} target="_blank">
                  {parse(brand('site.url'), {}).hostname}
                </Link>
              </FooterItem>
            )}
          </Column>
          <Column
            cols={4}
            css={`
              text-align: right;
            `}
          >
            &copy; {currentYear}. All rights reserved.{' '}
            {brand('business-name.legal')}.
          </Column>
          <Column
            cols={2}
            css={`
              text-align: right;
            `}
          >
            {brand('socials.facebook') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.facebook')}
                target="_blank"
              >
                <Icon icon={FacebookWithCircle} color="plain" size={30} />
              </Link>
            )}
            {brand('socials.youtube') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.youtube')}
                target="_blank"
              >
                <Icon icon={YoutubeWithCircle} color="plain" size={30} />
              </Link>
            )}
            {brand('socials.twitter') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.twitter')}
                target="_blank"
              >
                <Icon icon={TwitterWithCircle} color="plain" size={30} />
              </Link>
            )}
            {brand('socials.linkedin') && (
              <Link
                css={`
                  margin-left: 7px;
                `}
                href={brand('socials.linkedin')}
                target="_blank"
              >
                <Icon icon={LinkedinWithCircle} color="plain" size={30} />
              </Link>
            )}
          </Column>
        </Row>

        <FooterSeparator />

        {(brand('site.cali-privacy-url') || brand('site.do-not-sell-site')) && (
          <Row>
            <Column
              cols={12}
              css={`
                text-align: center;
              `}
            >
              <strong>California Residents</strong>
              {brand('site.cali-privacy-url') && (
                <>
                  <br />
                  <Link
                    css={`
                      color: ${props => props.theme.colors.plain};
                    `}
                    href={brand('site.cali-privacy-url')}
                    target="_blank"
                    underline
                  >
                    California Privacy Notice
                  </Link>
                </>
              )}
              {brand('site.do-not-sell-site') && (
                <>
                  <br />
                  <Link
                    css={`
                      color: ${props => props.theme.colors.plain};
                    `}
                    href={brand('site.do-not-sell-site')}
                    target="_blank"
                    underline
                  >
                    Do Not Sell My Personal Information
                  </Link>
                </>
              )}
            </Column>
          </Row>
        )}
      </Container>
    </FDRFooter>
  );
}

export default Footer;
export function NotFound() {
  const { t } = useTranslation();
  return (
    <Composition height="100vh" templateRows="auto 1fr auto">
      <div />
      <Container>
        <Panel>
          <PanelTitle>
            <PanelTitleHeading>{t('common.pageNotFound')}</PanelTitleHeading>
          </PanelTitle>
          <Text>{t('common.sorryThePageYouVisitedDNE')}</Text>
        </Panel>
      </Container>
      <Footer />
    </Composition>
  );
}
