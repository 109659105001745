import Cookies from 'js-cookie';

import { SESSION_ID_KEYS, BROWSER_ID_KEYS } from '../analytics/constants';

export const createIdBundle = subdomain => {
  const storedIds = sessionStorage.getItem('idBundle');
  const sessionIdKey = SESSION_ID_KEYS[subdomain];
  const browserIdKey = BROWSER_ID_KEYS[subdomain];

  let idBundle;
  if (storedIds) {
    idBundle = JSON.parse(storedIds);
  }

  // At the time of implementing only Achieve Resolution analytics requires session_id and browser_id
  // those are placed in session storage and cookies respectively in the Achieve dashboard
  // to add support for other brands, update the SESSION_ID_KEYS and BROWSER_ID_KEYS constants
  if (sessionIdKey && browserIdKey) {
    const session_id =
      sessionStorage.getItem(sessionIdKey) ?? Cookies.get(sessionIdKey);
    const browser_id =
      Cookies.get(browserIdKey) ?? sessionStorage.getItem(browserIdKey);
    idBundle = { session_id, browser_id, ...idBundle };
  }

  return idBundle;
};
