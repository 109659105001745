import React from 'react';
import { Box, Typography } from '@achieve/sunbeam';
import { colors } from './constants';
import { useBrand, useTranslation } from 'lib/hooks';
import { translateAbbrDateRange } from 'lib/utils';

export const CustomerServiceHours = ({ testIdPrefix }) => {
  const { t } = useTranslation();
  const brand = useBrand();
  const phoneHours = brand('contact.customer-service.phone-hours');

  return (
    <Box
      data-testid={`${testIdPrefix}-cs-hours-container`}
      display="flex"
      width="100%"
      flexDirection="column"
      gap="16px"
    >
      <Typography fontWeight="500" fontSize="16px" textAlign="left">
        {t('oneClick.hoursOfOperation')}
      </Typography>
      {phoneHours.map((item, i) => {
        return (
          <Box
            key={i}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography
              fontWeight="400"
              fontSize="16px"
              textAlign="left"
              color={colors.secondaryGrayColor}
            >
              {translateAbbrDateRange(item.dateRange)}{' '}
            </Typography>
            <Typography
              fontWeight="400"
              fontSize="16px"
              textAlign="left"
              color={colors.secondaryGrayColor}
            >
              {item.hours === 'Closed' || item.hours === 'closed'
                ? t('customerService.closed')
                : item.hours}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
