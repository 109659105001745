import { format } from 'date-fns/fp';
import { USD } from 'lib/utils';
import { axiosInstance as axios } from 'lib/axios';

export const sendGeneralCsRequest = async params => {
  try {
    const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/generalCsRequest`;
    await axios.post(endpoint, JSON.stringify(params), {
      validateStatus: status => status === 200
    });
  } catch (err) {
    if (err.response) {
      const error = new Error(
        err.response.data?.message || 'Email request failed.'
      );
      error.code = 'EMAIL_REQUEST_FAILED';
      throw error;
    } else {
      throw err;
    }
  }
};

export const sendOneTimeGCSemail = async ({
  depositDate,
  amount,
  dmfReason,
  user
}) => {
  const subject = 'Dashboard Request to Make an Additional Deposit';
  const { firstName = '', lastName = '', email = '' } = user;
  const message = `Dashboard "Additional One-Time Deposit" request from user: ${firstName} ${lastName} (${email.toLowerCase()}) \n
		Deposit Amount: $${Number(amount).toFixed(2)}
		Deposit Date: ${format('M/d/yyyy')(depositDate)}
		Deposit Reason: ${dmfReason}
		\n
		This client is a GCS banking customer.`;

  return await sendGeneralCsRequest({ subject, message });
};

export async function sendGCSIncreaseDepositRequestEmail({ client, values }) {
  const { firstName, lastName, clientEmail, draftType } = client;
  const { increaseAmount, startDate, endDate } = values;
  const subject = 'Dashboard What if deposit analysis Request';
  const message = `Dashboard "What If Deposit Analysis" request from user: ${firstName} ${lastName} (${clientEmail.toLowerCase()}) \n
		Increase Program Deposit ‘What if’ deposit analysis request: \n
		Draft Type: ${draftType} \n
		Amount INCREASE to: ${USD.format(increaseAmount)} \n
		Effective Date: ${format('MMM yyyy', startDate)} \n
		Effective Until: ${format('MMM yyyy', endDate)}
		Draft Modification Reason: \n`;

  return await sendGeneralCsRequest({ subject, message });
}
