import { createSelector } from 'reselect';
import { ALERT_SUBJECT_NAMES } from 'constants';

const notificationSubjectsForDisplayInAlertRibbon = Object.values(
  ALERT_SUBJECT_NAMES
);

export const getAllNotifications = state => state.models.notifications;

export const getAlertRibbonNotifications = createSelector(
  getAllNotifications,
  allNotifications => {
    const notificationArray = [];
    if (allNotifications && allNotifications.alerts) {
      allNotifications.alerts.forEach(item => {
        const ribbonItem = constructRibbonNotification(item);
        if (ribbonItem !== null) {
          notificationArray.push(ribbonItem);
        }
      });
    }
    return notificationArray;
  }
);

export const getInPageAlerts = createSelector(getAllNotifications, notifications => {
  if (!notifications) {
    return [];
  }

  return notifications.alerts.filter(
    alert => alert?.payload?.displayOnHomepage
  );
});

function constructRibbonNotification(notification) {
  const { subject, referenceIds } = notification;
  if (notificationSubjectsForDisplayInAlertRibbon.includes(subject)) {
    let link = `/notifications/alerts/${referenceIds[0]}`;
    if (subject === 'DA Suspended') {
      link = `/dedicated-account`;
    }
    return { ...notification, link };
  }
  return null;
}
