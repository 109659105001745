export const views = {
  loading: 'loading',
  success: 'success',
  contactAgent: 'contactAgent',
  tryAgain: 'tryAgain'
};

const analyticsCategory = 'auto_email_deposit_auth';
const analyticsLabel = '___';

const catAndLabel = {
  category: analyticsCategory,
  label: analyticsLabel
};

export const analyticsPayloads = {
  success: {
    ...catAndLabel,
    action: 'success'
  },
  failure: {
    ...catAndLabel,
    action: 'failure'
  },
  tryAgain: {
    ...catAndLabel,
    action: 'retry_click',
    label: 'clicked'
  },
  loading: {
    ...catAndLabel,
    action: 'loading'
  }
};
