import { axiosInstance as axios } from 'lib/axios';

import getApiUrl from 'lib/utils/getApiUrl';

export const getSuipLead = async () => {
  const apiUrl = getApiUrl();
  const uri = `${apiUrl}/lead`;
  let url = String(uri);
  const { data } = await axios.get(url);

  if (!data) return null;
  return data;
};
