import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userRecord from 'shared-reducers/userRecord';
import models from 'shared-reducers/models';
import dataStatus from 'shared-reducers/dataStatus';

const store = configureStore({
  reducer: combineReducers({ userRecord, models, dataStatus })
});

export default store;
