import { axiosInstance as axios } from 'lib/axios';

import getApiUrl from 'lib/utils/getApiUrl';
import { logger } from 'shared-components';

export async function fetchPtsData(fdrApplicantId) {
  if (!fdrApplicantId) {
    throw new Error('fdrApplicantId is a required');
  }
  const urlStem = getApiUrl();

  // payloadType parameter will be deprecated after proper backend is released
  const apiUrl = `${urlStem}/user/pts`;
  try {
    const response = await axios.get(apiUrl);

    if (response.status === 200) {
      return response.data;
    } else {
      const errorInfo = {
        status: response.status,
        message: response.statusText
      };
      throw new Error(JSON.stringify(errorInfo));
    }
  } catch (error) {
    logger.error('Error fetching path to settlement details', error);
    throw error;
  }
}
