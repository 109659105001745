import React from 'react';
import {
  FormInput,
  FormPassword,
  FormDatePicker,
  FormTextArea,
  FormSelect,
  FormCheckbox,
  FormSwitch,
  FormRadio
} from '@ffn/ui';
import { useField, Form } from 'formik';
import { Composition } from 'atomic-layout';
import NumberFormat from 'react-number-format';
import mergeProps from 'react-merge-props';
import { isValid } from 'date-fns';
import { omit } from 'ramda';
import { useLocale } from 'lib/hooks';

export function DashInput({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormInput
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
}

export function DashCheckbox({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormCheckbox
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
}

export function DashRadio({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormRadio
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
}

export function DashNumberFormat({
  msgs = [],
  successMsg,
  useFloat,
  ...props
}) {
  const [field, meta, { setValue }] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <NumberFormat
      customInput={FormInput}
      {...omit(['onChange'], field)}
      onValueChange={values => {
        if (useFloat) {
          setValue(values.floatValue);
        } else {
          setValue(values.value);
        }
      }}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
}

export function DashSwitch({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormSwitch
      {...mergeProps(field, props)}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
}

export function DashPassword({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormPassword
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
}

export function DashTextArea({ msgs = [], successMsg, noTouch, ...props }) {
  const [field, meta] = useField(props);
  const errMsg =
    (noTouch || meta.touched) && meta.error
      ? { type: 'error', message: meta.error }
      : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormTextArea
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
}

export function DashDatePicker({
  msgs = [],
  successMsg,
  dateFormat = 'MM/dd/yyyy',
  disabled,
  name,
  id,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  className,
  autoComplete = 'off',
  ...rest
}) {
  const { locale } = useLocale();
  const [{ value }, meta, { setValue }] = useField(name);
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && !meta.error ? { type: 'success', message: successMsg } : [];
  const handleChangeRaw = event => {
    const { value: newValue } = event.target;
    const validChars = /^\d{0,2}[./]{0,1}\d{0,2}[./]{0,1}\d{0,4}$/;
    if (!validChars.test(newValue)) {
      event.preventDefault();
    }

    if (isValid(new Date(newValue))) {
      setValue(newValue);
      customChange(newValue);
    }
  };

  const customChange = date => {
    if (typeof onChange === 'function') {
      onChange(date);
    }
  };

  const handleChange = date => {
    if (date && isValid(date)) {
      setValue(date);
      customChange(date);
    } else {
      setValue('');
      customChange('');
    }
  };
  return (
    <FormDatePicker
      {...rest}
      disabledKeyboardNavigation
      autoComplete={autoComplete}
      id={id || name}
      name={name}
      className={className}
      selected={value && new Date(value)}
      placeholderText={placeholder}
      dateFormat={dateFormat}
      onChangeRaw={handleChangeRaw}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
      locale={locale}
    />
  );
}

export function DashSelect({ msgs = [], successMsg, ...props }) {
  const [field, meta] = useField({ as: 'select', ...props });
  const errMsg =
    meta.touched && meta.error ? { type: 'error', message: meta.error } : [];
  const defaultSuccessMsg =
    successMsg && meta.touched && !meta.error
      ? { type: 'success', message: successMsg }
      : [];
  return (
    <FormSelect
      {...field}
      {...props}
      msgs={msgs.concat(errMsg).concat(defaultSuccessMsg)}
    />
  );
}

export function DashForm(props) {
  return <Composition as={Form} gap={16} {...props} />;
}
