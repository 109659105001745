import React from 'react';
import 'styled-components/macro';
import { Link, Text, Row, Column, Icon } from '@ffn/ui';
import { FeatureFlag } from 'shared-components';
import { useBrand } from 'lib/hooks';
import { formatPhoneNumberSafe } from 'lib/utils';
import { useTranslation } from 'lib/hooks';
import { translateAbbrDateRange } from 'lib/utils';
import { MessageCircle, Phone, Clock } from 'react-feather';

export function CustomerServiceHours({ hideChat = false }) {
  const brand = useBrand();
  const phoneHours = brand('contact.customer-service.phone-hours');
  const chatHours = brand('contact.customer-service.chat-hours');

  const PhoneHours = () => {
    const { t } = useTranslation();
    return (
      <>
        <Column cols={4}>
          {phoneHours.map((item, i) => {
            return (
              <Row key={i}>
                <Column cols={12}>
                  <Text
                    as="span"
                    css={`
                      font-weight: 600;
                    `}
                  >
                    {translateAbbrDateRange(item.dateRange)}
                  </Text>
                </Column>
              </Row>
            );
          })}
        </Column>
        <Column cols={6}>
          {phoneHours.map((item, i) => {
            return (
              <Row key={i}>
                <Column cols={12}>
                  <Text
                    as="span"
                    css={`
                      font-weight: 100;
                    `}
                  >
                    {item.hours === 'Closed'
                      ? t('customerService.closed')
                      : item.hours}
                  </Text>
                </Column>
              </Row>
            );
          })}
        </Column>
      </>
    );
  };

  const PhoneHoursColumn = () => {
    const { t } = useTranslation();
    if (!phoneHours || !phoneHours.length) {
      return null;
    }
    return (
      <Column
        cols={12}
        colsMd={7}
        marginTop={16}
        marginTopMd={0}
        marginBottom={16}
        marginBottomMd={0}
      >
        <Row marginTop={0} marginTopMd={16}>
          <Column
            cols={12}
            css={`
              color: ${props => props.theme.colors.black};
              font-size: 13px;
            `}
          >
            {t('customerService.customerServiceRepresentativesText')}:
          </Column>
        </Row>
        <Row marginTop={16}>
          <Column cols={2}>
            <Icon
              icon={Clock}
              size={25}
              css={`
                color: ${props => props.theme.colors.tertiaryBlue};
              `}
            />
          </Column>
          <PhoneHours />
        </Row>
      </Column>
    );
  };

  const ChatHoursColumn = () => {
    const { t } = useTranslation();
    if (!chatHours || !chatHours.length) {
      return null;
    }
    return (
      <Column cols={12} colsMd={7}>
        <Row>
          <Column
            cols={12}
            marginTop={16}
            marginTopMd={0}
            css={`
              color: ${props => props.theme.colors.black};
              font-size: 13px;
            `}
          >
            {t('customerService.chatHours')}
          </Column>
        </Row>
      </Column>
    );
  };

  return (
    <>
      {!hideChat && (
        <FeatureFlag flagKey="customer-service.chat">
          <Row>
            <Column cols={12} colsMd={5}>
              <Row>
                <Column cols={2}>
                  <Link
                    css={`
                      font-weight: 600;
                      color: ${props => props.theme.colors.tertiaryBlue};
                    `}
                  >
                    <MessageCircle />
                  </Link>
                </Column>
                <Column cols={10}>
                  <Link
                    css={`
                      font-weight: 600;
                      color: ${props => props.theme.colors.tertiaryBlue};
                    `}
                  >
                    Chat
                  </Link>
                </Column>
              </Row>
            </Column>
            <ChatHoursColumn />
          </Row>
        </FeatureFlag>
      )}
      <Row marginTop={0}>
        <Column
          cols={12}
          colsMd={5}
          marginTop={0}
          marginTopMd={0}
          marginBottomSm={12}
        >
          <Row marginTop={16}>
            <Column cols={2}>
              <Link
                href={`tel:${brand('contact.customer-service.phone')}`}
                css={`
                  font-weight: 600;
                  color: ${props => props.theme.colors.tertiaryBlue};
                `}
              >
                <Icon
                  icon={Phone}
                  size={25}
                  css={`
                    color: ${props => props.theme.colors.tertiaryBlue};
                  `}
                />
              </Link>
            </Column>
            <Column cols={10}>
              <Link
                href={`tel:${brand('contact.customer-service.phone')}`}
                css={`
                  font-weight: 600;
                  color: ${props => props.theme.colors.tertiaryBlue};
                `}
              >
                {formatPhoneNumberSafe(brand('contact.customer-service.phone'))}
              </Link>
            </Column>
          </Row>
        </Column>
        <PhoneHoursColumn />
      </Row>
    </>
  );
}

export default CustomerServiceHours;
