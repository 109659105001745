import { createSelector } from 'reselect';
import * as R from 'ramda';
import { isNotNil } from 'lib/utils';
import { getTransactions } from 'shared-selectors/transactions';
import { getPtSData } from './pts';

export function getAllAccounts(state) {
  return state.models?.accounts ?? [];
}

export function getAllSettlements(state) {
  return (
    state.models?.settlements?.map(settlement => {
      return {
        ...settlement,
        totalPayments:
          settlement.numberPaymentsRemaining + settlement.numberPaymentsPaid ??
          settlement.numberOfPayments
      };
    }) ?? []
  );
}

export const getAllAccountsById = createSelector(getAllAccounts, accounts => {
  const accountsObj = {};
  accounts.forEach(account => {
    accountsObj[account.accountId] = account;
  });
  return accountsObj;
});

export const getAllSettlementsById = createSelector(
  getAllSettlements,
  settlements => {
    const settlementsObj = {};
    settlements.forEach(settlement => {
      settlementsObj[settlement.settlementId] = settlement;
    });
    return settlementsObj;
  }
);

export function getLifecycleMarketingSegment(state) {
  return state.models?.client?.lifecycleMarketingSegment;
}

export function getLifecycleMarketingSegmentTag(state) {
  const segment = getLifecycleMarketingSegment(state);
  const matches = segment?.match(/\d+/g);
  if (!Array.isArray(matches) || matches.length !== 1) {
    return null;
  }
  return `mxLcmSegment${matches[0]}`;
}

export function getClient(state) {
  return state.models?.client;
}

const encodeCreditorName = account => {
  const { originalCreditor, creditorName } = account;
  // Being extra defensive here (see CX-7164) because of reports of originalCreditor being null or undefined in actual client data
  let creditor = originalCreditor ?? creditorName ?? '';

  const normalized = creditor.toLowerCase().replace(/\s/g, '-');
  return encodeURIComponent(normalized);
};

export const getAccountsInProgram = createSelector(getAllAccounts, accounts =>
  accounts
    .filter(R.propSatisfies(isNotNil, 'negotiationStatus'))
    .filter(
      R.propSatisfies(
        R.complement(R.test(/removed from program/i)),
        'negotiationStatus'
      )
    )
    .map(account => ({
      ...account,
      tag: `${encodeCreditorName(account)}-${account.accountNumber}`
    }))
);

export const getAccountsWithSettlements = createSelector(
  [getAccountsInProgram, getAllSettlements],
  (accounts, settlements) =>
    accounts.map(account => ({
      ...account,
      ...R.pipe(
        R.find(R.propEq('settlementId', account.settlementId)),
        R.omit(['account'])
      )(settlements)
    }))
);

export const getAccountByTag = createSelector(
  getAccountsWithSettlements,
  (_, accountTag) => accountTag,
  (accounts, accountTag) => R.find(R.propEq('tag', accountTag))(accounts)
);

export const getDebtsInNegotiation = createSelector(
  [getAccountsWithSettlements, getLifecycleMarketingSegment],
  (accounts, lifecycleMarketingSegment) => {
    const sorter = /4/i.test(lifecycleMarketingSegment)
      ? R.sortWith([R.descend(R.prop('clientApprovalDeclineDate'))])
      : R.pipe(
        R.map(({ accountPriority, ...rest }) => ({
          ...rest,
          accountPriority: accountPriority === 7 ? -7 : accountPriority
        })),
        R.sortWith([
          R.ascend(R.prop('accountPriority')),
          R.ascend(R.prop('settlementReadiness')),
          R.ascend(R.prop('financialReadiness'))
        ])
      );
    return R.pipe(
      R.filter(
        account =>
          R.isNil(account.settlementId) ||
          R.test(/authorization needed/i, account.negotiationsActivity)
      ),
      sorter
    )(accounts);
  }
);

export const getDebtsWithSettlements = createSelector(
  getAccountsWithSettlements,
  accounts => {
    return R.pipe(
      R.filter(
        account =>
          isNotNil(account.settlementId) &&
          R.complement(R.test(/authorization needed/i))(
            account.negotiationsActivity
          )
      ),
      R.sortWith([R.descend(R.prop('clientApprovalDeclineDate'))])
    )(accounts);
  }
);

export const getHighlightedDebt = createSelector(
  getPtSData,
  (ptsData) => {
    if (ptsData && ptsData.nextSettlements?.length > 0) {
      return ptsData.nextSettlements[0];
    }

    return null;
  });

export const selectEnrollNewDebtEnabled = createSelector(
  getClient,
  R.pipe(R.prop('cplusLeadPhase'), R.test(/(3|4)/i))
);

export const getSettlementTransactions = createSelector(
  getAllSettlements,
  getTransactions,
  (_, settlementId) => settlementId,
  (settlements, transactions, settlementId) => {
    const settlement = settlements.find(
      sett => sett.settlementId === settlementId
    );
    if (settlement) {
      return transactions.filter(trans => {
        if (
          (trans.relatedSettlement &&
            settlement.name === trans.relatedSettlement) ||
          (trans.relatedScheduleRecord &&
            trans.relatedScheduleRecord.indexOf('a0B') === 0 &&
            settlement.settlementId === trans.relatedScheduleRecord)
        ) {
          return true;
        }
        return false;
      });
    } else {
      return [];
    }
  }
);
