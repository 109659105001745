import React, { useEffect } from 'react';
import {
  Drawer,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  Divider,
  Typography
} from '@achieve/sunbeam';
import { LessonsList } from '../LessonsList';
import { TaskList } from '../TaskList';
import { useClientEducation } from 'lib/hooks';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import Nav from '../../DrawerNav/ClientEdu/Nav';

export const EduDrawer = () => {
  const { eduOpen, eduTab, setEduTab, closeDrawer } = useClientEducation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const drawerWidth = matches ? 'auto' : 450;

  // Prevent parent scrolling while edu module is open
  useEffect(() => {
    if (eduOpen) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = '';
    }
  }, [eduOpen]);

  // to add a tab/component, just append to tabList
  const tabList = [
    {
      index: 0,
      label: 'Lessons',
      component: <LessonsList />
    },
    { index: 1, label: 'To Dos', component: <TaskList /> }
  ];

  const handleTabChange = ({ index, label }) => {
    label = `client_edu_v1_1_${label.toLowerCase().replaceAll(' ', '_')}`;

    setEduTab(index);
    analyticsTrackEvent(
      {
        category: 'edu',
        action: 'tab_click',
        label
      },
      'tab clicked'
    );
  };

  return (
    <Drawer
      data-testid={`client-orientation-module-${eduOpen ? 'open' : 'closed'}`}
      anchor="right"
      hideBackdrop={false}
      open={eduOpen}
      variant="persistent"
      PaperProps={{
        sx: {
          bgcolor: `${theme.sb.colors.neutral.grey[8]} !important`
        }
      }}
      sx={{
        zIndex: 100,
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          width: drawerWidth
        }
      }}
    >
      <Nav
        title={
          <Typography variant="displayS20" fontWeight="bold" textAlign="center">
            New client orientation
          </Typography>
        }
        backAction={closeDrawer}
        testId="drawer-back-btn"
      />
      <Tabs
        sx={{
          borderBottom: 1,
          borderColor: theme.sb.colors.neutral.grey[5],
          bgcolor: theme.sb.colors.neutral.white
        }}
        value={eduTab}
        aria-label="basic tabs example"
        centered
        variant="fullWidth"
      >
        {tabList.map((tabItem, i) => (
          <Tab
            data-testid={`${tabItem.label}-tab`}
            key={i}
            label={
              <Typography sx={{ textTransform: 'none' }}>
                {tabItem.label}
              </Typography>
            }
            wrapped
            onClick={() => handleTabChange(tabItem)}
          />
        ))}
      </Tabs>
      {tabList[eduTab] ? tabList[eduTab].component : null}
      <Divider />
    </Drawer>
  );
};
