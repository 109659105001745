import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Composition } from 'atomic-layout';
import 'styled-components/macro';
import React, { useState } from 'react';
import { Icon, LoadingButton } from '@ffn/ui';
import { CustomPanel } from '../../CustomTabs';
import { DashTextArea } from 'shared-components';
import { onSubmit } from '../submitHandlers';
import {
  AttachedFiles,
  Dropzone,
  ErrorHeading,
  Heading,
  SuccessHeading,
  SuccessMessage,
  UtilityButtons
} from '../shared-components';
import { useTranslation } from 'lib/hooks';
import { Upload } from 'react-feather';

const areas = `
  Description
  UploadFiles
  AddFileMetadata
  Notes
  UploadToVault
  Utilities
`;

export function MiscellaneousDocuments({
  resetDocumentVault,
  path,
  reloadDocs
}) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const {
    fdrApplicantId,
    lawFirmId,
    firstName,
    lastName,
    email,
    csClientId
  } = useSelector(state => state.userRecord);

  const { t } = useTranslation();

  const userInfo = {
    fdrApplicantId,
    lawFirmId,
    fullName: `${firstName} ${lastName}`,
    email,
    csClientId
  };

  const handleSuccess = ({ actions }) => {
    setSuccess(true);
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleFailure = ({ e }) => {
    const message = e.message || t('docUpload.submissionFailed');
    setError(message);
  };

  const handleReset = () => {
    setError(null);
    setSuccess(null);
    if (reloadDocs) {
      reloadDocs();
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      actions.setSubmitting(true);
      const res = await onSubmit(
        values,
        actions,
        userInfo,
        'Miscellaneous Documents Document Upload'
      );

      if (res?.error) {
        return handleFailure({ e: res.error });
      }

      handleSuccess({ actions });
    } catch (e) {
      handleFailure({ e });
    }
  };

  return (
    <Formik initialValues={{ notes: '', files: [] }} onSubmit={handleSubmit}>
      {({
        values: { files },
        isSubmitting,
        isValid,
        setFieldValue,
        resetForm
      }) => {
        const isSuccessfulUpload =
          files.length && files.every(({ status }) => status === 'submitted');
        return (
          <CustomPanel
            justifyContent={success || error ? 'center' : 'space-between'}
            heading={
              success ? (
                <SuccessHeading />
              ) : error ? (
                <ErrorHeading error={error.message} />
              ) : (
                <Heading
                  title={t('docUpload.uploadMiscellaneousDocumentS')}
                  resetDocumentVault={resetDocumentVault}
                />
              )
            }
          >
            <Composition
              as={Form}
              areas={isSuccessfulUpload ? 'SuccessWindow' : areas}
              gap={24}
              autoComplete="off"
            >
              {({
                Description,
                UploadFiles,
                AddFileMetadata,
                Notes,
                UploadToVault,
                Utilities,
                SuccessWindow
              }) => (
                <>
                  {success ? (
                    <SuccessMessage
                      as={SuccessWindow}
                      files={files}
                      resetForm={resetForm}
                      path={path}
                      reloadDocs={reloadDocs}
                      handleReset={handleReset}
                    />
                  ) : (
                    <>
                      <Description>
                        {t('docUpload.miscellaneousDocumentSDescription')}
                      </Description>
                      <UploadFiles>
                        <Dropzone
                          document_type={'customer_upload'}
                          doctypeTitle={t('docUpload.miscellaneousDocumentS')}
                          setUploadedFiles={acceptedFiles =>
                            setFieldValue('files', files.concat(acceptedFiles))
                          }
                          files={files}
                        />
                      </UploadFiles>
                      <AddFileMetadata>
                        <AttachedFiles
                          uploadedFiles={files}
                          disclaimer={false}
                        />
                      </AddFileMetadata>
                      <Notes>
                        <DashTextArea
                          id="notes"
                          name="notes"
                          label={t('docUpload.notes')}
                          style={{ width: '100%' }}
                        />
                      </Notes>
                      <UploadToVault>
                        <LoadingButton
                          style={{
                            border: 'none'
                          }}
                          data-testid="upload-to-vault-button"
                          variant={
                            !isValid || !files.length ? 'disabled' : 'primary'
                          }
                          type="submit"
                          isLoading={isSubmitting}
                          disabled={
                            !isValid ||
                            !files.length ||
                            files.every(({ status }) => status === 'submitted')
                          }
                          widthMd="50%"
                          width="100%"
                          rightEnhancer={<Icon icon={Upload} size={24} />}
                        >
                          {t('docUpload.uploadToVault')}
                        </LoadingButton>
                      </UploadToVault>
                      <UtilityButtons
                        as={Utilities}
                        resetForm={resetForm}
                        resetDocumentVault={resetDocumentVault}
                      />
                    </>
                  )}
                </>
              )}
            </Composition>
          </CustomPanel>
        );
      }}
    </Formik>
  );
}

export default MiscellaneousDocuments;
