export function PlanSvg() {
  return (
    <svg
      aria-hidden="true"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4516_3476)">
        <path
          d="M19.7761 8.86523H1.22386C0.547941 8.86523 0 9.41318 0 10.0891V33.3352C0 34.0112 0.547941 34.5591 1.22386 34.5591H19.7761C20.4521 34.5591 21 34.0112 21 33.3352V10.0891C21 9.41318 20.4521 8.86523 19.7761 8.86523Z"
          fill="url(#paint0_radial_4516_3476)"
        />
        <path
          d="M18.473 10.9962H2.51965V32.2986H18.473V10.9962Z"
          fill="white"
        />
        <path
          d="M14.2399 8.05176H6.75277C6.37505 8.05176 6.06885 8.35796 6.06885 8.73568V10.9674C6.06885 11.3451 6.37505 11.6513 6.75277 11.6513H14.2399C14.6176 11.6513 14.9238 11.3451 14.9238 10.9674V8.73568C14.9238 8.35796 14.6176 8.05176 14.2399 8.05176Z"
          fill="#3300FF"
        />
        <path
          d="M10.4316 9.39801C11.3659 9.39801 12.1234 8.63734 12.1234 7.69901C12.1234 6.76067 11.3659 6 10.4316 6C9.49719 6 8.73975 6.76067 8.73975 7.69901C8.73975 8.63734 9.49719 9.39801 10.4316 9.39801Z"
          fill="#3300FF"
        />
        <path
          d="M11.0579 24.9626L9.11414 26.9208L4.08911 21.8742L6.03289 19.916L11.0579 24.9626Z"
          fill="#3300FF"
        />
        <path
          d="M9.12139 26.9136L7.17041 24.9554L14.9671 17.1299L16.9109 19.0881L9.12139 26.9136Z"
          fill="#3300FF"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_4516_3476"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.6112 10.0747) scale(28.0408 28.0408)"
        >
          <stop stop-color="#3B6AF5" />
          <stop offset="1" stop-color="#19AFE0" />
        </radialGradient>
        <clipPath id="clip0_4516_3476">
          <rect
            width="21"
            height="28.5591"
            fill="white"
            transform="translate(0 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
