export const analyticsTrackEvent = ({ category, action, label, value }) => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer.push({
    event: 'eventTracking',
    category,
    action,
    label,
    value
  });
};
