import { axiosInstance as axios } from 'lib/axios';

export const alertResolve = async payload => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/alertResolve`;
  try {
    const response = await axios.put(apiUrl, payload, {
      validateStatus: status => status === 200
    });
    return response.data;
  } catch (error) {
    throw new Error('ALERT_RESOLVE_FAILURE');
  }
};
