import { Breadcrumbs, BreadcrumbsItem, Link } from '@ffn/ui';
import { Link as RouterLink } from 'react-router-dom';
import { Composition } from 'atomic-layout';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'lib/hooks';
import { useWebviewAuth } from '@achieve/cx-auth';

const BreadCrumbs = ({ title, breadCrumbs, children, ...props }) => {
  const videoPage = window.location.pathname.split('/').includes('videos');
  const { t } = useTranslation();
  const { isWebview } = useWebviewAuth();

  if (isWebview) return null;

  return (
    <Composition
      areas={`
        crumbs
      `}
      css={`
        background: ${({ theme }) => theme.colors.grayLight};
        padding-bottom: 40px;
      `}
      {...props}
    >
      {({ Crumbs }) => (
        <>
          <Crumbs>
            <Breadcrumbs>
              {R.init(breadCrumbs).map((crumb, i) => (
                <BreadcrumbsItem key={i}>
                  <Link as={RouterLink} to={crumb.to}>
                    {t('nav', crumb.label)}
                  </Link>
                </BreadcrumbsItem>
              ))}
              {R.last(breadCrumbs) && (
                <BreadcrumbsItem>
                  {/* for videos the final breadcrumb is the video title, which contentful can add language support. for other pages, the final breadcrumb is keyed to nav.json for language support*/}
                  {videoPage
                    ? R.last(breadCrumbs).label
                    : t('nav', R.last(breadCrumbs).label)}
                </BreadcrumbsItem>
              )}
            </Breadcrumbs>
          </Crumbs>
        </>
      )}
    </Composition>
  );
};

export { BreadCrumbs };
