import React from 'react';
import { NavLink } from 'react-router-dom';
import { css } from 'styled-components/macro';
import { query, Box } from 'atomic-layout';
import { Row, Column, Icon, NavTabPanel, NavTab, Heading } from '@ffn/ui';
import { Close } from '@ffn/icons';

export function CustomTab({ icon, label, to }) {
  return (
    <NavTab
      as={NavLink}
      to={to}
      id={label}
      css={`
        padding: 10px 8px;
      `}
    >
      <Row marginBottom={5} paddingTop={5}>
        <Column cols={12}>
          <Icon icon={icon} size={30} />
        </Column>
      </Row>
      <Row>
        <Column
          cols={12}
          css={`
            font-size: 11px !important;
            line-height: 16px !important;
            @media ${query({ from: 'md' })} {
              font-size: 14px !important;
              line-height: 20px !important;
            }
          `}
        >
          {label}
        </Column>
      </Row>
    </NavTab>
  );
}

export function CustomPanel({
  children,
  heading,
  close,
  justifyContent = 'space-between',
  ...props
}) {
  return (
    <NavTabPanel
      css={css`
        padding: 32px;
        border-top: none;
        background: ${({ theme }) => theme.colors.plain};
        @media ${query({ to: 'md' })} {
          padding: 8px;
          border: none;
        }
      `}
      {...props}
      data-testid="online-services-tab-content"
    >
      <Box
        flex
        justifyContent={justifyContent}
        alignItems="center"
        marginBottom={16}
      >
        <Heading
          as="h2"
          css={`
            font-weight: 700;
          `}
        >
          {heading}
        </Heading>
        {close && (
          <NavLink to="/customer-service">
            <Icon icon={Close} size={16} color="secondaryBlueLight" />
          </NavLink>
        )}
      </Box>
      {children}
    </NavTabPanel>
  );
}
