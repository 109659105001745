import { axiosInstance as axios } from 'lib/axios';

export const getDraftReductions = async () => {
  try {
    const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/draftReductions`;

    const response = await axios.get(endpoint, {
      validateStatus: status => status < 500
    });

    if (response?.data?.success === false && response?.data?.error) {
      throw new Error(response?.data?.error);
    } else if (response?.status !== 200) {
      throw new Error('ERROR_FETCHING_DRAFT_REDUCTIONS');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default getDraftReductions;
