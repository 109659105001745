import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Box, Composition } from 'atomic-layout';
import { Link, Button, Icon } from '@ffn/ui';
import { NavLink, useLocation } from 'react-router-dom';
import { DashNumberFormat } from 'shared-components';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import EFTAModal from 'shared-components/EFTAModal';
import { getTestIds } from 'lib/utils/dataTestIds';
import { CustomDatePicker } from './CustomDatePicker';
import { startOfMonth, endOfMonth } from 'date-fns/fp';
import { USD } from 'lib/utils';
import { useTranslation } from 'lib/hooks';

const increaseDepositFormIDs = getTestIds('increaseDepositForm');

export function Form({
  minAmount,
  maxAmount,
  earliestDraftChangeDate,
  lastScheduledDraftDate,
  nextStep,
  goToSelect,
  draftTypeLabel
}) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { values, isValid, initialValues, setValues } = useFormikContext();
  const { t } = useTranslation();

  return (
    <>
      <Box style={{ fontWeight: 400 }}>
        {t('customerService.increaseProgramDeposit.increaseYour')}{' '}
        {draftTypeLabel}{' '}
        {t(
          'customerService.increaseProgramDeposit.depositToAccelerateYourProgram'
        )}
      </Box>
      <Composition gap={16} widthMd="50%">
        <DashNumberFormat
          prefix={'$'}
          thousandSeparator
          allowNegative={false}
          id="increaseAmount"
          name="increaseAmount"
          label={`${t(
            'customerService.increaseProgramDeposit.increaseDepositTo'
          )} (${USD.format(minAmount)} -
            ${USD.format(maxAmount)})`}
          useFloat
        />
        <CustomDatePicker
          id="startDate"
          name="startDate"
          label={t('changeDepositWizard.startDate')}
          dateFormat="MMM yyyy"
          selectsStart
          startDate={values.startDate}
          endDate={values.endDate}
          minDate={startOfMonth(earliestDraftChangeDate)}
          maxDate={endOfMonth(lastScheduledDraftDate)}
          showMonthYearPicker
        />
        <CustomDatePicker
          id="endDate"
          name="endDate"
          label={t('changeDepositWizard.endDate')}
          dateFormat="MMM yyyy"
          selectsEnd
          startDate={values.startDate}
          endDate={values.endDate}
          minDate={startOfMonth(earliestDraftChangeDate)}
          maxDate={endOfMonth(lastScheduledDraftDate)}
          showMonthYearPicker
        />
      </Composition>
      <EFTAModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
      <label style={{ display: 'flex', alignItems: 'center' }}>
        <Box
          style={{
            height: 18,
            width: 18,
            padding: '3px 8px 3px 3px'
          }}
        >
          <Field
            id="acknowledged"
            type="checkbox"
            name="acknowledged"
            style={{ height: 18, width: 18, margin: 0, marginRight: '8px' }}
          />
        </Box>
        <span>
          {t('customerService.increaseProgramDeposit.iHaveReadAndAgreeToThe')}{' '}
          <Link
            onClick={event => {
              event.preventDefault();
              setIsOpen(true);
            }}
          >
            {t(
              'customerService.increaseProgramDeposit.electronicFundsTransferAuthorization'
            )}
          </Link>
          .
        </span>
      </label>
      <Composition gap={16} widthMd="50%">
        <Button
          data-testid={increaseDepositFormIDs.reviewDepoistButton}
          disabled={!isValid}
          onClick={nextStep}
          variant="primary"
          height={40}
          width="100%"
          rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
        >
          {t('customerService.increaseProgramDeposit.reviewDepositIncrease')}
        </Button>
      </Composition>
      <Box flex justifyContent="center">
        {/customer-service/i.test(location.pathname) ? (
          <Link as={NavLink} to="..">
            {t('changeDepositWizard.back')}
          </Link>
        ) : (
          <Link onClick={goToSelect}>{t('changeDepositWizard.back')}</Link>
        )}
        <Box paddingHorizontal={8}>|</Box>
        <Link onClick={() => setValues({ ...initialValues })}>
          {t('changeDepositWizard.reset')}
        </Link>
      </Box>
    </>
  );
}
