import React, { useState, useMemo } from 'react';
import 'styled-components/macro';
import { Formik } from 'formik';
import * as yup from 'yup';
import { format, parse, getYear, differenceInYears, isBefore } from 'date-fns';
import { Column, Row, Icon, FormMessage, LoadingButton } from '@ffn/ui';
import { ContactQuestions, DashForm, DashInput } from 'shared-components';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { Box } from 'atomic-layout';
import { alertUpdate, refreshNotifications } from 'lib/api';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'lib/hooks';

/**
 * TODO:
 * At some point, we should centralize these RegExes into a single file so we can be
 * confident that we're using the same client-side validation patterns across the entire app
 */
const phoneRegExp = /^(\d{3}-|\(\d{3}\) )\d{3}-\d{4}$/;
const nameReqExp = /^([a-zA-Z]|-|_)+ ([a-zA-Z]|-|_)+$/;
const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

const isValidDate = dateStr => {
  const parsedDate = parse(dateStr, 'MM/dd/yyyy', new Date());
  return !(
    parsedDate.toString() === 'Invalid Date' ||
    !dateRegex.test(dateStr) ||
    getYear(parsedDate) < 1900
  );
};

// Helper function to transform the request reasons to a string suitable for use as an object key
const keyify = reasonsArray => {
  return reasonsArray.map(request => {
    // In this instance, we may get back an undefined value for requestReason, which means we're dealing with an 'Last Payment Date Verification' request
    const reason =
      request?.requestReason?.toLowerCase().replace(/( |-)/g, '') ||
      'lastpaymentdateverification';
    return {
      ...request,
      requestReason: reason
    };
  });
};

const buildUpdatePayload = (formValues, sfAccountId, alertIds) => {
  const alertUpdates = [];

  alertIds.forEach(({ alertId, requestReason }) => {
    /**
     * This is the basic shape for the information request update payload.
     * We will change the values here if we're dealing with a 'Last Payment Date Verification' request
     * because the `request_answer` field needs to be `last_Payment_Date`
     */
    let update = {
      request_answer: formValues[requestReason],
      alert_id: alertId,
      related_debtaccount: sfAccountId
    };

    if (requestReason === 'lastpaymentdateverification') {
      const rawLastPaymentDate = formValues[requestReason];
      const parsedDate = parse(rawLastPaymentDate, 'MM/dd/yyyy', new Date());
      /** Reformat the date here, because the API expects a YYYY-MM-DD format, and the form takes in a MM/DD/YYYY format */
      const formattedLastPaymentDate = format(parsedDate, 'yyyy-MM-dd');
      /** Rename the 'request_answer' field to 'last_Payment_Date' (not a typo)
       * for a Last Payment Date Verification request
       */
      update = { ...update, last_Payment_Date: formattedLastPaymentDate };
      // Removing the unneeded 'request_answer' field
      delete update.request_answer;
    }

    alertUpdates.push(update);
  });

  return alertUpdates;
};

/**
 * Our basic InputField that we'll render inside our form
 * We dynamically create an array of InputFields to render based on the request reasons array
 * passed to <InformationRequestForm />
 */
function InputField({ label, keyName }) {
  return (
    <DashInput
      id={keyName}
      name={keyName}
      label={label}
      data-test-id={`${keyName}-input`}
    />
  );
}

/**
 * The core InformationRequestForm component that gets rendered for an `InformationRequest` or `Last Payment Date Verification` alert.
 */
export function InformationRequestForm({
  requestReasons,
  sfAccountId,
  successCallback
}) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const errorMessages = {
    UNHANDLED_ERROR: t('common.form.validationsAndErrors.somethingWentWrong')
  };

  /**
   * Create inputComponents and validation reasons for each request field
   * Memoizing the result because we don't need to re-calculate this on every render
   */
  const [
    inputComponents,
    validationObject,
    alertIds,
    initialValues
  ] = useMemo(() => {
    /**
     * The 'text' field is unused currently, but had been displayed in 3.0 as
     * directions for filling out the form. The UI lib for 4.0 doesn't have a component for this.
     */
    const formFieldData = {
      accountholdername: {
        text: t('alerts.informationRequest.theNameOfTheAccountHolderAsIt'),
        label: t('alerts.informationRequest.accountHolderName')
      },
      coapplicantname: {
        text: t('alerts.informationRequest.theNameOfTheCoApplicantFor'),
        label: t('alerts.informationRequest.coApplicantName')
      },
      coapplicantssn: {
        text: t('alerts.informationRequest.theSocialSecurityNumberOf'),
        label: t('alerts.informationRequest.coApplicantSsn')
      },
      coapplicantdob: {
        text: t('alerts.informationRequest.theDateOfBirthOfTheCoA'),
        label: t('alerts.informationRequest.coApplicantDateOfBirth')
      },
      branchnumber: {
        text: t('alerts.informationRequest.theBranchNumberOrName'),
        label: t('alerts.informationRequest.branchNumberOrName')
      },
      medicalservicedate: {
        text: t('alerts.informationRequest.theDateOfServiceWith'),
        label: t('alerts.informationRequest.medicalServiceDate')
      },
      medicalfacilitycontactnumber: {
        text: t('alerts.informationRequest.thePhoneNumberForTheMedical'),
        label: t('alerts.informationRequest.medicalFacilityPhoneNumber')
      },
      lastpaymentdateverification: {
        text: t('alerts.informationRequest.theLastDateAPaymentWas'),
        label: t('alerts.informationRequest.lastPaymentDate')
      }
    };
    const validations = {
      accountholdername: yup
        .string()
        .trim()
        .required(
          t('alerts.informationRequest.accountHolderNameIsARequiredField')
        )
        .test(
          'numNotAllowed',
          t('alerts.informationRequest.fullNameIsRequired'),
          value => nameReqExp.test(value)
        ),
      coapplicantname: yup
        .string()
        .trim()
        .required(
          t('alerts.informationRequest.coApplicantNameIsARequiredField')
        )
        .test(
          'numNotAllowed',
          t('alerts.informationRequest.fullNameIsRequired'),
          value => nameReqExp.test(value)
        ),
      coapplicantssn: yup
        .string()
        .matches(ssnRegex, t('alerts.informationRequest.pleaseEnterValid_9'))
        .required(
          t('alerts.informationRequest.coApplicantSsnIsARequiredField')
        ),
      coapplicantdob: yup
        .string()
        .required(t('alerts.informationRequest.coApplicantDobIsARequiredField'))
        .test('DOB', t('alerts.informationRequest.pleaseEnterValidCo'), value =>
          isValidDate(value)
        )
        .test(
          'DOB',
          'Co-Applicant must be greater than 18 years old',
          value => {
            const parsedDate = parse(value, 'MM/dd/yyyy', new Date());
            const yearDiff = differenceInYears(new Date(), parsedDate);
            return yearDiff >= 18;
          }
        ),
      medicalservicedate: yup
        .string()
        .required(
          t('alerts.informationRequest.medicalServiceDateIsARequiredField')
        )
        .test(
          'Medical Service Date',
          t('alerts.informationRequest.pleaseEnterValidMedical'),
          value => isValidDate(value)
        )
        .test(
          'Medical Service Date',
          t('alerts.informationRequest.pleaseEnterAValid'),
          value => {
            const parsedDate = parse(value, 'MM/dd/yyyy', new Date());
            return isBefore(parsedDate, new Date());
          }
        ),
      lastpaymentdateverification: yup
        .string()
        .required(
          t('alerts.informationRequest.lastPaymentDateIsARequiredField')
        )
        .test(
          'Last Payment Date',
          t('alerts.informationRequest.pleaseEnterValidLast'),
          value => isValidDate(value)
        )
        .test(
          'Last Payment Date',
          t('alerts.informationRequest.pleaseEnterAValidLast'),
          value => {
            const parsedDate = parse(value, 'MM/dd/yyyy', new Date());
            return isBefore(parsedDate, new Date());
          }
        ),
      branchnumber: yup
        .string()
        .required(t('alerts.informationRequest.branchNumberIsARequiredField')),

      medicalfacilitycontactnumber: yup
        .string()
        .matches(
          phoneRegExp,
          t('alerts.informationRequest.pleaseEnterValid_10')
        )
        .required(
          t(
            'alerts.informationRequest.medicalFacilityContactNumberIsRequiredField'
          )
        )
    };
    // Normalize the request reasons to an array of normalized strings
    const requestReasonsObjs = keyify(requestReasons);
    // An array of input components to render
    let formFields = [];
    let validationObject = {};
    let valueObject = {};

    requestReasonsObjs.forEach(({ requestReason, alertId }) => {
      const fieldData = formFieldData[requestReason];
      if (!fieldData) {
        // If the reason is not in the formFieldData, skip it
        console.info(`No form field data for ${requestReason}`);
        return;
      } else {
        const { text, label } = fieldData;
        // Add the input component to the form fields array
        formFields.push(
          <InputField
            key={requestReason}
            text={text}
            label={t('alerts.requestReasons', label)}
            keyName={requestReason}
          />
        );
        // Set some empty initial values for the form
        valueObject[requestReason] = '';
        // Add field validation
        validationObject[requestReason] = validations[requestReason];
      }
    });

    return [formFields, validationObject, requestReasonsObjs, valueObject];
  }, [requestReasons, t]);

  const handleError = err => {
    analyticsTrackEvent(
      {
        category: 'alert',
        action: 'failure',
        label: 'ain'
      },
      'Information Request Failure'
    );

    setError({ message: err.error_code ? err.error_code : err.message });
  };

  const handleSubmit = async (values, alertIds, actions) => {
    actions.setSubmitting(true);
    const payload = buildUpdatePayload(values, sfAccountId, alertIds);

    setError(null);

    try {
      /** 
       * Generally speaking, this request will always return a 200 if the APEX REST API could understand the payload
       * However, if the operation cannot be completed, it will still return a 200 but the response contains a `success` field
       * that we need to check to determine the UI to display for the user.
       * An example failure response will look like this:
        {
            "success_message": null,
            "success": false,
            "sessionid": null,
            "recordid": null,
            "error_type": null,
            "error_message": "Invalid Account Number",
            "error_code": "INVALID_ACCOUNT_NUMBER"
        }
      */
      const res = await alertUpdate(payload);
      if (res.success) {
        actions.setSubmitting(false);
        // Wait 7.5 seconds and then refresh notifications data in Firestore, which
        // should clear any notifications that prompted the user to take this action.
        setTimeout(() => {
          refreshNotifications(['accounts', 'alertTasks']);
        }, 7500);
        analyticsTrackEvent(
          {
            category: 'alert',
            action: 'success',
            label: 'ain'
          },
          'Information Request Success'
        );
        successCallback();
      } else {
        actions.setSubmitting(false);
        handleError(res);
      }
    } catch (error) {
      actions.setSubmitting(false);
      console.error(error);
      handleError(error);
    }
  };

  return (
    <>
      <Row>
        <Column cols={12} colsMd={6}>
          {error ? (
            <FormMessage variant="error">
              {errorMessages[error.message] || errorMessages.UNHANDLED_ERROR}
            </FormMessage>
          ) : null}
          <Formik
            /** INITIAL VALUES ARE DYNAMICALLY GENERATED  */
            initialValues={initialValues}
            /** VALIDATION SCHEMA IS DYNAMICALLY GENERATED  */
            validationSchema={yup.object().shape(validationObject)}
            onSubmit={(values, actions) => {
              handleSubmit(values, alertIds, actions);
            }}
            validateOnMount
          >
            {({ isValid, isSubmitting }) => (
              <DashForm>
                {/** INPUT COMPONENTS ARE DYNAMICALLY GENERATED */}
                {inputComponents}
                <LoadingButton
                  type="submit"
                  variant="primary"
                  height={40}
                  width="100%"
                  rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
                  isLoading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                  data-test-id="ir-submit-button"
                >
                  {t('alerts.informationRequest.update')}
                </LoadingButton>
              </DashForm>
            )}
          </Formik>
        </Column>
      </Row>
      <Row>
        <Column cols={12}>
          <Box flex flexDirection="column" marginTop={16}>
            <ContactQuestions />
          </Box>
        </Column>
      </Row>
    </>
  );
}
