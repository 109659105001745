import {
  getPageContent,
  getPermalinkMedia,
  findContent,
  getRandomContent
} from 'lib/api';
import { useLawFirmName } from './useLawfirmName';
import { useQuery } from 'react-query';
import { useLocale } from './useLocale';

/**
 * Contentful getEntries hook
 * @param {String} pageUrl - fields.pageUrl
 * @param {String} content_type - defaults to mxPage
 * @param {Object} options - destructured
 */
export const useContent = ({
  pageUrl,
  content_type = 'mxPage',
  ...options
}) => {
  const { locale } = useLocale();
  const lawFirmName = useLawFirmName();

  // Using pageUrl here as a query key for caching; Related to bug CX-5366
  const { data, error, isLoading } = useQuery(
    [pageUrl, locale],
    async () => {
      return _call(getPageContent, {
        ...options,
        pageUrl,
        brand: lawFirmName,
        content_type,
        locale
      });
    },
    {
      staleTime: Infinity
    }
  );

  return { pageData: data, error, loading: isLoading };
};

/**
 * Contentful find content hook
 * @param {String} content_type - defaults to mxPage
 * @param {Object} options - destructured
 */
export const useFindContent = ({ content_type = 'mxPage', ...options }) => {
  const lawFirmName = useLawFirmName();
  const { locale } = useLocale();

  // Using content_type+options here as a query key for caching; Related to bug CX-5366
  const { data, error, isLoading } = useQuery(
    [content_type, options, locale],
    async () => {
      return _call(findContent, {
        content_type,
        locale,
        ...options,
        brand: lawFirmName
      });
    }
  );

  return { items: data, error, loading: isLoading };
};

/**
 * Return random content from Contentful
 * @param {String} content_type - defaults to mxPage
 * @param {Object} options - destructured
 */
export const useRandomContent = ({ content_type = 'mxPage', ...options }) => {
  const lawFirmName = useLawFirmName();
  const { locale } = useLocale();

  const { data, error, isLoading } = useQuery(
    // array to prevent cache conflicts between content types
    ['useRandomContent', content_type, options, locale],
    async () => {
      return _call(getRandomContent, {
        content_type,
        locale,
        ...options,
        brand: lawFirmName
      });
    }
  );

  return { item: data, error, loading: isLoading };
};

export const usePermalinkMedia = ({
  pageUrl,
  content_type = 'mxPermalinkMedia',
  ...options
}) => {
  const { locale } = useLocale();
  const lawFirmName = useLawFirmName();
  // Using pageUrl here as a query key for caching; Related to bug CX-5366
  const { data, error, isLoading } = useQuery([pageUrl, locale], async () => {
    return _call(getPermalinkMedia, {
      pageUrl,
      brand: lawFirmName,
      content_type,
      // so we can override brand, which comes in handy for CX-5699
      ...options
    });
  });

  return { url: data, error, loading: isLoading };
};

const _call = async (fn, options) => {
  try {
    return fn(options);
  } catch (e) {
    console.error(e);
    return e?.message || e;
  }
};
