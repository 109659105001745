import { Composition } from 'atomic-layout';
import { Container, Heading } from '@ffn/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { RailSidebar } from 'shared-components';
import { BreadCrumbs } from './BreadCrumbs';

const WithSidebar = ({
  children,
  sidebarFeatures = [],
  breadCrumbs = [],
  pageTitle,
  ...props
}) => {
  return (
    <Container paddingHorizontal={15}>
      <Composition
        areas={`
          title
          content
          sidebar
        `}
        areasLg={`title title
         content sidebar`}
        templateCols="100%"
        templateColsLg="1fr 300px"
        templateRows="auto 1fr auto"
        gapRowLg={0}
        gapColLg={30}
        {...props}
      >
        {({ Content, Sidebar, Title }) => (
          <>
            <Title data-testid="page-title" marginBottom={0}>
              <Heading>{pageTitle}</Heading>
              {breadCrumbs.length > 0 ? (
                <BreadCrumbs
                  data-testid="bread-crumbs"
                  breadCrumbs={breadCrumbs}
                ></BreadCrumbs>
              ) : null}
            </Title>
            <Content data-testid="main-content">{children}</Content>
            <Sidebar>
              <RailSidebar sidebarFeatures={sidebarFeatures} />
            </Sidebar>
          </>
        )}
      </Composition>
    </Container>
  );
};

WithSidebar.propTypes = {
  children: PropTypes.any.isRequired,
  sidebarFeatures: PropTypes.array
};

export { WithSidebar };
