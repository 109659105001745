import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/*** Generic component used to reset scroll position to the top of the page
 * when the app's route changes
 */
export default function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // We're using a hash to manage scroll position of accordion tabs
    // on mobile, so we don't want to reset the scroll position when
    // the hash changes
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
}
