import React from 'react';
import { useSelector } from 'react-redux';
import { modelsStatus, userRecordStatus } from 'shared-selectors/dataStatus';
import { BootstrapLoader } from '../BootstrapLoader';
import { LoadingError } from '../LoadingError';
import { ReactTourProvider } from '../ProductTour';

export const ProtectedRoutesWrapper = ({ children, renderTourProvider }) => {
  const modelsLoadingStatus = useSelector(modelsStatus);
  const userRecordLoadingStatus = useSelector(userRecordStatus);

  const RENDER_LOADER_UI = modelsLoadingStatus === 'loading';
  const RENDER_ERROR_UI =
    modelsLoadingStatus === 'error' || userRecordLoadingStatus === 'error';

  if (RENDER_LOADER_UI) {
    return <BootstrapLoader />;
  } else if (RENDER_ERROR_UI) {
    return <LoadingError />;
  } else {
    return renderTourProvider ? (
      <ReactTourProvider>{children}</ReactTourProvider>
    ) : (
      children
    );
  }
};
