import { getAppSubDomain } from 'shared-components';

export function useBrandAwareNavigation() {
  const subdomain = getAppSubDomain();
  const rootPath = getRootPath(subdomain);

  const assignRoute = relativePath => {
    // Remove a leading slash if one exists, but only if it's followed by one or more characters
    // This allows us to pass `/` as relativePath when we want to redirect to the root route
    // which can be different based on different brands.
    const routeSegment = relativePath.replace(/^\/(?=.)/, '');
    const pathToAssign =
      routeSegment === '/' ? `${rootPath}` : `${rootPath}/${routeSegment}`;

    window.location.assign(pathToAssign);
  };

  return assignRoute;
}

function getRootPath(subdomain) {
  switch (subdomain) {
    case 'achieve':
      return '/dashboard/resolution';
    case 'fdr':
      return '/dashboard';
    case 'turnbull':
      return '';
    default:
      return '';
  }
}
