import React from 'react';
import { NavLink } from 'react-router-dom';
import { useBrand, useTranslation } from 'lib/hooks';

export const FormError = ({ error }) => {
  const { t } = useTranslation();
  const brand = useBrand();
  // Bank change form error messages
  const messages = {
    validateBank: t('bankAccountChange.pleaseEnterAValidBankName'),
    RATE_LIMIT_EXCEEDED: t('bankAccountChange.rateLimitExceeded'),
    INVALID_ACCOUNT_NUMBER: t('bankAccountChange.theAccountNumberEntered'),
    DUPLICATE_ACCOUNT_NUMBER: (
      <span>
        {t('bankAccountChange.theAccountNumberYouEntered')}{' '}
        <NavLink to="/customer-service" exact>
          {t('bankAccountChange.contactCustomerService')}
        </NavLink>{' '}
      </span>
    ),
    ERROR_INVALID_ROUTING_NUMBER: t('bankAccountChange.routingNumberInvalid'),
    BANK_ACCOUNT_CHANGE_30_DAY_ERROR: (
      <span>
        {t('bankAccountChange.itHasNotBeen_30Days')}{' '}
        {t('bankAccountChange.requestPlease')}{' '}
        <span className="link open-chat">{t('bankAccountChange.chat')}</span>
        {', '}
        {t('bankAccountChange.call')} {brand('contact.customer-service.phone')}
        {', '}
        {t('bankAccountChange.orSubmitAn')}{' '}
        <NavLink exact to="/customer-service">
          {t('bankAccountChange.email')}
        </NavLink>{' '}
        {t('bankAccountChange.requestToContactCustomer')}
      </span>
    ),
    BANK_ACCOUNT_CHANGE_GENERAL_ERROR: t(
      'bankAccountChange.thereWasAProblemWith'
    ),
    UNHANDLED_ERROR: t('bankAccountChange.somethingWentWrong')
  };

  if (error.type === 'validateBank') {
    return error.message
      ? // when there is a message, send error's string typecasted validation code to the t function
        t(`bankAccountChange.errors.${String(error?.validationCode)}`, {
          // in the case of the t key not being found, default to original message in English
          defaultValue: error.message
        })
      : messages[error.type];
  } else if (messages[error.code]) {
    return messages[error.code];
  } else {
    return messages.UNHANDLED_ERROR;
  }
};
