import React, { createContext, useMemo } from 'react';

export const dashboardTypeContext = createContext();

/**
 * Gets the sub domain of the URL
 * Requires hostname to be in /etc/hosts
 * @return {String} subdomain
 */
export function getAppSubDomain() {
  //for making it work in local as well as production fdr
  if (window.location.hostname.match(/^(www.)?freedomdebtrelief\.local$/i)) {
    return 'fdr';
  }
  // Check if the user is from achieve.com or www.achieve.com, and set their 'subdomain' to 'achieve'
  if (window.location.hostname.match(/^(www.)?achieve\.com$/i)) {
    return 'achieve';
  }

  // FDR Dashboard in production will be served from
  if (
    window.location.hostname.match(/^(www\.)?freedomdebtrelief\.(com|local)$/i)
  ) {
    return 'fdr';
  }

  // only take first part of the word, turnbull-dev -> turnbull
  const wholeWordRegex = new RegExp(/^\w+/);
  // split hostname
  const hostnameParts = window.location.hostname.split('.');
  // sanity check
  if (!Array.isArray(hostnameParts) || hostnameParts.length < 2) {
    return null;
  }

  // did they come in on www. ?
  if (hostnameParts[0] === 'www') {
    // if so is there not a subdomain? www.dashboard.com
    if (hostnameParts.length === 3) return null;
    // return subdomain
    const match = hostnameParts[1].match(wholeWordRegex);
    if (!Array.isArray(match) || match.length < 1) return null;
    return match[0];
  }
  // is there not a subdomain? dashboard.com
  if (hostnameParts.length === 2) return null;
  // return subdomain
  const match = hostnameParts[0].match(wholeWordRegex);
  if (!Array.isArray(match) || match.length < 1) return null;
  return match[0];
}

export function ProvideDashboardType({ children }) {
  const subDomain = getAppSubDomain();
  const value = useMemo(() => {
    return { subDomain };
  }, [subDomain]);

  return (
    <dashboardTypeContext.Provider value={value}>
      {subDomain && children}
    </dashboardTypeContext.Provider>
  );
}
