import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@achieve/sunbeam';
import { Close } from '@styled-icons/material/Close';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import updateFirestoreUserRecord from 'lib/api/updateFirestoreUserRecord';
import { getPtS, getUserRecord } from 'shared-selectors/userRecord';
import { logger } from 'shared-components';

import { getInfoCardContent } from '../assets/infoCardMap';
import styles from './InformationCard.module.scss';
import { useAppData } from 'lib/hooks';

export const InformationCard = ({ metadata, type }) => {
  const { fetchUserRecord } = useAppData();
  const ptsFireStoreData = useSelector(getPtS);
  const userRecord = useSelector(getUserRecord);
  const [isOpen, setIsOpen] = useState(false);

  const showCard = useMemo(
    () => !ptsFireStoreData?.permanentlyDisableShowCards?.[type],
    [ptsFireStoreData, type]
  );

  const cardData = useMemo(() => getInfoCardContent(type, metadata), [
    type,
    metadata
  ]);

  useEffect(() => {
    if (showCard) {
      setIsOpen(true);
      analyticsTrackEvent(
        {
          action: 'loaded',
          category: 'pts',
          label: type
        },
        `Information Card ${type} Loaded`
      );
    }
  }, [showCard, type]);

  const handleClose = useCallback(async () => {
    if (!cardData) return;

    setIsOpen(false);
    analyticsTrackEvent(
      {
        category: 'pts',
        action: 'closed',
        label: type
      },
      `Information Card ${type} Closed`
    );
    const fdrApplicantId = userRecord?.fdrApplicantId;
    if (fdrApplicantId) {
      try {
        await updateFirestoreUserRecord({
          pts: {
            ...ptsFireStoreData,
            permanentlyDisableShowCards: {
              ...ptsFireStoreData?.permanentlyDisableShowCards,
              [type]: true
            }
          }
        });
        fetchUserRecord();
      } catch (error) {
        logger.error(
          `Failed to update Firestore user fdrApplicantId ${fdrApplicantId}:`,
          error
        );
        alert('Failed to close the information block. Please try again later.');
      }
    }
  }, [cardData, userRecord, type, ptsFireStoreData, fetchUserRecord]);

  if (!isOpen || !cardData) {
    return null;
  }

  const { id, title, icon, content } = cardData;

  return (
    <Box
      className={styles.cardContainer}
      component="section"
      aria-labelledby={`information-card-title-${id}`}
      data-testid={`information-card-${id}`}
    >
      <Box
        className={styles.cardHeading}
        id={`information-card-title-${id}`}
        data-testid={`information-card-title-${id}`}
      >
        {icon}
        {title}
        <Close
          className={styles.closeIcon}
          data-testid="information-card-close-button"
          size={20}
          onClick={handleClose}
        />
      </Box>
      <Box
        className={styles.cardContent}
        data-testid="information-card-content"
      >
        {content}
      </Box>
    </Box>
  );
};
