import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Icon } from '@ffn/ui';
import { AlertTriangle, Mail, Trash2 } from 'react-feather';
import { useSelector } from 'react-redux';
import 'styled-components/macro';
import { Box, Composition } from 'atomic-layout';
import { TaskComponent } from './components/TaskComponent';
import { formatWithOptions, parseISO } from 'date-fns/fp';

import { isNil, pipe } from 'ramda';
import { useLocale, useTranslation } from 'lib/hooks';
import { markTrashed } from 'lib/api';

const alertComponentMap = {
  task: TaskComponent
};

const areas = `
	typeIcon title action
  space date action
`;

function ContentHeader({ notificationType, title, date, deleteMessage }) {
  const { t, alertsTitleNameTranslator } = useTranslation();
  return (
    <Composition areas={areas} templateCols="1fr 9fr 2fr" gapRow={8}>
      {({ Title, Date, Action, TypeIcon }) => (
        <>
          <TypeIcon>
            {notificationType === 'alerts' && (
              <Icon icon={AlertTriangle} size={20} color="secondaryRed" />
            )}
            {notificationType === 'messages' && <Icon icon={Mail} size={20} />}
          </TypeIcon>
          <Title as="strong">{alertsTitleNameTranslator(title, 'title')}</Title>
          <Date style={{ fontSize: 13 }}>
            {t('common.datetime.date')}
            {': '}
            {date}
          </Date>
          {notificationType === 'messages' && (
            <Action>
              <div
                css={`
                  text-align: right;
                `}
              >
                <Icon icon={Trash2} size={20} onClick={deleteMessage} />
              </div>
            </Action>
          )}
        </>
      )}
    </Composition>
  );
}

export function NotificationContent() {
  const [isResolved, setIsResolved] = useState(false);
  const { referenceId, notificationType } = useParams();
  const notifications = useSelector(
    state => state?.models?.notifications ?? []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { alertsTitleNameTranslator } = useTranslation();
  const { dateFnsLocale } = useLocale();

  // Checking to see if the notification matches the referenceId param from the URL
  const notification = notifications[notificationType]?.find(x =>
    x.referenceIds.includes(referenceId)
  );

  if (isNil(notification)) {
    return <Navigate to={'..'} replace />;
  }

  const type = Object.keys(alertComponentMap).filter(key =>
    new RegExp(key, 'i').test(notification.referenceType)
  );

  const AlertComponent = alertComponentMap[type];

  const trashMessage = async id => {
    console.log('trash message');
    markTrashed(id);
  };

  return (
    <Composition templateCols="1fr" templateRows="auto auto 1fr" padding={16}>
      <ContentHeader
        notificationType={notificationType}
        title={alertsTitleNameTranslator(notification.title, 'title')}
        date={pipe(
          parseISO,
          formatWithOptions({ locale: dateFnsLocale }, 'cccc h:mm a')
        )(notification.createdAt)}
        deleteMessage={() => trashMessage(notification.id)}
      />
      <Box
        height={1}
        marginVertical={16}
        css={`
          background: ${({ theme }) => theme.colors.grayRegular};
        `}
      />
      <AlertComponent
        notification={notification}
        viewLocation="notifications"
        isVisible={true}
        isSubmitting={isSubmitting}
        updateSubmittingState={setIsSubmitting}
        isResolved={isResolved}
        setIsResolved={setIsResolved}
      />
    </Composition>
  );
}
