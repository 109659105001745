import React from "react";
import NumberFormat from "react-number-format";

export const Currency = ({ value, ...props }) => (
	<NumberFormat
		value={value}
		displayType={"text"}
		thousandSeparator
		decimalScale={2}
		fixedDecimalScale
		prefix={"$"}
		{...props}
	/>
);

export const Percentage = ({ value, ...props }) => (
	<NumberFormat
		value={value}
		displayType={"text"}
		decimalScale={0}
		suffix={"%"}
		{...props}
	/>
);

export const PhoneNumber = ({ value, ...props }) => (
	<NumberFormat value={value} displayType={"text"} format="###-###-####" />
);