import { axiosInstance as axios } from 'lib/axios';

export const alertUpdate = async updates => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/alertUpdate`;

  try {
    const sfObj = {
      alertUpdates: updates
    };

    const response = await axios.post(apiUrl, sfObj, {
      validateStatus: status => status === 200
    });

    return response.data;
  } catch (error) {
    throw new Error('ALERT_UPDATE_FAILURE');
  }
};
