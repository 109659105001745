// Routes that have params need those params in the page name
// This function interpolates the params into the page name
// Example: Profile - profileId -> Profile - 1234
export const interpolateParams = (mapObj, pathname) => {
  // 'Photo - profileId - photoId'
  let name = mapObj.name;
  const pathnameParts = pathname.split('/');
  const mapPathnameParts = mapObj.pathname.split('/');
  // [], [profile], [collin], [photo], [17]
  // [], [profile], [:profileId], [photo], [:photoId]
  mapPathnameParts.forEach((mapPathnamePart, index) => {
    if (mapPathnamePart.charAt(0) !== ':') return;
    name = name.replace(
      `${mapPathnamePart.substring(1)}`,
      pathnameParts[index]
    );
  });
  return name;
};
