import { axiosInstance as axios } from 'lib/axios';

import { getLawFirmName } from 'lib/utils';
import getApiUrl from 'lib/utils/getApiUrl';

export const blogListing = async ({
  lawFirmId,
  category = '',
  limit = 10,
  skip = 0
}) => {
  const apiUrl = getApiUrl();
  const url = `${apiUrl}/content/blogListing`;
  let tag = category === '' ? '' : 'mxBlogCategory';
  category
    .split('-')
    .forEach(part => (tag += part.charAt(0).toUpperCase() + part.slice(1)));

  try {
    const response = await axios.get(url, {
      validateStatus: status => status >= 200 && status < 500,
      params: {
        brand: getLawFirmName(lawFirmId),
        category: tag,
        limit,
        skip
      }
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export default blogListing;
