import React from 'react';

import { AchieveLogo, FDRLogo, TLGLogo } from './Logos';
import { useBrand } from 'lib/hooks';

const brandLogoMap = {
  Achieve: isDesktop => <AchieveLogo isDesktop={isDesktop} />,
  FDR: isDesktop => <FDRLogo isDesktop={isDesktop} />,
  Turnbull: isDesktop => <TLGLogo isDesktop={isDesktop} />
};

export const BrandLogo = ({ isDesktop }) => {
  const brand = useBrand();
  const getLogoComponent = brandLogoMap[brand('business-name.abbr')];
  const logo = getLogoComponent(isDesktop);

  return logo;
};

export default BrandLogo;
