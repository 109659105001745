export function removalReasonMap(requestReason, t) {
  const REMOVAL_REASONS = {
    'Account Holder Reported as Deceased': t(
      'alerts.accountRemovalRequest.accountHolderReportedAsDeceased'
    ),
    'Being Garnished': t('alerts.accountRemovalRequest.beingGarnished'),
    'Duplicate Account': t('alerts.accountRemovalRequest.duplicateAccount'),
    'Already Paid Off': t('alerts.accountRemovalRequest.alreadyPaidOff'),
    'Creditor No Longer Collecting': t(
      'alerts.accountRemovalRequest.creditorNoLongerCollecting'
    ),
    'Included in a Bankruptcy': t(
      'alerts.accountRemovalRequest.includedInABankruptcy'
    ),
    'Client Not Drafting - Turn on Drafts or Remove Account.': t(
      'alerts.accountRemovalRequest.clientNotDrafting'
    )
  };

  // in case request reason does not map, the object key lookup will return undefined
  // return the original untranslated (english) requestReason text instead
  return REMOVAL_REASONS[requestReason] ?? requestReason;
}
