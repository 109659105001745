import React from 'react';
import 'styled-components/macro';
import {
  Accordion,
  AccordionItem,
  AccordionHead,
  AccordionBody,
  Icon
} from '@ffn/ui';
import { query } from 'atomic-layout';
import { useTranslation } from 'lib/hooks';

export function AccordionTabs({
  config,
  selectedId,
  onTabClick,
  translationPath,
  unlimitedHeight = false
}) {
  const { t } = useTranslation();
  const scrollToTopOfElement = id => {
    const element = document.getElementById(id);
    if (!element) return;

    const elementTop = element.getBoundingClientRect().top;
    var scrollPosition = window.scrollY + elementTop - 50;

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth'
    });
  };
  const tabClick = id => {
    if (typeof onTabClick === 'function') {
      onTabClick(id);
    }
    // Setting a timeout here so that the scroll happens after the accordion has opened
    // and we know the proper position of the relevant element
    setTimeout(() => scrollToTopOfElement(id), 150);
  };
  return (
    <Accordion>
      {config.map((tab, key) => {
        const isOpen = selectedId === tab.id;
        const maxHeightWhenOpen = unlimitedHeight ? 'none' : '400px';
        return (
          <AccordionItem
            key={key}
            id={tab.id}
            open={isOpen}
            css={`
              max-height: ${isOpen ? maxHeightWhenOpen : '56px'};
              overflow: ${isOpen ? 'scroll' : 'hidden'};
              padding-left: 0;
            `}
          >
            <AccordionHead
              onClick={() => tabClick(tab.id)}
              css={`
                background: ${props => props.theme.colors.primaryBlue};
                color: ${props => props.theme.colors.plain};
                padding-left: 12px;
                border-bottom: 1px solid ${props => props.theme.colors.plain};
                height: 55px;
                margin-bottom: 0;
                display: flex;
                align-items: center;

                .icon {
                  margin-right: 40px;
                }
              `}
              className="tab-headers"
            >
              {tab.icon && <Icon icon={tab.icon} size={30} className="icon" />}
              {translationPath ? t(translationPath, tab.title) : tab.title}
            </AccordionHead>
            <AccordionBody
              css={`
                padding: 16px;
                max-height: ${maxHeightWhenOpen};
                overflow: scroll;
                background-color: ${props => props.theme.colors.plain};

                @media ${query({ from: 'md' })} {
                }
              `}
            >
              {isOpen && tab.content}
            </AccordionBody>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}

export default AccordionTabs;
