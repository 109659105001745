import { useEffect } from 'react';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { Button } from '@achieve/sunbeam';
import { useTour } from '@reactour/tour';
import { useProductTour } from 'lib/hooks';
import styles from './productTour.module.scss';

export function TourStepContent({
  heading,
  body,
  prepareUI,
  cleanupUI,
  isLastStep
}) {
  const { setIsOpen } = useTour();
  const { permanentlyDisableProductTour } = useProductTour();
  /***
   * Certain steps may require some action to be performed on the DOM
   * For instance, hovering on a menu or adding a temporary node for that step
   * The prepareUI and cleanupUI props are functions that will be called, if provided
   */
  useEffect(() => {
    if (prepareUI && typeof prepareUI === 'function') {
      prepareUI();
    }
    return () => {
      if (cleanupUI && typeof cleanupUI === 'function') {
        cleanupUI();
      }
    };
  }, [prepareUI, cleanupUI]);

  /***
   * Super hacky here, but we're using the body prop change to trigger a re-render of the component
   * and firing a resize event on the window so that the tour repositions itself correctly on
   * every step. This is a workaround for a bug in the reactour library.
   * https://github.com/elrumordelaluz/reactour/issues/515
   */
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize', { bubbles: true, composed: true })
      );
    }, 1000);
  }, [body]);

  return (
    <div data-testid="tour-step-content" className={styles.popover}>
      <h3 data-testid="tour-step-heading">{heading}</h3>
      <div data-testid="tour-step-content">
        {body}
        {isLastStep && (
          <Button
            data-testid="tour-end-button"
            sx={{ marginBottom: '1rem' }}
            onClick={() => {
              setIsOpen(false);
              permanentlyDisableProductTour();
              analyticsTrackEvent(
                {
                  category: 'product_tour',
                  action: 'clicked',
                  label: 'product_tour_done'
                },
                'Product Tour Done'
              );
            }}
          >
            End Tour
          </Button>
        )}
      </div>
    </div>
  );
}
