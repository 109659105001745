import React from 'react';

export const CongratsSVG = () => {
  return (
    <svg
      width="92"
      height="88"
      viewBox="0 0 92 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="svgTitle svgDesc"
    >
      <title id="svgTitle">Congratulations!</title>
      <desc id="svgDesc">A celebratory image with confetti</desc>
      <g clipPath="url(#clip0_2193_3195)">
        <path
          d="M70.6906 55.2703L25.1464 16.1592L2.75912 79.053C2.34872 80.2021 3.53888 82.3772 5.65244 84.1932C7.75574 85.999 10.0745 86.8506 11.1518 86.2863L70.6906 55.2806V55.2703Z"
          fill="#154BD6"
        />
        <path
          d="M10.3315 88.0003C8.43336 88.0003 6.21721 86.7075 4.64743 85.3635C2.60569 83.609 0.512646 80.7773 1.30267 78.5406L23.7002 15.6468C23.8747 15.1646 24.2645 14.8055 24.757 14.6721C25.2495 14.5387 25.7728 14.6618 26.1524 14.9902L71.6862 54.1013C72.0761 54.4296 72.2711 54.9323 72.2095 55.4453C72.1479 55.9481 71.8401 56.3995 71.3887 56.6355L11.8602 87.6515C11.3882 87.8977 10.8752 88.0003 10.3315 88.0003ZM25.8446 18.7864L4.20625 79.5768C4.17547 79.8641 4.67821 81.3416 6.64812 83.0345C8.50518 84.635 10.1057 85.0146 10.4648 84.912L67.9516 54.9631L25.8446 18.7864Z"
          fill="#154BD6"
        />
        <path
          d="M20.1295 30.2461L17.5029 37.6128C23.6281 51.3099 34.2678 61.5801 49.945 66.074L56.4601 62.6779C39.685 56.2757 27.9476 45.0308 20.1192 30.2461H20.1295Z"
          fill="white"
        />
        <path
          d="M12.3112 52.2031L9.56152 59.9392C11.7572 67.9727 18.8366 74.1287 29.0247 76.981L35.5706 73.5747C24.377 70.8045 15.0814 62.8735 12.3215 52.2031H12.3112Z"
          fill="white"
        />
        <path
          d="M5.4582 71.4707L3.48828 76.9906C4.98624 81.2074 8.24892 84.2136 13.3174 85.1678L18.2114 82.6131C11.8194 81.2074 7.32552 77.4523 5.44794 71.4707H5.4582Z"
          fill="white"
        />
        <path
          d="M71.1289 54.9336C73.4392 52.2419 65.0781 41.276 52.4539 30.4404C39.8298 19.6049 27.723 13.003 25.4127 15.6947C23.1024 18.3863 31.4634 29.3523 44.0876 40.1878C56.7118 51.0234 68.8186 57.6253 71.1289 54.9336Z"
          fill="#21B0E8"
        />
        <path
          d="M69.4596 57.0356C63.1805 57.0356 50.5709 47.7708 43.0914 41.3481C36.9046 36.0436 31.5284 30.5238 27.9374 25.8144C23.7513 20.3253 22.5509 16.683 24.254 14.6823C28.3478 9.91143 44.5381 21.5976 53.4643 29.2618C62.3905 36.926 76.3954 51.1566 72.3016 55.9275C71.645 56.697 70.6703 57.0356 69.4596 57.0356ZM27.1987 16.5702C26.9216 16.5702 26.7062 16.6215 26.5728 16.7138C25.8443 18.2836 31.5284 27.3739 45.1024 39.019C58.5943 50.6026 68.4131 54.8605 69.9726 53.9166C70.66 52.2442 64.9657 43.1846 51.4738 31.6011C39.1721 21.0435 29.9278 16.5702 27.2089 16.5702H27.1987Z"
          fill="#154BD6"
        />
        <path
          d="M83.1158 0.440375C81.5768 0.902075 88.1535 6.45273 85.7732 12.9884C84.819 15.6149 81.3408 13.2962 79.5966 12.7113C76.5802 11.7059 69.747 11.8803 69.5829 15.8509C69.4905 18.0671 73.9536 20.1806 74.2512 22.5199C74.6616 25.7928 69.3469 20.6423 68.1157 20.0883C65.325 18.8468 60.8927 19.7805 60.1232 22.9508C59.7025 24.6745 58.461 33.0159 60.7798 32.6978C61.7032 32.5747 62.8831 32.0617 62.8523 31.8052C62.5445 29.5377 62.5445 27.2805 62.6266 24.9925C62.9857 14.9685 68.5569 26.5418 73.4817 24.9618C82.7567 21.9761 70.6499 18.1081 72.4659 14.0657C73.6766 11.3775 78.15 15.3379 80.7047 15.3174C89.8361 15.2558 90.3286 4.52385 86.6555 0.573755C86.163 0.0402349 84.9318 -0.0931451 83.1158 0.450635V0.440375Z"
          fill="#154BD6"
        />
        <path
          d="M17.7488 26.6655C15.5839 21.0327 5.26239 21.8638 2.97441 20.6223C-1.23219 18.3241 13.9321 17.6367 11.9519 13.0505C10.4232 9.51075 0.942927 8.13591 5.10849 4.37049C7.66323 2.06199 13.3267 2.03121 12.3315 0.974434C9.28431 -2.26772 -3.20211 3.29319 2.20491 7.13043C4.07223 8.45397 6.26787 9.14139 8.14545 10.3726C15.1222 15.1332 -3.95109 14.6305 1.06605 20.1401C4.61601 24.0389 13.4499 20.8275 15.0196 24.9828C16.138 27.9685 13.6858 30.4104 11.2337 31.8262C10.8951 32.0212 10.8541 32.4829 11.1516 32.7291C11.8185 33.2934 12.7009 34.1655 13.101 33.9706C15.8404 32.647 19.0313 29.9897 17.7591 26.6655H17.7488Z"
          fill="#154BD6"
        />
        <path
          d="M29.3943 4.71934C39.0387 3.31372 45.5846 13.7584 44.8048 22.4076C44.2405 28.6764 40.7214 34.4631 35.222 37.5718C35.2015 37.5821 36.8739 40.3113 37.0996 40.1779C45.2665 35.5609 49.1756 24.6237 45.2358 15.9643C42.3117 9.53128 36.1659 1.549 28.4299 2.02096C28.0605 2.04148 27.8348 2.44162 28.0092 2.76994C28.4299 3.55996 28.9839 4.79116 29.3841 4.7296L29.3943 4.71934Z"
          fill="#154BD6"
        />
        <path
          d="M51.7616 49.4839C55.3424 43.964 62.709 40.691 68.8753 39.1213C75.8931 37.3463 82.7981 38.0953 89.7852 39.3778C90.1032 39.4393 90.3187 39.0597 90.093 38.8135C89.5287 38.1979 88.831 37.4284 88.2051 37.3052C81.0539 35.9407 73.995 34.966 66.8028 36.6076C60.339 38.0747 52.5311 41.4195 48.7862 47.1856C48.581 47.5037 51.6488 49.6378 51.7411 49.4839H51.7616Z"
          fill="#154BD6"
        />
        <path
          d="M7.8025 40.2688L5.66504 45.0449L10.4412 47.1824L12.5786 42.4063L7.8025 40.2688Z"
          fill="#21B0E8"
        />
        <path
          d="M20.4822 10.7248L16.7822 14.4248L20.4822 18.1248L24.1822 14.4248L20.4822 10.7248Z"
          fill="#21B0E8"
        />
        <path
          d="M29.3132 6.34399L27.1758 11.1201L31.9519 13.2576L34.0894 8.48146L29.3132 6.34399Z"
          fill="#21B0E8"
        />
        <path
          d="M46.9076 1.96403L42.2021 4.25293L44.491 8.95836L49.1965 6.66946L46.9076 1.96403Z"
          fill="#21B0E8"
        />
        <path
          d="M49.9464 14.9207L47.9795 19.7695L52.8283 21.7365L54.7953 16.8876L49.9464 14.9207Z"
          fill="#21B0E8"
        />
        <path
          d="M69.3487 3.06686L65.7988 6.91113L69.6431 10.461L73.193 6.61674L69.3487 3.06686Z"
          fill="#21B0E8"
        />
        <path
          d="M78.8872 49.1152L73.7578 50.1494L74.7921 55.2788L79.9214 54.2445L78.8872 49.1152Z"
          fill="#21B0E8"
        />
        <path
          d="M85.9481 54.6505L82.54 58.6211L86.5106 62.0291L89.9186 58.0585L85.9481 54.6505Z"
          fill="#21B0E8"
        />
        <path
          d="M67.8265 60.9377L63.1211 63.2266L65.41 67.932L70.1154 65.6431L67.8265 60.9377Z"
          fill="#21B0E8"
        />
        <path
          d="M80.9619 63.8816L75.7891 63.0928L75.0003 68.2656L80.1731 69.0544L80.9619 63.8816Z"
          fill="#21B0E8"
        />
        <path
          d="M73.9051 73.4754L68.6934 73.9424L69.1603 79.1541L74.372 78.6871L73.9051 73.4754Z"
          fill="#21B0E8"
        />
        <path
          d="M91.7875 71.1865L87.5244 68.1523L84.4903 72.4155L88.7534 75.4496L91.7875 71.1865Z"
          fill="#21B0E8"
        />
        <path
          d="M10.3215 87.2298C8.73117 87.2298 6.70995 86.1217 5.15043 84.7777C3.14973 83.0642 1.41579 80.5506 2.03139 78.7961L24.429 15.9023C24.5726 15.5022 25.0138 15.2867 25.4139 15.4303C25.8141 15.574 26.0193 16.0152 25.8859 16.4153L3.47805 79.3091C3.25233 79.9555 4.05261 81.8023 6.14565 83.608C8.22843 85.4035 10.1881 85.9165 10.7934 85.5985L70.3322 54.5928C70.7118 54.3978 71.1735 54.5415 71.3685 54.9211C71.5634 55.3007 71.4198 55.7624 71.0401 55.9573L11.5116 86.9631C11.1628 87.1477 10.7626 87.2298 10.3317 87.2298H10.3215Z"
          fill="#154BD6"
        />
      </g>
      <defs>
        <clipPath id="clip0_2193_3195">
          <rect
            width="91.5705"
            height="88"
            fill="white"
            transform="translate(0.214844)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
