import * as Yup from 'yup';
import i18next from 'i18next';

const validationSchema = Yup.object().shape({
  files: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(
        i18next.t('common.form.validationsAndErrors.required')
      ),
      document_type: Yup.string()
        .typeError('Select a valid document type')
        .required(i18next.t('common.form.validationsAndErrors.required'))
        .notOneOf(
          ['Select legal document type'],
          'Select a valid document type'
        ),
      metadata: Yup.object()
        .shape({
          fdr_sf_debt_account_id: Yup.string().required(
            i18next.t('common.form.validationsAndErrors.required')
          ),
          creditor: Yup.string()
            .required(i18next.t('common.form.validationsAndErrors.required'))
            .notOneOf(
              [i18next.t('docUpload.selectCreditor')],
              i18next.t('docUpload.selectAValidCreditor')
            ),
          document_type: Yup.string().required(
            i18next.t('common.form.validationsAndErrors.required')
          ),
          methodOfService: Yup.string().when('document_type', {
            is: 'summons_and_complaints',
            then: Yup.string()
              .typeError('Select a valid method of service')
              .required(i18next.t('common.form.validationsAndErrors.required'))
              .notOneOf(
                ['Select method of service'],
                'Select a valid method of service'
              )
          }),
          dateOfService: Yup.string().when('document_type', {
            is: 'summons_and_complaints',
            then: Yup.string().required(
              i18next.t('common.form.validationsAndErrors.required')
            )
          })
        })
        .required()
    })
  )
});

export default validationSchema;
