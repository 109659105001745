import React from 'react';
import { Box, Button, Typography, useTheme } from '@achieve/sunbeam';
import { CustomerServiceHours } from './CustomerServiceHours';
import { RetrySVG } from '../assets/Retry';
import { PhoneLink } from './PhoneLink';
import { colors } from './constants';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'react-i18next';

export const TryAgain = ({ testIdPrefix, analyticsPayloads }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const primaryBrandColor =
    theme?.palette?.primary?.main ?? colors.primaryBlueColor;

  return (
    <Box
      data-testid={`${testIdPrefix}-try-again-container`}
      display="flex"
      flexDirection="column"
      gap="48px"
      alignItems="center"
      maxWidth="365px"
      mx="18px"
    >
      <RetrySVG />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="16px"
        width="365px"
      >
        <Typography
          variant="displayM30"
          fontWeight="500"
          fontSize="28px"
          textAlign="center"
          lineHeight="33.6px"
        >
          {t('oneClick.pleaseTryAgain')}
        </Typography>
        <Typography
          variant="bodyM30"
          fontWeight="300"
          fontSize="16px"
          textAlign="center"
          color={colors.secondaryGrayColor}
        >
          {t('oneClick.thereMayHaveBeenANetworkIssue')}
          <br />
          {t('oneClick.checkYourConnectionAndTryAgain')}
        </Typography>
        <Button
          data-testid={`${testIdPrefix}-try-again-button`}
          sx={{
            backgroundColor: primaryBrandColor,
            textTransform: 'none',
            fontWeight: 700,
            width: '100%'
          }}
          size="large"
          onClick={() => {
            analyticsTrackEvent(analyticsPayloads.tryAgain, 'Retry clicked');

            let currentUrl = window.location.href;
            if (currentUrl.indexOf('?') > -1) {
              currentUrl += '&retry=true';
            } else {
              currentUrl += '?retry=true';
            }

            window.location.href = currentUrl;
          }}
        >
          <Box display="flex" gap="8px" alignItems="center">
            {t('oneClick.tryAgain')}
          </Box>
        </Button>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        alignItems="center"
        width="100%"
      >
        <Typography
          variant="bodyM20"
          color={colors.secondaryGrayColor}
          fontSize="16px"
        >
          {t('oneClick.haveQuestionsOrNeedHelp')}
        </Typography>
        <PhoneLink type="secondary" testIdPrefix={testIdPrefix} />
      </Box>
      <CustomerServiceHours testIdPrefix={testIdPrefix} />
    </Box>
  );
};
