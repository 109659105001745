import { useRef } from 'react';
import { analyticsTrackEvent } from 'lib/utils/analytics';

export function useNotificationTracking() {
  // Keeping this as a ref so it can be used to determine individual alert views
  const elementViewRecorded = useRef(false);

  function trackNotificationView({
    trackingLabel,
    viewLocation,
    isVisible,
    settlement_key = ''
  }) {
    if (
      trackingLabel &&
      viewLocation &&
      isVisible &&
      !elementViewRecorded.current
    ) {
      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'view_' + viewLocation,
          label: trackingLabel,
          settlement_key
        },
        trackingLabel
      );
      elementViewRecorded.current = true;
    }
  }
  return trackNotificationView;
}
