import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { HashLink } from 'react-router-hash-link';
import { Link } from '@ffn/ui';

const ContentfulLink = ({ node: { data }, children }) => {
  const isAssetHyperLink = data.target ? true : false;
  if (isAssetHyperLink) {
    return (
      <Link
        href={data.target?.fields?.file?.url}
        target="_blank"
      >
        {children}
      </Link>
    );
  } else {
    const uriIsRelative = data.uri?.startsWith('/');
    return uriIsRelative ? <Link as={HashLink} to={data.uri}>{children}</Link> : <Link href={data.uri} target="_blank">{children}</Link>;
  }
};

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <strong>{text}</strong>
  },
  renderNode: {
		[BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
		[BLOCKS.LIST_ITEM]: (node, children) => {
      const transformedChildren = documentToReactComponents(node, {
        renderMark: options.renderMark,
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => children,
          [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
          [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
          [BLOCKS.LIST_ITEM]: (node, children) => children,
          [INLINES.HYPERLINK]: (node, children) => <ContentfulLink node={node} children={children} />,
          [INLINES.ASSET_HYPERLINK]: (node, children) => <ContentfulLink node={node} children={children} />
        },
      });
      return <li>{transformedChildren}</li>;
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => <ContentfulLink node={node} children={children} />,
    [INLINES.HYPERLINK]: (node, children) => <ContentfulLink node={node} children={children} />
  }
};

export const parseRichText = body => {
  return body ? documentToReactComponents(body, options) : null;
};
