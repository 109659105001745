import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';

import { getClient } from 'shared-selectors/client';

import { useAuth, useLaunchDarkly } from 'lib/hooks';
import { getAppSubDomain, logger } from 'shared-components';
import {
  fetchProgramDetails,
  fetchProgramDetailsEligibility
} from 'lib/api/cfos';
import { calculateComparisonPercentage } from '../../shared-components/ProgramDetails/utils';

const LOGGING_PREFIX = 'PROGRAM DETAILS :: ';

// returns eligibility boolean
// calls ffn-cx-api to check if user is eligible for program
// calls LD to check if flag is set
export const useProgramDetails = () => {
  const subdomain = getAppSubDomain();
  const { isAuthenticated } = useAuth();
  const client = useSelector(getClient);
  const { flags, activeFlagNames, fetchSingleFlag } = useLaunchDarkly();
  const [eligibility, setEligibility] = useState(false);
  const [programDetails, setProgramDetails] = useState({
    programMonthlyPayment: null,
    previousMonthlyPayment: null,
    programLength: null,
    previousLength: null,
    programTotalCost: null,
    previousTotalCost: null,
    monthlyPaymentPercentages: null,
    programLengthPercentages: null,
    totalCostPercentages: null
  });
  const [areProgramDetailsValid, setAreProgramDetailsValid] = useState(true);

  // checks if user is eligible for program
  const { data: eligibilityData, loading, error } = useQuery({
    queryKey: ['programDetailsEligibility'],
    queryFn: fetchProgramDetailsEligibility,
    staleTime: Infinity, // Never consider the data stale, will prevent unnecessary recalls
    enabled: isAuthenticated,
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        return false;
      }
      return failureCount < 2;
    }
  });

  // retrieves program details data
  const { data: cfosData, loading: queryLoading, error: queryError } = useQuery(
    {
      queryKey: ['program-details'],
      queryFn: fetchProgramDetails,
      staleTime: Infinity,
      enabled: isAuthenticated,
      retry: (failureCount, error) => {
        if (error.response?.status === 404) {
          return false;
        }
        return failureCount < 2;
      }
    }
  );

  useEffect(() => {
    if (cfosData) {
      const {
        programMonthlyPayment,
        previousMonthlyPayment,
        programLength,
        previousLength,
        programTotalCost,
        previousTotalCost
      } = cfosData;

      // Calculates percentage comparisons between current and previous program details.
      // 'program' and 'previous' are compared directly: if the previous value is larger, it's set as 100%, and the program is a relative percentage of this.
      // These percentages help to visually represent the data as bar graphs in the UI, ensuring that the longest bar is always 100% of the width.
      // The values also confirm that the new program offers better terms than the previous one before showing the comparison to the user.

      const monthlyPaymentPercentages = calculateComparisonPercentage(
        previousMonthlyPayment,
        programMonthlyPayment
      );

      const programLengthPercentages = calculateComparisonPercentage(
        previousLength,
        programLength
      );

      const totalCostPercentages = calculateComparisonPercentage(
        previousTotalCost,
        programTotalCost
      );

      let clientSignDate = '';
      if (client?.clientSignDate && !clientSignDate) {
        try {
          clientSignDate = format(
            parseISO(client?.clientSignDate),
            'MMM dd, yyyy'
          );
        } catch (error) {
          logger.error(
            `${LOGGING_PREFIX} There was an error formatting client sign date: `,
            error
          );
        }
      }

      const monthlySavings = previousMonthlyPayment - programMonthlyPayment;

      // checks to make sure the program total nor monthly costs are higher than previous
      setAreProgramDetailsValid(
        monthlyPaymentPercentages.program !== 100 &&
          totalCostPercentages.program !== 100
      );

      setProgramDetails({
        clientSignDate,
        monthlyPaymentPercentages,
        monthlySavings,
        previousLength,
        previousMonthlyPayment,
        previousTotalCost,
        programLength,
        programLengthPercentages,
        programMonthlyPayment,
        programTotalCost,
        totalCostPercentages
      });
    }
  }, [cfosData, client?.clientSignDate]);

  // fetches LD flag only if user is eligible for program
  useEffect(() => {
    if (eligibilityData?.eligible) {
      fetchSingleFlag(activeFlagNames?.programDetails, { brand: subdomain });
    }
  }, [activeFlagNames, eligibilityData?.eligible, fetchSingleFlag, subdomain]);

  // sets eligibility based on data and flags
  useEffect(() => {
    if (!eligibilityData || !flags) {
      return;
    }
    if (
      eligibilityData.eligible &&
      flags[activeFlagNames.programDetails] === 'show' &&
      areProgramDetailsValid
    ) {
      setEligibility(true);
    }
  }, [activeFlagNames, eligibilityData, flags, areProgramDetailsValid]);

  // if there is an error or loading, set eligibility to false
  useEffect(() => {
    if (error || loading || queryLoading || queryError) {
      setEligibility(false);
    }
  }, [error, loading, queryLoading, queryError]);
  return {
    programDetails,
    eligibility
  };
};
