import { useSiteData } from 'lib/hooks';

// Hook to determine which requested sidebar elements are allowed for this brand
export function useAllowedSidebarFeatures(requestedFeatures) {
  const { features } = useSiteData();

  // features.sidebar.global is a flag that should trigger no sidebar element to be rendered
  if (!features.sidebar || !features.sidebar.global) {
    return [];
  }

  // Check to see that db has requestedFeatures set to true
  const permittedFeaturesList = requestedFeatures.filter(
    featureName => features?.sidebar[featureName] === true
  );
  return permittedFeaturesList;
}
