import { useSelector } from 'react-redux';

import { getLawFirmName } from 'lib/utils';
import { getLawFirmId } from 'shared-selectors/userRecord';

export const useLawFirmName = () => {
  //const getLawFirmId = createSelector(getClient, client => client?.lawFirmId);
  const lawFirmId = useSelector(getLawFirmId);
  return getLawFirmName(lawFirmId);
};
