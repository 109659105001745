import { getAppSubDomain } from '../../shared-components/ProvideDashboardType';

function getApiUrl() {
  const subdomain = getAppSubDomain();
  const {
    REACT_APP_1059_CX_API_URL,
    REACT_APP_3001_CX_API_URL,
    REACT_APP_ARP_CX_API_URL
  } = process.env;

  if (subdomain === 'fdr') {
    return REACT_APP_1059_CX_API_URL;
  } else if (subdomain === 'turnbull') {
    return REACT_APP_ARP_CX_API_URL;
  } else if (subdomain === 'achieve') {
    return REACT_APP_3001_CX_API_URL;
  } else {
    throw new Error('ERROR: Cannot match CX API URL from domain');
  }
}

export default getApiUrl;
