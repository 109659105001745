import React, { useCallback, useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme
} from '@achieve/sunbeam';

import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'lib/hooks';
import styles from './CoreQuestion.module.scss';

/**
 * CoreQuestion renders an expandable accordion element with customizable styles and content.
 * Default style includes a grey border with heading/summary variant set to "ascendLabelSm", margin, and
 * details/body variant set to "ascendBodySm".
 *
 * @component
 * @param {Object} props - The props for the CoreQuestion component.
 * @param {React.ReactNode} [props.detailsComponent] - The core question answer/details content.
 * @param {string} props.id - The unique identifier for the accordion element.
 * @param {string} props.summaryKey - The 'pts.' prefixed i18n key for the summary/heading of the accordion.
 * @param {Function} props.details - A function returning the JSX content for the accordion details.
 * @param {string} [props.backgroundColor] - The background color for the accordion header.
 * @param {boolean} [props.border=true] - Determines if the accordion should have a border.
 * @param {string} [props.borderColor] - The color of the border, defaults to a theme-specific grey.
 * @param {string} [props.bodyVariant='ascendBodySm'] - Ascend Typography variant for the body/content.
 * @param {string} [props.summaryVariant='ascendLabelSm'] - Ascend Typography variant for the summary/header.
 * @returns {React.ReactElement} - A React element representing a single accordion item.
 */
export function CoreQuestion({
  detailsComponent,
  id,
  summaryKey,
  backgroundColor,
  border = true,
  borderColor,
  bodyVariant = 'ascendBodySm',
  summaryVariant = 'ascendLabelSm'
}) {
  const { t } = useTranslation();

  const theme = useTheme();

  // Calculate dynamic styles based on component props to apply conditional styling.
  const grey = theme?.ascend?.colors?.neutral[150] ?? '#c0cbd8';
  const detailsTextColor = theme?.ascend?.colors?.primary ?? '#1D252F';
  // const : theme?.ascend?.colors?.content?.primary ?? ";
  const dynamicStyles = {
    backgroundColor,
    borderTop: border ? '1px solid' : undefined,
    borderColor: borderColor || grey // Defaults to grey if no borderColor provided.
  };

  // state for whether the component is expanded/collapsed
  const [expanded, setExpanded] = useState(true);

  const handleChange = useCallback(() => {
    if (expanded) {
      // Only log collapse events
      analyticsTrackEvent(
        {
          action: 'collapsed',
          category: 'pts',
          label: id
        },
        `Core Question ${id} Collapsed`
      );
    }
    setExpanded(!expanded);
  }, [expanded, id]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={dynamicStyles}
      className={styles.coreQuestionWrapper}
      data-testid={`core-question-${id}`}
    >
      <AccordionSummary
        id={`core-question-header-${id}`}
        data-testid={`core-question-header-${id}`}
        className={styles.coreQuestionSummary}
        aria-controls={`core-question-details-${id}`}
      >
        <Typography variant={summaryVariant}>{t(summaryKey)}</Typography>
      </AccordionSummary>
      <AccordionDetails
        data-testid={`core-question-details-${id}`}
        id={`core-question-details-${id}`}
        className={styles.coreQuestionDetails}
      >
        <Typography color={detailsTextColor} variant={bodyVariant}>
          {detailsComponent}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
