import { createSlice } from '@reduxjs/toolkit';
import { rootReset } from '../store/actions';

const initialState = {
  models: 'loading',
  userRecord: 'loading'
};

const dataStatusSlice = createSlice({
  name: 'dataStatus',
  initialState,
  reducers: {
    modelsErrored: state => {
      return {
        ...state,
        models: 'error'
      };
    },
    modelsLoaded: state => {
      return {
        ...state,
        models: 'success'
      };
    },
    userRecordLoaded: state => {
      return {
        ...state,
        userRecord: 'success'
      };
    },
    userRecordErrored: state => {
      return {
        ...state,
        userRecord: 'error'
      };
    }
  },
  extraReducers: {
    [rootReset]: () => {
      return { ...initialState };
    }
  }
});

export const {
  modelsLoaded,
  modelsErrored,
  userRecordLoaded,
  userRecordErrored
} = dataStatusSlice.actions;

export default dataStatusSlice.reducer;
