/**
 * An error boundary component we'll use to wrap the child components and prevent the app from crashing
 * if we encounter an unhandled rendering error in any component rendered as a child.
 *
 * More here: https://reactjs.org/docs/error-boundaries.html
 *
 */

import React from 'react';
import { Panel, PanelTitle, PanelTitleHeading } from '@ffn/ui';

import { analyticsTrackEvent } from 'lib/utils/analytics';
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // updates state so next render will show the error UI and not crash
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch() {
    analyticsTrackEvent(
      { category: 'render', action: 'render', label: 'error' },
      'Render Error Caught by Error Boundary'
    );
  }

  render() {
    if (this.state.hasError) {
      // custom error UI return children will have interface render children components without render issues
      return (
        <div>
          <Panel data-testid="errorBoundaryMessage">
            <PanelTitle
              css={`
                margin-bottom: 12px !important;
              `}
            >
              <PanelTitleHeading>Page Error</PanelTitleHeading>
            </PanelTitle>
            <p>
              We are sorry, we have encountered an error with the resource you
              requested. Please return to the <a href="/">homepage</a>. If the
              problem persists, please contact{' '}
              <a href="/customer-service">Customer Service</a>.
            </p>
          </Panel>
        </div>
      );
    }

    return this.props.children;
  }
}
