import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box } from 'atomic-layout';
import { ContactQuestions } from 'shared-components';
import { FormError } from './components';
import { Text, LoadingButton, Icon, FormMessage } from '@ffn/ui';
import 'styled-components/macro';
import styled from 'styled-components';
import {
  useNotificationTracking,
  useBrand,
  useTranslation,
  useAppData
} from 'lib/hooks';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { alertUpdate, loadDocuSign, saveSigningCompletion } from 'lib/api';
import { format } from 'date-fns/fp';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { CheckmarkCircle } from '@ffn/icons';

const HeaderContainer = styled.div`
  color: ${props => props.theme.colors.secondaryGreen};
  background-color: white;
  padding: 16px;
  span {
    font-weight: bold;
    margin-left: 24px;
  }
`;

export function POANeeded({
  notification,
  viewLocation,
  isVisible,
  isFromAlertBox
}) {
  const { fetchUserRecord } = useAppData();
  const { t } = useTranslation();
  const client = useSelector(state => state?.models?.client ?? {});
  const userRecord = useSelector(state => state?.userRecord ?? {});
  const params = new URLSearchParams(window.location.search);
  const signingEvent = params.get('event');
  const [error, setError] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const {
    trackingLabel,
    referenceIds,
    payload: {
      accountNumber,
      lfNameAtPoaCreation,
      originalCreditor,
      poaDocumentLink,
      dSignEnvelopeId,
      emailAtCreation,
      legalPartnerEnrolled,
      clientGuid
    }
  } = notification;
  const [signingComplete, setSigningComplete] = useState(
    userRecord?.docuSign?.includes(referenceIds[0])
  );
  const isSigned =
    ['viewing_compelete', 'signing_complete'].includes(signingEvent) ||
    userRecord?.docuSign?.includes(referenceIds[0]);
  const brand = useBrand();
  const bizName = brand('business-name.abbr');
  const trackNotificationView = useNotificationTracking();

  const saveDocusignSigned = useCallback(async () => {
    await saveSigningCompletion({
      userRecord,
      alertId: referenceIds[0]
    });
    fetchUserRecord();
  }, [fetchUserRecord, userRecord, referenceIds]);

  useEffect(() => {
    if (signingComplete !== isSigned) {
      if (isSigned) {
        setSigningComplete(true);
        saveDocusignSigned().then(() => {
          analyticsTrackEvent(
            {
              category: 'alert',
              action: 'docusign_poa_signed',
              label: trackingLabel
            },
            `${trackingLabel} - Docusign Signed`
          );
        });
      } else {
        setSigningComplete(false);
      }
    }
  }, [
    isSigned,
    signingComplete,
    trackingLabel,
    saveDocusignSigned,
    referenceIds
  ]);

  useEffect(() => {
    trackNotificationView({
      trackingLabel,
      viewLocation,
      isVisible
    });
  }, [trackingLabel, viewLocation, isVisible, trackNotificationView]);

  const openPoaDocumentLink = async () => {
    try {
      setBtnDisabled(true);
      if (poaDocumentLink) {
        await alertUpdate([
          {
            alert_id: referenceIds[0],
            last_action_date: format('yyyy-MM-dd', new Date())
          }
        ]);
        analyticsTrackEvent(
          {
            category: 'alert',
            action: 'action',
            label: trackingLabel
          },
          `${trackingLabel} - Button Clicked`
        );
        window.open(poaDocumentLink, '_blank');
        setBtnDisabled(false);
      } else {
        await alertUpdate([
          {
            alert_id: referenceIds[0],
            last_action_date: format('yyyy-MM-dd', new Date())
          }
        ]);
        analyticsTrackEvent(
          {
            category: 'alert',
            action: 'action',
            label: trackingLabel
          },
          `${trackingLabel} - Button Clicked`
        );
        const docuSignUrl = await loadDocuSign({
          fullName: `${client?.firstName} ${client?.lastName}`,
          email: emailAtCreation,
          envelopeId: dSignEnvelopeId,
          clientGuid,
          alertId: referenceIds[0]
        });

        if (!docuSignUrl) {
          throw new Error('NO_DOCUSIGN_URL_RETURNED');
        }

        analyticsTrackEvent(
          {
            category: 'alert',
            action: 'redirect_to_docusign',
            label: trackingLabel
          },
          `${trackingLabel} - Docusign Redirect`
        );

        window.location.href = docuSignUrl;
        setBtnDisabled(false);
      }
    } catch (e) {
      setError(e.message);
      setBtnDisabled(false);
      analyticsTrackEvent(
        {
          category: 'alert',
          action: 'failure',
          label: trackingLabel,
          error: e.message
        },
        `${trackingLabel} - Docusign API Error`
      );
    }
  };

  const SuccessMessage = () => {
    return (
      <div
        css={`
          border-color: ${props => props.theme.colors.secondaryGreen};
          border-width: ${!isFromAlertBox ? '1px' : '0'};
          border-style: solid;
        `}
        data-testid="POANeeded-Success"
      >
        <HeaderContainer key={0}>
          <Icon icon={CheckmarkCircle} size={20}></Icon>
          <span>{t('alerts.success')}</span>
        </HeaderContainer>
        <Box paddingHorizontal={!isFromAlertBox ? 16 : 0}>
          <p
            css={`
              margin-top: 0;
            `}
          >
            {t('alerts.successMessages.documentsCompleted')}
          </p>
        </Box>
      </div>
    );
  };

  return (
    <Box
      paddingHorizontal={16}
      paddingVertical={8}
      css={`
        background: ${props => props.theme.colors.plain};
      `}
      data-testid="POANeeded-Alert"
    >
      {error && (
        <FormMessage variant="error" data-testid="POANeeded-Error">
          <FormError errorCode={error} />
        </FormMessage>
      )}
      {signingComplete ? (
        <SuccessMessage />
      ) : (
        <>
          <Text>
            {t('alerts.poaNeeded.kickOff1')} {originalCreditor}{' '}
            {t('alerts.poaNeeded.kickOff2')} {accountNumber}
            {t('alerts.poaNeeded.kickOff3')}
          </Text>
          <Text>
            {t('alerts.poaNeeded.bestSettlement1')} {lfNameAtPoaCreation}
            {t('alerts.poaNeeded.bestSettlement2')}
            {legalPartnerEnrolled && '*'}
          </Text>
          <Text>
            {t('alerts.poaNeeded.partnerNegotiate1')}{' '}
            <strong>{t('alerts.poaNeeded.partnerNegotiate2')}</strong>{' '}
            {t('alerts.poaNeeded.partnerNegotiate3')}
          </Text>
          <Text
            css={`
              text-align: center;
            `}
          >
            <LoadingButton
              width="50%"
              type="button"
              variant="primary"
              onClick={openPoaDocumentLink}
              rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
              disabled={btnDisabled}
              isLoading={btnDisabled}
              data-testid="POANeeded-SubmitBtn"
            >
              {btnDisabled
                ? t('alerts.poaNeeded.loadingDocuments')
                : t('alerts.poaNeeded.signDocuments')}
            </LoadingButton>
          </Text>
          {legalPartnerEnrolled && (
            <Text data-testid="POANeeded-LegalPartnerEnrolledMsg">
              <small>
                *{t('alerts.poaNeeded.negotiationServiceDisclaimer')}
              </small>
            </Text>
          )}
          {bizName === 'FDR' && (
            <Text data-testid="POANeeded-FDROnlyMsg">
              <small>{t('alerts.poaNeeded.notAttorneysDisclaimer')}</small>
            </Text>
          )}
        </>
      )}
      <Text
        as="div"
        css={`
          margin-top: 32px;
        `}
      >
        <ContactQuestions />
      </Text>
    </Box>
  );
}

export default POANeeded;
