import styles from './productTour.module.scss';

export function TourArrow({ direction, onClick, hide }) {
  return (
    <button
      aria-label={direction === 'left' ? 'Previous Button' : 'Next Button'}
      aria-hidden={hide}
      data-testid={`tour-arrow-${direction}`}
      onClick={onClick}
      className={styles.navArrow}
      style={{
        visibility: hide ? 'hidden' : 'visible'
      }}
    >
      <Arrow direction={direction} />
    </button>
  );
}

function Arrow({ direction }) {
  if (direction === 'left') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="leftArrow"
      >
        <path
          d="M15 8L1 8M1 8L8 15M1 8L8 1"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (direction === 'right') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="rightArrow"
      >
        <path
          d="M1 8H15M15 8L8 1M15 8L8 15"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return null;
}
