import React from 'react';
import { PageLayout } from 'shared-components';
import {
  Button,
  Heading,
  Image,
  Panel,
  PanelTitle,
  PanelTitleHeading
} from '@ffn/ui';
import { useBrand } from 'lib/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'lib/hooks';
export function ErrorFallback() {
  const brand = useBrand();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <PageLayout style={{ textAlign: 'center' }}>
      <Panel data-testid="errorBoundaryMessage">
        <PanelTitle
          css={`
            margin-bottom: 12px !important;
          `}
        >
          <PanelTitleHeading>{t('common.pageError')}</PanelTitleHeading>
        </PanelTitle>
        <Heading as="h2" style={{ padding: '1rem 0' }}>
          {t('common.weAreSorryAnUnexpectedErrorOccurred')}
        </Heading>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
            justifyContent: 'center'
          }}
        >
          <div style={{ paddingTop: '1rem' }}>
            <Button variant="primary" onClick={() => navigate('/')}>
              {t('common.returnToHomepage')}
            </Button>
          </div>
          <div style={{ padding: '4rem 0 1rem 0' }}>
            <Image src={brand('logo.main')} width="97" />
          </div>
        </div>
      </Panel>
    </PageLayout>
  );
}

export default ErrorFallback;
