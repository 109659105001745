import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { siteDataContext } from 'shared-components/ProvideSiteData';
import { getLawFirmId } from 'shared-selectors/userRecord';
import { useQueryParam } from 'lib/hooks';
function makeBrand(brandConfig) {
  return stringPath => {
    if (!stringPath) {
      return brandConfig;
    }
    return R.path(stringPath.split('.'))(brandConfig);
  };
}
export function useBrand() {
  const { brandConfig } = React.useContext(siteDataContext);
  const lawFirmIdFromUser = useSelector(getLawFirmId);
  const queryParams = useQueryParam();

  const lawFirmId = lawFirmIdFromUser ?? queryParams.get('lawfirmid');

  const mergedConfigs = R.mergeDeepRight(
    brandConfig?.default ?? brandConfig,
    brandConfig?.[lawFirmId] ?? {}
  );

  return React.useMemo(() => makeBrand(mergedConfigs), [mergedConfigs]);
}
