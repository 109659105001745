import React from 'react';
import { css } from 'styled-components/macro';

export function Meter({ max, min = 0, value = 0, ...props }) {
  return (
    <div
      css={`
        background: ${props => props.theme.colors.grayRegular};
        width: 100%;
        height: 6px;
      `}
      {...props}
    >
      <div
        css={css`
          background: ${props =>
            value / max === 1
              ? props.theme.colors.secondaryGreen
              : props.theme.colors.meterBlue};
          width: ${(value / max) * 100}%;
          height: 6px;
        `}
      />
    </div>
  );
}
