import { axiosInstance as axios } from 'lib/axios';

export const validateBank = async payload => {
  try {
    const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/bankValidate`;

    const response = await axios.post(endpoint, payload, {
      validateStatus: status => status === 200
    });

    return response.data;
  } catch (error) {
    const errObj = { ...error, type: 'validateBank' };
    throw errObj;
  }
};
