import React from 'react';

import { Typography } from '@achieve/sunbeam';

import styles from './Section.module.scss';

export const Section = ({ children, id, supNumber, title }) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionHeading}>
        <div
          className={styles.sectionTitle}
          data-testid={`program-details-section-title-${id}`}
        >
          <Typography variant="ascendLabelMd">{title}</Typography>
        </div>
        <div
          className={styles.sectionSup}
          data-testid={`program-details-section-sup-${id}`}
        >
          <Typography variant="ascendDisplayXs">
            <sup>{supNumber}</sup>
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
};
