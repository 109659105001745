import { useMemo } from 'react';
import { useTheme } from '@achieve/sunbeam';

import styles from './productTour.module.scss';

export function TourProgressDots({ totalSteps, currentStep }) {
  const theme = useTheme();
  const dots = useMemo(() => new Array(totalSteps).fill(''), [totalSteps]);

  return (
    <div data-testid="tour-progress-indicator" className={styles.navDots}>
      {dots.map((_, index) => {
        return (
          <div
            aria-label={
              index === currentStep ? `Step ${index + 1} of ${totalSteps}` : ''
            }
            className={styles.navDot}
            data-testid={`tour-step-${index + 1}`}
            key={index}
            style={{
              backgroundColor:
                index === currentStep
                  ? theme.palette.primary.main
                  : theme.palette.primary.grey
            }}
          />
        );
      })}
    </div>
  );
}
