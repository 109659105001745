import React from 'react';
import { Only } from 'atomic-layout';
import { Tabs } from './Tabs';
import { AccordionTabs } from './AccordionTabs';

export function DashTabs({
  config,
  selectedId,
  onTabClick,
  translationPath,
  unlimitedHeight = false
}) {
  return (
    <>
      <Only from="lg">
        <Tabs
          config={config}
          selectedId={selectedId}
          onTabClick={onTabClick}
          translationPath={translationPath}
        />
      </Only>
      <Only to="lg">
        <AccordionTabs
          unlimitedHeight={unlimitedHeight}
          config={config}
          selectedId={selectedId}
          onTabClick={onTabClick}
          translationPath={translationPath}
        />
      </Only>
    </>
  );
}

export default DashTabs;
