export const BUSINESS_ID_MAP = {
  achieve: 'ar',
  fdr: 'fdr',
  turnbull: 'turnbull'
};

export const STATEMENT_PROVIDER = {
  CFT: 'CFT',
  GCS: 'GCS'
};

export const ANALYTICS_PAGE_NAME_MAP = {
  // Login - Page Loaded (/login)
  '/login': { name: 'Login' },
  // Registration - Page Loaded (/activate)
  '/activate': { name: 'Registration' },
  // Forgot Password - Page Loaded (/forgot-password)
  '/forgot-password': { name: 'Forgot Password' },
  // Reset Password - Page Loaded (/reset-password)
  '/reset-password': { name: 'Reset Password' },
  // Home - Page Loaded ( / )
  '/': { name: 'Homepage' },
  // Enrolled Debt - Page Loaded (/enrolled-debt)
  '/enrolled-debt': { name: 'Enrolled Debt' },
  // Enrolled Debt Details - Page Loaded (/enrolled-debt/debt-name-1234)
  '/enrolled-debt/:debtName': {
    name: 'Enrolled Debt Details',
    params: ['debtName']
  },
  // Negotiation Overview - Upcoming - Page Loaded (/enrolled-debt/negotiation-overview/upcoming)
  '/enrolled-debt/negotiation-overview/upcoming': {
    name: 'Negotiation Overview - Upcoming'
  },
  // Negotiation Overview - Current - Page Loaded (/enrolled-debt/negotiation-overview/current)
  '/enrolled-debt/negotiation-overview/current': {
    name: 'Negotiation Overview - Current'
  },
  // Negotiation Overview - In Process - Page Loaded (/enrolled-debt/negotiation-overview/in-process)
  '/enrolled-debt/negotiation-overview/in-process': {
    name: 'Negotiation Overview - In Process'
  },
  // Negotiation Overview - Resolved - Page Loaded (/enrolled-debt/negotiation-overview/resolved)
  '/enrolled-debt/negotiation-overview/resolved': {
    name: 'Negotiation Overview - Resolved'
  },
  // Settlement Overview - Page Loaded (/enrolled-debt/settlement-overview)
  '/enrolled-debt/settlement-overview': { name: 'Settlement Overview' },
  // Dedicated Account - Page Loaded (/dedicated-account)
  '/dedicated-account': { name: 'Dedicated Account' },
  // Program Resources - Page Loaded (/program-resources)
  '/program-resources': { name: 'Program Resources' },
  // How It Works - Page Loaded (/program-resources/how-it-works)
  '/program-resources/how-it-works': { name: 'How It Works' },
  // Credit Impact - Page Loaded (/program-resources/credit-impact)
  '/program-resources/credit-impact': {
    name: 'Credit Impact'
  },
  // Handling Creditor Calls - Page Loaded (/program-resources/creditor-calls)'
  '/program-resources/creditor-calls': { name: 'Handling Creditor Calls' },
  // Videos - [VIDEO PAGE TITLE] - Page Loaded (/program-resources/videos/*)
  '/program-resources/videos/:videoId': {
    name: 'Videos - videoId',
    params: ['videoId']
  },
  // FAQ - Page Loaded (/program-resources/faq)
  '/program-resources/faq': { name: 'FAQ' },
  // Program Glossary - Page Loaded (/program-resources/program-glossary)
  '/program-resources/program-glossary': { name: 'Program Glossary' },
  // Helpful Documents - Page Loaded (/program-resources/helpful-documents)
  '/program-resources/helpful-documents': { name: 'Helpful Documents' },
  // Blog - Page Loaded (/program-resources/blog)
  '/program-resources/blog': { name: 'Blog' },
  // Blog - [BLOG PAGE TITLE] - Page Loaded (/program-resources/blog/*)
  '/program-resources/blog/:blogId': {
    name: 'Blog - blogId',
    params: ['blogId']
  },
  // Customer Service - Page Loaded (/customer-service)
  '/customer-service': { name: 'Customer Service' },
  // Deposits - Page Loaded (/customer-service/deposits)
  '/customer-service/deposits': { name: 'Deposits' },
  // Bank Account Change - Page Loaded (/customer-service/bank-account-change)
  '/customer-service/bank-account-change': { name: 'Bank Account Change' },
  // Enroll New Debt - Page Loaded (/customer-service/enroll-new-debt)
  '/customer-service/enroll-new-debt': { name: 'Enroll New Debt' },
  // Document Upload - Page Loaded (/customer-service/document-upload)
  '/customer-service/document-upload': { name: 'Document Upload' },
  // Account-Statements - Page Loaded (/customer-service/document-upload/account-statements)
  '/customer-service/document-upload/account-statements': {
    name: 'Account Statements'
  },
  // Past Due-Notices - Page Loaded  (/customer-service/document-upload/past-due-notices)
  '/customer-service/document-upload/past-due-notices': {
    name: 'Past Due Notices'
  },
  // Collection-Letters - Page Loaded  (/customer-service/document-upload/collection-letters)
  '/customer-service/document-upload/collection-letters': {
    name: 'Collection Letters'
  },
  // Legal-Documents - Page Loaded  (/customer-service/document-upload/legal-documents)
  '/customer-service/document-upload/legal-documents': {
    name: 'Legal Documents'
  },
  //  (Miscellaneous-Documents - Page Loaded /customer-service/document-upload/miscellaneous-documents)
  '/customer-service/document-upload/miscellaneous-documents': {
    name: 'Miscellaneous Documents'
  },
  //  One Time Deposit - Page Loaded (/customer-service/deposits/one-time-deposit)
  '/customer-service/deposits/one-time-deposit': { name: 'One Time Deposit' },
  // Increase Program Deposit - Page Loaded (/customer-service/deposits/increase-program-deposit)
  '/customer-service/deposits/increase-program-deposit': {
    name: 'Increase Program Deposit'
  },
  // Change Deposit - Page Loaded (/customer-service/deposits/change-deposit  )
  '/customer-service/deposits/change-deposit': { name: 'Change Deposit' },
  // Alerts - Page Loaded (/notifications/alerts)
  '/notifications/alerts': { name: 'Alerts' },
  // Alerts - [ALERT SUBJECT] - Page Loaded (/notifications/alerts/*)
  '/notifications/alerts/:alertId': {
    name: 'Alerts - alertId',
    params: ['alertId']
  },
  // Messages - Page Loaded (/notifications/messages)
  '/notifications/messages': { name: 'Messages' },
  // Messages - [MESSAGE SUBJECT] - Page Loaded (/notifications/messages/*)
  '/notifications/messages/:messageId': {
    name: 'Messages - messageId',
    params: ['messageId']
  },
  // Document Vault - Page Loaded (/document-vault)
  '/document-vault': { name: 'Document Vault' },
  // Settings - Program - Page Loaded (/settings/program)
  '/settings/program': { name: 'Settings - Program' },
  // Settings - Login - Page Loaded (/settings/login)
  '/settings/login': { name: 'Settings - Login' },
  // Settings - Contact Info - Page Loaded (/settings/contact)
  '/settings/contact': { name: 'Settings - Contact Info' },
  // Settings - Communication - Page Loaded (/settings/preferences)
  '/settings/preferences': { name: 'Settings - Communication' },
  // About Us - Page Loaded (/about)
  '/about': { name: 'About Us' },
  // Disclosures - Page Loaded (/disclosures)
  '/disclosures': { name: 'Disclosures' },
  // Page Error - Page Loaded (/page-error)
  '/page-error': { name: 'Page Error' }
};

export const aalPhoneNumber = '1-833-418-3097';

export const ALERT_SUBJECT_NAMES = {
  DA_SUSPENDED: 'DA Suspended',
  SETTLEMENT_CONFLICT: 'Settlement Conflict',
  ANV: 'Account Number Verification',
  AAO: 'Add Account Opportunity',
  SETTLEMENT_AUTH: 'Settlement Authorization',
  INSUFFICIENT_FUNDS: 'Insufficient Funds',
  CCF: 'Client Contact Family',
  ASO: 'Accelerated Settlement Opportunity',
  REMOVAL_REQUEST: 'Removal Request',
  INFO_REQUEST: 'Information Request',
  LAST_PAYMENT: 'Last Payment Date Verification',
  FMC: 'First Milestone Call',
  SMC: 'Second Milestone Call',
  POA_NEEDED: 'POA Needed',
  SETTLEMENT_AUTH_SMS: 'Settlement Authorization SMS Opt-in Reminder'
};

export const BRAND_COLOR_THEMES = {
  turnbull: {
    colors: {
      backgroundColor: '#F8F8FC',
      gradientEnd: '#3F5463',
      gradientStart: '#2d3c47',
      primaryBlue: '#717075',
      secondaryBlue: '#2d3c47',
      secondaryBlueLight: '#717075',
      secondaryNavy: '#29324a',
      tertiaryBlue: '#717075',
      tertiaryBlueLight: '#29324a',
      primaryButtonColor: '#717075',
      tertiaryButtonColor: '#717075',
      meterBlue: '#717075',
      secondaryGradientStart: '#2d3c47',
      secondaryGradientEnd: '#29324a'
    }
  },
  fdr: {
    colors: {
      backgroundColor: '#F8F8FC',
      gradientEnd: '#0E5882',
      gradientStart: '#041B93',
      bgPrimaryGray: '#2E343E',
      primaryButtonColor: '#041B93',
      tertiaryButtonColor: '#21A1FF',
      meterBlue: '#041B93',
      secondaryGradientStart: '#0E5882',
      secondaryGradientEnd: '#213246'
    }
  },
  achieve: {
    colors: {
      backgroundColor: '#fff',
      gradientEnd: '#041B93',
      gradientStart: '#041B93',
      grayLight: '#F2F4FE',
      primaryBlue: '#041B93',
      secondaryBlue: '#3300FF',
      secondaryBlueLight: '#3300FF',
      primaryButtonColor: '#3300FF',
      secondaryRed: '#CB000E',
      tertiaryButtonColor: '#3300FF',
      meterBlue: '#3300FF',
      secondaryGradientStart: '#041B93',
      secondaryGradientEnd: '#041B93'
    }
  }
};

// Brand-specific variables for configuring the Auth0 provider
export const AUTH0_PROVIDER_VARS = {
  achieve: {
    domain: process.env.REACT_APP_AUTH0_AR_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_AR_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_AR_CALLBACK_URL,
    audience: process.env.REACT_APP_AUTH0_AR_AUDIENCE,
    scope: 'openid profile email name'
  },
  fdr: {
    domain: process.env.REACT_APP_AUTH0_FDR_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_FDR_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_FDR_CALLBACK_URL,
    audience: process.env.REACT_APP_AUTH0_FDR_AUDIENCE,
    scope: 'openid profile email name'
  },
  turnbull: {
    domain: process.env.REACT_APP_AUTH0_TURNBULL_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_TURNBULL_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_TURNBULL_CALLBACK_URL,
    audience: process.env.REACT_APP_AUTH0_TURNBULL_AUDIENCE,
    scope: 'openid profile email name'
  }
};

// Brand specific google secret key
export const GOOGLE_RECAPTCHA_SITE_KEY = {
  achieve: process.env.REACT_APP_ACHIEVE_GOOGLE_RECAPTCHA_SITE_KEY,
  fdr: process.env.REACT_APP_FDR_GOOGLE_RECAPTCHA_SITE_KEY,
  turnbull: process.env.REACT_APP_TURNBULL_GOOGLE_RECAPTCHA_SITE_KEY
};
// passed to the customTheme prop in SunbeamThemeProvider
// merges into achieve theme, thus achieve key is empty
export const SUNBEAM_THEME_DICTIONARY = {
  achieve: {},
  fdr: {
    sb: {
      colors: {
        primary: {
          achieveBlue: BRAND_COLOR_THEMES.achieve.colors.primaryBlue
        }
      }
    },
    palette: {
      primary: {
        main: BRAND_COLOR_THEMES.achieve.colors.primaryBlue
      }
    }
  },
  turnbull: {
    sb: {
      colors: {
        primary: {
          achieveBlue: BRAND_COLOR_THEMES.turnbull.colors.primaryBlue
        }
      }
    },
    palette: {
      primary: {
        main: BRAND_COLOR_THEMES.turnbull.colors.primaryBlue
      }
    }
  }
};

export const BREAKPOINTS = {
  xs: {
    maxWidth: '575px'
  },
  sm: {
    minWidth: '576px',
    maxWidth: '767px'
  },
  md: {
    minWidth: '768px',
    maxWidth: '1120px'
  },
  lg: {
    minWidth: '1121px',
    maxWidth: '1199px'
  },
  xl: {
    minWidth: '1200px'
  }
};

export const STATES_WITH_SPECIAL_DISCLAIMERS = {
  IA: 'mxStateIA',
  MT: 'mxStateMT'
};

export const ACHIEVE_ROOT_URL = process.env.REACT_APP_ACHIEVE_ROOT_URL;
