import React, { useEffect } from 'react';
import { Box } from 'atomic-layout';
import 'styled-components/macro';
import { Text } from '@ffn/ui';
import ReadyToUploadComponent from '../shared-components/ReadyToUploadComponent';
import { DashInput, DashSelect, DashCheckbox } from 'shared-components';
import { useTranslation } from 'lib/hooks';

export function AdditionalMetadataFields({
  accounts,
  creditorIsNotListed,
  fileIndex,
  originalPrevCreditor,
  currentFile: { metadata },
  setFieldValue,
  showReadyToUpload,
  fileWasRejected,
  isSubmitting,
  fileWasSubmitted
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (metadata.sameNumberAsOriginal?.length) {
      const selector = `select[name='files.${fileIndex}.metadata.originalPrevCreditor']`;
      const { accountNumber } = document.querySelector(
        selector
      ).selectedOptions[0].dataset;
      setFieldValue(
        `files.${fileIndex}.metadata.newAccountNumber`,
        `Account number ending in ${accountNumber}`
      );
      return;
    }
    if (!metadata.sameNumberAsOriginal?.length) {
      setFieldValue(`files.${fileIndex}.metadata.newAccountNumber`, '');
    }
  }, [metadata.sameNumberAsOriginal, fileIndex, setFieldValue]);

  return (
    <>
      <Box>
        <DashInput
          id={`files.${fileIndex}.metadata.newCreditorName`}
          name={`files.${fileIndex}.metadata.newCreditorName`}
          label={t('docUpload.newCreditorName')}
          disabled={isSubmitting || fileWasSubmitted}
        />
      </Box>
      <Box>
        <DashSelect
          id={`files.${fileIndex}.metadata.originalPrevCreditor`}
          name={`files.${fileIndex}.metadata.originalPrevCreditor`}
          label={t('docUpload.originalOrPreviousCreditor')}
          style={{ paddingRight: '32px' }}
          disabled={isSubmitting || fileWasSubmitted}
        >
          {!originalPrevCreditor && (
            <option>{t('docUpload.selectPreviousCreditor')}</option>
          )}
          {accounts.map(obj => (
            <option
              key={`originalPrevCreditor-${obj.accountId}`}
              data-account-id={obj.accountId}
              data-account-number={obj.accountNumber}
            >
              {`${obj.originalCreditor} ${obj.accountNumber}`}
            </option>
          ))}
        </DashSelect>
      </Box>
      <Box>
        <DashInput
          id={`files.${fileIndex}.metadata.newAccountNumber`}
          name={`files.${fileIndex}.metadata.newAccountNumber`}
          label={t('docUpload.newAccountNumber')}
          disabled={
            metadata.sameNumberAsOriginal?.length ||
            isSubmitting ||
            fileWasSubmitted
          }
        />
        <Box marginTop={16}>
          <DashCheckbox
            id={`files.${fileIndex}.metadata.sameNumberAsOriginal`}
            name={`files.${fileIndex}.metadata.sameNumberAsOriginal`}
            type="checkbox"
            disabled={
              !metadata.originalPrevCreditor || isSubmitting || fileWasSubmitted
            }
            value="Checked"
            checked={metadata.sameNumberAsOriginal?.length}
            css={`
              color: ${props => props.theme.colors.primaryGray} !important;
              cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
            `}
          >
            <Text
              as="span"
              css={`
                font-size: 12px;
                cursor: ${!metadata.originalPrevCreditor ||
                isSubmitting ||
                fileWasSubmitted
                  ? 'not-allowed'
                  : 'pointer'};
              `}
            >
              {t('docUpload.sameAccountNumberAsOriginal')}
            </Text>
          </DashCheckbox>
          {showReadyToUpload && creditorIsNotListed ? (
            <ReadyToUploadComponent fileWasRejected={fileWasRejected} />
          ) : null}
        </Box>
      </Box>
    </>
  );
}
