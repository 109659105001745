import { uploadToECM } from './uploadToECM';

export const defaultUpload = async file => {
  const { metadata } = file;

  try {
    const payload = {
      file,
      attributes: JSON.stringify(metadata)
    };
    return await uploadToECM(payload);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
