import { matchPath } from './matchPath';
import { ANALYTICS_PAGE_NAME_MAP } from '../../../constants';
import { interpolateParams } from './interpolateParams';
// This function gets the human readable name of the page
// the names are stored in ANALYTICS_PAGE_NAME_MAP
export const getPageName = pathname => {
  // just in case
  if (!pathname) return 'pathname_undefined';

  // paths without params
  if (ANALYTICS_PAGE_NAME_MAP[pathname]) {
    return ANALYTICS_PAGE_NAME_MAP[pathname].name;
  }

  // paths with params
  const mapObj = matchPath(pathname);
  if (mapObj) {
    return interpolateParams(mapObj, pathname);
  }

  // path not in map
  return pathname;
};
