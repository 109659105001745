import { createSlice } from '@reduxjs/toolkit';
import { rootReset } from '../store/actions';

const initialState = {};

const userRecordSlice = createSlice({
  name: 'userRecord',
  initialState,
  reducers: {
    updateUserRecord: (state, action) => {
      let { createdAt: createdAtDate } = action.payload;
      // We need to convert the createdAt date from the Firestore user record to a Date
      if (createdAtDate?._seconds) {
        createdAtDate = Date(createdAtDate._seconds).toString();
      }
      return { ...action.payload, createdAt: createdAtDate };
    }
  },
  extraReducers: {
    [rootReset]: () => {
      return { ...initialState };
    }
  }
});

export const { updateUserRecord } = userRecordSlice.actions;

export default userRecordSlice.reducer;
