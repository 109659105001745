import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'atomic-layout';
import { Icon } from '@ffn/ui';
import { CheckmarkCircle } from '@ffn/icons';

import {
  AuthorizeOnlyBankAccountChangeForm,
  Currency,
  NormalBankAccountChangeForm,
  CustomerServiceHours
} from 'shared-components';
import { useNotificationTracking } from 'lib/hooks';
import { shortDateWithLongYear } from 'lib/utils';
import { Link } from '@ffn/ui';
import { useTranslation } from 'lib/hooks';

// spaNsfCode values that indicate we're dealing with a renewal
const RENEW_CODES = [
  'R01',
  'R06',
  'R07',
  'R08',
  'R09',
  'R10',
  'R16',
  'R14',
  'R15',
  'R17',
  'R18',
  'R19',
  'R21',
  'R22',
  'R23',
  'R24',
  'R25',
  'R26',
  'R27',
  'R28',
  'R29',
  'R30',
  'R31',
  'R32',
  'R33',
  'R34',
  'R35'
];

const PlainBox = styled.div`
  background-color: ${props => props.theme.colors.plain};
  padding: ${props => (props.isResolved ? '0px' : '16px')};
  padding-top: 0;
  border: ${props =>
    props.isResolved
      ? `1px solid ${props.theme.colors.secondaryGreen}`
      : 'none'};
`;

export const WhiteBox = styled.div`
  background-color: white;
  padding: 16px;
  margin-top: 24px;
`;

const ToggleLinkContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: ${props => (props.isSmallScreen ? 'center' : 'flex-end')};
  padding: 0 16px;
`;

const OptionallyPaddedBox = styled.div`
  padding: ${props => (props.isPadded ? '16px' : '0px')};
`;

const HeaderContainer = styled.div`
  color: ${props => props.theme.colors.secondaryGreen};
  background-color: white;
  padding: 16px;
  span {
    font-weight: bold;
    margin-left: 24px;
  }
`;

/**
 * In the case of a renewal, the user is able to either simply authorize a transaction on their bank account
 * or additionally change their banking info. This is enabled with a toggle button that changes the form component that gets rendered.
 */
const ToggleableBankAccountChangeForm = ({
  isFromAlert,
  setIsResolved,
  isResolved
}) => {
  const { t } = useTranslation();
  const [formToShow, setFormToShow] = useState('authorizeOnly');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const toggleButtonText =
    formToShow === 'authorizeOnly'
      ? t('alerts.DASuspended.changeBankInfo')
      : t('alerts.DASuspended.renewBankInfo');
  const hideToggleLink = isSubmitting || isResolved;

  const successCallback = () => {
    if (setIsResolved && typeof setIsResolved === 'function') {
      setIsResolved(true);
    }
  };

  return (
    <>
      {formToShow === 'authorizeOnly' ? (
        <AuthorizeOnlyBankAccountChangeForm
          isFromAlert={isFromAlert}
          updateSubmittingState={boolStatus => setIsSubmitting(boolStatus)}
          successCallback={successCallback}
        />
      ) : (
        <NormalBankAccountChangeForm
          isFromAlert={isFromAlert}
          updateSubmittingState={setIsSubmitting}
          successCallback={successCallback}
        />
      )}
      {/** Prevent user from navigating to a different form when form submission is pending or successfully completed */}
      {hideToggleLink ? null : (
        <ToggleLinkContainer isSmallScreen={isSmallScreen}>
          <Link
            onClick={() => {
              if (formToShow === 'authorizeOnly') {
                setFormToShow('normal');
              } else {
                setFormToShow('authorizeOnly');
              }
            }}
            // conditionally setting data-testid, it toggles between 'Change Bank Info' and 'Renew Bank Info'
            data-testid={
              formToShow === 'authorizeOnly'
                ? 'changeBankInfoButton'
                : 'renewBankInfoButton'
            }
          >
            <span>{toggleButtonText}</span>
          </Link>
        </ToggleLinkContainer>
      )}
    </>
  );
};

export function DASuspendedAlert({
  notification,
  viewLocation,
  isVisible,
  isResolved,
  setIsResolved
}) {
  const {
    payload: { nsfAmount, nsfDate, nextScheduledDraftDate, spa, spaNsfCode },
    trackingLabel
  } = notification;
  const trackNotificationView = useNotificationTracking();
  const { t } = useTranslation();

  useEffect(() => {
    trackNotificationView({
      trackingLabel,
      viewLocation,
      isVisible
    });
  }, [trackingLabel, viewLocation, isVisible, trackNotificationView]);

  // Check if the alertTask is a renewal
  const isRenew = RENEW_CODES.includes(spaNsfCode);
  const nsfDateLanguage = nsfDate ? `on ${shortDateWithLongYear(nsfDate)}` : '';

  // Dynamically building our messages to render
  let message = [
    <p key={0}>
      <strong>
        {t('alerts.DASuspended.yourProgramDeposit')} {nsfDateLanguage} for{' '}
        <Currency value={nsfAmount} />{' '}
        {t('alerts.DASuspended.failedToClearWeReUnable')}
      </strong>
    </p>
  ];

  // Control whether we display the bank account change form or messaging about how to contact customer service
  // GCS accounts (as of 01/2022) are being phased out and require help from CS agents
  const requiresCustomerServiceIntervention =
    spa === 'GCS' || nextScheduledDraftDate === null;

  // const requiresCustomerServiceIntervention = true;

  // If the code is not a renewal code, the user can only change their bank account
  // however, if the code is a renewal code, the user either change their bank account or simply reauthorize one
  const FormToRender = (() => {
    if (requiresCustomerServiceIntervention) {
      return null;
    } else if (isRenew) {
      return (
        <ToggleableBankAccountChangeForm
          isFromAlert={true}
          setIsResolved={setIsResolved}
          isResolved={isResolved}
        />
      );
    } else {
      return (
        <NormalBankAccountChangeForm
          isFromAlert={true}
          successCallback={() => setIsResolved(true)}
        />
      );
    }
  })();

  if (requiresCustomerServiceIntervention) {
    // Subtle, but we're actually adding to message here
    message.push(
      <span key={1}>
        <strong>{t('alerts.DASuspended.toReactivateYourDedicated')}</strong>
        <WhiteBox>
          <CustomerServiceHours />
        </WhiteBox>
      </span>
    );
  } else if (isRenew) {
    // Redeclaring message here
    message = [
      <p key={0}>
        <strong>
          {t('alerts.DASuspended.yourProgramDeposit')} {nsfDateLanguage}{' '}
          {t('alerts.DASuspended.for')} <Currency value={nsfAmount} />{' '}
          {t('alerts.DASuspended.failedToClearWeReUnableTo')}
        </strong>
      </p>
    ];
  }

  // Don't display any message if the alert has already been resolved
  if (isResolved) {
    message = [
      <HeaderContainer key={0}>
        <Icon icon={CheckmarkCircle} size={20}></Icon>
        <span>{t('alerts.accountNumberVerification.success')}</span>
      </HeaderContainer>
    ];
  }

  return (
    <PlainBox isResolved={isResolved}>
      {message}
      <OptionallyPaddedBox isPadded={isResolved}>
        {FormToRender}
      </OptionallyPaddedBox>
    </PlainBox>
  );
}

export default DASuspendedAlert;
