import { isWithinInterval, startOfDay, endOfDay, parseISO } from "date-fns/fp";

export const updateAllDraftFees = ({
	values: { startDate, endDate, increaseAmount },
	allDraftFees,
	monthlyDraftsFeeAmount
}) => {
	const isInPaymentChangeInterval = isWithinInterval({
		start: startOfDay(startDate),
		end: endOfDay(endDate)
	});
	const updatedDrafts = allDraftFees.futureDrafts.map(
		({ draftFeeWrapper, ...restOfDraft }) => {
			if (
				isInPaymentChangeInterval(parseISO(draftFeeWrapper.draftsFeeDate)) &&
				draftFeeWrapper.debitAmount === monthlyDraftsFeeAmount
			) {
				return {
					...restOfDraft,
					isChanged: true,
					draftFeeWrapper: {
						...draftFeeWrapper,
						overrideDraftDate: null,
						overrideDebitAmount: Number(increaseAmount + ".00")
					}
				};
			}
			return { ...restOfDraft, draftFeeWrapper };
		}
	);

	return {
		...allDraftFees,
		depositModificationType: "Positive",
		depositModificationReason: "Increase Program Deposit",
		futureDrafts: updatedDrafts
	};
};
