/**
 * Renames a document based on its metadata and file extension. In default case we return the original file name as is because we don't have a specific format for it provided to us
 * @param {File} file - The original file object.
 * @param {number} lawFirmId - The ID of the law firm.
 * @param {number} fdrApplicantId - The ID of the FDR applicant.
 * @param {string} name - The name of the client.
 * @returns {File} - The renamed file object.
 */
export const renameDocument = (file, lawFirmId, fdrApplicantId, name) => {
  const fileExtension = file?.document?.name.split('.').pop();
  //Regex to replace special characters and space from creditor name with _
  const NON_ALPHA_NUMERIC_CHARS = /[^0-9A-Za-z]/g;
  const formatCreditor = file?.metadata?.creditor?.replace(
    NON_ALPHA_NUMERIC_CHARS,
    '_'
  );

  const tlgLawFirmIds = [1001, 1002, 1003];
  const isTLG = tlgLawFirmIds.includes(Number(lawFirmId));
  const TLG_PREFIX = 'TLGLIT';

  let prefixLIT = isTLG ? TLG_PREFIX : 'LIT';
  let prefixISL = isTLG ? TLG_PREFIX : 'ISL';
  let prefixJDG = isTLG ? TLG_PREFIX : 'JDG';

  let newName;

  switch (file.metadata.document_type) {
    case 'statement_of_account':
      newName = `Account Statement_${formatCreditor}.${fileExtension}`;
      break;
    case 'collection_letter':
      newName = `Collection letter_${formatCreditor}.${fileExtension}`;
      break;
    case 'late_notice':
      newName = `Past due letter_${formatCreditor}.${fileExtension}`;
      break;
    case 'summons_and_complaints':
      newName = `${prefixLIT}_${formatCreditor}_S&C.${fileExtension}`;
      break;
    case 'demand_letter':
      newName = `${prefixISL}_${formatCreditor}_ISL_Demand Ltr.${fileExtension}`;
      break;
    case 'intent_to_file_suit':
      newName = `${prefixISL}_${formatCreditor}_ISL_Intent to file Suit.${fileExtension}`;
      break;
    case 'answer':
      newName = `${prefixLIT}_${formatCreditor}_Answer.${fileExtension}`;
      break;
    case 'change_of_attorneys':
      newName = `${prefixLIT}_${formatCreditor}_Change of Attorney.${fileExtension}`;
      break;
    case 'intent_to_file_default':
      newName = `${prefixLIT}_${formatCreditor}_Intent to file Default.${fileExtension}`;
      break;
    case 'motion_for_default':
      newName = `${prefixLIT}_${formatCreditor}_Mtn for Default.${fileExtension}`;
      break;
    case 'motion_for_alternate_service':
      newName = `${prefixLIT}_${formatCreditor}_Mtn for Alt Service.${fileExtension}`;
      break;
    case 'register_for_hearing_date':
      newName = `${prefixLIT}_${formatCreditor}_Reg for Hearing Date.${fileExtension}`;
      break;
    case 'attempt_to_serve':
      newName = `${prefixLIT}_${formatCreditor}_Attempt to Serve.${fileExtension}`;
      break;
    case 'consent_of_judgement':
      newName = `${prefixJDG}_${formatCreditor}_Consent of Judgment.${fileExtension}`;
      break;
    case 'garnishment':
      newName = `${prefixJDG}_${formatCreditor}_Garnisment.${fileExtension}`;
      break;
    case 'notice_of_judgement':
      newName = `${prefixJDG}_${formatCreditor}_Notice of Judgement.${fileExtension}`;
      break;
    case 'property_lien':
      newName = `${prefixJDG}_${formatCreditor}_Property Lien.${fileExtension}`;
      break;
    case 'satisfaction_of_judgement':
      newName = `${prefixJDG}_${formatCreditor}_Satisfaction of Judgement.${fileExtension}`;
      break;
    case 'subpoena':
      newName = `${prefixJDG}_${formatCreditor}_Subpoena.${fileExtension}`;
      break;
    case 'bankruptcy_document':
      newName = `Bankruptcy_${fdrApplicantId}_${name}.${fileExtension}`;
      break;
    default:
      newName = file.document.name;
      break;
  }

  const newFileObj = new File([file.document], newName, {
    type: file?.document?.type
  });

  newFileObj.metadata = { ...file?.metadata };
  newFileObj.id = file?.id;
  newFileObj.path = file?.document?.path;

  return newFileObj;
};
