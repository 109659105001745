import { axiosInstance as axios } from 'lib/axios';

export const aalInterest = async ({
  clientName,
  clientEmail,
  cplusLoanId,
  opportunityId
}) => {
  try {
    const endpoint = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/aalInterest`;

    const postVars = {
      clientName,
      clientEmail,
      cplusLoanId,
      opportunityId
    };

    const response = await axios.post(endpoint, postVars, {
      validateStatus: status => status < 500
    });

    if (response?.data?.success === false && response?.data?.error) {
      throw new Error(response?.data?.error);
    } else if (response?.status !== 200) {
      throw new Error('AAL_INTEREST_REQUEST_ERROR');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default aalInterest;
