import React from 'react';
import 'styled-components/macro';
import { analyticsTrackEvent } from 'lib/utils/analytics';

export function VideoPlayer({ thumbnail, mp4, webm, ogv, videoTitle }) {
  return (
    <div
      data-testid="video-player"
      css={`
        width: 100%;
      `}
    >
      <video
        css={`
          width: 100%;
        `}
        poster={thumbnail}
        preload="metadata"
        controls
        controlsList="nodownload"
        onPlay={() =>
          analyticsTrackEvent(
            {
              category: 'video',
              action: 'play',
              label: videoTitle
            },
            'Video Player Video Play'
          )
        }
        onPause={() =>
          analyticsTrackEvent(
            {
              category: 'video',
              action: 'pause',
              label: videoTitle
            },
            'Video Player Video Pause'
          )
        }
        onEnded={() =>
          analyticsTrackEvent(
            {
              category: 'video',
              action: 'end',
              label: videoTitle
            },
            'Video Player Video End'
          )
        }
        key={mp4}
      >
        <source src={mp4} type="video/mp4" />
        <source src={webm} type="video/webm" />
        <source src={ogv} type="video/ogg" />
      </video>
    </div>
  );
}

export default VideoPlayer;
