import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { OrientationButton } from 'shared-components/EduWizard/OrientationButton';
import { AlertRibbon } from 'shared-components/Alerts';
import { getAlertRibbonNotifications } from 'shared-selectors/alerts';

const StickyContainer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: min-content;
`;

const OrientationButtonContainer = styled.div`
  position: absolute;
  top: ${props => (props.isAlertRibbonPresent ? '-4rem' : '-8rem')};
  right: 1rem;
`;

export const StickyFooterBlock = () => {
  const notificationsForAlertRibbon = useSelector(getAlertRibbonNotifications);
  // the variable is useful because nw we have chat and orientation button flaoting on same page. this will ensure it does not overlap.
  const isAlertRibbonPresent = notificationsForAlertRibbon.length > 0;
  return (
    <>
      <StickyContainer>
        <OrientationButtonContainer isAlertRibbonPresent={isAlertRibbonPresent}>
          <OrientationButton />
        </OrientationButtonContainer>
        <AlertRibbon />
      </StickyContainer>
    </>
  );
};
