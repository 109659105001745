import * as Yup from 'yup';
import i18next from 'i18next';

const validationSchema = Yup.object().shape({
  files: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(
        i18next.t('common.form.validationsAndErrors.required')
      ),
      metadata: Yup.object().shape({
        fdr_sf_debt_account_id: Yup.string().required(
          i18next.t('common.form.validationsAndErrors.required')
        ),
        creditor: Yup.string()
          .required(i18next.t('common.form.validationsAndErrors.required'))
          .notOneOf(
            [i18next.t('docUpload.selectCreditor')],
            i18next.t('docUpload.selectAValidCreditor')
          ),
        document_type: Yup.string().required(
          i18next.t('common.form.validationsAndErrors.required')
        ),
        currentBalance: Yup.number()
          .required(i18next.t('common.form.validationsAndErrors.required'))
          .typeError(
            i18next.t(
              'common.form.validationsAndErrors.pleaseSpecifyAValidNumber'
            )
          )
          .test(
            i18next.t('common.form.validationsAndErrors.twoDecimal'),
            i18next.t('common.form.validationsAndErrors.invalidDecimal'),
            value => {
              if (value === undefined) return;
              return (
                !value.toString().includes('.') ||
                value.toString().split('.')[1].length <= 2
              );
            }
          )
      })
    })
  )
});

export default validationSchema;
