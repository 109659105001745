import React from 'react';
import { NavLink } from 'react-router-dom';
import { useBrand } from 'lib/hooks';
import { getUrlExtension } from 'lib/utils';
import 'styled-components/macro';
import { Container, Image } from '@ffn/ui';
import { Composition } from 'atomic-layout';

import { getTestIds } from 'lib/utils/dataTestIds';
const headerTestIDs = getTestIds('header');

const testAreas = `
brand
`;

export function MobileNav() {
  const brand = useBrand();

  return (
    <Composition
      as={Container}
      areas={testAreas}
      templateCols="1fr"
      templateRows="64px"
    >
      {({ Brand }) => (
        <>
          <Brand flex justifyContent="center">
            {getUrlExtension(brand('logo.main')) === 'svg' ? (
              <NavLink to="/" data-testid={headerTestIDs.brand_home_link}>
                <Image
                  src={brand('logo.main')}
                  css={`
                    height: 48px;
                    padding: 8px 0px;
                  `}
                />
              </NavLink>
            ) : (
              <NavLink
                to="/upload"
                css={`
                  display: flex;
                  align-items: center;
                `}
                data-testid={headerTestIDs.brand_home_link}
              >
                <Image
                  src={brand('logo.main')}
                  css={`
                    max-height: 48px;
                    max-width: 180px;
                    vertical-align: middle;
                    padding: 8px 0px;
                  `}
                />
              </NavLink>
            )}
          </Brand>
        </>
      )}
    </Composition>
  );
}
