import React from 'react';
import { NavLink } from 'react-router-dom';
import { useBrand } from 'lib/hooks';
import 'styled-components/macro';
import { Container, Image } from '@ffn/ui';
import { Only, Composition } from 'atomic-layout';
import { MobileNav } from './MobileNav';
import { getUrlExtension } from 'lib/utils';
import { getTestIds } from 'lib/utils/dataTestIds';
const headerTestIDs = getTestIds('header');

const mobileTemplate = `
 brand
`;
const desktopTemplate = `
  brand
`;

function DesktopNav() {
  const brand = useBrand();
  return (
    <Composition
      as={Container}
      areas={mobileTemplate}
      areasLg={desktopTemplate}
      templateCols="1fr"
      templateRows="64px"
      templateColsLg="max-content"
      templateRowsLg="80px"
      alignItems="center"
    >
      {({ Brand }) => (
        <>
          <Brand flex justifyContent="center" marginRight={16}>
            <NavLink
              to="/upload"
              css={`
                display: block;
              `}
              data-testid={headerTestIDs.brand_home_link}
            >
              {getUrlExtension(brand('logo.main')) === 'svg' ? (
                <Image
                  src={brand('logo.main')}
                  css={`
                    height: 48px;
                  `}
                />
              ) : (
                <Image
                  src={brand('logo.main')}
                  css={`
                    max-height: 48px;
                    max-width: 180px;
                    vertical-align: middle;
                  `}
                />
              )}
            </NavLink>
          </Brand>
        </>
      )}
    </Composition>
  );
}

export function LeadDocUploadNav() {
  return (
    <>
      <Only to="lg">
        <MobileNav />
      </Only>
      <Only from="lg">
        <DesktopNav />
      </Only>
    </>
  );
}

export default LeadDocUploadNav;
