import { Box, Button, Link, useMediaQuery, useTheme } from '@achieve/sunbeam';
import { useBrand, useTranslation } from 'lib/hooks';
import { PhoneOutlineSVG } from '../assets/PhoneOutline';
import { PhoneSolidSVG } from '../assets/PhoneSolid';
import { colors } from './constants';

export const PhoneLink = ({ type = 'primary', testIdPrefix }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const brand = useBrand();
  const phone = brand('contact.customer-service.phone');
  const { t } = useTranslation();
  const primaryBrandColor =
    theme?.palette?.primary?.main ?? colors.primaryBlueColor;
  const testId = `${testIdPrefix}-phone-link`;

  if (isMobile && type === 'primary') {
    return (
      <Button
        data-testid={testId}
        sx={{
          backgroundColor: primaryBrandColor,
          textTransform: 'none',
          fontWeight: 700,
          width: '100%'
        }}
        size="large"
        href={`tel:${phone}`}
      >
        <Box display="flex" gap="8px" alignItems="center">
          <PhoneSolidSVG /> {t('oneClick.call')} {phone}
        </Box>
      </Button>
    );
  } else if (isMobile && type === 'secondary') {
    return (
      <Button
        data-testid={testId}
        sx={{
          textTransform: 'none',
          fontWeight: 700,
          width: '100%',
          color: primaryBrandColor
        }}
        size="large"
        variant="outlined"
        href={`tel:${phone}`}
      >
        <Box display="flex" gap="8px" alignItems="center">
          <PhoneOutlineSVG /> {phone}
        </Box>
      </Button>
    );
  } else {
    return (
      <Link
        data-testid={testId}
        href={`tel:${phone}`}
        sx={{
          fontWeight: 700,
          fontSize: '18px',
          color: primaryBrandColor
        }}
      >
        <Box display="flex" gap="8px" alignItems="center">
          <PhoneOutlineSVG />
          {phone}
        </Box>
      </Link>
    );
  }
};
