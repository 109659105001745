import axios from 'axios';
import getApiUrl from 'lib/utils/getApiUrl';

export const autoSettlementAuth = async ({ settlementCode }) => {
  const apiUrlStem = getApiUrl();
  const apiUrl = `${apiUrlStem}/settlement-auth/${settlementCode}`;

  try {
    const response = await axios.post(apiUrl, {});

    return response.data;
  } catch (e) {
    throw e;
  }
};
