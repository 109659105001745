import React, { useEffect, useState, useMemo } from 'react';
import { ThumbsDown, ThumbsUp } from 'react-feather';
import { IconButton, Typography } from '@achieve/sunbeam';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'lib/hooks';

import styles from './ThumbsUpDown.module.scss';
/**
 * ThumbsUpDown component displays a thumbs up and thumbs down button for users to provide feedback.
 * It also shows a thank you message after the user submits their feedback.
 *
 * @param {string} featureKey - The key used to identify the feature for which the feedback is being provided.
 * @returns {JSX.Element} ThumbsUpDown component.
 */
export const ThumbsUpDown = ({ featureKey }) => {
  const { t } = useTranslation();
  const [showThxMsg, setShowThxMsg] = useState(false);
  useEffect(() => {
    const getFeedbackValueForSession = sessionStorage.getItem(featureKey);
    if (getFeedbackValueForSession) {
      setShowThxMsg(true);
    }
  }, [featureKey]);

  /**
   * Updates sessionStorage with the featureKey and sends an analytics event.
   * @param {string} voteType - The type of vote (up or down).
   * @returns {void}
   */
  const handleButtonClick = useMemo(() => {
    return voteType => {
      sessionStorage.setItem(featureKey, true);
      setShowThxMsg(true);
      analyticsTrackEvent(
        {
          category: featureKey,
          action: 'clicked',
          label: voteType,
          eventName: `thumbs ${voteType} selected`
        },
        `${featureKey} Response`
      );
    };
  }, [featureKey]);

  return (
    <div
      className={styles.feedbackContainer}
      data-testid="program-feedback-container"
    >
      <Typography variant="ascendLabelMd">
        {t('thumbsUpDown.doYouFindThisHelpful')}
      </Typography>
      {showThxMsg ? (
        <Typography variant="ascendLabelSm">
          {t('thumbsUpDown.thanksForYourResponse')}
        </Typography>
      ) : (
        <div
          className={styles.btnContainer}
          data-testid="feedback-buttons-container"
        >
          <div className={styles.btn} data-testid="thumbs-down-btn">
            <IconButton
              style={{ backgroundColor: '#FEDDE6', color: '#000' }}
              onClick={() => handleButtonClick('down')}
            >
              <ThumbsDown />
            </IconButton>
            <Typography variant="ascendBodySm">
              {t('thumbsUpDown.no')}
            </Typography>
          </div>
          <div className={styles.btn} data-testid="thumbs-up-btn">
            <IconButton
              style={{ backgroundColor: '#CFF2E5', color: '#000' }}
              onClick={() => handleButtonClick('up')}
            >
              <ThumbsUp />
            </IconButton>
            <Typography variant="ascendBodySm">
              {t('thumbsUpDown.yes')}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
