import styled from 'styled-components';

export const SubmitButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  &:first-child {
    margin-bottom: 16px;
  }
  @media (max-width: 768px) {
    padding: 0 16px;
    flex-direction: column;
    justify-content: space-between;
  }
`;
