import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@ffn/ui';
import 'styled-components/macro';
import { CheckCircle } from 'react-feather';
import { Box, Typography, useTheme } from '@achieve/sunbeam';
import { useTranslation } from 'lib/hooks';

export function SuccessHeading({ message }) {
  const { t } = useTranslation();
  const messageTitle = t('docUpload.successFilesUploadedTitle');
  const theme = useTheme();
  const textColor = theme?.ascend?.colors?.content?.primary;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="16px"
      data-testid="document-upload-success"
    >
      <NavLink
        to="/customer-service/document-upload"
        css={`
          color: ${({ theme }) => theme.colors.primaryGray};
          display: flex;
        `}
      >
        <Icon alt="successful" icon={CheckCircle} size={32} />
      </NavLink>
      <Typography color={textColor} variant="ascendHeadingSm" component="h2">
        <strong>{message ?? messageTitle}</strong>
      </Typography>
    </Box>
  );
}

export default SuccessHeading;
