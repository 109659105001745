import React, { useState } from 'react';
import { Button, LoadingButton, FormInput, FormMessage } from '@ffn/ui';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Box, Composition } from 'atomic-layout';
import { useSelector } from 'react-redux';

import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import {
  DashForm,
  DashInput,
  DashSelect,
  ContactQuestions
} from 'shared-components';
import { getTestIds } from 'lib/utils/dataTestIds';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { newBankAccountChange } from './util';
import { FormError } from './FormError';
import { WhiteBox } from './WhiteBox';
import { SubmitButtonContainer } from './SubmitButtonContainer';
import { refreshNotifications } from '../../lib/api';
import { useBrand } from 'lib/hooks';
import { shortDateWithLongYear } from 'lib/utils';
import { useTranslation } from 'lib/hooks';

const csTestIDs = getTestIds('customer_service');

function SuccessMessage({ isFromAlert, handleReset }) {
  const { t } = useTranslation();

  return (
    <Composition gap={24} templateCols="1fr" templateRows="auto auto auto">
      <Box>{t('bankAccountChange.updateNoticeText')}</Box>
      {!isFromAlert && (
        <>
          <Composition gap={16} widthMd="50%">
            <DashInput
              id="newAccountNumber"
              name="newAccountNumber"
              label={t('bankAccountChange.newAccountNumber')}
              readOnly
              data-test-id="New-Account-Number-Input"
            />
            <DashInput
              id="newRoutingNumber"
              name="newRoutingNumber"
              label={t('bankAccountChange.newRoutingNumber')}
              readOnly
              data-test-id="New-Routing-Number-Input"
            />
            <DashInput
              id="newAccountNames"
              name="newAccountNames"
              label={t('bankAccountChange.nameSOnAccountSeparateByCommas')}
              readOnly
              data-test-id="New-Account-Name-Input"
            />
            <DashInput
              id="newBankName"
              name="newBankName"
              label={t('bankAccountChange.bankName')}
              readOnly
              data-test-id="New-Bank-Name-Input"
            />
            <DashSelect
              id="newAccountType"
              name="newAccountType"
              label={t('bankAccountChange.bankAccountType')}
              disabled
            >
              <option value="checking">
                {t('bankAccountChange.checking')}{' '}
              </option>
              <option value="savings">{t('bankAccountChange.savings')} </option>
            </DashSelect>
          </Composition>
          <Box flex alignItems="center" justifyContent="center">
            <Button
              variant="tertiary"
              height={40}
              rightEnhancer={<ChevronThinRight size={14} />}
              onClick={handleReset}
            >
              {t('bankAccountChange.okThanks')}
            </Button>
          </Box>
        </>
      )}
    </Composition>
  );
}

export function NormalBankAccountChangeForm({
  successCallback,
  errorCallback,
  updateSubmittingState,
  isFromAlert
}) {
  const client = useSelector(state => state?.models?.client);
  const [error, setError] = useState();
  const [state, setState] = useState('form');
  const brand = useBrand();
  const { t } = useTranslation();
  const normalValidationSchema = Yup.object().shape({
    newAccountNumber: Yup.string()
      .strict()
      .trim(t('common.form.validationsAndErrors.noSpacesAtBeginningOrEnding'))
      .matches(/^\d+$/, t('common.form.validationsAndErrors.mustBeANumber'))
      .required(
        t('bankAccountChange.accountNumber') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('bankAccountChange.accountNumber'))
      .min(6, t('common.form.validationsAndErrors.validAccountNumberIsBetween'))
      .max(
        17,
        t('common.form.validationsAndErrors.validAccountNumberIsBetween')
      ),
    newRoutingNumber: Yup.string()
      .strict()
      .trim(t('common.form.validationsAndErrors.noSpacesAtBeginningOrEnding'))
      .matches(/^\d+$/, t('common.form.validationsAndErrors.mustBeANumber'))
      .length(9, t('common.form.validationsAndErrors.routingNumberMust'))
      .required(
        t('bankAccountChange.routingNumber') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('bankAccountChange.routingNumber')),
    newAccountNames: Yup.string()
      .required(
        t('common.form.names') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('common.form.names')),
    newBankName: Yup.string()
      .required(
        t('bankAccountChange.bankName') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('bankAccountChange.bankName')),
    newAccountType: Yup.string()
      .oneOf(['checking', 'savings'])
      .required(
        t('bankAccountChange.bankAccountType') +
          ' ' +
          t('common.form.validationsAndErrors.isARequiredField')
      )
      .label(t('bankAccountChange.bankAccountType')),
    authorize: Yup.boolean().oneOf(
      [true],
      t('common.form.validationsAndErrors.mustBeChecked')
    )
  });
  return (
    <Formik
      initialValues={{
        newAccountNumber: '',
        newRoutingNumber: '',
        newAccountNames: '',
        newBankName: '',
        newAccountType: 'checking',
        authorize: false
      }}
      validationSchema={normalValidationSchema}
      onSubmit={async (values, formikBag) => {
        try {
          await newBankAccountChange({
            fieldValues: { ...values, type: 'newAccount' },
            clientId: client.accountId,
            fdrApplicantId: client.fdrApplicantId
          });

          // Wait 5 seconds and then refresh notifications data in Firestore, which
          // should clear any notifications that prompted the user to take this action.
          setTimeout(refreshNotifications, 5000);

          /**
           * The success callback is our way for the parent component
           * to do something after the form is successfully submitted
           */
          if (typeof successCallback === 'function') {
            successCallback();
          }
          setState('success');
          setError(null);
          analyticsTrackEvent(
            {
              category: 'csr',
              action: 'success',

              label: 'bank_account_change'
            },
            'Bank Account Change Success'
          );
        } catch (err) {
          if (typeof errorCallback === 'function') {
            errorCallback();
          }
          setError(err);
          analyticsTrackEvent(
            {
              category: 'csr',
              action: 'failure',
              label: 'bank_account_change'
            },
            'Bank Account Change Failure'
          );
        } finally {
          if (typeof updateSubmittingState === 'function') {
            updateSubmittingState(false);
          }
        }
      }}
      onReset={() => setState('form')}
      validateOnMount
    >
      {props => (
        <>
          {state === 'form' && (
            <DashForm>
              <WhiteBox>
                {error && (
                  <FormMessage variant="error" data-testid="form-error">
                    <FormError error={error} />
                  </FormMessage>
                )}
                <Composition gap={16} widthMd="50%">
                  <FormInput
                    value={client.routingNumberBankName}
                    readOnly
                    label={t('bankAccountChange.currentBankName')}
                    data-testid={csTestIDs.cs_account_name_input}
                  />
                  <FormInput
                    value={'****' + client.bankAccountNumber}
                    readOnly
                    label={t('bankAccountChange.currentAccountNumber')}
                    data-testid={csTestIDs.cs_account_number_input}
                  />
                  <DashInput
                    id="newAccountNumber"
                    name="newAccountNumber"
                    label={t('bankAccountChange.newAccountNumber')}
                    disabled={props.isSubmitting}
                    data-testid={csTestIDs.cs_new_account_number_input}
                  />
                  <DashInput
                    id="newRoutingNumber"
                    name="newRoutingNumber"
                    label={t('bankAccountChange.newRoutingNumber')}
                    disabled={props.isSubmitting}
                    data-testid={csTestIDs.cs_new_routing_number_input}
                  />
                  <DashInput
                    id="newAccountNames"
                    name="newAccountNames"
                    label={t(
                      'bankAccountChange.nameSOnAccountSeparateByCommas'
                    )}
                    disabled={props.isSubmitting}
                    data-testid={csTestIDs.cs_new_account_name_input}
                  />
                  <DashInput
                    id="newBankName"
                    name="newBankName"
                    label={t('bankAccountChange.bankName')}
                    disabled={props.isSubmitting}
                    data-testid={csTestIDs.cs_new_bank_name_input}
                  />
                  <DashSelect
                    id="newAccountType"
                    name="newAccountType"
                    label={t('bankAccountChange.bankAccountType')}
                    disabled={props.isSubmitting}
                    data-testid={csTestIDs.cs_new_bank_type_select}
                  >
                    <option value="checking">
                      {t('bankAccountChange.checking')}
                    </option>
                    <option value="savings">
                      {t('bankAccountChange.savings')}
                    </option>
                  </DashSelect>
                </Composition>
                <label
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    margin: '24px 0px'
                  }}
                >
                  <Box
                    style={{
                      height: 18,
                      width: 18,
                      margin: '0px 16px 0px 0px'
                    }}
                  >
                    <Field
                      type="checkbox"
                      name="authorize"
                      style={{ height: 18, width: 18 }}
                      data-testid={csTestIDs.cs_new_bank_confirm}
                    />
                  </Box>
                  <span
                    css={`
                      font-size: 13px;
                      font-weight: 400;
                    `}
                  >
                    {t('bankAccountChange.achText1')}
                    {', '}
                    {shortDateWithLongYear(new Date())}
                    {', '}
                    {t('bankAccountChange.achText2')}{' '}
                    {brand('business-name.long')}{' '}
                    {t('bankAccountChange.achText3')}{' '}
                    {shortDateWithLongYear(client.nextScheduledDraftDate)}{' '}
                    {t('bankAccountChange.achText4')}
                  </span>
                </label>
              </WhiteBox>
              <SubmitButtonContainer>
                <ContactQuestions />
                <Box widthMd="50%" paddingLeftMd={16}>
                  <LoadingButton
                    type="submit"
                    variant="primary"
                    height={40}
                    width="100%"
                    isLoading={props.isSubmitting}
                    disabled={!props.isValid}
                    rightEnhancer={<ChevronThinRight size={14} />}
                    data-testid={csTestIDs.cs_new_bank_update_button}
                    onClick={() => {
                      if (typeof updateSubmittingState === 'function') {
                        updateSubmittingState(true);
                      }
                    }}
                  >
                    {t('bankAccountChange.updateBankAccount')}
                  </LoadingButton>
                </Box>
              </SubmitButtonContainer>
            </DashForm>
          )}
          {state === 'success' && (
            <SuccessMessage
              isFromAlert={isFromAlert}
              handleReset={props.resetForm}
            />
          )}
        </>
      )}
    </Formik>
  );
}
