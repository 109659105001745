import React from 'react';
import { Box } from 'atomic-layout';
import { useSelector } from 'react-redux';
import { Heading, Link } from '@ffn/ui';
import { ScheduleCallForm } from './ScheduleCallForm';
import { markRead } from 'lib/api';
import { SuccessMessage } from './ScheduleSuccessMessage';
// fromMsToLocal depends on date-fns, which uses it's own locales
// they need to be imported here, as the useLocale hook returns locale as a string
import { fromMstToLocal } from './timeFunctions';
import { useTranslation } from 'lib/hooks';
import { useLocale } from 'lib/hooks';

export function MilestoneCallConfirmationMessage(props) {
  const { t } = useTranslation();

  const [showScheduleCallForm, setShowScheduleCallForm] = React.useState(false);
  const [hasRescheduled, setHasRescheduled] = React.useState(false);
  const [rescheduledTime, setRescheduledTime] = React.useState(null);
  const clientState = useSelector(state => state.models.client);
  const { firstName } = clientState;
  const { notification } = props;
  const {
    callDate,
    callTime,
    availableTimes,
    alertId,
    isRead
  } = notification?.payload;
  const { trackingLabel } = notification;

  const successCallback = dateTimeObj => {
    setRescheduledTime(dateTimeObj);
    setHasRescheduled(true);
  };

  const successRefresh = () => {
    setTimeout(() => {
      setHasRescheduled(false);
      setShowScheduleCallForm(false);
    }, 10000);
  };

  const { dateFnsLocale } = useLocale();

  // Because we get back times in MDT, we need to parse them as UTC and then convert to Local time, for display in the client
  const displayTime = fromMstToLocal(callDate, callTime, true, dateFnsLocale);

  if (hasRescheduled) {
    return (
      <div>
        <SuccessMessage
          scheduledTime={rescheduledTime}
          hasRescheduled={hasRescheduled}
        />
      </div>
    );
  }

  return (
    <div
      tabIndex={0}
      onFocus={() => {
        // If the message isn't read when the user focuses the messages tab, mark it as read
        if (!isRead) {
          const { id: notificationId } = props?.notification;
          // Make a call to service that adds the proper alertId to the list of read messages
          markRead(notificationId);
        }
      }}
    >
      <Box>
        <p>
          {t('alerts.milestoneCalls.hi')} {firstName},
        </p>
        <p>
          {t('alerts.milestoneCalls.weLookForwardToConnectingWith')}{' '}
          {displayTime}
          {'. '} {t('alerts.milestoneCalls.asAlwaysPleaseContinue')}{' '}
          <Link onClick={() => setShowScheduleCallForm(!showScheduleCallForm)}>
            {t('alerts.milestoneCalls.reschedule')}
          </Link>
          .
        </p>
      </Box>
      {showScheduleCallForm ? (
        <>
          <Heading as="h3" marginTop={0}>
            {t('alerts.milestoneCalls.rescheduleCall')}
          </Heading>
          <Box>
            <ScheduleCallForm
              trackingLabel={trackingLabel}
              successCallback={successCallback}
              successRefresh={successRefresh}
              availableTimes={availableTimes}
              alertId={alertId}
            />
          </Box>
        </>
      ) : null}
    </div>
  );
}
