import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Composition } from 'atomic-layout';
import { Helmet } from 'react-helmet-async';
import 'styled-components/macro';
import { useQuery } from 'react-query';
import { useWebviewAuth } from '@achieve/cx-auth';

import {
  useAuth,
  useBrand,
  useDashboardType,
  useLaunchDarkly
} from 'lib/hooks';

import {
  ChangePasswordModal,
  EduWizard,
  FallBackLoader,
  FeatureFlag,
  GenesysScript,
  logger,
  ProtectedRoutesWrapper,
  RouteGuard,
  StickyFooterBlock,
  UpscopeInit
} from 'shared-components';
import { AutoSettlementAuth } from 'shared-components/OneClickAuthorization/AutoSettlementAuth';
import { AutoDepositAuth } from 'shared-components/OneClickAuthorization/AutoDepositAuth';
import {
  AchieveFooter,
  ErrorFallbackRouter,
  Footer as FDRFooter,
  LoginError,
  Nav
} from 'shared-pages/common';
import {
  About,
  ClientEngagementAgreement,
  CsServicesChangeDeposit,
  CustomerService,
  DedicatedAccount,
  Disclosures,
  DocumentVault,
  EnrolledDebt,
  EnrolledDebtDetail,
  Home,
  NegotiationOverview,
  NotFound,
  Notifications,
  PrivacyPolicy,
  ProgramResources,
  Settings,
  SettlementOverview
} from 'shared-pages/dashboard/lazy';
import {
  AccountStatements,
  BankAccountChange,
  CollectionLetters,
  Deposits,
  EnrollNewDebt,
  IncreaseProgramDeposit,
  LegalDocuments,
  MiscellaneousDocuments,
  OneTimeDeposit,
  PastDueNotices,
  SelectDocType
} from 'shared-pages/dashboard/CustomerService';
import {
  EmptyContent,
  NotificationContent,
  NotificationsList
} from 'shared-pages/dashboard/Notifications';
import { ErrorFallback } from 'shared-pages/common/lazy';
import { ErrorBoundary } from 'react-error-boundary';
import {
  analyticsHotjarIdentify,
  analyticsTrackEvent
} from 'lib/utils/analytics';
import { getLawFirmId } from 'shared-selectors/userRecord';
import { getClientLanguage } from 'shared-selectors/client';
import { getLifecycleMarketingSegment } from 'shared-selectors/enrolled-debt';

const areas = `
  header
  main
  footer
`;

/**
 * Renders an iframe element used for authentication.
 * Retrieves a code parameter from the URL's query string and passes it to a callback function.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.authCodeFunction - The callback function to be called with the code parameter.
 * @returns {JSX.Element} - The rendered iframe element.
 */
function AuthIframe({ authCodeFunction }) {
  const redirect_uri = process.env.REACT_APP_GENESYS_REDIRECT_URL;
  const audience = process.env.REACT_APP_AUTH0_FDR_AUDIENCE;
  const client_id = process.env.REACT_APP_GENESYS_CLIENT_ID;
  const login_url = process.env.REACT_APP_AUTH0_FDR_ISSUER_URL;
  const iframeSrc = `${login_url}authorize?client_id=${client_id}&scope=openid+email+name+profile+offline_access&audience=${audience}&redirect_uri=${redirect_uri}&prompt=none&response_type=code`;

  /**
   * Handles the iframe's load event.
   * Extracts the code parameter from the URL's query string and calls the authCodeFunction callback function.
   *
   * @param {Event} event - The load event object.
   */
  const handleLoad = event => {
    try {
      const params = new URLSearchParams(
        event.target.contentWindow.location.search
      );
      const code = params.get('code');
      if (code) {
        authCodeFunction(code);
      }
    } catch (error) {
      logger.error('Error from onload event:', error);
    }
  };

  /**
   * Handles the iframe's error event.
   *
   * @param {Event} event - The error event object.
   */
  const handleError = event => {
    logger.error('Error:', event.message);
  };

  return (
    <iframe
      src={iframeSrc}
      width={0}
      height={0}
      title="Auth iFrame"
      style={{ display: 'none' }}
      onLoad={handleLoad}
      onError={handleError}
    ></iframe>
  );
}

export function Dashboard() {
  const brand = useBrand();
  const { isAuthenticated } = useAuth();
  const { subDomain } = useDashboardType();
  const navigate = useNavigate();
  const { flags, activeFlagNames } = useLaunchDarkly();
  const lawFirmId = useSelector(getLawFirmId);
  const { isWebview } = useWebviewAuth();
  const lcmSegment = useSelector(getLifecycleMarketingSegment);
  const showUpscope =
    flags &&
    flags[activeFlagNames.loadUpscopeScript] === 'show' &&
    // eslint-disable-next-line eqeqeq
    lawFirmId != '3001'; // keep non type sensitive
  const showUpscopeAR =
    flags &&
    flags[activeFlagNames.arDashboardCobrowse] === 'show' &&
    // eslint-disable-next-line eqeqeq
    lawFirmId == '3001';

  const userRecord = useSelector(state => state.userRecord);
  const { csClientId, fdrApplicantId, firstName, lastName } = userRecord;
  const clientName = `${firstName} ${lastName}`;
  const clientLangFromSf = useSelector(getClientLanguage);
  const [iFrameRendered, setIFrameRendered] = useState(true);
  const [code, setCode] = useState('');
  const isFDR = subDomain === 'fdr';
  const iframeAuth0Code = new URLSearchParams(window.location.search).get(
    'code'
  );

  // Not assigning any variables as this is not a client facing call
  // and the retry logic is handled within useQuery and analyticsHotjarIdentify
  useQuery(
    ['hotjarIdentifySignIn', fdrApplicantId, lawFirmId, lcmSegment],
    () => analyticsHotjarIdentify(fdrApplicantId, { lawFirmId, lcmSegment }),
    {
      enabled: !!fdrApplicantId && !!lawFirmId && !!lcmSegment,
      staleTime: Infinity,
      retry: 1
    }
  );

  let canRenderGenesysScript;
  if (isAuthenticated && csClientId && isFDR && code && clientLangFromSf) {
    canRenderGenesysScript = true;
  } else {
    canRenderGenesysScript = false;
  }
  useEffect(() => {
    if (code) {
      setIFrameRendered(false);
    }
  }, [code]);

  // LEAVING THIS HERE AS A REMINDER THAT WE MAY
  //  NEED TO HANDLE THESE ROUTES IN THE FUTURE
  // const dashboardRoutes = useRoutes([
  //   { path: '/forgot-password', element: <ForgotPassword /> },
  //   { path: '/setup-password', element: <SetupPassword /> },
  //   { path: '/reset-password', element: <ResetPassword /> }
  // ]);

  const errorBoundaryErrorHandler = error => {
    analyticsTrackEvent(
      { category: 'render', action: 'render', label: 'error' },
      `Render Error Caught by Error Boundary: ${error}`
    );
  };

  // Define the routes and components we want to render if Dashboard
  // is being loaded as WebView via the native app
  if (isWebview) {
    return (
      <Composition
        areas={areas}
        templateCols="1fr"
        templateRows="1fr"
        height="100vh"
      >
        {({ Main }) => (
          <>
            <div
              css={`
                background-color: ${props =>
                  props.theme.colors.backgroundColor};
                height: 100%;
              `}
            >
              <ErrorBoundary
                FallbackComponent={ErrorFallbackRouter}
                onError={errorBoundaryErrorHandler}
                onReset={() => {
                  navigate('/page-error');
                }}
              >
                <Main height="100%">
                  <Suspense fallback={<FallBackLoader isPage />}>
                    <Routes>
                      {/****
                       **** RESTRICTED ROUTES ****
                       ** NOTE: We're wrapping all these routes in a <RouteGuard /> component
                       ** to ensure that the user is authenticated and has the appropriate tokens/permissions needed.
                       ****/}
                      <Route
                        element={
                          <RouteGuard
                            wrapper={ProtectedRoutesWrapper}
                            isDashboard
                          />
                        }
                      >
                        <Route path="/" element={<Home />} />
                        <Route
                          path="/dedicated-account"
                          element={<DedicatedAccount />}
                        />
                        <Route
                          path="/document-vault"
                          element={<DocumentVault />}
                        />
                        <Route
                          path="/customer-service"
                          element={<CustomerService />}
                        >
                          <Route
                            path="bank-account-change"
                            element={<BankAccountChange />}
                          />
                          <Route path="deposits" element={<Deposits />}></Route>
                          <Route
                            path="deposits/change-deposit/*"
                            element={<CsServicesChangeDeposit />}
                          />
                          <Route
                            path="deposits/increase-program-deposit"
                            element={<IncreaseProgramDeposit />}
                          />
                          <Route
                            path="deposits/one-time-deposit"
                            element={<OneTimeDeposit close />}
                          />
                          <Route
                            path="document-upload"
                            element={<SelectDocType />}
                          />
                          <Route
                            path="document-upload/account-statements"
                            element={<AccountStatements />}
                          />
                          <Route
                            path="document-upload/past-due-notices"
                            element={<PastDueNotices />}
                          />
                          <Route
                            path="document-upload/collection-letters"
                            element={<CollectionLetters />}
                          />
                          <Route
                            path="document-upload/legal-documents"
                            element={<LegalDocuments />}
                          />
                          <Route
                            path="document-upload/miscellaneous-documents"
                            element={<MiscellaneousDocuments />}
                          />
                          <Route
                            path="enroll-new-debt"
                            element={<EnrollNewDebt />}
                          />
                        </Route>
                        <Route
                          path="/enrolled-debt"
                          element={<EnrolledDebt />}
                        />
                        <Route
                          path="/enrolled-debt/:accountTag"
                          element={<EnrolledDebtDetail />}
                        />
                        <Route
                          path="/enrolled-debt/negotiation-overview"
                          element={<NegotiationOverview />}
                        />
                        <Route
                          path="/enrolled-debt/negotiation-overview/:sub"
                          element={<NegotiationOverview />}
                        />
                        <Route
                          path="/enrolled-debt/settlement-overview"
                          element={<SettlementOverview />}
                        />
                        <Route
                          path="/notifications"
                          element={<Notifications />}
                        >
                          <Route
                            path=""
                            element={
                              <Navigate to="/notifications/alerts" replace />
                            }
                          />
                          <Route
                            path=":notificationType"
                            element={<NotificationsList />}
                          >
                            <Route
                              path=":referenceId"
                              element={<NotificationContent />}
                            />
                            <Route path="" element={<EmptyContent />} />
                          </Route>
                        </Route>
                        <Route
                          path="/program-resources/*"
                          element={<ProgramResources />}
                        />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/settings/:sub" element={<Settings />} />
                      </Route>

                      {/****
                       **** OPEN ROUTES ****
                       ****/}
                      <Route path="/about" element={<About />} />
                      <Route
                        path="/cea/:lawFirmId"
                        element={<ClientEngagementAgreement />}
                      />
                      <Route
                        path="/cea-generic/:lawFirmId"
                        element={
                          <ClientEngagementAgreement
                            ceaUrl={'client-engagement-agreement-generic'}
                          />
                        }
                      />
                      <Route path="/disclosures" element={<Disclosures />} />
                      <Route path="/login-error" element={<LoginError />} />
                      <Route path="/page-error" element={<ErrorFallback />} />
                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                      />
                      {/*** EMAIL AUTHORIZATION ROUTES */}
                      <Route
                        path="/deposit-authorization/:alertId"
                        element={<AutoDepositAuth />}
                      />
                      <Route
                        path="/settlement-authorization/:settlementCode"
                        element={<AutoSettlementAuth />}
                      />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Suspense>
                  {iFrameRendered && isFDR && (
                    <AuthIframe authCodeFunction={setCode} />
                  )}
                </Main>
              </ErrorBoundary>
            </div>
          </>
        )}
      </Composition>
    );
  }

  return (
    <Composition
      areas={areas}
      templateCols="1fr"
      templateRows="auto 1fr auto"
      height="100vh"
    >
      {({ Header, Main, Footer }) => (
        <>
          <Helmet>
            <title>{brand('business-name.long')} Dashboard</title>
          </Helmet>
          <Header>
            <Nav />
            {canRenderGenesysScript && (
              <GenesysScript
                id={csClientId}
                name={clientName}
                authCode={code}
                language={clientLangFromSf}
              />
            )}
            {showUpscope || showUpscopeAR ? <UpscopeInit /> : null}
          </Header>
          <div
            css={`
              background-color: ${props => props.theme.colors.backgroundColor};
              height: 100%;
            `}
          >
            <ErrorBoundary
              FallbackComponent={ErrorFallbackRouter}
              onError={errorBoundaryErrorHandler}
              onReset={() => {
                navigate('/page-error');
              }}
            >
              <Main height="100%">
                <Suspense fallback={<FallBackLoader isPage />}>
                  <Routes>
                    {/****
                     **** RESTRICTED ROUTES ****
                     ** NOTE: We're wrapping all these routes in a <RouteGuard /> component
                     ** to ensure that the user is authenticated and has the appropriate tokens/permissions needed.
                     ****/}
                    <Route
                      element={
                        <RouteGuard
                          wrapper={ProtectedRoutesWrapper}
                          isDashboard
                        />
                      }
                    >
                      <Route path="/" element={<Home />} />
                      <Route
                        path="/dedicated-account"
                        element={<DedicatedAccount />}
                      />
                      <Route
                        path="/document-vault"
                        element={<DocumentVault />}
                      />
                      <Route
                        path="/customer-service"
                        element={<CustomerService />}
                      >
                        <Route
                          path="bank-account-change"
                          element={<BankAccountChange />}
                        />
                        <Route path="deposits" element={<Deposits />}></Route>
                        <Route
                          path="deposits/change-deposit/*"
                          element={<CsServicesChangeDeposit />}
                        />
                        <Route
                          path="deposits/increase-program-deposit"
                          element={<IncreaseProgramDeposit />}
                        />
                        <Route
                          path="deposits/one-time-deposit"
                          element={<OneTimeDeposit close />}
                        />
                        <Route
                          path="document-upload"
                          element={<SelectDocType />}
                        />
                        <Route
                          path="document-upload/account-statements"
                          element={<AccountStatements />}
                        />
                        <Route
                          path="document-upload/past-due-notices"
                          element={<PastDueNotices />}
                        />
                        <Route
                          path="document-upload/collection-letters"
                          element={<CollectionLetters />}
                        />
                        <Route
                          path="document-upload/legal-documents"
                          element={<LegalDocuments />}
                        />
                        <Route
                          path="document-upload/miscellaneous-documents"
                          element={<MiscellaneousDocuments />}
                        />
                        <Route
                          path="enroll-new-debt"
                          element={<EnrollNewDebt />}
                        />
                      </Route>
                      <Route path="/enrolled-debt" element={<EnrolledDebt />} />
                      <Route
                        path="/enrolled-debt/:accountTag"
                        element={<EnrolledDebtDetail />}
                      />
                      <Route
                        path="/enrolled-debt/negotiation-overview"
                        element={<NegotiationOverview />}
                      />
                      <Route
                        path="/enrolled-debt/negotiation-overview/:sub"
                        element={<NegotiationOverview />}
                      />
                      <Route
                        path="/enrolled-debt/settlement-overview"
                        element={<SettlementOverview />}
                      />
                      <Route path="/notifications" element={<Notifications />}>
                        <Route
                          path=""
                          element={
                            <Navigate to="/notifications/alerts" replace />
                          }
                        />
                        <Route
                          path=":notificationType"
                          element={<NotificationsList />}
                        >
                          <Route
                            path=":referenceId"
                            element={<NotificationContent />}
                          />
                          <Route path="" element={<EmptyContent />} />
                        </Route>
                      </Route>
                      <Route
                        path="/program-resources/*"
                        element={<ProgramResources />}
                      />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/settings/:sub" element={<Settings />} />
                    </Route>

                    {/****
                     **** OPEN ROUTES ****
                     ****/}
                    <Route path="/about" element={<About />} />
                    <Route
                      path="/cea/:lawFirmId"
                      element={<ClientEngagementAgreement />}
                    />
                    <Route
                      path="/cea-generic/:lawFirmId"
                      element={
                        <ClientEngagementAgreement
                          ceaUrl={'client-engagement-agreement-generic'}
                        />
                      }
                    />
                    <Route path="/disclosures" element={<Disclosures />} />
                    <Route path="/login-error" element={<LoginError />} />
                    <Route path="/page-error" element={<ErrorFallback />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    {/*** EMAIL AUTHORIZATION ROUTES */}
                    <Route
                      path="/deposit-authorization/:alertId"
                      element={<AutoDepositAuth />}
                    />
                    <Route
                      path="/settlement-authorization/:settlementCode"
                      element={<AutoSettlementAuth />}
                    />
                    {/*** CATCH-ALL 404 ROUTE */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
                {!iframeAuth0Code && iFrameRendered && isFDR && (
                  <AuthIframe authCodeFunction={setCode} />
                )}
              </Main>
            </ErrorBoundary>
            {isAuthenticated && <EduWizard />}
          </div>
          <FeatureFlag flagKey="footer.footer">
            <Footer>
              {subDomain === 'achieve' ? <AchieveFooter /> : <FDRFooter />}
            </Footer>
          </FeatureFlag>
          <ChangePasswordModal />
          <StickyFooterBlock />
        </>
      )}
    </Composition>
  );
}
