import { useEffect, useState } from 'react';

/**
 * This hook can be used to determine if an element is visible within the viewport, using
 * IntersectionObserver under the hood.
 *
 * More on IntersectionObserver: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */

export function useElementIsVisible(ref, pctVisible = 0.5) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentNode = ref.current;
    if (currentNode) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        },
        {
          root: null, // Defaults to viewport when null
          rootMargin: '0px', // Margin around the root element to apply (0 is the default, just being explicit in setting it here)
          threshold: pctVisible // Percentage of element that needs to be visible in order to count as 'in view'
        }
      );

      // Actually observe the element
      observer.observe(currentNode);

      // Clean up the observer when the component unmounts
      return () => observer.unobserve(currentNode);
    }
  }, [ref, pctVisible]);

  return isVisible;
}
