import { useContext, useMemo } from "react";
import { siteDataContext } from "shared-components/ProvideSiteData";
import { useSelector } from "react-redux";
import * as R from "ramda";
import { createSelector } from "reselect";
import { getClient } from "shared-selectors/enrolled-debt";

function makePage(pages) {
	return stringPath => R.path(stringPath.split("."))(pages);
}
const getLawFirmId = createSelector(getClient, client => client?.lawFirmId);

export function usePage() {
	const { pages } = useContext(siteDataContext);
	const lawFirmId = useSelector(getLawFirmId);
	const page = useMemo(
		() =>
			makePage(
				R.mergeDeepRight(pages?.default ?? pages, pages?.[lawFirmId] ?? {})
			),
		[pages, lawFirmId]
	);

	return page;
}
