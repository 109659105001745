import { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../constants/index';
import { recaptchaHandler } from 'lib/api';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { getAppSubDomain, logger } from 'shared-components';
import { isLocalInstance } from '../../lib/utils/isLocalInstance';

/**
 * Renders a Google reCAPTCHA widget and handles the verification of the reCAPTCHA response token.
 * @param {Object} props - The component props.
 * @param {Function} props.setIsReCaptchaVerified - A function to update the state indicating whether the reCAPTCHA is verified.
 * @param {Object} props.reCaptchaRef - A reference to the reCAPTCHA component.
 * @returns {JSX.Element} - The rendered component.
 */
export function ReCaptcha({ setIsReCaptchaVerified, reCaptchaRef }) {
  const subdomain = getAppSubDomain();
  const SITE_KEY = GOOGLE_RECAPTCHA_SITE_KEY[subdomain];
  const appRunningLocally = isLocalInstance();

  /**
   * Executes the reCAPTCHA widget when the reCaptchaRef is updated.
   */
  useEffect(() => {
    if (reCaptchaRef?.current && !appRunningLocally) {
      reCaptchaRef.current.execute();
    }
  }, [reCaptchaRef, appRunningLocally]);

  if (appRunningLocally) {
    setIsReCaptchaVerified(true);
    return null;
  }

  /**
   * Handles the reCAPTCHA response token and verifies it.
   * @param {string} token - The reCAPTCHA response token.
   */
  const handleReCaptcha = async token => {
    try {
      const response = await recaptchaHandler(token, subdomain);
      if (response.success === true) {
        analyticsTrackEvent(
          {
            category: 'auto_email_sett_auth',
            action: 'captcha_successfully_verified',
            label: 'ras'
          },
          'ReCaptcha Success on non-login settlement page'
        );
        setIsReCaptchaVerified(true);
        reCaptchaRef.current.reset();
      } else {
        // 'Recaptcha response came back as false hence client will see call-us page.'
        setIsReCaptchaVerified(false);
      }
    } catch (error) {
      logger.error('Error verifying reCAPTCHA:', error);
      setIsReCaptchaVerified(false);
    }
  };

  return (
    <ReCAPTCHA
      sitekey={SITE_KEY}
      onChange={handleReCaptcha}
      size="invisible"
      ref={reCaptchaRef}
      badge="bottomright"
    />
  );
}
