import { Image } from '@ffn/ui';
import { useBrand } from 'lib/hooks';

export const AchieveLogo = ({ isDesktop }) => {
  const brand = useBrand();

  return (
    <Image
      alt="Achieve logo"
      src={brand('logo.main')}
      width={isDesktop ? '180px' : '120px'}
    />
  );
};
