import React from 'react';
import 'styled-components/macro';
import { Box } from 'atomic-layout';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { Icon, Button } from '@ffn/ui';
import { Currency } from 'shared-components';
import { shortDateWithLongYear } from 'lib/utils';
import * as constants from '../../../constants';
import { useTranslation } from 'lib/hooks';

const UserStillHasDeficitMessage = ({
  deficitAmount,
  handleMakeAnotherDepositClick
}) => {
  const { t } = useTranslation();
  return (
    <Box>
      <p>
        {t('alerts.insufficientFunds.howeverYourSettlement')}{' '}
        <Currency value={deficitAmount} />.{' '}
        {t(
          'alerts.insufficientFunds.toGetYourProgramBackOnTrackScheduleAnotherDepositNow'
        )}
      </p>
      <Button
        onClick={() => handleMakeAnotherDepositClick()}
        variant="tertiary"
        height={40}
        width="100%"
        rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
      >
        {t('alerts.insufficientFunds.makeAnotherDeposit')}
      </Button>
    </Box>
  );
};

export function SuccessStep({
  client,
  successData,
  initialAmount,
  handleMakeAnotherDepositClick
}) {
  const { t } = useTranslation();
  // Subtle, but initialAmount will change after the deposit and Firebase cache updates
  // with the correct alert amount from Salesforce.
  // TODO: A possibility exists that this could be out of sync for a moment.
  let displayDeficitAmount = initialAmount - successData?.amount;

  return (
    <Box
      flex
      flexDirection="column"
      css={`
        gap: 24px;
      `}
    >
      {client.spa === constants.STATEMENT_PROVIDER.CFT && (
        <Box>
          {t('alerts.insufficientFunds.yourOneTimeAdditionalDepositOf')}{' '}
          <Currency value={successData?.amount} />{' '}
          {t('alerts.insufficientFunds.hasBeenScheduledFor')}{' '}
          {shortDateWithLongYear(successData?.depositDate)}.
        </Box>
      )}

      {displayDeficitAmount > 0 && (
        <UserStillHasDeficitMessage
          deficitAmount={displayDeficitAmount}
          handleMakeAnotherDepositClick={handleMakeAnotherDepositClick}
        />
      )}

      {client.spa === constants.STATEMENT_PROVIDER.GCS && (
        <Box>
          {t(
            'alerts.insufficientFunds.yourRequestForAOneTimeAdditionalDepositOf'
          )}{' '}
          <Currency value={successData?.amount} />{' '}
          {t('alerts.insufficientFunds.hasBeenReceivedOneOfOur')}
        </Box>
      )}
    </Box>
  );
}
