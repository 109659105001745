import { axiosInstance as axios } from 'lib/axios';

export const updateCommunicationPreferences = async ({ preferences }) => {
  const apiUrl = `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/emailPreferences`;
  try {
    const response = await axios.put(apiUrl, preferences, {
      validateStatus: status => status >= 200 && status < 500
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export default updateCommunicationPreferences;
