import React from 'react';
import { useContent } from 'lib/hooks';
import EduCarousel from './EduCarousel';
import { ErrorView, FallBackLoader } from 'shared-components';
import {
  Drawer,
  useMediaQuery,
  useTheme,
  Box,
  Divider,
  Grid,
  Typography
} from '@achieve/sunbeam';
import InfoCard from '../InfoCard';
import Nav from '../../DrawerNav/SettlementAuth/Nav';

import { useClientEducation } from 'lib/hooks';
import { LessonPreviewCard } from '../LessonPreviewCard';
import { useMemo } from 'react';

export const LessonsList = () => {
  const { pageData, error, loading } = useContent({ pageUrl: 'carousel-list' });

  if (loading) return <FallBackLoader data-testid="fallback-loader" />;
  if (error || (!pageData && !loading)) return <ErrorView />;

  const sections = pageData?.fields?.sections;

  return (
    <>
      <Grid container position="relative">
        <Box
          p={['24px', '24px', '40px']}
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'center',
            color: '#000',
            flexDirection: 'column',
            backgroundColor: '#F5F5F5'
          }}
        >
          <Typography variant="displayM20" fontWeight="bold">
            Your start to a debt-free future
          </Typography>
          <Typography variant="bodyS30" fontWeight="regular">
            This new client orientation will start you on the right path for
            your debt relief program. Learn all you need to start your first
            month successfully.
          </Typography>
        </Box>
      </Grid>
      <Grid container justifyContent="center">
        {sections?.map(({ fields }, i) => (
          <LessonItem key={fields.trackingId} fields={fields} index={i} />
        ))}
      </Grid>
      <InfoCard
        title={'Expert negotiators on your side'}
        subTitle={'200+ debt negotiators with the negotiating power you need'}
      />
      {sections ? <LessonDrawer lessons={sections} /> : null}
    </>
  );
};

function LessonItem({ fields, index }) {
  const { openLesson } = useClientEducation();
  const { trackingId } = fields;

  if (!fields) return null;

  return (
    <Box display="flex" justifyContent="center">
      <LessonPreviewCard
        size="lg"
        lesson={fields}
        index={index}
        handleOpenClick={() =>
          openLesson({ trackingId, invokedFrom: 'edu_drawer' })
        }
      />
    </Box>
  );
}

function LessonDrawer({ lessons }) {
  const { lessonToDisplay } = useClientEducation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const lessonsDictionary = useMemo(() => {
    const lessonsDictionary = {};
    lessons.forEach(({ fields }, index) => {
      const nextLessonData = lessons[index + 1];
      lessonsDictionary[fields.trackingId] = {
        ...fields,
        lessonIndex: index,
        nextLessonData: nextLessonData ? { ...nextLessonData.fields } : null
      };
    });

    return lessonsDictionary;
  }, [lessons]);

  const drawerWidth = isMobile ? '100%' : 450;
  const lessonData = lessonsDictionary[lessonToDisplay];

  if (!lessonData) return null;

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={lessonToDisplay !== null}
      sx={{
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          width: drawerWidth
        }
      }}
    >
      <DrawerContent lessonData={lessonData} isMobile={isMobile} />
    </Drawer>
  );
}

function DrawerContent({ lessonData, isMobile }) {
  const { closeLesson } = useClientEducation();
  const theme = useTheme();

  if (!lessonData) return null;

  const { lessonIndex, title } = lessonData;

  return (
    <>
      <Nav
        backAction={closeLesson}
        title={
          <Box flexDirection="column" display="flex">
            <Typography
              color={theme.sb.colors.neutral.grey[2]}
              variant="bodyS30"
              fontWeight="bold"
              textAlign="center"
            >
              Lesson {lessonIndex + 1}
            </Typography>
            <Typography
              variant="displayS10"
              fontWeight="bold"
              textAlign="center"
            >
              {title}
            </Typography>
          </Box>
        }
        testId={`lesson-back-btn ${lessonIndex}`}
      />
      <Divider />
      <EduCarousel lessonData={lessonData} isMobile={isMobile} />
    </>
  );
}
