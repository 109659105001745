/** Returns the application path as a string based on 
 *  requirements for Tealium
 * 
 * @param {object} currentPath 
 * @returns {string} pathString
 * 
 * page_path - <application-name> | <url path> 
      EX. FDR Client Dashboard | program-resources: videos: achieving-your-goals
      for no path, ie homepage, return empty string
 */
export const createPagePathString = (currentPath, appNameString) => {
  let path = currentPath.split('/').filter(subPath => subPath !== '');

  const pathPartCount = path.length;

  let pathString = pathPartCount !== 0 ? appNameString : '';

  if (pathPartCount) {
    let pathIndex = 0;
    while (pathIndex < pathPartCount) {
      pathString += ` ${path[pathIndex]}:`;
      pathIndex++;
    }

    // path shouldn't end with a :
    // removes final character
    pathString = pathString.substring(0, pathString.length - 1);

    return pathString;
  }
};
