import React from 'react';
import { useTheme } from '@achieve/sunbeam';
export const CallUsSVG = () => {
  const theme = useTheme()
  const blue = theme?.palette?.primary?.main ?? "#154199";

  return (
    <svg
      aria-hidden="true"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2264_11244)">
        <path
          d="M32.001 2.66602C18.7476 2.66602 8.00098 13.4127 8.00098 26.666V45.3327C8.00098 49.7593 11.5743 53.3327 16.001 53.3327H24.001V31.9993H13.3343V26.666C13.3343 16.346 21.681 7.99935 32.001 7.99935C42.321 7.99935 50.6676 16.346 50.6676 26.666V31.9993H40.001V53.3327H50.6676V55.9994H32.001V61.3327H48.001C52.4276 61.3327 56.001 57.7593 56.001 53.3327V26.666C56.001 13.4127 45.2543 2.66602 32.001 2.66602Z"
          fill={blue}
        />
      </g>
      <defs>
        <clipPath id="clip0_2264_11244">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
