import React from 'react';
import { Box, CircularProgress } from '@achieve/sunbeam';

export function FallBackLoader({ isPage }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      margin={isPage ? '50px' : 0}
      data-testid="fallbackLoader"
    >
      <CircularProgress />
    </Box>
  );
}

export default FallBackLoader;
