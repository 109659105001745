import React from 'react';
import 'styled-components/macro';
import { useBrand } from 'lib/hooks';
import { Alert } from '@ffn/icons';
import { Icon } from '@ffn/ui';
import { Composition } from 'atomic-layout';

export const SiteNoticeBanner = () => {
  const brand = useBrand();
  const canShow = brand('site.site-notice-enabled');
  if (!canShow) {
    return null;
  }
  const siteMessage = brand('site.site-notice-message');
  return (
    <div
      css={`
          width: 100%;
          background: #bd252c;
          color: #FFF;
          padding-top: 16px;
          padding-bottom: 16px;
        `}
    >
      <div
        css={`
          width: 100%;
          max-width: 1200px;
          margin: auto;
          padding-right: 15px;
          padding-left: 15px;
          font-weight: 400;
        `}
      >
        <Composition
          areas={`Ico TextMsg`}
          gap={16}
          templateCols="32px 1fr"
          templateRows="auto"
        >
          {({ Ico, TextMsg }) => (
            <>
              <Ico
                alignItems="center"
              >
                <span
                  css={`
                    vertical-align: middle;
                    display: inline-block;
                  `}
                >
                  <Icon icon={Alert} size={32} />
                </span>
              </Ico>
              <TextMsg>
                {siteMessage}
              </TextMsg>
            </>
          )}
        </Composition>
      </div>
    </div>
  );
};

export default SiteNoticeBanner;
