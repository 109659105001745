import React from 'react';
import 'styled-components/macro';
import { Box, Composition } from 'atomic-layout';
import { ChevronThinRight } from '@styled-icons/entypo/ChevronThinRight';
import { Icon, Button, Heading, Link } from '@ffn/ui';
import { NavLink } from 'react-router-dom';
import { Alert } from '@ffn/icons';
import { useBrand } from 'lib/hooks';
import { isNil } from 'ramda';
import { useTranslation } from 'lib/hooks';

function UnableToPerformRequest({ goToSelect }) {
  const { t } = useTranslation();
  const brand = useBrand();
  return (
    <Box flex flexDirection="column" alignItems="center">
      <Composition
        areas={`
					alertIcon alertText
					space contact
				`}
        templateCols="32px 1fr"
        alignItems="center"
        gapCol={16}
        width="100%"
        marginBottom={24}
      >
        {({ AlertIcon, AlertText, Contact }) => (
          <>
            <AlertIcon>
              <Icon icon={Alert} size={32} color="secondaryRed" />
            </AlertIcon>
            <AlertText>
              <Heading
                as="h2"
                css={`
                  font-weight: 700;
                  color: ${({ theme }) => theme.colors.secondaryRed};
                `}
              >
                {t('changeDepositWizard.unableToCompleteRequestText')}
              </Heading>
            </AlertText>
            <Contact>
              {t('changeDepositWizard.youCan')} {t('changeDepositWizard.call')}{' '}
              <Link href={`tel:${brand('contact.customer-service.phone')}`}>
                {brand('contact.customer-service.phone')}
              </Link>{' '}
              {t('changeDepositWizard.toContactCustomerService')}.
            </Contact>
          </>
        )}
      </Composition>
      {isNil(goToSelect) ? (
        <NavLink to="/customer-service" style={{ textDecoration: 'none' }}>
          <Button
            variant="tertiary"
            height={40}
            width={150}
            rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
          >
            {t('changeDepositWizard.done')}
          </Button>
        </NavLink>
      ) : (
        <Button
          variant="tertiary"
          height={40}
          width={150}
          rightEnhancer={<Icon icon={ChevronThinRight} size={14} />}
          onClick={goToSelect}
        >
          {t('changeDepositWizard.done')}
        </Button>
      )}
    </Box>
  );
}

export default UnableToPerformRequest;
