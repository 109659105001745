import React from 'react';
import { Composition } from 'atomic-layout';
import 'styled-components/macro';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation } from 'lib/hooks';

export function LanguageToggle({ language, changeLocale }) {
  const { t } = useTranslation();

  return (
    <div
      css={`
        width: 160px;
        border-radius: 4px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid ${props => props.theme.colors.grayDark};
        padding: 2px;
        cursor: pointer;
      `}
    >
      <Composition areas={`English Spanish`} templateCols="auto">
        {({ English, Spanish }) => (
          <>
            <English
              data-testid="english-language-toggle-switch"
              onClick={() => {
                changeLocale('en-US');
                analyticsTrackEvent(
                  {
                    category: 'language-switch',
                    action: 'click',
                    label: 'en-US'
                  },
                  'Language Switch'
                );
              }}
            >
              {language === 'en-US' ? (
                <div
                  css={`
                    background: ${props => props.theme.colors.secondaryRed};
                    color: ${props => props.theme.colors.plain};
                    border-radius: 4px;
                    text-align: center;
                    font-size: 12px;
                    padding: 4px;
                  `}
                >
                  {t('common.english')}
                </div>
              ) : (
                <div
                  css={`
                    background: ${props => props.theme.colors.plain};
                    color: ${props => props.theme.colors.secondaryRed};
                    border-radius: 4px;
                    text-align: center;
                    font-size: 12px;
                    padding: 4px;
                  `}
                >
                  {t('common.english')}
                </div>
              )}
            </English>
            <Spanish
              data-testid="spanish-language-toggle-switch"
              onClick={() => {
                changeLocale('es');
                analyticsTrackEvent(
                  {
                    category: 'language-switch',
                    action: 'click',
                    label: 'es'
                  },
                  'Language Switch'
                );
              }}
            >
              {language === 'es' ? (
                <div
                  css={`
                    background: ${props => props.theme.colors.secondaryRed};
                    color: ${props => props.theme.colors.plain};
                    border-radius: 4px;
                    text-align: center;
                    font-size: 12px;
                    padding: 4px;
                  `}
                >
                  {t('common.spanish')}
                </div>
              ) : (
                <div
                  css={`
                    background: ${props => props.theme.colors.plain};
                    color: ${props => props.theme.colors.secondaryRed};
                    border-radius: 4px;
                    text-align: center;
                    font-size: 12px;
                    padding: 4px;
                  `}
                >
                  {t('common.spanish')}
                </div>
              )}
            </Spanish>
          </>
        )}
      </Composition>
    </div>
  );
}

export default LanguageToggle;
