import { Typography } from '@achieve/sunbeam';
import PropTypes from 'prop-types';

import useStyles from './ProgressBar.styles';

const ProgressBar = props => {
  const { completed, headerName = '', showCompletedPercentage = false } = props;
  const { classes } = useStyles();
  const animationStyles = {
    width: `${completed}%`
  };
  return (
    <div className={classes.rootContainer}>
      <Typography
        variant="bodyS20"
        fontWeight="bold"
        className={classes.progressLabelStyles}
      >
        {headerName}
      </Typography>
      {showCompletedPercentage ? (
        <Typography
          variant="bodyS20"
          fontWeight="bold"
          className={classes.progressPercStyles}
        >
          {completed}%
        </Typography>
      ) : (
        ''
      )}
      <section className={classes.containerStyles}>
        <div
          className={classes.fillerStyles}
          data-testid={`progress-bar-${completed}`}
          style={animationStyles}
        ></div>
      </section>
    </div>
  );
};

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired
};

export default ProgressBar;
