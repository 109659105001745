/** This component is the react-boundary-error FallbackComponent prop.
 * It may not render it's own content because react router is unmounted
 * when an error is thrown, the fallback page is loaded,
 * and the Nav and Footer becomes unresponsive. This component
 * calls the resetErrorBoundary callback, which calls react-router's useNavigate
 * to send users to the ErrorFallback component which renders with the appropriate message
 * and functioning Nav components
 */

import React, { useEffect } from 'react';

export function ErrorFallbackRouter({ resetErrorBoundary }) {
  // useEffect insures the callback is only called after the component mounts
  useEffect(() => {
    resetErrorBoundary();
  }, [resetErrorBoundary]);
  return <></>;
}
