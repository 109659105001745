import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  useAuth,
  useBrand,
  useChangePasswordModal,
  useLocale,
  useTranslation
} from 'lib/hooks';
import 'styled-components/macro';

import {
  Badge,
  Container,
  Dropdown,
  DropdownItem,
  DropdownSeparator,
  Icon,
  Image,
  Link
} from '@ffn/ui';
import {
  AddDeposit,
  AskQuestion,
  Close,
  HowItWorks,
  Menu,
  Video,
  WelcomeCall
} from '@ffn/icons';

import { BrandLogo, FeatureFlag, LanguageToggle } from 'shared-components';
import { Box, Composition } from 'atomic-layout';
import { useSelector } from 'react-redux';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { EVENT_ACTION } from 'lib/utils/eventHorizon';
import {
  ArrowLeft,
  Bell,
  FileText,
  Lock,
  LogOut,
  Settings,
  User
} from 'react-feather';

import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import styles from './Nav.module.scss';
import { ReturnToMemberDashboardMenuItem } from './ReturnToMemberDashboardMenuItem';

import { getTestIds } from 'lib/utils/dataTestIds';

const headerTestIDs = getTestIds('header');

const domain = document?.domain ?? '';

const testAreas = `
menus brand accountInfo
`;

export function MobileNav({ showMenuItems }) {
  const brand = useBrand();
  const { logout } = useAuth();
  const notifications = useSelector(state => state.models.notifications);
  const badgeTotal = notifications?.totalAlerts + notifications?.totalMessages;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = React.useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen, setDrawerOpen]);
  const theme = useTheme();
  const isMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const { openChangePasswordModal } = useChangePasswordModal();

  // state to handle profile dropdown visibility
  const [profileVisible, setProfileVisible] = useState(false);
  const showProfile = useCallback(() => setProfileVisible(true), []);
  const hideProfile = useCallback(() => setProfileVisible(false), []);

  const { t } = useTranslation();
  const { changeLocale, locale } = useLocale();

  const handleMenuClick = menuItemName => {
    setDrawerOpen(false);
    analyticsTrackEvent(
      {
        event_action: EVENT_ACTION.LINK_CLICK,
        nav_link_section: 'Mega Menu',
        mega_menu_column: menuItemName,
        mega_menu_tab: menuItemName,
        mega_menu_link: menuItemName,
        track_event: 'mega_menu'
      },
      `${menuItemName} Link Click`
    );
  };

  const handleProfileClick = (profileItemName, path) => {
    hideProfile();
    analyticsTrackEvent(
      {
        event_action: EVENT_ACTION.DROPDOWN_SELECT,
        nav_link_section: 'Header',
        click_type: 'Link Click',
        click_id: `Profile: ${profileItemName}`,
        click_url: 'https://' + domain + path,
        clickText: `Profile: ${profileItemName}`,
        track_event: 'global_header'
      },
      `Profile: ${profileItemName} Link Click`
    );
  };

  return (
    <>
      <Composition
        as={Container}
        areas={testAreas}
        templateCols="100px 1fr 100px"
        templateRows="64px"
        paddingHorizontal={15}
      >
        {({ Menus, Brand, AccountInfo }) => (
          <>
            <Brand flex justifyContent="center">
              <NavLink
                to="/"
                data-testid={headerTestIDs.brand_home_link}
                className={styles.logoWrapper}
              >
                <BrandLogo />
              </NavLink>
            </Brand>
            {showMenuItems && (
              <>
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  variant="persistent"
                  PaperProps={{
                    sx: {
                      borderRight: '0px'
                    }
                  }}
                >
                  <Composition
                    templateCols="1fr auto"
                    templateRows="1fr"
                    css={`
                      height: 100%;
                      width: ${isMdBreakpoint ? '100vw' : '360px'};
                    `}
                  >
                    <Box
                      css={`
                        font-weight: 600;
                      `}
                    >
                      <div
                        className="tourLinkWrapper"
                        data-tour="navigation-menu"
                      >
                        <Box paddingHorizontal={24} marginVertical={24}>
                          <Link
                            as={NavLink}
                            to="/"
                            onClick={() => handleMenuClick('Homepage')}
                            data-testid={headerTestIDs.mobile_drawer_home_link}
                          >
                            <Image height={40} src={brand('logo.main')} />
                          </Link>
                        </Box>
                        <FeatureFlag pathKey="/enrolled-debt">
                          <Box
                            className={styles.mobileItem}
                            paddingHorizontal={24}
                            marginVertical={24}
                            data-tour="enrolled-debt-nav"
                          >
                            <Link
                              as={NavLink}
                              to="/enrolled-debt"
                              onClick={() => handleMenuClick('Enrolled Debt')}
                              dark
                              data-testid={headerTestIDs.enrolled_debt_link}
                            >
                              {t('nav.enrolledDebt')}
                            </Link>
                          </Box>
                        </FeatureFlag>
                        <FeatureFlag pathKey="/dedicate-account">
                          <Box
                            paddingHorizontal={24}
                            marginVertical={24}
                            className={styles.mobileItem}
                          >
                            <Link
                              as={NavLink}
                              data-tour="dedicated-account-nav"
                              to="/dedicated-account"
                              onClick={() =>
                                handleMenuClick('Dedicated Account')
                              }
                              dark
                              data-testid={headerTestIDs.dedicated_account_link}
                            >
                              {t('nav.dedicatedAccount')}
                            </Link>
                          </Box>
                        </FeatureFlag>
                        <FeatureFlag pathKey="/program-resources">
                          <Box
                            paddingHorizontal={24}
                            marginVertical={24}
                            className={styles.mobileItem}
                          >
                            <Link
                              as={NavLink}
                              data-tour="program-resources-nav"
                              to="/program-resources"
                              onClick={() =>
                                handleMenuClick('Program Resources')
                              }
                              dark
                              data-testid={
                                headerTestIDs.mobile_program_resources_link
                              }
                            >
                              {t('nav.programResources')}
                            </Link>
                          </Box>
                        </FeatureFlag>
                        <FeatureFlag pathKey="/customer-service">
                          <Box
                            paddingHorizontal={24}
                            marginVertical={24}
                            className={styles.mobileItem}
                          >
                            <Link
                              as={NavLink}
                              data-tour="customer-service-nav"
                              to="/customer-service"
                              onClick={() =>
                                handleMenuClick('Customer Service')
                              }
                              dark
                              data-testid={headerTestIDs.customer_service_link}
                            >
                              {t('nav.customerService')}
                            </Link>
                          </Box>
                        </FeatureFlag>
                        <FeatureFlag flagKey="nav.key-resources">
                          <Box
                            css={`
                              background: ${({ theme }) =>
                                theme.colors.grayLight};
                            `}
                          >
                            <Box
                              paddingHorizontal={24}
                              paddingTop={24}
                              paddingBottom={12}
                              css={`
                                font-size: 12px;
                                font-weight: 300;
                                color: ${({ theme }) => theme.colors.black};
                              `}
                            >
                              {t('nav.keyResources')}
                            </Box>
                            <FeatureFlag pathKey="/program-resources/how-it-works">
                              <Box
                                paddingHorizontal={24}
                                paddingVertical={12}
                                className={styles.mobileItem}
                              >
                                <Link
                                  as={NavLink}
                                  to="/program-resources/how-it-works"
                                  onClick={() => {
                                    analyticsTrackEvent(
                                      {
                                        event_action: EVENT_ACTION.LINK_CLICK,
                                        nav_link_section: 'Mega Menu',
                                        mega_menu_column: 'How it Works',
                                        mega_menu_tab: 'How it Works',
                                        mega_menu_link: 'How it Works',
                                        track_event: 'mega_menu'
                                      },
                                      'How it Works Link Click'
                                    );
                                  }}
                                  dark
                                  data-testid={
                                    headerTestIDs.mobile_howitworks_link
                                  }
                                >
                                  <Icon icon={HowItWorks} size={20} />
                                  <span style={{ marginLeft: 8 }}>
                                    {t('nav.howItWorks')}
                                  </span>
                                </Link>
                              </Box>
                            </FeatureFlag>
                            <FeatureFlag pathKey="/program-resources/faq">
                              <Box paddingHorizontal={24} paddingVertical={12}>
                                <Link
                                  as={NavLink}
                                  to="/program-resources/faq"
                                  onClick={() => {
                                    analyticsTrackEvent(
                                      {
                                        event_action: EVENT_ACTION.LINK_CLICK,
                                        nav_link_section: 'Mega Menu',
                                        mega_menu_column: 'FAQ',
                                        mega_menu_tab: 'FAQ',
                                        mega_menu_link: 'FAQ',
                                        track_event: 'mega_menu'
                                      },
                                      'FAQ Link Click'
                                    );
                                  }}
                                  dark
                                  data-testid={headerTestIDs.mobile_faq_link}
                                >
                                  <Icon icon={AskQuestion} size={20} />
                                  <span style={{ marginLeft: 8 }}>FAQ</span>
                                </Link>
                              </Box>
                            </FeatureFlag>
                            <FeatureFlag pathKey="/program-resources/videos">
                              <Box paddingHorizontal={24} paddingVertical={12}>
                                <Link
                                  as={NavLink}
                                  to="/program-resources/videos"
                                  onClick={() => {
                                    analyticsTrackEvent(
                                      {
                                        event_action: EVENT_ACTION.LINK_CLICK,
                                        nav_link_section: 'Mega Menu',
                                        mega_menu_column: 'Helpful Videos',
                                        mega_menu_tab: 'Helpful Videos',
                                        mega_menu_link: 'Helpful Videos',
                                        track_event: 'mega_menu'
                                      },
                                      'Helpful Videos Link Click'
                                    );
                                  }}
                                  dark
                                  data-testid={headerTestIDs.mobile_videos_link}
                                >
                                  <Icon icon={Video} size={20} />
                                  <span style={{ marginLeft: 8 }}>
                                    {t('nav.helpfulVideos')}
                                  </span>
                                </Link>
                              </Box>
                            </FeatureFlag>
                            <FeatureFlag pathKey="/customer-service">
                              <Box paddingHorizontal={24} paddingVertical={12}>
                                <Link
                                  as={NavLink}
                                  to="/customer-service"
                                  onClick={() => {
                                    analyticsTrackEvent(
                                      {
                                        event_action: EVENT_ACTION.LINK_CLICK,
                                        nav_link_section: 'Mega Menu',
                                        mega_menu_column: 'Contact Us',
                                        mega_menu_tab: 'Contact Us',
                                        mega_menu_link: 'Contact Us',
                                        track_event: 'mega_menu'
                                      },
                                      'Contact Us Link Click'
                                    );
                                  }}
                                  dark
                                  data-testid={
                                    headerTestIDs.mobile_drawer_cs_link
                                  }
                                >
                                  <Icon icon={WelcomeCall} size={20} />
                                  <span style={{ marginLeft: 8 }}>
                                    {t('nav.contactUs')}
                                  </span>
                                </Link>
                              </Box>
                            </FeatureFlag>
                            <FeatureFlag pathKey="/customer-service/deposits">
                              <Box
                                paddingHorizontal={24}
                                paddingTop={12}
                                paddingBottom={24}
                              >
                                <Link
                                  as={NavLink}
                                  to="/customer-service/deposits"
                                  onClick={() => {
                                    analyticsTrackEvent(
                                      {
                                        event_action: EVENT_ACTION.LINK_CLICK,
                                        nav_link_section: 'Mega Menu',
                                        mega_menu_column: 'Add a Deposit',
                                        mega_menu_tab: 'Add a Deposit',
                                        mega_menu_link: 'Add a Deposit',
                                        track_event: 'mega_menu'
                                      },
                                      'Add a Deposit Link Click'
                                    );
                                  }}
                                  dark
                                  data-testid={
                                    headerTestIDs.mobile_drawer_deposits_link
                                  }
                                >
                                  <Icon icon={AddDeposit} size={20} />
                                  <span style={{ marginLeft: 8 }}>
                                    {t('nav.addADeposit')}
                                  </span>
                                </Link>
                              </Box>
                            </FeatureFlag>
                          </Box>
                        </FeatureFlag>
                      </div>
                    </Box>
                    <Box
                      paddingTop={40}
                      paddingHorizontal={24}
                      css={`
                        background: ${({ theme }) => theme.colors.primaryBlue};
                        color: ${({ theme }) => theme.colors.plain};
                      `}
                    >
                      <div onClick={toggleDrawer}>
                        <Icon
                          icon={Close}
                          size={32}
                          css={`
                            cursor: pointer;
                          `}
                          data-testid={headerTestIDs.mobile_drawer_close_button}
                        />
                      </div>
                    </Box>
                  </Composition>
                </Drawer>

                <Menus flex>
                  <Icon
                    id="open-menu"
                    icon={Menu}
                    size={24}
                    color="primaryBlue"
                    css={`
                      cursor: pointer;
                      margin-top: 20px;
                    `}
                    data-testid={headerTestIDs.mobile_drawer_menu_button}
                    /** We have to write this as anonymous function below
                     * so we can programmatically trigger the nav open event from the Product Tour feature
                     * onClick={toggleDrawer} won't work, for some unknown reason. */
                    onClick={() => toggleDrawer()}
                  />
                </Menus>
                <AccountInfo flex justifyContent="right">
                  <div id="tour-trigger-profile-dropdown">
                    <Dropdown
                      visible={profileVisible}
                      onClickOutside={() => hideProfile()}
                      content={
                        <>
                          <FeatureFlag flagKey="nav.return-to-member-dashboard">
                            <DropdownItem
                              as={ReturnToMemberDashboardMenuItem}
                              to="/dashboard/products"
                              onClick={() => {
                                analyticsTrackEvent(
                                  {
                                    event_action: EVENT_ACTION.DROPDOWN_SELECT,
                                    nav_link_section: 'Header',
                                    click_type: 'Link Click',
                                    click_id:
                                      'Profile: Return to Member Dashboard',
                                    click_url:
                                      'https://' +
                                      domain +
                                      '/dashboard/products',
                                    clickText:
                                      'Profile: Return to Member Dashboard', // ex. Profile: Document Vault
                                    track_event: 'global_header'
                                  },
                                  'Profile: Return to Member Dashboard Link Click'
                                );
                              }}
                            >
                              <Icon icon={ArrowLeft} size={20} />
                              <span
                                style={{ marginLeft: 8 }}
                                data-testid={
                                  headerTestIDs.account_return_to_member_dashboard_link
                                }
                              >
                                {t('nav.returnToMemberDashboard')}
                              </span>
                            </DropdownItem>
                          </FeatureFlag>
                          <FeatureFlag flagKey="nav.return-to-member-dashboard">
                            <DropdownSeparator />
                          </FeatureFlag>
                          {showMenuItems && (
                            <>
                              <FeatureFlag pathKey="/notifications">
                                <DropdownItem
                                  as={NavLink}
                                  data-tour="notifications"
                                  to="/notifications"
                                  onClick={() =>
                                    handleProfileClick(
                                      'Notifications',
                                      '/notifications'
                                    )
                                  }
                                >
                                  <Badge count={badgeTotal}>
                                    <Icon icon={Bell} size={20} />
                                  </Badge>
                                  <span
                                    style={{ marginLeft: 8 }}
                                    data-testid={
                                      headerTestIDs.account_notifications_link
                                    }
                                  >
                                    {t('nav.notifications')}
                                  </span>
                                </DropdownItem>
                              </FeatureFlag>
                              <FeatureFlag pathKey="/document-vault">
                                <DropdownItem
                                  as={NavLink}
                                  to="/document-vault"
                                  onClick={() =>
                                    handleProfileClick(
                                      'Document Vault',
                                      '/document-vault'
                                    )
                                  }
                                >
                                  <Icon icon={FileText} size={20} />
                                  <span
                                    style={{ marginLeft: 8 }}
                                    data-testid={
                                      headerTestIDs.mobile_account_vault_link
                                    }
                                  >
                                    {t('nav.documentVault')}
                                  </span>
                                </DropdownItem>
                              </FeatureFlag>
                              <FeatureFlag pathKey="/settings/program">
                                <DropdownItem
                                  as={NavLink}
                                  to="/settings/program"
                                  onClick={() =>
                                    handleProfileClick(
                                      'Account Settings',
                                      '/account/settings'
                                    )
                                  }
                                >
                                  <Icon icon={Settings} size={20} />
                                  <span
                                    style={{ marginLeft: 8 }}
                                    data-testid={
                                      headerTestIDs.account_settings_link
                                    }
                                  >
                                    {t('nav.accountSettings')}
                                  </span>
                                </DropdownItem>
                              </FeatureFlag>
                              <FeatureFlag pathKey="/change-password">
                                <DropdownItem
                                  as={NavLink}
                                  onClick={() => {
                                    handleProfileClick(
                                      'Change Password',
                                      '/account/change-password'
                                    );
                                    openChangePasswordModal();
                                  }}
                                >
                                  <Icon icon={Lock} size={20} />
                                  <span
                                    style={{ marginLeft: 8 }}
                                    data-testid={
                                      headerTestIDs.account_change_password
                                    }
                                  >
                                    {t('nav.changePassword')}
                                  </span>
                                </DropdownItem>
                              </FeatureFlag>
                            </>
                          )}
                          <DropdownItem
                            onClick={() => {
                              hideProfile();
                              analyticsTrackEvent(
                                {
                                  event_action: EVENT_ACTION.DROPDOWN_SELECT,
                                  nav_link_section: 'Header',
                                  click_type: 'Link Click',
                                  click_id: 'Profile: Logout',
                                  click_url: 'https://' + domain + '/login',
                                  clickText: 'Profile: Logout',
                                  track_event: 'global_header'
                                },
                                'Profile: Logout Link Click'
                              );
                              logout();
                            }}
                            css={`
                              cursor: pointer;
                            `}
                          >
                            <Icon icon={LogOut} size={20} />
                            <span
                              style={{ marginLeft: 8 }}
                              data-testid={headerTestIDs.account_logout_link}
                            >
                              {t('nav.logout')}
                            </span>
                          </DropdownItem>
                          <FeatureFlag flagKey="nav.spanish-toggle">
                            <DropdownSeparator />
                            <LanguageToggle
                              language={locale}
                              changeLocale={changeLocale}
                            />
                          </FeatureFlag>
                        </>
                      }
                      placement="bottom-end"
                      css={`
                        width: 500px !important;
                      `}
                    >
                      <Box
                        height={44}
                        marginLeft={20}
                        marginRight={0}
                        marginTop={20}
                        onClick={() =>
                          profileVisible ? hideProfile() : showProfile()
                        }
                      >
                        <Badge
                          count={badgeTotal}
                          id="tour-trigger-profile-mobile"
                        >
                          <Icon
                            icon={User}
                            css={`
                              color: ${props => props.theme.colors.black};
                            `}
                            size={24}
                          />
                        </Badge>
                      </Box>
                    </Dropdown>
                  </div>
                </AccountInfo>
              </>
            )}
          </>
        )}
      </Composition>
    </>
  );
}
