import { useContext } from 'react';
import { es, enUS } from 'date-fns/locale';

import i18n from 'lang/i18n';
import LocaleContext from 'lang/LocaleContext';
import updateFirestoreUserRecord from 'lib/api/updateFirestoreUserRecord';
import { useAppData } from './useAppData';

export function useLocale() {
  const { fetchUserRecord } = useAppData();
  const { locale } = useContext(LocaleContext);

  const changeLocale = async (language, shouldSet = true) => {
    if (locale !== language) {
      i18n.changeLanguage(language);
      if (shouldSet) {
        // Persist the change to Firestore
        await updateFirestoreUserRecord({ language });
        // Update the user record in the Redux store
        fetchUserRecord();
      }
    }
  };

  // for the sake of Contentful and any libraries or components that expect
  // the locale to be either 'es' or 'en-US', such as the language toggle component...

  // in the case of a spanish locale that is not 'es', such as 'es-MX' we
  // change locale to 'es'

  // in the case of a non english or spanish locale, such as 'uk' we change to 'en-US'

  if (locale !== 'es' && locale.includes('es')) {
    changeLocale('es', false);
  } else if (locale !== 'en-US' && locale !== 'es') {
    changeLocale('en-US', false);
  }

  const dateFnsLocale = locale === 'es' ? es : enUS;

  return {
    changeLocale,
    locale,
    dateFnsLocale
  };
}
