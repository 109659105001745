import React from 'react';
import { is, isEmpty } from 'ramda';
import { useSiteData } from 'lib/hooks';
import { NotFound } from 'shared-pages/common';

export function buildRouteObject(routes) {
  return Object.entries(routes).reduce((acc, [path, enabledOrChildren]) => {
    if (enabledOrChildren === false) {
      return acc.concat({ path, element: <NotFound /> });
    }
    if (is(Object, enabledOrChildren)) {
      const children = buildRouteObject(enabledOrChildren);
      if (isEmpty(children)) {
        return acc;
      }
      return acc.concat({ path, element: <NotFound />, children });
    }
    return acc;
  }, []);
}

export function useDisabledRouteObject() {
  const { routes } = useSiteData();
  return buildRouteObject(routes);
}
