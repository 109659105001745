import { createSelector } from 'reselect';

export const getUserRecord = state => state?.userRecord;

export const getLawFirmId = createSelector(
  getUserRecord,
  userRecord => userRecord.lawFirmId
);

export const getUserEduData = createSelector(getUserRecord, userRecord =>
  Object.keys(userRecord).length > 0 ? userRecord?.clientEdu : null
);

export const getFirstName = createSelector(
  getUserRecord,
  userRecord => userRecord.firstName
);

export const getProductTourData = createSelector(getUserRecord, userRecord =>
  Object.keys(userRecord).length > 0 ? userRecord?.productTour : null
);

export const getPtS = createSelector(getUserRecord, userRecord =>
  Object.keys(userRecord).length > 0 ? userRecord?.pts : null
);
