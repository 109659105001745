import React from 'react';
import i18next from 'i18next';
import { AlertTriangle } from 'react-feather';
import { AlertCardTitle } from '../AlertCard/AlertCardTitle';
import { AlertCardParagraph } from '../AlertCard/AlertCardContent';
import { ContactUsComponent } from '../AlertCard/ContactUsComponent';

const { t } = i18next;

/**
 * Converts a date string into a more readable format using the specified locale and options.
 * @param {string} dateStr - The date string to be converted.
 * @returns {string} - The formatted date string.
 */
const getTranslatedDate = dateStr => {
  const date = new Date(dateStr);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  };
  return date.toLocaleDateString('en-US', options);
};

const ALERT_CARD_MAP = {
  //Not for MVP. just placeholder
  failed_deposit: {
    id: 'failed_deposit',
    icon: <AlertTriangle size={24} />,
    title: () => (
      <AlertCardTitle>{t('pts.depositReminderTitle')}</AlertCardTitle>
    ),
    content: () => (
      <AlertCardParagraph>{t('pts.depositReminderContent')}</AlertCardParagraph>
    )
  },
  // title render function has conditional logic to fail gracefully if the date is unavailable
  deposit_reminder: {
    id: 'deposit_reminder',
    icon: <AlertTriangle size={24} />,
    title: metadata => {
      const date = getTranslatedDate(metadata?.nextScheduledDraftDate);
      return (
        <AlertCardTitle>
          {date !== 'Invalid Date'
            ? t('pts.depositReminderTitle', {
                date
              })
            : t('pts.depositReminderTitleNoDate')}
        </AlertCardTitle>
      );
    },
    content: () => (
      <AlertCardParagraph>{t('pts.depositReminderContent')}</AlertCardParagraph>
    ),
    contactUs: () => (
      <ContactUsComponent>{t('pts.haveAQuestion')}</ContactUsComponent>
    )
  }
};

/**
 * Retrieves and constructs the content, title, and contact information for a specified alert card type using predefined configurations.
 * @param {string} cardType - The type of the alert card.
 * @param {object} metadata - Additional data needed for generating specific content, such as dates.
 * @returns {object|null} - An object containing the constructed content, title, and contact information for the specified alert card type, or null if the card type is not recognized.
 */
export function getAlertCardContent(cardType, metadata) {
  const cardTypes = Object.keys(ALERT_CARD_MAP);

  if (!cardTypes.includes(cardType)) {
    return null;
  }

  const cardData = ALERT_CARD_MAP[cardType];
  const content = cardData.content(metadata);
  const title = cardData.title(metadata);
  const contactUs = cardData.contactUs(metadata);

  return {
    ...cardData,
    content,
    title,
    contactUs
  };
}
