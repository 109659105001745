import React from 'react';
import 'styled-components/macro';
import { EduDrawer } from './EduDrawer';
import { useClientEducation } from 'lib/hooks';

export const EduWizard = () => {
  const { userShouldSeeEduModule } = useClientEducation();

  if (!userShouldSeeEduModule) return null;

  return <EduDrawer />;
};
