import React, { Suspense } from 'react';
import { PageLayout } from 'shared-components';
import { OnlineServices } from './components/OnlineServices';
import { ContactUs } from './components/ContactUs';
import { EmailUs } from './components/EmailUs';
import { MailOrFaxUs } from './components/MailOrFaxUs';
import { FeatureFlag, FallBackLoader } from 'shared-components';
import { useTranslation } from 'lib/hooks';

export function CustomerService() {
  const { t } = useTranslation();
  return (
    <Suspense fallback={<FallBackLoader isPage />}>
      <PageLayout
        pageTitle={t('nav.customerService')}
        sidebarFeatures={['related-video', 'key-resources', 'related-article']}
      >
        <ContactUs />
        <FeatureFlag flagKey="customer-service.online-services">
          <OnlineServices />
        </FeatureFlag>
        <FeatureFlag flagKey="customer-service.email-us">
          <EmailUs />
        </FeatureFlag>
        <FeatureFlag flagKey="customer-service.mail-or-fax-us">
          <MailOrFaxUs />
        </FeatureFlag>
      </PageLayout>
    </Suspense>
  );
}

export default CustomerService;
