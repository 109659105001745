import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { shortDateWithLongYear } from 'lib/utils';
import { useNotificationTracking } from 'lib/hooks';
import { useTranslation } from 'lib/hooks';

import { Icon, Row, Column } from '@ffn/ui';
import { ArrowDown, ArrowUp } from '@ffn/icons';

import { Currency, CustomerServiceHours } from 'shared-components';
import RedraftDeposit from 'shared-components/Deposits/RedraftForm';

const Message = styled.p`
  font-weight: bold;
`;

const ContentBox = styled.div`
  padding: 16px;
  background-color: white;
`;

const PlainBox = styled.div`
  background-color: ${props => props.theme.colors.plain};
  padding: 16px;
  padding-top: 0;
`;

export function InsufficientFundsAlert({
  notification,
  viewLocation,
  isVisible
}) {
  const trackNotificationView = useNotificationTracking();
  const { spa, totalAmount, items } = notification?.payload;
  const { t } = useTranslation();

  useEffect(() => {
    trackNotificationView({
      trackingLabel: 'mdrd',
      viewLocation,
      isVisible
    });
  }, [viewLocation, isVisible, trackNotificationView]);

  let message = [
    <Message key={1}>
      {t('alerts.insufficientFunds.dueToRecentFailedOrSuspendedDeposits')}{' '}
      <Currency value={totalAmount} />{' '}
      {t('alerts.insufficientFunds.lowerThanExpectedAsAResultThereMay')}
    </Message>
  ];

  // Control whether we display the bank account change form or messaging about how to contact customer service
  // GCS accounts (as of 01/2022) are being phased out and require help from CS agents
  const requiresCustomerServiceIntervention = spa === 'GCS';

  if (requiresCustomerServiceIntervention) {
    return (
      <>
        <p>{t('alerts.insufficientFunds.toGetYourProgramBackOnTrack')}</p>
        <CustomerServiceHours />
      </>
    );
  }

  return (
    <PlainBox>
      {message}
      <ContentBox>
        <CollapsibleItemizedDeposits deposits={items} total={totalAmount} />
        <RedraftDeposit initialAmount={totalAmount} />
      </ContentBox>
    </PlainBox>
  );
}

const StyledDepositsBox = styled.div`
  margin-top: ${props => (props.isOpen ? '16px' : 0)};
  padding: 16px;
  padding-bottom: ${props => (props.isOpen ? '16px' : 0)};
  padding-top: 0;
  margin-bottom: 24px;
  max-height: ${props => (props.isOpen ? 'fit-content' : '0px')};
  overflow: hidden;
  border-bottom: 1px solid #f2f2f2;
  transition: max-height 0.3s ease-in-out;
`;
const HeaderContainer = styled.div`
  background-color: white;
  font-size: 18px;
  border-top: 1px solid #f2f2f2;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: bold;
    margin-left: 24px;
  }
`;
const IconContainer = styled.div`
  color: ${props => props.theme.colors.tertiaryBlueLight};
`;
const FinalRow = styled(Row)`
  margin-top: 8px;
  border-top: 1px solid ${props => props.theme.colors.grayLight};
  padding-top: 8px;
`;
const BoldedColumn = styled(Column)`
  font-weight: bold;
`;
const TableRow = styled(Row)`
  padding: 4px;
  ${props => css`
    &:nth-child(even) {
      background: ${props.theme.colors.plain};
    }

    &:nth-child(odd) {
      background: ${props.theme.colors.grayLight};
    }
  `}}
`;

const CollapsibleItemizedDeposits = ({ deposits, total }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <HeaderContainer>
        <div>{t('alerts.insufficientFunds.detailsOnProgramDeficit')}</div>
        <IconContainer>
          <Icon
            icon={isOpen ? ArrowUp : ArrowDown}
            size={20}
            onClick={isOpen ? () => setIsOpen(false) : () => setIsOpen(true)}
            data-testid="deficitDetailsFoldingArrow"
          />
        </IconContainer>
      </HeaderContainer>

      <StyledDepositsBox isOpen={isOpen} data-testid="ProgramDeficitTable">
        {deposits.map(({ status, amount, date }, i) => {
          const formattedDate = shortDateWithLongYear(date);
          return (
            <TableRow key={i}>
              <BoldedColumn cols={4}>
                {t('alerts.insufficientFunds', status)}
              </BoldedColumn>
              <BoldedColumn cols={4}>
                <Currency value={amount}></Currency>
              </BoldedColumn>
              <BoldedColumn cols={4}>{formattedDate}</BoldedColumn>
            </TableRow>
          );
        })}
        <FinalRow>
          <BoldedColumn cols={4}>
            {t('alerts.insufficientFunds.total')}
          </BoldedColumn>
          <BoldedColumn cols={6}>
            <Currency value={total}></Currency>
          </BoldedColumn>
        </FinalRow>
      </StyledDepositsBox>
    </>
  );
};
