import axios from 'axios';
import getApiUrl from 'lib/utils/getApiUrl';

export const getLeadDocUploadUserByOobCode = async config => {
  const uri = _buildApiURI();
  let url = String(uri);
  const headers = await _buildHeaders(config.oobCode);
  const { data } = await axios.post(`${url}`, {}, { headers });

  if (!data) return null;
  return data;
};

export const _buildApiURI = () => {
  const apiUrl = getApiUrl();
  return `${apiUrl}/lead/validate`;
};

const _buildHeaders = async oobCode => {
  const headers = {
    'Content-Type': 'application/json',
    'x-oob-code': oobCode
  };

  return headers;
};
