import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  useAuth,
  useChangePasswordModal,
  useLocale,
  useTranslation
} from 'lib/hooks';
import { Box, Composition, Only } from 'atomic-layout';
import 'styled-components/macro';

import {
  Badge,
  Container,
  Dropdown,
  DropdownItem,
  DropdownSeparator,
  Icon
} from '@ffn/ui';
import {
  BrandLogo,
  FeatureFlag,
  LanguageToggle,
  SiteNoticeBanner
} from 'shared-components';
import { DesktopNavItems } from './DesktopNavItems';
import { getTestIds } from 'lib/utils/dataTestIds';
import { MobileNav } from './MobileNav';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { EVENT_ACTION } from 'lib/utils/eventHorizon';
import {
  ArrowLeft,
  Bell,
  FileText,
  Lock,
  LogOut,
  Settings,
  User
} from 'react-feather';

import styles from './Nav.module.scss';
import { ReturnToMemberDashboardMenuItem } from './ReturnToMemberDashboardMenuItem';
import { modelsStatus } from 'shared-selectors/dataStatus';

const headerTestIDs = getTestIds('header');
const mobileTemplate = `
  menus brand account
`;
const desktopTemplate = `
  brand menus account
`;

function DesktopNav({ showMenuItems }) {
  const { t } = useTranslation();
  const { changeLocale, locale } = useLocale();
  const userAccount = useSelector(state => state.userRecord);
  const { isAuthenticated, logout } = useAuth();
  const firstName = userAccount?.firstName;
  const notifications = useSelector(state => state.models.notifications);
  const badgeTotal = notifications?.totalAlerts + notifications?.totalMessages;
  const domain = document?.domain ?? '';
  const { openChangePasswordModal } = useChangePasswordModal();

  const handleProfileClick = (profileItemName, path) => {
    analyticsTrackEvent(
      {
        event_action: EVENT_ACTION.DROPDOWN_SELECT,
        nav_link_section: 'Header',
        click_type: 'Link Click',
        click_id: `Profile: ${profileItemName}`,
        click_url: 'https://' + domain + path,
        clickText: `Profile: ${profileItemName}`,
        track_event: 'global_header'
      },
      `Profile: ${profileItemName} Link Click`
    );
  };
  return (
    <>
      <Composition
        as={Container}
        areas={mobileTemplate}
        areasLg={desktopTemplate}
        templateCols="50px 1fr 50px"
        templateRows="64px"
        templateColsLg="min-content max-content 1fr"
        templateRowsLg="80px"
        alignItems="center"
        data-tour="navigation-menu"
      >
        {({ Menus, Brand, Account }) => (
          <>
            <Brand flex justifyContent="center" marginRight={16}>
              <NavLink
                to="/"
                className={styles.logoWrapper}
                data-testid={headerTestIDs.brand_home_link}
              >
                <BrandLogo isDesktop />
              </NavLink>
            </Brand>
            {isAuthenticated && (
              <>
                <Menus>
                  <Only from="lg" flex>
                    {showMenuItems && <DesktopNavItems />}
                  </Only>
                </Menus>
                <Account justify="end">
                  {firstName && (
                    <div id="tour-trigger-profile-dropdown">
                      <Dropdown
                        content={
                          <>
                            <FeatureFlag flagKey="nav.return-to-member-dashboard">
                              <DropdownItem
                                as={ReturnToMemberDashboardMenuItem}
                                to="/dashboard/products"
                                onClick={() => {
                                  analyticsTrackEvent(
                                    {
                                      event_action:
                                        EVENT_ACTION.DROPDOWN_SELECT,
                                      nav_link_section: 'Header',
                                      click_type: 'Link Click',
                                      click_id:
                                        'Profile: Return to Member Dashboard',
                                      click_url:
                                        'https://' +
                                        domain +
                                        '/dashboard/products',
                                      clickText:
                                        'Profile: Return to Member Dashboard', // ex. Profile: Document Vault
                                      track_event: 'global_header'
                                    },
                                    'Profile: Return to Member Dashboard Link Click'
                                  );
                                }}
                              >
                                <Icon icon={ArrowLeft} size={20} />
                                <span
                                  style={{ marginLeft: 8 }}
                                  data-testid={
                                    headerTestIDs.account_return_to_member_dashboard_link
                                  }
                                >
                                  {t('nav.returnToMemberDashboard')}
                                </span>
                              </DropdownItem>
                            </FeatureFlag>
                            <FeatureFlag flagKey="nav.return-to-member-dashboard">
                              <DropdownSeparator />
                            </FeatureFlag>
                            {showMenuItems && (
                              <>
                                <FeatureFlag pathKey="/notifications">
                                  <DropdownItem
                                    as={NavLink}
                                    to="/notifications"
                                    data-tour="notifications"
                                    onClick={() =>
                                      handleProfileClick(
                                        'Notifications',
                                        '/notifications'
                                      )
                                    }
                                  >
                                    <Badge count={badgeTotal} size={20}>
                                      <Icon icon={Bell} />
                                    </Badge>
                                    <span
                                      style={{ marginLeft: 8 }}
                                      data-testid={
                                        headerTestIDs.account_notifications_link
                                      }
                                    >
                                      {t('nav.notifications')}
                                    </span>
                                  </DropdownItem>
                                </FeatureFlag>
                                <FeatureFlag pathKey="/document-vault">
                                  <DropdownItem
                                    as={NavLink}
                                    to="/document-vault"
                                    onClick={() =>
                                      handleProfileClick(
                                        'Document Vault',
                                        '/document-vault'
                                      )
                                    }
                                  >
                                    <Icon icon={FileText} size={20} />
                                    <span
                                      style={{ marginLeft: 8 }}
                                      data-testid={
                                        headerTestIDs.account_vault_link
                                      }
                                    >
                                      {t('nav.documentVault')}
                                    </span>
                                  </DropdownItem>
                                </FeatureFlag>
                                <FeatureFlag pathKey="/settings/program">
                                  <DropdownItem
                                    as={NavLink}
                                    to="/settings/program"
                                    onClick={() =>
                                      handleProfileClick(
                                        'Account Settings',
                                        '/account/settings'
                                      )
                                    }
                                  >
                                    <Icon icon={Settings} size={20} />
                                    <span
                                      style={{ marginLeft: 8 }}
                                      data-testid={
                                        headerTestIDs.account_settings_link
                                      }
                                    >
                                      {t('nav.accountSettings')}
                                    </span>
                                  </DropdownItem>
                                </FeatureFlag>
                                <FeatureFlag pathKey="/change-password">
                                  <DropdownItem
                                    as={NavLink}
                                    onClick={() => {
                                      handleProfileClick(
                                        'Change Password',
                                        '/account/change-password'
                                      );
                                      openChangePasswordModal();
                                    }}
                                  >
                                    <Icon icon={Lock} size={20} />
                                    <span
                                      style={{ marginLeft: 8 }}
                                      data-testid={
                                        headerTestIDs.account_change_password
                                      }
                                    >
                                      {t('nav.changePassword')}
                                    </span>
                                  </DropdownItem>
                                </FeatureFlag>
                              </>
                            )}
                            <DropdownItem
                              onClick={() => {
                                analyticsTrackEvent(
                                  {
                                    event_action: EVENT_ACTION.DROPDOWN_SELECT,
                                    nav_link_section: 'Header',
                                    click_type: 'Link Click',
                                    click_id: 'Profile: Logout',
                                    click_url: 'https://' + domain + '/login',
                                    clickText: 'Profile: Logout',
                                    track_event: 'global_header'
                                  },
                                  'Profile: Logout Link Click'
                                );
                                logout();
                              }}
                              css={`
                                cursor: pointer;
                              `}
                            >
                              <Icon icon={LogOut} size={20} />
                              <span
                                style={{ marginLeft: 8 }}
                                data-testid={headerTestIDs.account_logout_link}
                              >
                                {t('nav.logout')}
                              </span>
                            </DropdownItem>
                            <FeatureFlag flagKey="nav.spanish-toggle">
                              <DropdownSeparator />
                              <LanguageToggle
                                language={locale}
                                changeLocale={changeLocale}
                              />
                            </FeatureFlag>
                          </>
                        }
                        placement="bottom-end"
                      >
                        <Box
                          flex
                          alignItems="center"
                          css={`
                            cursor: pointer;
                          `}
                          height="100%"
                        >
                          <Badge count={badgeTotal}>
                            <Icon
                              icon={User}
                              isDesktop={30}
                              css={`
                                color: ${props => props.theme.colors.black};
                              `}
                            />
                          </Badge>
                          <span
                            css={`
                              font-isdesktop: 17px;
                              font-weight: 700;
                              margin-left: 8px;
                              color: ${({ theme }) => theme.colors.black};
                            `}
                            data-testid={headerTestIDs.account_menu_button}
                          >
                            {firstName}
                          </span>
                        </Box>
                      </Dropdown>
                    </div>
                  )}
                </Account>
              </>
            )}
          </>
        )}
      </Composition>
    </>
  );
}

export function Nav() {
  const { isAuthenticated } = useAuth();
  const modelsLoadingStatus = useSelector(modelsStatus);
  const MODELS_LOADED = modelsLoadingStatus === 'success';

  const SHOW_MENU_ITEMS = isAuthenticated && MODELS_LOADED;

  return (
    <>
      <Only from="xs" to="lg">
        <MobileNav showMenuItems={SHOW_MENU_ITEMS} />
      </Only>
      <Only from="lg">
        <DesktopNav showMenuItems={SHOW_MENU_ITEMS} />
      </Only>
      <SiteNoticeBanner />
    </>
  );
}
