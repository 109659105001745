import React from 'react';
import { Box } from 'atomic-layout';
import 'styled-components/macro';
import ReadyToUploadComponent from '../shared-components/ReadyToUploadComponent';
import { DashSelect, DashDatePicker } from 'shared-components';
import { useTranslation } from 'lib/hooks';

function AdditionalMetadataFields({
  fileIndex,
  currentFile: { metadata },
  showReadyToUpload,
  isSummonsAndComplaints,
  fileWasRejected,
  isSubmitting,
  fileWasSubmitted,
  sfDidNotUpdate
}) {
  const { t } = useTranslation();

  const alllMetadataIsCompleted =
    showReadyToUpload && metadata.methodOfService && metadata.dateOfService;
  return (
    <>
      <Box>
        <DashSelect
          id={`files.${fileIndex}.metadata.methodOfService`}
          name={`files.${fileIndex}.metadata.methodOfService`}
          label="Method of Service"
          disabled={isSubmitting || fileWasSubmitted}
          style={{ paddingRight: '32px' }}
        >
          {!metadata.methodOfService && (
            <option>{t('docUpload.selectMethodOfService')}</option>
          )}
          <option>{t('docUpload.mail')}</option>
          <option>{t('docUpload.inPerson')}</option>
          <option>{t('docUpload.leftAtDoor')}</option>
          <option>{t('docUpload.leftWithFriendFamily')}</option>
        </DashSelect>
        {alllMetadataIsCompleted &&
        isSummonsAndComplaints &&
        !fileWasSubmitted ? (
          <ReadyToUploadComponent
            sfDidNotUpdate={sfDidNotUpdate}
            fileWasRejected={fileWasRejected}
            from="md"
          />
        ) : null}
      </Box>
      <Box>
        <DashDatePicker
          placeholder="Select a date"
          id={`files.${fileIndex}.metadata.dateOfService`}
          name={`files.${fileIndex}.metadata.dateOfService`}
          label="Date of Service"
          maxDate={new Date()}
          excludeOutOfBoundsTimes
          disabled={isSubmitting || fileWasSubmitted}
        />
        {alllMetadataIsCompleted &&
        isSummonsAndComplaints &&
        !fileWasSubmitted ? (
          <ReadyToUploadComponent
            sfDidNotUpdate={sfDidNotUpdate}
            fileWasRejected={fileWasRejected}
            to="md"
          />
        ) : null}
      </Box>
    </>
  );
}

export default AdditionalMetadataFields;
